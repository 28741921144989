import React from 'react';
import {useQuery} from '@apollo/client';

import {ErrorCard} from '../../../error-card';
import {Loading} from '../../../loading';
import {ReportingPeriodContext} from '../../reporting-period';
import {GetProfileVehicleDocument} from '../graphql';
import {EditProfileVehiclesContextProvider} from './context';
import {EditProfileVehiclesWizard} from './wizard';
import {ProfileVehiclesFragmentDocument} from '../fragments';
import {useFragment} from '../../../../graphql/generated';

export function OperationsEditVehiclesSection() {
    const reportingPeriod = React.useContext(ReportingPeriodContext);
    const {
        loading,
        error,
        data: res,
    } = useQuery(GetProfileVehicleDocument, {
        variables: {companyReportingPeriodId: reportingPeriod.id},
    });

    const data = useFragment(ProfileVehiclesFragmentDocument, res?.getProfileVehicle);

    return (
        <div>
            {loading && <Loading />}
            {error && <ErrorCard />}
            {data && (
                <EditProfileVehiclesContextProvider answers={data}>
                    <EditProfileVehiclesWizard />
                </EditProfileVehiclesContextProvider>
            )}
        </div>
    );
}
