import React from 'react';

import {VStack} from '@sphericsio/design-system';

import {ProfileVehiclesContext} from './context';
import {ProfileSectionPanel} from '../section-panel';

export function ProfileVehiclesIntro() {
    const ctx = React.useContext(ProfileVehiclesContext);
    return (
        <VStack>
            <ProfileSectionPanel
                heading="Vehicles"
                type="pending"
                reportingPeriod={ctx.reportingPeriod}
                onPressStart={() => ctx.dispatch({type: 'start'})}
            >
                We need you to tell us about the petrol and diesel you used for business travel
                during this reporting period.
            </ProfileSectionPanel>
        </VStack>
    );
}
