import React from 'react';
import {useMutation} from '@apollo/client';

import {TextButton, DetailText, VStack} from '@sphericsio/design-system';

import {PageHeader} from '../components/page-header';
import {ErrorCard} from '../components/error-card';
import {Loading} from '../components/loading';
import {graphql} from '../graphql/generated';
import {HSplitLayout} from '../components/layout/h-split';
import {PosterGetReady} from '../components/onboarding/poster-get-ready';
import {PosterLayout} from '../components/onboarding/poster-layout';

const ResendEmailVerificationDocument = graphql(/* GraphQL */ `
    mutation ResendEmailVerification {
        resendEmailVerification
    }
`);

export function Unverified() {
    const [submit, {loading, error, data}] = useMutation(ResendEmailVerificationDocument);

    return (
        <HSplitLayout cta="login">
            <VStack>
                <PageHeader
                    title="We need to verify your email"
                    subtitle="Please follow the link sent to your email to start using Sage Earth."
                />
                <VStack>
                    <DetailText inline={false}>
                        Didn&apos;t get an email?{' '}
                        <TextButton noPadding onPress={() => submit()}>
                            Click here
                        </TextButton>{' '}
                        to resend.
                    </DetailText>
                    {loading && <Loading />}
                    {error && <ErrorCard />}
                    {data && (
                        <div className="border-l-2 pl-5">
                            <DetailText>Your email is on its way.</DetailText>
                        </div>
                    )}
                </VStack>
            </VStack>
            <PosterLayout>
                <PosterGetReady />
            </PosterLayout>
        </HSplitLayout>
    );
}
