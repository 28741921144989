import React, {PropsWithChildren} from 'react';
import {Link} from 'react-router-dom';
import classnames from 'classnames';

import {
    bgColorClassnames,
    ButtonLabel,
    DetailText,
    Heading2,
    HStack,
    Link as TextLink,
    LoadingSpinner,
    textColorClassnames,
    VStack,
} from '@sphericsio/design-system';

import {ButtonLink} from '../link';
import {ErrorCard} from '../error-card';
import {PosterLayout} from '../onboarding/poster-layout';
import {SageEarthLogo} from '../logo';

type Cta = 'signup' | 'login' | 'logout';
type HSplitLayoutProps = {
    cta?: Cta;
    wide?: boolean;
    children: React.ReactNode;
};

export function HSplitLayout(props: HSplitLayoutProps) {
    const content = React.Children.toArray(props.children);

    return (
        <div className="flex flex-1">
            <LightSection {...props}>{content[0]}</LightSection>
            <DarkSection {...props}>{content[1]}</DarkSection>
        </div>
    );
}

function LightSection({children, wide}: Pick<HSplitLayoutProps, 'wide' | 'children'>) {
    return (
        <div className="flex w-7/12 flex-col">
            <div className={`flex w-full ${bgColorClassnames('black')} py-3.5`}>
                <div className="w-44 pl-8">
                    <Link to="/">
                        <SageEarthLogo />
                    </Link>
                </div>
            </div>

            <div className="flex flex-1 items-center p-10 px-28">
                <div className={classnames('w-full h-full flex items-center', {'max-w-md': !wide})}>
                    {children}
                </div>
            </div>
        </div>
    );
}

function DarkSection({children, cta}: Pick<HSplitLayoutProps, 'cta' | 'children'>) {
    const generateButtonText = (cta: Cta) => {
        switch (cta) {
            case 'signup':
                return 'Sign up';
            case 'login':
                return 'Login';
            case 'logout':
                return 'Logout';
        }
    };
    return (
        <div
            className={`flex flex-col fixed h-screen w-5/12 right-0 ${bgColorClassnames(
                'minor-action',
            )} ${textColorClassnames('white')} overflow-hidden`}
            style={{
                backgroundRepeat: 'no-repeat',
                backgroundSize: '35%, 7%, 30%',
                backgroundPosition: 'left top, right 30%, left bottom',
                backgroundColor: '#000000',
            }}
        >
            <div className=" flex justify-end items-center space-x-10 px-5 pt-5">
                <DetailText inherit>
                    <TextLink
                        href={'https://help.earth.sage.com/en'}
                        uppercase={false}
                        openInNewWindow
                        invert
                    >
                        <ButtonLabel>FAQs</ButtonLabel>
                    </TextLink>
                </DetailText>
                <DetailText inherit>
                    <TextLink
                        href={'https://www.earth.sage.com/'}
                        uppercase={false}
                        openInNewWindow
                        invert
                    >
                        <ButtonLabel>earth.sage.com</ButtonLabel>
                    </TextLink>
                </DetailText>
                {cta && (
                    <DetailText inherit>
                        <ButtonLink outline to={`/${cta}`} color="white">
                            {generateButtonText(cta)}
                        </ButtonLink>
                    </DetailText>
                )}
            </div>
            <div className="flex flex-1 py-15">{children}</div>
        </div>
    );
}

export function HSplitLoading({
    children,
    error,
    errorMessage,
    callToAction,
    text,
}: PropsWithChildren<{
    text?: string;
    error?: Error;
    errorMessage?: string;
    callToAction?: Cta;
}>) {
    return (
        <HSplitLayout cta={callToAction}>
            <VStack>
                {error === undefined && errorMessage === undefined ? (
                    <HStack>
                        <LoadingSpinner size={40} />
                        {text && <Heading2>{text}</Heading2>}
                    </HStack>
                ) : (
                    <ErrorCard message={errorMessage} error={error} />
                )}
            </VStack>
            <PosterLayout>{children}</PosterLayout>
        </HSplitLayout>
    );
}
