import {graphql} from '../../graphql/generated';

export const GetCategorisationHierarchyDocument = graphql(/* GraphQL */ `
    query getCategorisationHierarchy {
        getCategorisationHierarchy {
            key
            name
            children {
                key
                name
                children {
                    key
                    name
                }
            }
        }
    }
`);

export const SetUserCategorisationDocument = graphql(/* GraphQL */ `
    mutation setUserCategorisation($id: ID!, $taxonomyKey: String!, $taxonomyTermKey: String!) {
        setUserCategorisation(
            id: $id
            taxonomyKey: $taxonomyKey
            taxonomyTermKey: $taxonomyTermKey
        ) {
            id
        }
    }
`);

export const VerifyMerchantCategorisationByCompanyDocument = graphql(/* GraphQL */ `
    mutation verifyMerchantCategorisationByCompany(
        $merchantId: String!
        $taxonomyKey: String!
        $taxonomyTermKey: String!
    ) {
        verifyMerchantCategorisationByCompany(
            merchantId: $merchantId
            taxonomyKey: $taxonomyKey
            taxonomyTermKey: $taxonomyTermKey
        ) {
            isTransactionUserCategorisationDifferent
        }
    }
`);

export const SetCompanyMerchantCategorisationDocument = graphql(/* GraphQL */ `
    mutation setCompanyMerchantCategorisation(
        $id: ID!
        $taxonomyKey: String!
        $taxonomyTermKey: String!
    ) {
        setCompanyMerchantCategorisation(
            id: $id
            taxonomyKey: $taxonomyKey
            taxonomyTermKey: $taxonomyTermKey
        ) {
            id
        }
    }
`);
