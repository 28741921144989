import React from 'react';
import {useMutation} from '@apollo/client';

import {SetUserCategorisationDocument} from '../../../graphql/generated/graphql';
import {useBackgroundExecutionStatus} from '../../../hooks/background-execution';
import {UserCategorisationHierarchyItem} from '../hierarchy';
import {SaveStatus} from '../categorisation';

function useSaveCategorisation() {
    const [save, {loading, error, data}] = useMutation(SetUserCategorisationDocument);
    const poll = useBackgroundExecutionStatus();
    const backgroundExecutionId = React.useMemo(() => {
        return data?.setUserCategorisation.id;
    }, [data]);

    React.useEffect(() => {
        if (backgroundExecutionId != null && !poll.called) {
            poll.startPolling({id: backgroundExecutionId});
        }
    }, [backgroundExecutionId, poll]);

    if (poll.called) {
        return [
            save,
            {
                loading: poll.loading || !poll.finished,
                error: poll.error,
                data: poll.data,
                finished: poll.finished,
            },
        ] as const;
    } else {
        return [save, {loading, error, data, finished: false}] as const;
    }
}
type SaveCategorisationProps = {
    id: string;
    selected: UserCategorisationHierarchyItem | null;
    status: SaveStatus;
    onStatusChange: (status: SaveStatus) => void;
    children?: React.ReactNode;
};
export function SaveCategorisation({
    id,
    selected,
    status,
    onStatusChange,
    children,
}: SaveCategorisationProps) {
    const [save, {loading, error, finished}] = useSaveCategorisation();

    React.useEffect(() => {
        if (selected && status === 'pending') {
            save({
                variables: {
                    id,
                    taxonomyKey: selected?.taxonomyKey,
                    taxonomyTermKey: selected?.taxonomyTermKey,
                },
            });
            onStatusChange('saving');
        }
    }, [selected]);

    React.useEffect(() => {
        if (finished && !error) {
            onStatusChange('success');
        }
        if (loading && status !== 'saving') {
            onStatusChange('saving');
        }
        if (error || (finished && error)) {
            onStatusChange('error');
        }
    }, [loading, error, finished]);

    return <div className="relative">{children}</div>;
}
