import React from 'react';

import {DetailText, VStack} from '@sphericsio/design-system';

import {HSplitLayout} from '../components/layout/h-split';
import {PosterLayout} from '../components/onboarding/poster-layout';
import {PageHeader} from '../components/page-header';
import {PosterDisconnected} from '../components/onboarding/poster-disconnected';

export function SorryToSeeYouGo() {
    return (
        <HSplitLayout cta="logout">
            <VStack full>
                <PageHeader title="We’re sorry to see you go." />
                <DetailText bold>Your subscription has been cancelled.</DetailText>
                <DetailText>
                    You will no longer have access to the product, your account, or your carbon
                    footprint.
                </DetailText>
            </VStack>
            <PosterLayout>
                <PosterDisconnected />
            </PosterLayout>
        </HSplitLayout>
    );
}
