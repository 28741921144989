import {FragmentType, graphql} from '../../../graphql/generated';

export const CompanyAddressesFragmentDocument = graphql(/* GraphQL */ `
    fragment CompanyAddresses on Company {
        id
        addresses {
            id
            address
            postcode
            building_type {
                id
                label
            }
            created_at
            updated_at
        }
    }
`);
export type CompanyAddressesFragment = FragmentType<typeof CompanyAddressesFragmentDocument>;
