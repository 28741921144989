import React from 'react';
import * as Yup from 'yup';
import {useElements, useStripe} from '@stripe/react-stripe-js';
import {ApolloError, useMutation} from '@apollo/client';

import {
    Button,
    ButtonLabel,
    CircleTick,
    Heading3,
    Heading5,
    HStack,
    Panel,
    textColorClassnames,
    VStack,
} from '@sphericsio/design-system';
import {Form, Input} from '@sphericsio/forms';

import {CardInput} from './card-input';
import {SubscriptionContext} from './context';
import {PromoCodeInput, PromotionDetails} from './promo-code-input';
import {SubscriptionProduct} from '../../graphql/generated/graphql';
import {CreateSubscriptionDocument} from './graphql';
import {Bugsnag} from '../../util/bugsnag';
import {ErrorCard} from '../error-card';
import {VatBreakdown} from './vat-breakdown';

type PackageDetailsProps = {
    product: SubscriptionProduct;
    promotion?: PromotionDetails;
    onEdit: () => void;
};

function PackageDetails({product, promotion, onEdit}: PackageDetailsProps) {
    return (
        <HStack condensed full>
            <div className={`w-5 h-5 ${textColorClassnames('success')} self-start mt-1`}>
                <CircleTick invert />
            </div>
            <VStack full condensed>
                <HStack full>
                    <Heading5>Your package</Heading5>
                    <div className="cursor-pointer" onClick={onEdit}>
                        <ButtonLabel colour="minor-action">Edit</ButtonLabel>
                    </div>
                </HStack>

                <VatBreakdown selectedProduct={product} promotion={promotion} />
            </VStack>
        </HStack>
    );
}

type SubscriptionsErrorMessageProps = {
    error?: ApolloError;
    errorMessage?: string;
};
function SubscriptionsErrorMessage({error, errorMessage}: SubscriptionsErrorMessageProps) {
    if (error == null && errorMessage == null) {
        return null;
    }

    let message = errorMessage;
    if (error != null && error.message === 'INVALID_PROMOTION_CODE') {
        message = 'Your code was invalid.';
    }

    return <ErrorCard message={message} />;
}

const formSchema = Yup.object({
    cardholderName: Yup.string().required("Please enter the cardholder's name"),
    cardInput: Yup.boolean().isTrue('Please enter your card details'),
});

const initialValues = {
    cardholderName: '',
    cardInput: false,
};

type PaymentDetailsProps = {
    onCancel: () => void;
};
export function PaymentDetails({onCancel}: PaymentDetailsProps) {
    const {dispatch, promotion, selectedProduct} = React.useContext(SubscriptionContext);
    const elements = useElements();
    const stripe = useStripe();
    const [stripeLoading, setStripeLoading] = React.useState(false);
    const [createSubscription, {loading, error}] = useMutation(CreateSubscriptionDocument);
    const [errorMessage, setErrorMessage] = React.useState<string>();

    const onSuccess = () => {
        dispatch({type: 'stripeSuccess'});
    };

    return (
        <VStack align="start" large full>
            <Heading3>Payment Details</Heading3>
            <VStack full>
                {selectedProduct && (
                    <PackageDetails
                        product={selectedProduct}
                        promotion={promotion}
                        onEdit={() => dispatch({type: 'selectPackage'})}
                    />
                )}
                <Form
                    initialValues={initialValues}
                    validationSchema={formSchema}
                    onSubmit={async (values) => {
                        if (stripe == null || elements == null || selectedProduct == null) {
                            return;
                        }

                        const cardEl = elements.getElement('card');
                        if (cardEl == null) {
                            return;
                        }
                        setStripeLoading(true);
                        setErrorMessage(undefined);

                        try {
                            const createPaymentMethodResult = await stripe.createPaymentMethod({
                                type: 'card',
                                card: cardEl,
                                billing_details: {
                                    name: values.cardholderName,
                                },
                            });

                            if (createPaymentMethodResult.error) {
                                setErrorMessage(createPaymentMethodResult.error.message);
                                setStripeLoading(false);
                                return;
                            }

                            const {data} = await createSubscription({
                                variables: {
                                    priceId: selectedProduct.priceId,
                                    stripePaymentMethodId:
                                        createPaymentMethodResult.paymentMethod.id,
                                    code: promotion?.id,
                                },
                            });

                            if (data == null) {
                                return;
                            }

                            if (data.createSubscription.clientSecret == null) {
                                onSuccess();
                                return;
                            }

                            let result;
                            if (data.createSubscription.type === 'PaymentIntent') {
                                result = await stripe.confirmCardPayment(
                                    data.createSubscription.clientSecret,
                                    {
                                        payment_method: {
                                            card: cardEl,
                                            billing_details: {
                                                name: values.cardholderName,
                                            },
                                        },
                                    },
                                );
                            } else if (data.createSubscription.type === 'SetupIntent') {
                                result = await stripe.confirmCardSetup(
                                    data.createSubscription.clientSecret,
                                    {
                                        payment_method: {
                                            card: cardEl,
                                            billing_details: {
                                                name: values.cardholderName,
                                            },
                                        },
                                    },
                                );
                            } else {
                                throw new Error(
                                    `unknown SubscriptionCreationResponseType: ${data.createSubscription.type}`,
                                );
                            }

                            if (result.error) {
                                setErrorMessage(result.error.message);
                                setStripeLoading(false);
                            } else {
                                onSuccess();
                            }
                        } catch (err: any) {
                            Bugsnag.notify(err);
                            console.error(err);
                            setErrorMessage(
                                'Sorry, there was an error processing your subscription. Please try again later.',
                            );
                            setStripeLoading(false);
                        }
                    }}
                >
                    <div className="flex flex-1 flex-col w-96 space-y-5 text-left ">
                        <Panel bg="minor-action">
                            <Input name="cardholderName" placeholder="Cardholder name" />
                            <CardInput name="cardInput" />
                        </Panel>
                        <PromoCodeInput
                            name="code"
                            onApplyCode={(promotion) =>
                                dispatch({type: 'setPromotion', payload: promotion})
                            }
                            onUnapplyCode={() => dispatch({type: 'unsetPromotion'})}
                        />

                        {(error != null || errorMessage != null) && (
                            <SubscriptionsErrorMessage error={error} errorMessage={errorMessage} />
                        )}
                        <HStack>
                            <ButtonLabel colour="minor-action">
                                <span className="cursor-pointer" onClick={() => onCancel()}>
                                    Cancel
                                </span>
                            </ButtonLabel>
                            <Button
                                type="submit"
                                isDisabled={!!error}
                                isLoading={loading || stripeLoading}
                            >
                                Continue to payment
                            </Button>
                        </HStack>
                    </div>
                </Form>
            </VStack>
        </VStack>
    );
}
