import React from 'react';
import {ApolloError} from '@apollo/client';

import {Button, DetailText, HStack, Panel, VStack} from '@sphericsio/design-system';

import {Loading} from '../loading';
import {ErrorCard} from '../error-card';
import {DateDisplay} from '../date-display';
import {ConnectionContext} from '../../views/settings/connection-context';
import {FileUploadDetail} from '../../graphql/generated/graphql';

/**
 * Component that allows user to upload data upload files
 * disabled if a job is in progress
 */
export function DataUploadSettings({
    disableUploads,
    loading,
    error,
    setShowModal,
    data,
}: {
    disableUploads: boolean;
    loading: boolean;
    error?: ApolloError;
    setShowModal: (v: any) => void;
    data?: FileUploadDetail[];
}) {
    const {currentReportType} = React.useContext(ConnectionContext);

    const [uploads, setUploads] = React.useState<FileUploadDetail[]>([]);

    React.useEffect(() => {
        if (data) {
            const uploads = data.filter(
                (fileUpload) => fileUpload.upload_request_type === currentReportType,
            );
            setUploads(uploads);
        }
    }, [data, currentReportType]);

    return (
        <Panel border>
            <div className="px-3 py-1">
                <div className="pb-10 tracking-wider">
                    <DetailText>YOUR UPLOADS</DetailText>
                </div>
                <VStack full seperator>
                    <div className="pb-2">
                        <HStack align="start" full>
                            <DetailText>Date range</DetailText>
                            <DetailText>File name</DetailText>
                            <DetailText>Date of upload</DetailText>
                        </HStack>
                    </div>
                    {loading && <Loading />}
                    {error && <ErrorCard />}
                    {uploads.map((upload) => (
                        <div className="py-2" key={upload.id}>
                            <HStack align="start" full>
                                <DetailText>
                                    <DateDisplay>
                                        {upload.transaction_upload?.period_start ??
                                            upload.turnover_upload?.coverage_from_date ??
                                            ''}
                                    </DateDisplay>{' '}
                                    -{' '}
                                    <DateDisplay>
                                        {upload.transaction_upload?.period_end ??
                                            upload.turnover_upload?.coverage_to_date ??
                                            ''}
                                    </DateDisplay>
                                </DetailText>
                                <div className="w-1/4 truncate">
                                    <DetailText>
                                        {upload.request.filename}.{upload.request.file_extension}
                                    </DetailText>
                                </div>
                                <DetailText>
                                    <DateDisplay>{upload.created_at}</DateDisplay>
                                </DetailText>
                            </HStack>
                        </div>
                    ))}
                    <div className="self-end pt-5">
                        <Button
                            onPress={() => {
                                setShowModal(true);
                            }}
                            isDisabled={disableUploads}
                        >
                            Upload files
                        </Button>
                    </div>
                </VStack>
            </div>
        </Panel>
    );
}
