import React, {PropsWithChildren} from 'react';
import {Route, Routes} from 'react-router-dom';

import {AccountingSignup} from './signup';
import {AccountingLoginCallback} from './login-callback';
import {AccountingComponentProps} from './types';
import {FinancialProviderCallback} from './callback';
import {NotFound} from '../not-found';
import {NotLoggedIn} from '../../components/route-guard';
import {FinancialProviderCallbackSuccessUsingLocation} from './callback-success';

// pass any additional vendor-specific routes as children
export function AccountingRoutes({
    provider,
    children,
}: PropsWithChildren<AccountingComponentProps>) {
    return (
        <Routes>
            <NotLoggedIn>
                <Route
                    path="login/callback"
                    element={<AccountingLoginCallback provider={provider} />}
                />
                <Route path="signup" element={<AccountingSignup provider={provider} />} />
            </NotLoggedIn>
            <Route path="callback" element={<FinancialProviderCallback provider={provider} />} />
            <Route path="success" element={<FinancialProviderCallbackSuccessUsingLocation />} />
            <Route path="*" element={<NotFound />} />
            {children}
        </Routes>
    );
}
