import {PartialConfiguration} from '@sphericsio/config';

import {Config} from './default';

const config: PartialConfiguration<Config> = {
    apiHost: 'https://api.earth.sage.com',
    baseUrl: 'https://app.earth.sage.com',
    intercom: {
        appId: 't3w5lalc',
    },
    matomo: {
        enabled: true,
        host: 'https://analytics.spherics.io',
    },
    featureFlags: {
        artificiallyDelayLoading: false,
        spreadsheetUploads: false,
    },
    stripe: {
        apiKey: 'pk_live_51JL32MI6aJc6muILL1Emn4UCWLNLt83eldzUMnztNDGV1suLzt3eIadqRb5gRuJ5HMauQ8jsT2iwvgBIPrXuZUa300bNjwdZ3a',
    },
    bugsnag: {
        enabled: true,
    },
};
export default config;
