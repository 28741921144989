import React from 'react';
import {useQuery} from '@apollo/client';
import * as Yup from 'yup';
import type {ResultOf} from '@graphql-typed-document-node/core';

import {
    DetailText,
    HStack,
    Panel,
    StickyHeaderList,
    TextButton,
    VStack,
} from '@sphericsio/design-system';
import {FormikPillRadio, PillRadioOption, Form, Button} from '@sphericsio/forms';

import {Loading} from '../../loading';
import {ErrorCard} from '../../error-card';
import {MoneyDisplay} from '../../money';
import {DateDisplay} from '../../date-display';
import {Emoji} from '../../emoji';
import {TabPageHeader} from '../../page-header';
import {
    EditProfileEnergyConfirmedSpend,
    ProfileEnergyTarrifType,
    SuggestedEnergyTransactionActionPayload,
} from './types';
import {CompanyReportingPeriod} from '../../../graphql/generated/graphql';
import {GetSuggestedEnergySpendsDocument} from './graphql';

type ProfileEnergyTarrifTypeMap = Record<string, ProfileEnergyTarrifType | 'none' | ''>;
function buildInitialMerchantTarrifs(
    data: ResultOf<typeof GetSuggestedEnergySpendsDocument>,
    confirmedSpend: EditProfileEnergyConfirmedSpend[],
) {
    return data.getSuggestedEnergyTransactions.reduce((memo, merchant) => {
        const confirmedMerchant = confirmedSpend.find((s) => s.id === merchant.id);
        const confirmedTariff =
            confirmedMerchant?.transactions[0].categorisation_metadata.energyTariff;
        return {
            ...memo,
            [merchant.id]: (confirmedTariff as ProfileEnergyTarrifType) ?? '',
        };
    }, {} as ProfileEnergyTarrifTypeMap);
}

type ProfileEnergySuggestedProps = {
    reportingPeriod: CompanyReportingPeriod;
    onNext: (value: SuggestedEnergyTransactionActionPayload) => void;
    confirmedSpend?: EditProfileEnergyConfirmedSpend[];
};

export function ProfileEnergySuggested({
    reportingPeriod,
    onNext,
    confirmedSpend = [],
}: ProfileEnergySuggestedProps) {
    const {loading, error, data} = useQuery(GetSuggestedEnergySpendsDocument, {
        variables: {from: reportingPeriod.period_start, to: reportingPeriod.period_end},
    });
    const initialMerchantTarrifs = React.useMemo(() => {
        if (data == null) {
            return {};
        }

        return buildInitialMerchantTarrifs(data, confirmedSpend);
    }, [data]);
    const formSchema = React.useMemo(() => {
        if (data == null) {
            return Yup.object({}).strict();
        } else {
            return Yup.object(
                data.getSuggestedEnergyTransactions.reduce(
                    (schema, merchant) => ({
                        ...schema,
                        [merchant.id]: Yup.string().required(
                            'Please choose a tarrif, or none if this is not an energy supplier',
                        ),
                    }),
                    {} as Record<string, Yup.StringSchema>,
                ),
            );
        }
    }, [data]);

    function buildTransactionValues(merchantTarrifs: ProfileEnergyTarrifTypeMap) {
        if (data == null) {
            return {};
        }

        return data.getSuggestedEnergyTransactions.reduce((memo, merchant) => {
            let tarrif: ProfileEnergyTarrifType | null;
            const tarrifFromMap = merchantTarrifs[merchant.id];
            if (tarrifFromMap === '' || tarrifFromMap === 'none') {
                tarrif = null;
            } else {
                tarrif = tarrifFromMap;
            }
            return merchant.transactions.reduce(
                (txnMemo, txn) => ({
                    ...txnMemo,
                    [txn.id]: tarrif,
                }),
                memo,
            );
        }, {} as SuggestedEnergyTransactionActionPayload);
    }

    const text = 'white';
    const borderSelected = 'white';
    const borderUnselected = 'pill-default';
    const bgUnselected = 'transparent';
    return (
        <div>
            {loading && <Loading />}
            {error && <ErrorCard />}
            {data && (
                <Form
                    initialValues={initialMerchantTarrifs}
                    validationSchema={formSchema}
                    onSubmit={(values) => onNext(buildTransactionValues(values))}
                >
                    <VStack testId="energyTransactionsForm">
                        <TabPageHeader
                            title="Are the transactions in this list electricity or gas bills?"
                            subtitle="To ensure your carbon impact is accurate, please confirm the following transactions are energy bills."
                        />
                        <div className="flex-1">
                            <StickyHeaderList
                                headers={data.getSuggestedEnergyTransactions}
                                items={data.getSuggestedEnergyTransactions.map(
                                    (merchant) => merchant.transactions,
                                )}
                                renderHeader={(merchant) => (
                                    <HStack>
                                        <div className="flex-1">
                                            <DetailText inline={false} inherit bold>
                                                {merchant.merchant_name}
                                            </DetailText>
                                            <DetailText inline={false} inherit>
                                                We have identified {merchant.transactions.length}{' '}
                                                transactions from this supplier.
                                            </DetailText>
                                        </div>
                                        <FormikPillRadio name={merchant.id}>
                                            <PillRadioOption
                                                borderSelected={borderSelected}
                                                borderUnselected={borderUnselected}
                                                textSelected={text}
                                                textUnselected={text}
                                                bgUnselected={bgUnselected}
                                                value="electricity"
                                            >
                                                Electricity
                                            </PillRadioOption>
                                            <PillRadioOption
                                                borderSelected={borderSelected}
                                                borderUnselected={borderUnselected}
                                                textSelected={text}
                                                textUnselected={text}
                                                bgUnselected={bgUnselected}
                                                value="gas"
                                            >
                                                Gas
                                            </PillRadioOption>
                                            <PillRadioOption
                                                borderSelected={borderSelected}
                                                borderUnselected={borderUnselected}
                                                textSelected={text}
                                                textUnselected={text}
                                                bgUnselected={bgUnselected}
                                                value="dual_fuel"
                                            >
                                                Dual-fuel
                                            </PillRadioOption>
                                            <PillRadioOption
                                                borderSelected={borderSelected}
                                                borderUnselected={borderUnselected}
                                                textSelected={text}
                                                textUnselected={text}
                                                bgUnselected={bgUnselected}
                                                value="none"
                                            >
                                                None
                                            </PillRadioOption>
                                        </FormikPillRadio>
                                    </HStack>
                                )}
                                renderItem={(transaction) => (
                                    <DetailText inline={false}>
                                        <HStack>
                                            <div className="w-1/6">
                                                <DetailText bold>
                                                    <DateDisplay>
                                                        {transaction.transaction_date}
                                                    </DateDisplay>
                                                </DetailText>
                                            </div>
                                            <div className="w-1/12 text-left">
                                                <MoneyDisplay money={transaction.amount} />
                                            </div>
                                            <div className="flex-1">
                                                <DetailText>{transaction.description}</DetailText>
                                            </div>
                                        </HStack>
                                    </DetailText>
                                )}
                            />
                            {data.getSuggestedEnergyTransactions.length === 0 && (
                                <Panel>
                                    <DetailText inline={false}>
                                        <Emoji>⚠️ </Emoji>
                                        We have checked your accounting data and couldn&apos;t find
                                        any transactions that looked like they were energy bills.
                                    </DetailText>
                                    <DetailText inline={false}>
                                        You can estimate the amount of energy you used last quarter
                                        in the next section.
                                    </DetailText>
                                </Panel>
                            )}
                        </div>
                        <HStack>
                            {data.getSuggestedEnergyTransactions.length > 0 && (
                                <TextButton
                                    onPress={() =>
                                        onNext(
                                            buildTransactionValues(
                                                buildInitialMerchantTarrifs(data, []),
                                            ),
                                        )
                                    }
                                >
                                    I don&apos;t recognise any of these transactions
                                </TextButton>
                            )}
                            <Button type="submit">
                                {data.getSuggestedEnergyTransactions.length === 0
                                    ? 'Go to next question'
                                    : 'Save and continue'}
                            </Button>
                        </HStack>
                    </VStack>
                </Form>
            )}
        </div>
    );
}
