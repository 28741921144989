import {graphql} from '../../../graphql/generated';

export const GetCompanyAddressesDocument = graphql(/* GraphQL */ `
    query GetCompanyAddresses {
        currentCompany {
            ...CompanyAddresses
        }
    }
`);

// TODO reconcile these two calls - is there a need for both?
export const AddAddressesDocument = graphql(/* GraphQL */ `
    mutation AddAddresses($address: CompanyAddressInput!) {
        addCompanyAddresses(params: [$address]) {
            id
        }
    }
`);

export const AddCompanyAddressesDocument = graphql(/* GraphQL */ `
    mutation AddCompanyAddresses($params: [CompanyAddressInput!]!) {
        addCompanyAddresses(params: $params) {
            ...CompanyAddresses
        }
    }
`);

export const GetSuggestedAddressesDocument = graphql(/* GraphQL */ `
    query GetSuggestedAddresses {
        getCompanySuggestedAddresses {
            source
            address
            postcode
        }
        currentUser {
            financialProvider {
                financial_provider
            }
        }
    }
`);
