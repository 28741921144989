import {PartialConfiguration} from '@sphericsio/config';

import {Config} from './default';

const config: PartialConfiguration<Config> = {
    apiHost: 'https://api.dev2.dev.spherics.io',
    baseUrl: 'https://app.dev2.dev.spherics.io',
    allowFakeFinancialProvider: true,
    featureFlags: {
        spreadsheetUploads: true,
    },
    bugsnag: {
        enabled: true,
    },
};
export default config;
