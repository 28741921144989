import {ApolloError} from '@apollo/client';
import React from 'react';

import {useFragment} from '../../../graphql/generated';
import {
    CompanyAddress,
    CompanyReportingPeriod,
    ModifyProfilePeopleDocument,
} from '../../../graphql/generated/graphql';
import {CompanyAddressesFragment, CompanyAddressesFragmentDocument} from '../places/fragments';
import {ReportingPeriodContext, reportingPeriodPlaceholder} from '../reporting-period';
import {useModifySection} from '../use-modify-section';

type Address = Pick<CompanyAddress, 'id' | 'address' | 'postcode'>;
type ProfilePeopleWizardStep =
    | 'unknown'
    | 'intro'
    | 'places_required'
    | 'employee_count'
    | 'places'
    | 'completed'
    | 'period_open';

type ProfilePeopleState = {
    employeeCount?: number;
    placesPercentages: Record<string, number>;
    workingFromHomePercent?: number;
    currentStep: ProfilePeopleWizardStep;
    shouldSubmit: boolean;
};

type ProfilePeopleAction =
    | {type: 'start'}
    | {type: 'setEmployeeCount'; payload: number}
    | {
          type: 'setPlacesPercentages';
          payload: {placesPercentages: Record<string, number>; workingFromHomePercent: number};
      }
    | {type: 'complete'}
    | {
          type: 'reset';
          payload: {reportingPeriod: CompanyReportingPeriod; addresses: Address[]};
      };

function usePeopleReducer(reportingPeriod: CompanyReportingPeriod, addresses: Address[]) {
    function initialStepForReportingPeriod(
        reportingPeriod: CompanyReportingPeriod,
        addresses: Address[],
    ) {
        return reportingPeriod.isOpen
            ? 'period_open'
            : addresses.length === 0
              ? 'places_required'
              : reportingPeriod.people != null
                ? 'completed'
                : 'intro';
    }

    const initialState: ProfilePeopleState = {
        placesPercentages: {},
        shouldSubmit: false,
        currentStep: initialStepForReportingPeriod(reportingPeriod, addresses),
    };
    return React.useReducer(
        (state: ProfilePeopleState, action: ProfilePeopleAction): ProfilePeopleState => {
            switch (action.type) {
                case 'start':
                    return {
                        ...state,
                        currentStep: 'employee_count',
                    };

                case 'setEmployeeCount':
                    return {
                        ...state,
                        employeeCount: action.payload,
                        currentStep: 'places',
                    };

                case 'setPlacesPercentages':
                    return {
                        ...state,
                        placesPercentages: action.payload.placesPercentages,
                        workingFromHomePercent: action.payload.workingFromHomePercent,
                        shouldSubmit: true,
                    };

                case 'complete':
                    return {
                        ...initialState,
                        shouldSubmit: false,
                        currentStep: 'completed',
                    };

                case 'reset':
                    return {
                        ...initialState,
                        currentStep: initialStepForReportingPeriod(
                            action.payload.reportingPeriod,
                            action.payload.addresses,
                        ),
                    };
            }
        },
        initialState,
    );
}

type ProfilePeopleContextValue = {
    dispatch: (action: ProfilePeopleAction) => void;
    currentStep: ProfilePeopleWizardStep;
    reportingPeriod: CompanyReportingPeriod;
    addresses: Address[];
    submitting?: boolean;
    error?: ApolloError;
};

export const ProfilePeopleContext = React.createContext<ProfilePeopleContextValue>({
    dispatch: () => {
        // placeholder
    },
    reportingPeriod: reportingPeriodPlaceholder,
    addresses: [],
    currentStep: 'intro',
});

type ProfilePeopleContextProviderProps = {
    children: React.ReactNode;
    company: CompanyAddressesFragment;
};
export function ProfilePeopleContextProvider({
    company,
    children,
}: ProfilePeopleContextProviderProps) {
    const addresses = useFragment(CompanyAddressesFragmentDocument, company).addresses;
    const reportingPeriod = React.useContext(ReportingPeriodContext);
    const [state, dispatch] = usePeopleReducer(reportingPeriod, addresses);
    const [submit, {loading, error, finished}] = useModifySection(ModifyProfilePeopleDocument, [
        'GetProfilePeopleEntry',
    ]);
    const reportingPeriodId = reportingPeriod.id;

    React.useEffect(() => {
        const {shouldSubmit, employeeCount, workingFromHomePercent} = state;
        if (shouldSubmit && employeeCount != null && workingFromHomePercent != null) {
            submit({
                variables: {
                    params: {
                        numberOfEmployees: employeeCount,
                        percentWorkingFromHome: workingFromHomePercent,
                        percentWorkingFromPlaces: Object.entries(state.placesPercentages).map(
                            ([id, percent]) => ({
                                id,
                                percent,
                            }),
                        ),
                        companyReportingPeriodId: reportingPeriodId,
                    },
                },
            });
        }
    }, [submit, reportingPeriodId, state]);

    React.useEffect(() => {
        if (finished) {
            dispatch({type: 'complete'});
        }
    }, [finished]);

    React.useEffect(() => {
        dispatch({type: 'reset', payload: {reportingPeriod, addresses}});
    }, [reportingPeriod, addresses]);

    return (
        <ProfilePeopleContext.Provider
            value={{
                dispatch,
                currentStep: state.currentStep,
                reportingPeriod,
                addresses,
                submitting: loading,
                error,
            }}
        >
            {children}
        </ProfilePeopleContext.Provider>
    );
}
