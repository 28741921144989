import {graphql} from '../../../graphql/generated';

export const ProfilePeopleEntryFragmentDocument = graphql(/* GraphQL */ `
    fragment ProfilePeopleEntry on ProfilePeople {
        id
        number_of_employees
        percent_working_from_home
        workplace_entries {
            percent
            address {
                id
                address
                postcode
            }
        }
    }
`);
