import React from 'react';
import {useSearchParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import {VStack} from '@sphericsio/design-system';

import {HSplitLayout} from '../components/layout/h-split';
import {PosterLayout} from '../components/onboarding/poster-layout';
import {PageHeader} from '../components/page-header';
import {PosterJoinTheMovement} from '../components/onboarding/poster-join-the-movement';
import {Loading} from '../components/loading';
import {useExternalRedirect} from '../hooks/use-external-redirect';
import {ErrorCard} from '../components/error-card';
import {graphql} from '../graphql/generated';

export const invitedAuthorisationUrlQuery = graphql(/* GraphQL */ `
    query GetInvitedAuthorisationUrl($inviteCode: String!) {
        authorisationUrl(inviteCode: $inviteCode) {
            url
        }
    }
`);

export const inviteCodeQueryParam = 'code';

export function Invited() {
    const externalRedirect = useExternalRedirect();

    const [searchParams] = useSearchParams();
    const code = searchParams.get(inviteCodeQueryParam);

    const {error: queryError} = useQuery(invitedAuthorisationUrlQuery, {
        skip: !code,
        variables: {
            inviteCode: code ?? '',
        },
        onCompleted(data) {
            externalRedirect(data.authorisationUrl.url, true);
        },
    });

    let displayError: string | Error | null = null;
    if (!code) {
        displayError = 'The invite code is missing from the URL.';
    }
    if (queryError) {
        displayError = queryError;
    }

    return (
        <HSplitLayout cta="logout">
            <VStack full>
                {displayError === null ? (
                    <>
                        <PageHeader title="Checking your invite code" />
                        <Loading />
                    </>
                ) : (
                    <>
                        <PageHeader title="There is a problem with your invite code" />
                        <ErrorCard
                            message={typeof displayError === 'string' ? displayError : undefined}
                            error={typeof displayError === 'object' ? displayError : undefined}
                        />
                    </>
                )}
            </VStack>
            <PosterLayout>
                <PosterJoinTheMovement />
            </PosterLayout>
        </HSplitLayout>
    );
}
