import React from 'react';
import {useLocation, Navigate} from 'react-router-dom';
import {useIntercom} from 'react-use-intercom';
import {ApolloError, useMutation} from '@apollo/client';

import {GetCurrentUserDocument} from '../../components/user-context';
import {FullScreenError, FullScreenLoading} from '../../components/full-screen';
import {AccountingComponentProps} from './types';
import {graphql} from '../../graphql/generated';

const FinancialProviderCallbackDocument = graphql(/* GraphQL */ `
    mutation FinancialProviderCallback($params: String!, $provider: FinancialProviderName!) {
        financialProviderCallback(params: $params, provider: $provider) {
            id
        }
    }
`);

function isUserAlreadyExistsError(error: ApolloError) {
    const {
        graphQLErrors: [gqlError],
    } = error;
    return (
        gqlError && gqlError.extensions?.code === 'FORBIDDEN' && gqlError.message === 'USER_EXISTS'
    );
}

export function FinancialProviderCallback({provider}: AccountingComponentProps) {
    const location = useLocation();
    const {trackEvent} = useIntercom();
    const [preMutation, setPreMutation] = React.useState(true);
    const [providerCallback, {loading, error, data}] = useMutation(
        FinancialProviderCallbackDocument,
    );

    React.useLayoutEffect(() => {
        providerCallback({
            variables: {
                params: `${location.pathname}${location.search}`,
                provider,
            },
            refetchQueries: [{query: GetCurrentUserDocument}],
            awaitRefetchQueries: true,
        });
        setPreMutation(false);
    }, []);

    React.useEffect(() => {
        trackEvent(`Accountancy Platform Connected to ${provider}`);
    }, []);

    if (loading || preMutation) {
        return <FullScreenLoading />;
    }

    if (error != null && isUserAlreadyExistsError(error)) {
        return (
            <FullScreenError
                message="You already have a Sage Earth account."
                buttonLabel="Login"
                buttonHref="/login"
            />
        );
    }

    if (error) {
        return <FullScreenError buttonHref="/sync" />;
    }

    return (
        <Navigate
            to="../success"
            state={{backgroundExecutionId: data?.financialProviderCallback?.id}}
        />
    );
}
