import React, {ImgHTMLAttributes} from 'react';

import logo from '../images/logo-green.svg';

export interface SageEarthLogoProps extends ImgHTMLAttributes<HTMLImageElement> {
    className?: string;
}

export const SageEarthLogo = (
    {className, ...props}: SageEarthLogoProps = {className: 'w-full self-center'},
) => (
    <img
        src={logo}
        aria-label="Sage Earth logo"
        className={className}
        alt="Sage Earth"
        {...props}
    />
);
