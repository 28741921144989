import React from 'react';

import {
    CircleCross,
    CirclePlusIcon,
    DetailText,
    textColorClassnames,
} from '@sphericsio/design-system';

import {TransactionWithCategorisationMetadata} from '../../graphql/generated/graphql';
import {carbonAmountFormatter} from '../carbon-amount';

type FormattedTransactionEmissionsProps = {amount: number};
export function FormattedTransactionEmissions({amount}: FormattedTransactionEmissionsProps) {
    const {formattedAmount, unit} = carbonAmountFormatter({amount});
    return (
        <div className="min-w-min whitespace-nowrap">
            {formattedAmount} {unit}
        </div>
    );
}

type TransactionFeedEmissionsProps = {
    transaction: TransactionWithCategorisationMetadata;
    isExcludedView: boolean;
    onClick: (txn: TransactionWithCategorisationMetadata) => void;
};
export function TransactionFeedEmissions({
    transaction,
    isExcludedView,
    onClick,
}: TransactionFeedEmissionsProps) {
    return (
        <div className="flex flex-row justify-between">
            {!isExcludedView && (
                <DetailText bold>
                    <FormattedTransactionEmissions
                        amount={transaction.categorisation_metadata.kg_co2e ?? 0}
                    />
                </DetailText>
            )}
            <div className="w-6 h-6" onClick={() => onClick(transaction)}>
                {transaction.categorisation_metadata.isIgnored ? (
                    <span data-testid="includeButton" className={textColorClassnames('success')}>
                        <CirclePlusIcon />
                    </span>
                ) : (
                    <span data-testid="excludeButton" className={textColorClassnames('error')}>
                        <CircleCross />
                    </span>
                )}
            </div>
        </div>
    );
}
