import * as Yup from 'yup';

const POSTCODE_REGEX = /^([a-z]{1,2}\d[a-z\d]?)\s*(\d[a-z]{2})$/i;

export function PostcodeSchema() {
    return Yup.string().matches(POSTCODE_REGEX, 'Postcode is invalid');
}

export function formatPostcode(postcode: string) {
    const test = POSTCODE_REGEX.exec(postcode);
    if (!test) {
        return postcode;
    }
    const [, outcode, incode] = test;
    return `${outcode.toUpperCase()} ${incode.toUpperCase()}`;
}
