import React, {useMemo} from 'react';
import {ParentSize} from '@visx/responsive';

import {DetailText, SegmentSubheader} from '@sphericsio/design-system';

import {CO2e, displayInTonnes} from '../../carbon-amount';
import {Quarter, SeriesData, YearlyEmissions} from './types';
import {maxEmissions} from './util';
import {Legend} from './legend';
import {CarbonFootprintBarChart} from './carbon-footprint-bar-chart';
import {chartMargins} from './style';

export type CarbonFootprintTrendChartProps = {
    currentYear: YearlyEmissions;
    previousYear: YearlyEmissions | null;
};

export function CarbonFootprintTrendChart({
    currentYear,
    previousYear,
}: CarbonFootprintTrendChartProps) {
    const currentYearData = useMemo(() => getSeriesData(currentYear), [currentYear]);
    const previousYearData = useMemo(
        () => previousYear && getSeriesData(previousYear),
        [previousYear],
    );

    const max = useMemo(
        () => maxEmissions(currentYearData, previousYearData),
        [currentYearData, previousYearData],
    );

    const unit = displayInTonnes(max) ? 'Tonnes' : 'kg';

    return (
        <div className="w-full">
            <SegmentSubheader>
                Your carbon footprint trend: current year vs last year
            </SegmentSubheader>
            <DetailText colour="secondary">
                Measured in {unit} <CO2e />
            </DetailText>

            <div>
                <ParentSize>
                    {({width}) => (
                        <CarbonFootprintBarChart
                            width={width}
                            currentYearData={currentYearData}
                            previousYearData={previousYearData}
                            maxValue={max}
                        />
                    )}
                </ParentSize>
            </div>

            <Legend
                currentYear={currentYear.financialYear}
                previousYear={previousYear?.financialYear}
                left={chartMargins.left}
            />
        </div>
    );
}

function getSeriesData(year: YearlyEmissions): SeriesData {
    return year.quarters.map((q, i) => ({
        quarter: (i + 1) as Quarter,
        dataPoint: q,
        year: year.financialYear,
    }));
}
