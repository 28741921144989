import {Configuration} from '@sphericsio/config';
import {LogLevelConfig} from '@sphericsio/logging';

const logging: LogLevelConfig = {
    defaultLevel: 'debug',
};

const config = {
    appName: 'frontend',
    apiHost: 'http://localhost:5000',
    baseUrl: 'http://localhost:8080',
    google: {
        maps: {
            apiKey: 'AIzaSyAdd8uCLrZxwfnz2fA3aCifS0M7MWsGpOw',
        },
    },
    bugsnag: {
        apiKey: '0a76254f451ef091db24f7124c4c0afb',
        enabled: false,
        suppressLogs: false,
    },
    intercom: {
        appId: 'd82j486a',
    },
    logging,
    featureFlags: {
        achievements: false,
        artificiallyDelayLoading: true,
        subscriptions: true,
        spreadsheetUploads: true,
    },
    matomo: {
        enabled: false,
        host: 'https://matomo.stage.spherics.io',
        siteId: 2,
    },
    allowFakeFinancialProvider: false,
    stripe: {
        apiKey: 'pk_test_51JL32MI6aJc6muILPO7piwN2Q4BMuei2pW0cXirHRJ0CiB6MIp3DPZAgE7k69L4VdFo1wceALLX5nUDRwzc4YdFN00CWnuUgkF',
    },
    rewardful: {
        apiKey: '7b6bee',
    },
};

export type Config = Configuration<typeof config>;
export default config;
