import {createInstance, useMatomo} from '@datapunt/matomo-tracker-react';

import config from '../../config';

export const matomo = createInstance({
    urlBase: config.matomo.host,
    siteId: config.matomo.siteId,
    disabled: !config.matomo.enabled, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: {
        // optional, enabled by default
        active: true, // optional, default value: true
        seconds: 10, // optional, default value: `15
    },
    configurations: {
        requireCookieConsent: true,
    },
});

export function identifyUser(userId: string) {
    matomo.pushInstruction('setUserId', userId);
}

export function unidentifyUser() {
    matomo.pushInstruction('resetUserId');
}

export function useTrackPageView() {
    const {trackPageView} = useMatomo();
    return trackPageView;
}

export function useTrackEvent() {
    const {trackEvent} = useMatomo();
    return trackEvent;
}
