import React from 'react';
import {useLocation} from 'react-router-dom';
import {useMatomo} from '@datapunt/matomo-tracker-react';

import {useTrackPageView, identifyUser, unidentifyUser} from '../services/matomo';
import {useCurrentUserQueryData} from './user-context';

export function MatomoPageTracker() {
    const matomo = useMatomo();
    matomo.enableLinkTracking();
    const trackPageView = useTrackPageView();
    const location = useLocation();
    const userData = useCurrentUserQueryData();
    React.useEffect(() => {
        trackPageView({
            href: location.pathname,
        });
    }, [location, trackPageView]);
    React.useEffect(() => {
        if (userData == null) {
            unidentifyUser();
        } else {
            identifyUser(userData.id);
        }
    });
    return null;
}
