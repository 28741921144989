import React from 'react';
import {Navigate, useParams, useLocation} from 'react-router-dom';
import {useMutation} from '@apollo/client';

import {VStack} from '@sphericsio/design-system';

import {ResetPasswordForm} from '../../components/reset-password-form';
import {PageHeader} from '../../components/page-header';
import {graphql} from '../../graphql/generated';
import {HSplitLayout} from '../../components/layout/h-split';
import {PosterLayout} from '../../components/onboarding/poster-layout';
import {PosterJoinTheMovement} from '../../components/onboarding/poster-join-the-movement';

const ResetPasswordDocument = graphql(/* GraphQL */ `
    mutation ResetPassword($id: ID!, $token: String!, $password: String!) {
        changePassword(id: $id, token: $token, password: $password)
    }
`);

type ResetPasswordState = {
    id: string;
    token: string;
};
export function ResetPassword() {
    const [submit, {loading, error, data}] = useMutation(ResetPasswordDocument);
    const {id, token} = useParams();
    const location = useLocation();
    const state = location.state as ResetPasswordState;

    if (id != null && token != null) {
        return <Navigate to="/reset-password" state={{id, token}} />;
    }

    if (state == null) {
        return <Navigate to="/" />;
    }

    if (data != null) {
        return <Navigate to="/login" />;
    }

    return (
        <HSplitLayout cta="login">
            <VStack>
                <PageHeader
                    title="Reset your password"
                    subtitle="Create a new password, then login using that new password"
                />

                <ResetPasswordForm
                    submitting={loading}
                    error={error}
                    onSubmit={(password) => {
                        submit({
                            variables: {password, id: state.id, token: state.token},
                        });
                    }}
                />
            </VStack>
            <PosterLayout>
                <PosterJoinTheMovement />
            </PosterLayout>
        </HSplitLayout>
    );
}
