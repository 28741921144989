import React from 'react';
import ReactDOMServer from 'react-dom/server';

type CarbonAmountProps = {
    amount: number;
    asTonnes?: boolean;
};

const KgNumberFormat = new Intl.NumberFormat('en-GB', {maximumFractionDigits: 0});
const TonnesNumberFormat = new Intl.NumberFormat('en-GB', {maximumFractionDigits: 1});
export function carbonAmountFormatter({amount, asTonnes}: CarbonAmountProps) {
    let scaledAmount = amount;
    let unit = 'kg';
    let numberFormat = KgNumberFormat;

    if (asTonnes || (asTonnes === undefined && displayInTonnes(amount))) {
        scaledAmount = amount / 1_000;
        unit = 'Tonnes';
        numberFormat = TonnesNumberFormat;
    }

    return {
        rawAmount: amount,
        formattedAmount: numberFormat.format(scaledAmount),
        unit,
    };
}

const tonnesThreshold = 100_000;
export function displayInTonnes(amount: number) {
    return amount >= tonnesThreshold;
}

export function CO2e() {
    return (
        <>
            CO<sub>2</sub>e
        </>
    );
}

export function CarbonAmount(props: CarbonAmountProps) {
    const {formattedAmount, unit} = carbonAmountFormatter(props);
    return (
        <>
            {formattedAmount} {unit} <CO2e />
        </>
    );
}

export function carbonAmountHTMLString(props: CarbonAmountProps) {
    return ReactDOMServer.renderToStaticMarkup(<CarbonAmount {...props} />);
}
