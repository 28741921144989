import React from 'react';

import {CategoryTheme, HStack} from '@sphericsio/design-system';

import {CategoryListItem} from './categorisation';
import {UserCategorisationHierarchyItem} from './hierarchy';

type CategoryRowReadOnlyProps = {
    categorisation: UserCategorisationHierarchyItem[];
    theme: CategoryTheme;
};

export function CategoryRowReadOnly({categorisation, theme}: CategoryRowReadOnlyProps) {
    const terms = categorisation.map(({title, key}, index) => (
        <CategoryListItem
            title={title}
            theme={theme}
            key={`category-${key}`}
            last={index === categorisation.length - 1}
        />
    ));

    return (
        <HStack condensed>
            <div className="flex flex-row flex-start space-x-1 items-center">{terms}</div>
        </HStack>
    );
}
