import React from 'react';
import classnames from 'classnames';

import {
    bgColorClassnames,
    DetailText,
    HStack,
    textColorClassnames,
    Tooltip,
} from '@sphericsio/design-system';

import {FinancialAccountsFilterEnum, TransactionFilterEnum} from '../graphql/generated/graphql';
import {capitaliseFirstLetter} from '../services/utils/string';
import {HelpIcon} from './icons';

export type Filter = {
    filter: string;
    count: number;
    tooltipText?: string;
};

type CountPillProps = {
    children: React.ReactNode;
    isSelected?: boolean;
};
function CountPill({isSelected, children}: CountPillProps) {
    return (
        <div
            className={classnames(
                'px-3 py-1 font-body text-center outline-none rounded-full',
                isSelected && bgColorClassnames('secondary'),
                !isSelected && bgColorClassnames('minor-action'),
            )}
        >
            <DetailText size="small" colour={isSelected ? 'white' : 'default'}>
                {children}
            </DetailText>
        </div>
    );
}

type FDO = {
    key: string;
    name: string;
};

type FilterOptionsProps = {
    filterOptions: Filter[];
    filterDisplayOrder: FDO[];
    selected: FilterEnums;
    onSelect: (selected: FilterEnums) => void;
};
export function FilterOptions({
    filterOptions,
    filterDisplayOrder,
    selected,
    onSelect,
}: FilterOptionsProps) {
    return (
        <HStack large>
            {filterDisplayOrder.map((f) => {
                const option = filterOptions.find(({filter}) => filter === f.key);

                if (!option) {
                    return null;
                }

                return (
                    <Filter
                        name={capitaliseFirstLetter(f.name)}
                        key={option.filter}
                        filterOption={option}
                        onSelect={onSelect}
                        selected={selected}
                    />
                );
            })}
        </HStack>
    );
}

export type FilterEnums = TransactionFilterEnum | FinancialAccountsFilterEnum;
type FilterProps = {
    name: string;
    filterOption: Filter;
    selected: FilterEnums;
    onSelect: (selected: FilterEnums) => void;
};
function Filter({name, filterOption, selected, onSelect}: FilterProps) {
    const {filter, count, tooltipText} = filterOption;

    return (
        <div
            data-testid="filter"
            onClick={() => onSelect(filter as FilterEnums)}
            className="cursor-pointer flex flex-row space-x-2.5 items-center"
        >
            <DetailText bold={selected === filter}>{name}</DetailText>
            {tooltipText && (
                <div className="relative">
                    <Tooltip place="right" align="end" tooltipText={tooltipText}>
                        <div
                            className={`w-6 h-6 flex items-center justify-center ${textColorClassnames(
                                'tooltip',
                            )}`}
                        >
                            <HelpIcon />
                        </div>
                    </Tooltip>
                </div>
            )}
            <CountPill isSelected={selected === filter}>{count}</CountPill>
        </div>
    );
}
