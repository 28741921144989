import React from 'react';
import {parseJSON, format, parse, isValid} from 'date-fns';

import {Bugsnag} from '../util/bugsnag';

export function parseDateString(date: string) {
    return parse(date, 'yyyy-MM-dd', new Date());
}

export function formatDateForUrl(date: Date) {
    return format(date, 'yyyy-MM-dd');
}

export function formatDateForDisplay(date: Date) {
    return format(date, 'dd/MM/yyyy');
}

export function formatDateString(date: string, short = false, includeDay = false) {
    let parsedDate = parseJSON(date);
    if (!isValid(parsedDate)) {
        // string is not in json format, try parsing straight date instead
        parsedDate = parseDateString(date);
    }

    try {
        if (short) {
            return format(parsedDate, 'dd.MM.yy');
        } else {
            return format(parsedDate, includeDay ? 'EEE dd MMM yyyy' : 'dd MMM yyyy');
        }
    } catch (err: any) {
        console.error(err);
        Bugsnag.leaveBreadcrumb('errorParsingDate', {date});
        Bugsnag.notify(err);
        return date;
    }
}

type DateDisplayProps = {
    children: string;
    short?: boolean;
};
export function DateDisplay({children, short}: DateDisplayProps) {
    return <>{formatDateString(children, short)}</>;
}
