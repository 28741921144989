import {graphql} from '../../../graphql/generated';

export const ProfileGHGFragmentDocument = graphql(/* GraphQL */ `
    fragment ProfileGHGEntry on ProfileGHG {
        id
        owned_appliences
        topped_up
        spend_split_percent
        gasEntries {
            gas_type
            kg
        }
        confirmedSpend {
            id
            merchant_name
            amount
            transactions {
                id
            }
        }
    }
`);
