import React from 'react';
import {useQuery} from '@apollo/client';

import {
    Button,
    ButtonLabel,
    DetailText,
    HStack,
    Panel,
    StickyHeaderList,
    Strong,
    TextButton,
    Toggle,
    VStack,
} from '@sphericsio/design-system';

import {CompanyReportingPeriod} from '../../../graphql/generated/graphql';
import {Loading} from '../../loading';
import {ErrorCard} from '../../error-card';
import {MoneyDisplay} from '../../money';
import {DateDisplay} from '../../date-display';
import {Emoji} from '../../emoji';
import {TabPageHeader} from '../../page-header';
import {useSuggestedTransactionsState, MerchantTransactionData} from '../hooks';
import {GetSuggestedVehicleSpendsDocument} from './graphql';

type ProfileVehiclesSuggestedProps = {
    onNext: (values: Record<string, boolean>) => void;
    reportingPeriod: CompanyReportingPeriod;
    defaultValue?: boolean;
    confirmedSpend?: MerchantTransactionData[];
};
export function ProfileVehiclesSuggested({
    onNext,
    reportingPeriod,
    defaultValue = true,
    confirmedSpend,
}: ProfileVehiclesSuggestedProps) {
    const {loading, error, data} = useQuery(GetSuggestedVehicleSpendsDocument, {
        variables: {from: reportingPeriod.period_start, to: reportingPeriod.period_end},
    });
    const {transactionStates, setAllTransactionsToValue, toggleTransactionState} =
        useSuggestedTransactionsState(
            data?.getSuggestedVehicleTransactions,
            defaultValue,
            confirmedSpend,
        );

    const submit = () => onNext(transactionStates);

    return (
        <div>
            {loading && <Loading />}
            {error && <ErrorCard />}
            {data && (
                <VStack testId="fuelTransactionsForm">
                    <TabPageHeader
                        title="Are the transactions in this list for vehicle fuel (petrol or diesel)?"
                        subtitle="To ensure your carbon impact is accurate, please confirm the following transactions are fuel purchases."
                    />
                    <div className="flex-1">
                        <StickyHeaderList
                            headers={data.getSuggestedVehicleTransactions}
                            items={data.getSuggestedVehicleTransactions.map(
                                (merchant) => merchant.transactions,
                            )}
                            renderHeader={(merchant) => (
                                <HStack>
                                    <div className="flex-1">
                                        <DetailText inline={false} inherit bold>
                                            <Strong>{merchant.merchant_name}</Strong>
                                        </DetailText>
                                        <DetailText inline={false} inherit>
                                            We have identified {merchant.transactions.length}{' '}
                                            transactions from this supplier.
                                        </DetailText>
                                    </div>
                                    <HStack>
                                        <div
                                            className="cursor-pointer"
                                            onClick={() =>
                                                setAllTransactionsToValue(
                                                    merchant.transactions.map(
                                                        (transaction) => transaction.id,
                                                    ),
                                                    false,
                                                )
                                            }
                                        >
                                            <ButtonLabel>No to all</ButtonLabel>
                                        </div>
                                        <div
                                            className="cursor-pointer"
                                            onClick={() =>
                                                setAllTransactionsToValue(
                                                    merchant.transactions.map(
                                                        (transaction) => transaction.id,
                                                    ),
                                                    true,
                                                )
                                            }
                                        >
                                            <ButtonLabel>Yes to all</ButtonLabel>
                                        </div>
                                    </HStack>
                                </HStack>
                            )}
                            renderItem={(transaction) => (
                                <DetailText inline={false}>
                                    <HStack>
                                        <div className="w-1/6">
                                            <DetailText bold>
                                                <DateDisplay>
                                                    {transaction.transaction_date}
                                                </DateDisplay>
                                            </DetailText>
                                        </div>
                                        <div className="w-1/12 text-left">
                                            <MoneyDisplay money={transaction.amount} />
                                        </div>
                                        <div className="flex-1">
                                            <DetailText>{transaction.description}</DetailText>
                                        </div>
                                        <div className="pr-5">
                                            <Toggle
                                                value={transactionStates[transaction.id]}
                                                onToggle={() =>
                                                    toggleTransactionState(transaction.id)
                                                }
                                                onLabel="Yes"
                                                offLabel="No"
                                            />
                                        </div>
                                    </HStack>
                                </DetailText>
                            )}
                        />
                        {data.getSuggestedVehicleTransactions.length === 0 && (
                            <Panel>
                                <DetailText inline={false}>
                                    <Emoji>⚠️ </Emoji>
                                    We have checked your accounting data and couldn&apos;t find any
                                    transactions that looked like they were for fuel.
                                </DetailText>
                                <DetailText inline={false}>
                                    You can estimate the amount of fuel you purchased last quarter
                                    in the next section.
                                </DetailText>
                            </Panel>
                        )}
                    </div>
                    <HStack>
                        {data.getSuggestedVehicleTransactions.length > 0 && (
                            <TextButton
                                onPress={() => {
                                    const value = setAllTransactionsToValue(
                                        Object.keys(transactionStates),
                                        false,
                                    );
                                    onNext(value);
                                }}
                            >
                                I don&apos;t recognise any of these transactions
                            </TextButton>
                        )}
                        <Button onPress={submit}>
                            {data.getSuggestedVehicleTransactions.length === 0
                                ? 'Go to next question'
                                : 'Save and continue'}
                        </Button>
                    </HStack>
                </VStack>
            )}
        </div>
    );
}
