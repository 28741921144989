import React from 'react';
import {useMutation} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import {VStack} from '@sphericsio/design-system';

import {LoginForm, SageIDLogin} from '../components/auth/login-form';
import {useCurrentUserQueryData} from '../components/user-context';
import {graphql} from '../graphql/generated';
import {HSplitLayout} from '../components/layout/h-split';
import {PosterLayout} from '../components/onboarding/poster-layout';
import {PosterJoinTheMovement} from '../components/onboarding/poster-join-the-movement';
import {PageHeader} from '../components/page-header';
import {useExternalRedirect} from '../hooks/use-external-redirect';

export const GetIdentityProviderAuthorisationUrlDocument = graphql(/* GraphQL */ `
    mutation GetIdentityProviderAuthorisationUrl($params: AuthoriseParams!) {
        getIdentityProviderAuthorisationUrl(params: $params) {
            url
        }
    }
`);

export const GetSageIDMigrationUrlDocument = graphql(/* GraphQL */ `
    mutation GetSageIDMigrationUrl($email: String!, $password: String!) {
        getSageIDMigrationUrl(email: $email, password: $password) {
            url
        }
    }
`);

const LegacyLogin: React.FC = () => {
    const externalRedirect = useExternalRedirect();
    const [email, setEmail] = React.useState<string>();

    const [getSageIDMigrationUrl, {called: migrationUrlLoading, error: migrationUrlError}] =
        useMutation(GetSageIDMigrationUrlDocument, {
            onCompleted(data) {
                if (data.getSageIDMigrationUrl) {
                    externalRedirect(data.getSageIDMigrationUrl.url);
                }
            },
        });
    const [getAuthorisationUrl, {called: sageIdLoading, error: sageIdError}] = useMutation(
        GetIdentityProviderAuthorisationUrlDocument,
        {
            onCompleted(data) {
                if (data.getIdentityProviderAuthorisationUrl) {
                    externalRedirect(data.getIdentityProviderAuthorisationUrl.url);
                }
            },
        },
    );

    const navigate = useNavigate();
    const userData = useCurrentUserQueryData();

    const showPasswordForm = sageIdError?.graphQLErrors[0].message === 'USER_USES_PASSWORD_LOGIN';

    React.useEffect(() => {
        if (userData != null) {
            navigate('/');
        }
    }, []);

    return (
        <HSplitLayout cta="signup">
            <VStack>
                <PageHeader title="Welcome back!" />
                {!showPasswordForm && (
                    <SageIDLogin
                        submitting={sageIdLoading && !sageIdError}
                        error={sageIdError}
                        onSubmit={(email) => {
                            setEmail(email);
                            getAuthorisationUrl({
                                variables: {
                                    params: {
                                        email,
                                        flow: 'login',
                                    },
                                },
                            }).catch(() => {
                                /* Catching for testability purposes. See https://github.com/apollographql/apollo-client/issues/7167 */
                            });
                        }}
                    />
                )}
                {showPasswordForm && (
                    <LoginForm
                        email={email}
                        submitting={migrationUrlLoading && !migrationUrlError}
                        error={migrationUrlError}
                        onSubmit={(email, password) => {
                            getSageIDMigrationUrl({
                                variables: {email, password},
                            }).catch(() => {
                                /* Catching for testability purposes. See https://github.com/apollographql/apollo-client/issues/7167 */
                            });
                        }}
                    />
                )}
            </VStack>
            <PosterLayout>
                <PosterJoinTheMovement />
            </PosterLayout>
        </HSplitLayout>
    );
};

export {LegacyLogin};
