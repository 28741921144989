import * as React from 'react';

import {Paragraph, textColorClassnames, VStack} from '@sphericsio/design-system';

import {FinancialAccountsFeed} from '../financial-accounts/financial-accounts-verification-feed';
import {FinancialAccountFilters} from '../financial-accounts/filters';

export function FinancialAccounts() {
    return (
        <VStack large>
            <div className={textColorClassnames()}>
                <Paragraph>
                    We strongly suggest regularly reviewing the list of your accounting codes below
                    to confirm you agree with the category we have suggested for each. For extra
                    context you can expand each code to see the transactions covered within it, and
                    edit any categories that are inaccurate.
                </Paragraph>
            </div>
            <FinancialAccountFilters />
            <FinancialAccountsFeed />
        </VStack>
    );
}
