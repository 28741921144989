import React from 'react';
import {ApolloError, useLazyQuery} from '@apollo/client';
import {format, sub, isWithinInterval} from 'date-fns';
import * as locale from 'date-fns/locale';

import {DetailText} from '@sphericsio/design-system';

import {
    CompanyReportingPeriod,
    FinancialDateFilter,
    GetCompanyFinancialDateFiltersQuery,
} from '../../graphql/generated/graphql';
import {graphql} from '../../graphql/generated';
import {IntervalTypes, SelectedPeriod, Selection} from './config';
import {formatFinancialYear, formatQuarter, periodIsValid, findQuarterNumber} from './utils';

export const GetCompanyFinancialDateFiltersDocument = graphql(/* GraphQL */ `
    query GetCompanyFinancialDateFilters($showQuartersEndingInFuture: Boolean!) {
        getCompanyFinancialDateFilter(showQuartersEndingInFuture: $showQuartersEndingInFuture) {
            minDate
            years {
                periodStart
                periodEnd
                isBaselineYear
                isBaselineYearCompleted
                quarters {
                    periodStart
                    periodEnd
                    quarterNumber
                }
            }
        }
    }
`);

type FinancialDateFilterValue = {
    loading: boolean;
    error?: ApolloError;
    data?: GetCompanyFinancialDateFiltersQuery;
    setSelection: (v: {type: IntervalTypes; periodStart: string; periodEnd: string}) => void;
    selectedPeriod?: SelectedPeriod;
    intervalType?: IntervalTypes;
    intervalValue: JSX.Element;
    intervalTypesAvailable: string[];
    setIntervalTypesAvailable: (v: string[]) => void;
    reportingPeriods: CompanyReportingPeriod[];
    setReportingPeriods: (v: CompanyReportingPeriod[]) => void;
    disableQuartersWithinBaselinePeriod?: boolean;
    indicateBaselineYearInQuarter?: boolean;
};

export const FinancialDateFilterContext = React.createContext<FinancialDateFilterValue>({
    loading: true,
    setSelection: () => {
        // do nothing
    },
    intervalType: IntervalTypes.quarter,
    intervalValue: <></>,
    intervalTypesAvailable: [],
    setIntervalTypesAvailable: () => {
        // do nothing
    },
    reportingPeriods: [],
    setReportingPeriods: () => {
        //
    },
});

type FinancialDateFilterContextProps = {
    children: React.ReactNode;
    intervalTypesToDisplay?: string[];
    showQuartersEndingInFuture?: boolean;
    initialPeriod?: SelectedPeriod;
    disableQuartersWithinBaselinePeriod?: boolean;
    indicateBaselineYearInQuarter?: boolean;
};

function getMatchingFinancialYear(filter: FinancialDateFilter, selection: Selection) {
    return isWithinInterval(new Date(selection.periodStart), {
        start: new Date(filter.periodStart),
        end: new Date(filter.periodEnd),
    });
}

function generateIntervalValue(selection?: Selection, filters?: FinancialDateFilter[]) {
    if (!selection) {
        return <DetailText>Select a date range</DetailText>;
    } else if (selection.type === IntervalTypes.year) {
        return (
            <DetailText>
                {formatFinancialYear(selection.periodStart, selection.periodEnd)}
            </DetailText>
        );
    } else if (selection.type === IntervalTypes.quarter) {
        const matchingFinancialYear = filters?.find((f) => getMatchingFinancialYear(f, selection));
        return (
            <div className="space-x-1">
                <DetailText>
                    {matchingFinancialYear
                        ? formatFinancialYear(
                              matchingFinancialYear.periodStart,
                              matchingFinancialYear.periodEnd,
                          )
                        : formatFinancialYear(selection.periodStart, selection.periodEnd)}
                    :
                </DetailText>
                <DetailText>{formatQuarter(selection.quarterNumber)}</DetailText>
            </div>
        );
    } else {
        return (
            <DetailText>
                {format(new Date(selection.periodStart), 'P', {
                    locale: locale.enGB,
                })}{' '}
                -{' '}
                {format(new Date(selection.periodEnd), 'P', {
                    locale: locale.enGB,
                })}
            </DetailText>
        );
    }
}

export function FinancialDateFilterContextProvider({
    children,
    intervalTypesToDisplay,
    showQuartersEndingInFuture = true,
    initialPeriod,
    disableQuartersWithinBaselinePeriod,
    indicateBaselineYearInQuarter,
}: FinancialDateFilterContextProps) {
    const [fetchFilters, {loading, error, data}] = useLazyQuery(
        GetCompanyFinancialDateFiltersDocument,
        {
            fetchPolicy: 'network-only',
        },
    );

    const [intervalTypesAvailable, setIntervalTypesAvailable] = React.useState<string[]>([]);
    const [intervalType, setIntervalType] = React.useState<IntervalTypes>();
    const [intervalValue, setIntervalValue] = React.useState(generateIntervalValue());
    const [selectedPeriod, setSelectedPeriod] = React.useState<SelectedPeriod>();
    const [reportingPeriods, setReportingPeriods] = React.useState<CompanyReportingPeriod[]>([]);

    const quarters = React.useMemo(
        () =>
            data?.getCompanyFinancialDateFilter?.years.reduce(
                (prev, curr) => prev.concat(curr.quarters),
                [] as SelectedPeriod[],
            ),
        [data?.getCompanyFinancialDateFilter?.years],
    );

    React.useEffect(() => {
        fetchFilters({
            variables: {
                showQuartersEndingInFuture,
            },
        });
    }, [showQuartersEndingInFuture]);

    React.useEffect(() => {
        if (intervalTypesToDisplay) {
            setIntervalTypesAvailable(
                Object.values(IntervalTypes).filter((t) => {
                    return intervalTypesToDisplay.includes(t);
                }),
            );
        } else {
            setIntervalTypesAvailable(Object.values(IntervalTypes));
        }
    }, [intervalTypesToDisplay]);

    React.useEffect(() => {
        if (intervalTypesAvailable.includes(IntervalTypes.custom)) {
            setIntervalType(IntervalTypes.custom);
        } else if (intervalTypesAvailable.includes(IntervalTypes.quarter)) {
            setIntervalType(IntervalTypes.quarter);
        } else if (intervalTypesAvailable.includes(IntervalTypes.year)) {
            setIntervalType(IntervalTypes.year);
        }
    }, [intervalTypesAvailable, data?.getCompanyFinancialDateFilter?.years]);

    React.useEffect(() => {
        if (intervalType && !selectedPeriod) {
            if (intervalType === IntervalTypes.custom) {
                setSelectedPeriod({
                    periodStart: sub(new Date(), {
                        years: 1,
                    }).toISOString(),
                    periodEnd: new Date().toISOString(),
                });
            }
            const filters = data?.getCompanyFinancialDateFilter?.years;
            const quarterFilters = disableQuartersWithinBaselinePeriod
                ? filters
                : filters?.filter((y) => !y.isBaselineYear);
            if (
                intervalType === IntervalTypes.quarter &&
                quarterFilters &&
                quarterFilters.length > 0
            ) {
                if (
                    initialPeriod &&
                    periodIsValid(quarterFilters, initialPeriod, IntervalTypes.quarter) &&
                    quarters
                ) {
                    setSelectedPeriod({
                        periodStart: initialPeriod.periodStart,
                        periodEnd: initialPeriod.periodEnd,
                        quarterNumber: findQuarterNumber(initialPeriod, quarters),
                    });
                } else {
                    const lastYearsQuarters = quarterFilters[quarterFilters.length - 1].quarters;
                    if (lastYearsQuarters.length) {
                        setSelectedPeriod({
                            periodStart:
                                lastYearsQuarters[lastYearsQuarters.length - 1].periodStart,
                            periodEnd: lastYearsQuarters[lastYearsQuarters.length - 1].periodEnd,
                            quarterNumber: lastYearsQuarters.length,
                        });
                    } else {
                        // if not available we allow the user to pick the period
                    }
                }
            }
            if (intervalType === IntervalTypes.year && filters) {
                if (initialPeriod && periodIsValid(filters, initialPeriod, IntervalTypes.year)) {
                    setSelectedPeriod({
                        periodStart: initialPeriod.periodStart,
                        periodEnd: initialPeriod.periodEnd,
                    });
                } else {
                    const lastYear = filters[filters.length - 1];
                    if (lastYear) {
                        setSelectedPeriod({
                            periodStart: lastYear.periodStart,
                            periodEnd: lastYear.periodEnd,
                        });
                    } else {
                        // if not available we allow the user to pick the period
                    }
                }
            }
        }
    }, [intervalType, data?.getCompanyFinancialDateFilter?.years]);

    React.useEffect(() => {
        if (intervalType && selectedPeriod) {
            setIntervalValue(
                generateIntervalValue(
                    {...selectedPeriod, type: intervalType},
                    data?.getCompanyFinancialDateFilter?.years,
                ),
            );
        }
    }, [selectedPeriod, intervalType, data?.getCompanyFinancialDateFilter?.years]);

    const setSelection = (s: Selection) => {
        setIntervalType(s.type);
        setSelectedPeriod(s);
    };

    return (
        <FinancialDateFilterContext.Provider
            value={{
                data,
                loading,
                error,
                setSelection,
                selectedPeriod,
                intervalType,
                intervalValue,
                intervalTypesAvailable,
                setIntervalTypesAvailable,
                setReportingPeriods,
                reportingPeriods,
                disableQuartersWithinBaselinePeriod,
                indicateBaselineYearInQuarter,
            }}
        >
            {children}
        </FinancialDateFilterContext.Provider>
    );
}

type PickerContextValue = {
    showPicker: boolean;
    setShowPicker: (v: boolean) => void;
};

export const PickerContext = React.createContext<PickerContextValue>({
    showPicker: false,
    setShowPicker: () => {
        // do nothing
    },
});

export function PickerContextProvider({children}: {children: React.ReactNode}) {
    const [showPicker, setShowPicker] = React.useState(false);

    return (
        <PickerContext.Provider
            value={{
                showPicker,
                setShowPicker,
            }}
        >
            {children}
        </PickerContext.Provider>
    );
}
