import React from 'react';

import {HStack, Panel} from '@sphericsio/design-system';

type HorizontalSectionsProps = {
    children: React.ReactNodeArray;
};
export function HorizontalSections({children}: HorizontalSectionsProps) {
    return (
        <HStack align="stretch">
            {React.Children.map(children, (child) => (
                <Panel>
                    <div className="flex flex-1 h-full">{child}</div>
                </Panel>
            ))}
        </HStack>
    );
}
