import React from 'react';
import {Axis} from '@visx/xychart';
import {bottomTickLabelProps} from '@visx/axis/lib/axis/AxisBottom';
import {AxisProps} from '@visx/xychart/lib/components/axis/Axis';
import {leftTickLabelProps} from '@visx/axis/lib/axis/AxisLeft';
import {format} from 'date-fns';

import {Preset} from '@sphericsio/design-system';

import {carbonAmountFormatter} from '../carbon-amount';

function BaseAxis(props: Partial<AxisProps> & Pick<AxisProps, 'orientation'>) {
    return (
        <Axis
            numTicks={5}
            stroke={Preset.theme.extend.colors.utility.major[75]}
            strokeWidth={1}
            hideTicks={true}
            {...props}
        />
    );
}

const tickLabelProps = {
    fontFamily: Preset.theme.fontFamily.body.join(','),
    fontSize: 12,
    fill: Preset.theme.extend.colors.yin[65],
};

export function BottomAxis(props: Partial<AxisProps>) {
    return (
        <BaseAxis
            orientation="bottom"
            tickLabelProps={() => ({
                ...bottomTickLabelProps(),
                ...tickLabelProps,
            })}
            {...props}
        />
    );
}

export function LeftAxis(props: Partial<AxisProps>) {
    return (
        <BaseAxis
            orientation="left"
            tickLabelProps={() => ({
                ...leftTickLabelProps(),
                ...tickLabelProps,
            })}
            {...props}
        />
    );
}

export function YAxisEmissions(props: Partial<AxisProps> & {useTonnes: boolean}) {
    return (
        <LeftAxis
            tickFormat={(v) => {
                const {formattedAmount} = carbonAmountFormatter({
                    amount: v,
                    asTonnes: props.useTonnes,
                });

                return formattedAmount;
            }}
            {...props}
        />
    );
}

/**
 * Axis to display the month & year.
 *
 * It expects the x values to be valid Date instances.
 */
export function XAxisYearMonth(props: Partial<AxisProps>) {
    return <BottomAxis tickFormat={(value) => format(value, 'MMM yy')} {...props} />;
}

/**
 * Axis to display quarter numbers.
 *
 * It expects the x values to be a numeric value between 1 and 4.
 */
export function XAxisQuarter(props: Partial<AxisProps>) {
    return <BottomAxis tickFormat={(value) => `Q${value}`} {...props} />;
}
