import React from 'react';
import * as Yup from 'yup';
import {useMutation} from '@apollo/client';

import {HStack, VStack} from '@sphericsio/design-system';
import {Form, Input, Button, TextButton, Textarea} from '@sphericsio/forms';

import {PostcodeSchema} from './postcode';
import {ErrorCard} from '../../error-card';
import {TabPageHeader} from '../../page-header';
import {BuildingTypeSelect} from './building-type-select';
import {AddAddressesDocument} from './graphql';

const FORM_SCHEMA = Yup.object({
    address: Yup.string().required('Address is required'),
    postcode: PostcodeSchema().required('Postcode is required'),
    buildingType: Yup.string().required('Building type is required'),
});

type ProfilePlacesAddAddressFormProps = {
    address?: {
        address: string;
        postcode: string;
    };
    onNext: () => void;
    onAddAnother: () => void;
};
export function ProfilePlacesAddAddressForm({
    address = {address: '', postcode: ''},
    onNext,
    onAddAnother,
}: ProfilePlacesAddAddressFormProps) {
    const [addAddress, {loading, error, data}] = useMutation(AddAddressesDocument);
    const [buttonPressed, setButtonPressed] = React.useState<'add_another' | 'finish'>();
    React.useEffect(() => {
        if (data != null) {
            if (buttonPressed === 'add_another') {
                onAddAnother();
            } else {
                onNext();
            }
        }
    }, [data, buttonPressed, onAddAnother, onNext]);
    return (
        <div>
            <VStack testId="addAddressForm">
                <TabPageHeader title="Please enter an address." />
                <Form
                    initialValues={{...address, name: '', buildingType: ''}}
                    onSubmit={(values) => {
                        addAddress({
                            variables: {
                                address: {
                                    address: values.address,
                                    postcode: values.postcode,
                                    buildingType: values.buildingType,
                                },
                            },
                        });
                    }}
                    validationSchema={FORM_SCHEMA}
                >
                    <Textarea name="address" placeholder="Address" />
                    <Input name="postcode" placeholder="Postcode" />

                    <TabPageHeader
                        title="Please tell us what this building is used for."
                        subtitle="Please select the most accurate category of building from the list."
                    />
                    <BuildingTypeSelect
                        name="buildingType"
                        placeholder="Please select from the list below"
                    />

                    <HStack>
                        <TextButton
                            isLoading={buttonPressed === 'add_another' && loading}
                            type="submit"
                            onPress={() => setButtonPressed('add_another')}
                        >
                            Save and add another address
                        </TextButton>
                        <Button
                            isLoading={buttonPressed === 'finish' && loading}
                            type="submit"
                            onPress={() => setButtonPressed('finish')}
                        >
                            Save and continue
                        </Button>
                    </HStack>

                    {error && <ErrorCard message="There was an error adding your address" />}
                </Form>
            </VStack>
        </div>
    );
}
