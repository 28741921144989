import React from 'react';

import {DetailText, Heading3, Heading5, HighlightText} from '@sphericsio/design-system';

type ListItemStyleType = 'number' | 'image';

type PosterListItemStyleProps = {
    style: ListItemStyleType;
    index?: string;
    image?: React.ReactNode;
};
function PosterListItemStyle({style, index, image}: PosterListItemStyleProps) {
    const numberedList = () => {
        return (
            <Heading3 colour="logo">
                <HighlightText>{index}</HighlightText>
            </Heading3>
        );
    };

    let content = null;
    switch (style) {
        case 'number':
            content = numberedList();
            break;
        case 'image':
            content = image;
            break;
    }

    return (
        <div className="w-6 mt-0.5">
            <HighlightText>{content}</HighlightText>
        </div>
    );
}

type PosterListItemHeaderProps = {children: React.ReactNode};
export function PosterListItemHeader({children}: PosterListItemHeaderProps) {
    return <Heading5 colour="white">{children}</Heading5>;
}
type PosterListItemProps = {
    style: ListItemStyleType;
    index?: string;
    image?: React.ReactNode;
    header?: string;
    children?: React.ReactNode;
};
export function PosterListItem({style, index, header, image, children}: PosterListItemProps) {
    return (
        <div className="flex flex-row space-x-5">
            <PosterListItemStyle style={style} index={index} image={image} />
            <div className="flex flex-1 flex-col">
                <PosterListItemHeader>{header}</PosterListItemHeader>
                <DetailText inherit>{children}</DetailText>
            </div>
        </div>
    );
}

type PosterListProps = {
    children: React.ReactNode;
};
export function PosterList({children}: PosterListProps) {
    return <div className="grid grid-cols-1 gap-x-5 gap-y-10">{children}</div>;
}
