import React from 'react';
import {useLocation, Navigate, useNavigate} from 'react-router-dom';
import {useIntercom} from 'react-use-intercom';

import {DetailText, LoadingSpinner, VStack} from '@sphericsio/design-system';

import {useBackgroundExecutionStatus} from '../../hooks/background-execution';
import {PageHeader} from '../../components/page-header';
import {ErrorCard} from '../../components/error-card';
import {HSplitLayout} from '../../components/layout/h-split';
import {PosterLayout} from '../../components/onboarding/poster-layout';
import {PosterJoinTheMovement} from '../../components/onboarding/poster-join-the-movement';

type FinancialProviderCallbackSuccessProps = {
    backgroundExecutionId: string;
};
export function FinancialProviderCallbackSuccess({
    backgroundExecutionId,
}: FinancialProviderCallbackSuccessProps) {
    const {loading, error, data, finished} = useBackgroundExecutionStatus(backgroundExecutionId, {
        pollInterval: 5000,
        maxNumberOfTries: 60,
    });
    const navigate = useNavigate();
    const {trackEvent} = useIntercom();
    const failed = data?.fetchBackgroundExecution?.status === 'failed';
    const complete = data?.fetchBackgroundExecution?.completed_at !== null;

    React.useEffect(() => {
        if (data && finished) {
            trackEvent('Accountancy data synced');
            navigate('/');
        }
    }, [data, finished, navigate]);

    return (
        <HSplitLayout cta="logout">
            <VStack>
                <PageHeader title="Please wait whilst we sync your data" />
                <DetailText inline={false}>
                    This usually takes a couple of minutes but dependent on the volume of data, it
                    can take up to 20 minutes.
                </DetailText>
                {finished && !error && !failed && (
                    <DetailText inline={false}>Processing complete!</DetailText>
                )}
                {finished && !complete && (
                    <DetailText inline={false}>
                        Your upload is still being processed. Please come back later.
                    </DetailText>
                )}
                {(!finished || loading) && <LoadingSpinner />}
                {error && <ErrorCard />}
                {failed && (
                    <VStack>
                        <ErrorCard message="Sorry, we couldn't sync your data. Please try again later." />
                    </VStack>
                )}
            </VStack>
            <PosterLayout>
                <PosterJoinTheMovement />
            </PosterLayout>
        </HSplitLayout>
    );
}

type LocationState = {
    backgroundExecutionId?: string;
};

export function FinancialProviderCallbackSuccessUsingLocation() {
    const location = useLocation();
    const {backgroundExecutionId} = location.state as LocationState;
    if (backgroundExecutionId == null) {
        return <Navigate to="/" />;
    } else {
        return <FinancialProviderCallbackSuccess backgroundExecutionId={backgroundExecutionId} />;
    }
}
