import React from 'react';
import {useQuery} from '@apollo/client';
import {Link} from 'react-router-dom';

import {DetailText, HStack, textColorClassnames, VStack} from '@sphericsio/design-system';

import {ActionsPanel} from './actions';
import {UserMenu} from './user-menu';
import {BellIcon, ChevronLeftIcon} from './icons';
import {PageHeader} from './page-header';
import {graphql} from '../graphql/generated';
import {CurrentUserFragment} from '../graphql/generated/graphql';

const GetActionsDocument = graphql(/* GraphQL */ `
    query GetActions {
        getActionsForCompany {
            ...ActionsPanel_ActionItem
        }
    }
`);

type TopHeaderProps = {
    user: CurrentUserFragment;
    title?: string;
    subtitle?: React.ReactNode;
    tooltip?: React.ReactNode;
    backLink?: string;
    backLinkText?: string;
};
export function TopHeader({
    user,
    title,
    tooltip,
    subtitle,
    backLink,
    backLinkText,
}: TopHeaderProps) {
    const {data} = useQuery(GetActionsDocument, {
        fetchPolicy: 'network-only',
    });
    const [showingActions, setShowingActions] = React.useState(false);
    React.useEffect(() => {
        if (data != null && data.getActionsForCompany.length > 0) {
            setShowingActions(true);
        }
    }, [data]);
    return (
        <HStack align="start">
            <div className="flex flex-1 mb-5">
                <VStack full>
                    {backLink && backLinkText && (
                        <Link to={backLink}>
                            <div className={`flex items-center ${textColorClassnames('logo')}`}>
                                <div className="h-6 w-6">
                                    <ChevronLeftIcon />
                                </div>
                                <DetailText>{backLinkText}</DetailText>
                            </div>
                        </Link>
                    )}
                    {showingActions && <ActionsPanel actions={data?.getActionsForCompany} />}
                    {title && (
                        <PageHeader
                            title={title}
                            subtitle={subtitle}
                            tooltip={tooltip}
                            useHeading1
                        />
                    )}
                </VStack>
            </div>
            <div className="flex w-right-col justify-end">
                <HStack>
                    <div
                        data-testid="actionsButton"
                        className={`h-8 w-6 ${textColorClassnames('minor-action')} cursor-pointer`}
                        onClick={() => setShowingActions((value) => !value)}
                    >
                        <BellIcon
                            filled={showingActions}
                            showDot={data ? data.getActionsForCompany.length > 0 : false}
                        />
                    </div>
                    <UserMenu user={user} />
                </HStack>
            </div>
        </HStack>
    );
}
