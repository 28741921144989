import React from 'react';
import {Tooltip as VisxTooltip} from '@visx/xychart';
import Icon from 'carbon-react/lib/components/icon';

import {bgColorClassnames, DetailText} from '@sphericsio/design-system';

import {carbonAmountFormatter} from '../../carbon-amount';
import {SeriesData, SeriesDataItem} from './types';
import {colors, tooltipLegendLineWidth} from './style';
import {NoOperationalDataIcon} from './no-operational-data-icon';
import {formatYear} from './util';

/**
 * Context: We want the tooltip to always appear above the cursor,
 * and centered over the bars.
 *
 * We only have limited control over the positioning of the tooltip container generated by visx.
 * Our approach is as followed:
 * 1. With the limited options we have, we position the container such that:
 *   container.y = cursor.y
 *   container.x = bars.center
 * 2. Then, we can style the content relative to the container:
 *   content.y = -100% - <padding> // renders the content _above_ the cursor
 *   content.x = -50% // renders the content centered over the bars
 */
const visxTooltipPositioning = {
    // absolute positioning
    applyPositionStyle: true,
    // don't include any additional margins to the container
    offsetTop: 0,
    offsetLeft: 0,
    // don't reposition based on page bounds
    detectBounds: false,
    // snap container x position to the center of the bars
    snapTooltipToDatumX: true,
};

const visxTooltipProps = {
    unstyled: true,
    // Ensures that moving the mouse over the tooltip (or container) doesn't dismiss the tooltip
    className: 'pointer-events-none',
    ...visxTooltipPositioning,
};

const tooltipCursorDistance = 12;
const tooltipContentPositioningStyle = {
    transform: `translate(-50%, calc(-100% - ${tooltipCursorDistance}px))`,
};

export function Tooltip({
    currentYear,
    previousYear,
}: {
    currentYear: SeriesData;
    previousYear: SeriesData | null;
}) {
    return (
        <VisxTooltip<SeriesDataItem>
            {...visxTooltipProps}
            renderTooltip={({tooltipData}) => {
                const datum = tooltipData?.nearestDatum?.datum;
                if (!datum) {
                    return null;
                }
                const {quarter} = datum;
                const current = currentYear[quarter - 1];
                const previous = previousYear && previousYear[quarter - 1];
                return (
                    <div style={tooltipContentPositioningStyle}>
                        <TooltipCard current={current} previous={previous} />
                    </div>
                );
            }}
        />
    );
}

export type TooltipCardProps = {current: SeriesDataItem; previous: SeriesDataItem | null};
export function TooltipCard({current, previous}: TooltipCardProps) {
    const currentEmissions = current.dataPoint?.kg_co2e;
    const previousEmissions = previous?.dataPoint?.kg_co2e;
    if (!currentEmissions && !previousEmissions) {
        return null;
    }
    return (
        <div className={`rounded p-4 ${bgColorClassnames('white')} shadow-100 pr-0`}>
            {currentEmissions && previousEmissions && (
                <TooltipCompare current={currentEmissions} previous={previousEmissions} />
            )}

            <div className="flex flex-row">
                <TooltipEmissionsLabel data={previous} colour={colors.previousYear} />
                <TooltipEmissionsLabel data={current} colour={colors.currentYear} />
            </div>
        </div>
    );
}

function TooltipEmissionsLabel({data, colour}: {data: SeriesDataItem | null; colour: string}) {
    const emissions = data?.dataPoint?.kg_co2e;
    if (!emissions) {
        return null;
    }
    const {formattedAmount, unit} = carbonAmountFormatter({amount: emissions});
    const noOperationalDataWarning = data.dataPoint?.hasOperationalData ? null : (
        <NoOperationalDataIcon bgSize="extra-small" fontSize="small" className="align-baseline" />
    );
    return (
        <div className="flex flex-row text-0">
            <RoundedVerticalLine width={tooltipLegendLineWidth} colour={colour} />
            <div className="ml-2 flex flex-col mr-8 whitespace-nowrap">
                <DetailText bold>
                    {formattedAmount} {unit}&nbsp;&nbsp;{noOperationalDataWarning}
                </DetailText>
                <DetailText bold size="small" colour="secondary">
                    {formatYear(data.year)}: Q{data.quarter}
                </DetailText>
            </div>
        </div>
    );
}

function RoundedVerticalLine({colour, width}: {colour: string; width: number}) {
    const borderWidth = width / 2;
    return (
        <div
            style={{
                width: 0,
                borderWidth,
                borderColor: colour,
                borderStyle: 'solid',
                borderRadius: borderWidth,
            }}
        />
    );
}

const deltaPillStyle = {
    backgroundColor: colors.deltaBg,
    color: colors.deltaFg,
};

function TooltipCompare({current, previous}: {current: number; previous: number}) {
    // We don't explicitely care about current === previous
    // This is such an edge case, that it's not worth designing something seperately for it.
    // It'll just show 0% down from previous year
    const isUp = current > previous;

    const relativeUp = current / previous - 1;
    const percentUp = Math.round(relativeUp * 100);
    const {percentUpLabel, deltaSymbol, deltaText} = isUp
        ? {
              percentUpLabel: `+${percentUp}`,
              deltaSymbol: (
                  <Icon type="caret_up" color={deltaPillStyle.color} bgSize="extra-small" />
              ),
              deltaText: 'up on previous year',
          }
        : {
              percentUpLabel: `${percentUp}`,
              deltaSymbol: (
                  <Icon type="caret_down" color={deltaPillStyle.color} bgSize="extra-small" />
              ),
              deltaText: 'down from previous year',
          };
    return (
        <div className="mb-4">
            <div className="text-0">
                <span
                    style={deltaPillStyle}
                    className="inline-flex flex-row items-center pl-1 pr-2 rounded"
                >
                    <DetailText size="extra-small" inline inherit>
                        {deltaSymbol}{' '}
                    </DetailText>
                    <DetailText bold size="extra-small" inline inherit>
                        {percentUpLabel}%
                    </DetailText>
                </span>
            </div>
            <DetailText bold size="extra-small">
                &nbsp;{deltaText}
            </DetailText>
        </div>
    );
}
