import React from 'react';
import BugsnagClient from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import config from '../../config';
import {useCurrentUserQueryData} from '../components/user-context';

export const Bugsnag = BugsnagClient.start({
    apiKey: config.bugsnag.apiKey,
    plugins: [new BugsnagPluginReact()],
    releaseStage: DEPLOY_ENV,
    appVersion: BUILD_NUMBER,
    enabledReleaseStages: config.bugsnag.enabled ? [DEPLOY_ENV] : [],
    logger: config.bugsnag.suppressLogs ? null : undefined,
});

const BugsnagPlugin = Bugsnag.getPlugin('react');
if (BugsnagPlugin == null) {
    const err = new Error('missing React plugin for bugsnag');
    Bugsnag.notify(err);
    throw err;
}

export const ErrorBoundary = BugsnagPlugin.createErrorBoundary(React);

export function RecordBugsnagUser() {
    const userData = useCurrentUserQueryData();
    React.useEffect(() => {
        Bugsnag.setUser(userData?.id, userData?.email, userData?.name);
    }, [Bugsnag, userData]);
    return null;
}
