import React from 'react';
import {useMutation} from '@apollo/client';
import {useIntercom} from 'react-use-intercom';

import {FullScreenError, FullScreenLoading} from '../components/full-screen';
import {graphql} from '../graphql/generated';
import {useExternalRedirect} from '../hooks/use-external-redirect';

export const LogoutDocument = graphql(/* GraphQL */ `
    mutation Logout {
        logout
    }
`);

const Logout: React.FC = () => {
    const externalRedirect = useExternalRedirect();
    const intercom = useIntercom();
    const [logout, {error}] = useMutation(LogoutDocument, {
        onCompleted() {
            externalRedirect('https://earth.sage.com');
        },
    });

    React.useEffect(() => {
        intercom.hardShutdown();
        logout().catch(() => {
            /* Catching for testability purposes. See https://github.com/apollographql/apollo-client/issues/7167 */
        });
    }, [logout]);

    return error ? (
        <FullScreenError error={error} />
    ) : (
        <FullScreenLoading message="Logging you out..." />
    );
};

export {Logout};
