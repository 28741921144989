import React from 'react';
import {Navigate, Route, Routes as RouterRoutes} from 'react-router-dom';

import {
    EmailVerified,
    HasActiveSubscription,
    HasNoApiSync,
    HasNoDataUpload,
    HasSage50Enabled,
    HasTransactionCategorisationEnabled,
    LoggedIn,
    NotLoggedIn,
    TermsAndConditionsAccepted,
    WithConnectionStatus,
} from './components/route-guard';
import config from '../config';
import {Index} from './views';
import {Dashboard} from './views/dashboard';
import {LegacyLogin} from './views/legacy-login';
import {Logout} from './views/logout';
import {ExternalIdentityProvider} from './views/external-identity-provider';
import {FinancialProviderSync} from './views/financial-provider-sync';
import {Suppliers} from './views/suppliers';
import {NotFound} from './views/not-found';
import {Verify} from './views/verify';
import {CompanyDetails} from './views/onboarding/company-details';
import {ForgotPassword} from './views/reset-password/forgot-password';
import {ForgotPasswordSuccess} from './views/reset-password/forgot-password-success';
import {ResetPassword} from './views/reset-password/reset-password';
import {Operations} from './views/operations/operations';
import {Unverified} from './views/unverified';
import {MatomoPageTracker} from './components/matomo-page-tracker';
import {LoginAccounting} from './views/accounting/login';
import {AccountingRoutes} from './views/accounting/routes';
import {TermsAndConditions, termsAndConditionsRoute} from './views/terms';
import {XeroRoutes} from './views/accounting/xero/routes';
import {Targeting} from './views/targeting';
import {TargetingSetTarget} from './views/targeting/set-target';
import {TopActivitiesWrapper} from './views/top-activities';
import {SubscriptionRoutes} from './views/subscription/routes';
import {FinancialDataSettings} from './views/settings/financial-data-settings';
import {CompanySubscriptionSettings} from './views/settings/subscription';
import {FileUploadWizard} from './components/file-upload-wizard';
import {SorryToSeeYouGo} from './views/sorry-to-see-you-go';
import {IdentityProviderCallback} from './views/signup/callback';
import {FinancialProviderCompanySelect} from './views/accounting/company-select';
import {Categorisation} from './views/categorisation';
import {Invited} from './views/invited';

const Routes: React.FC = () => {
    return (
        <>
            <MatomoPageTracker />
            <RouterRoutes>
                <LoggedIn>
                    <Route path="/" element={<Index />} />
                    <TermsAndConditionsAccepted>
                        <EmailVerified>
                            <HasNoDataUpload>
                                <WithConnectionStatus statuses={[null]}>
                                    <Route path="/sync" element={<FinancialProviderSync />} />
                                </WithConnectionStatus>
                                <WithConnectionStatus statuses={['missing_tenant']}>
                                    <Route
                                        path="/company-select"
                                        element={<FinancialProviderCompanySelect />}
                                    />
                                </WithConnectionStatus>
                            </HasNoDataUpload>
                            <HasActiveSubscription>
                                <HasNoApiSync>
                                    <HasSage50Enabled>
                                        <Route path="/upload">
                                            <Navigate to="/sage50" replace />
                                        </Route>
                                        <Route
                                            path="/sage50"
                                            element={<FileUploadWizard provider="sage50_upload" />}
                                        />
                                    </HasSage50Enabled>
                                </HasNoApiSync>
                                <WithConnectionStatus statuses={['ready', 'missing_token']}>
                                    <HasTransactionCategorisationEnabled>
                                        <Route
                                            path="/categorisation"
                                            element={<Categorisation />}
                                        />
                                    </HasTransactionCategorisationEnabled>
                                    <Route
                                        path="/suppliers/identify"
                                        element={<Suppliers page="identify" />}
                                    />
                                    <Route
                                        path="/suppliers/transactions"
                                        element={<Suppliers page="transactions" />}
                                    />
                                    <Route
                                        path="/suppliers/financial-accounts"
                                        element={<Suppliers page="financial-accounts" />}
                                    />
                                    <Route
                                        path="/operations/:periodStart/:periodEnd/:page"
                                        element={<Operations />}
                                    />
                                    <Route path="/operations/:page" element={<Operations />} />
                                    <Route path="/operations" element={<Operations />} />
                                    <Route path="/targeting/set" element={<TargetingSetTarget />} />
                                    <Route path="/targeting" element={<Targeting />} />
                                    <Route
                                        path="/baseline/:periodStart/:periodEnd/:page"
                                        element={<Operations isBaselineView />}
                                    />
                                    <Route
                                        path="/baseline/:page"
                                        element={<Operations isBaselineView />}
                                    />
                                    <Route
                                        path="/baseline"
                                        element={<Operations isBaselineView />}
                                    />
                                    <Route
                                        path="/activities/:periodStart/:periodEnd"
                                        element={<TopActivitiesWrapper />}
                                    />
                                    <Route
                                        path="/onboarding/company-details"
                                        element={<CompanyDetails />}
                                    />
                                    <Route path="/dashboard/:period" element={<Dashboard />} />
                                    <Route path="/dashboard" element={<Dashboard />} />
                                    <Route
                                        path="/suppliers"
                                        element={<Navigate to="/suppliers/financial-accounts" />}
                                    />
                                    <Route
                                        path="/suppliers/top"
                                        element={<Suppliers page="top" />}
                                    />
                                    <Route
                                        path="/suppliers/transactions"
                                        element={<Suppliers page="transactions" />}
                                    />
                                </WithConnectionStatus>
                            </HasActiveSubscription>
                        </EmailVerified>
                    </TermsAndConditionsAccepted>
                    <Route path="/subscription/*" element={<SubscriptionRoutes />} />
                    <Route path="/settings/data-connection" element={<FinancialDataSettings />} />
                    <Route
                        path="/settings/subscription"
                        element={<CompanySubscriptionSettings />}
                    />
                    <Route path="/settings" element={<Navigate to="/settings/data-connection" />} />
                    <Route path="/unverified" element={<Unverified />} />
                    <Route path="/sorry-to-see-you-go" element={<SorryToSeeYouGo />} />
                    <Route path={termsAndConditionsRoute} element={<TermsAndConditions />} />
                    <Route path="/logout" element={<Logout />} />
                </LoggedIn>
                <NotLoggedIn>
                    <Route path="/login/accounting" element={<LoginAccounting />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/forgot-password/success" element={<ForgotPasswordSuccess />} />
                    <Route path="/reset-password/:id/:token" element={<ResetPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/legacy-login" element={<LegacyLogin />} />
                    <Route path="/login" element={<ExternalIdentityProvider />} />
                    <Route path="/signup" element={<ExternalIdentityProvider />} />
                    <Route path="/invited" element={<Invited />} />
                </NotLoggedIn>

                <Route path="/verify/:id/:token" element={<Verify />} />
                <Route path="/sage-id/callback" element={<IdentityProviderCallback />} />
                <Route path="/xero/*" element={<XeroRoutes />} />
                <Route path="/quickbooks/*" element={<AccountingRoutes provider="quickbooks" />} />
                <Route path="/sage/*" element={<AccountingRoutes provider="sage" />} />
                {config.allowFakeFinancialProvider && (
                    <Route path="/fake/*" element={<AccountingRoutes provider="fake" />} />
                )}
                <Route path="*" element={<NotFound />} />
            </RouterRoutes>
        </>
    );
};

export {Routes};
