import React from 'react';
import {useQuery, useMutation} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import {VStack} from '@sphericsio/design-system';

import {Layout} from '../../components/layout';
import {ErrorCard} from '../../components/error-card';
import {Loading} from '../../components/loading';
import {SetBaselinePanel} from '../../components/targeting/set-baseline-panel';
import {TargetingBaselinePanel} from '../../components/targeting/baseline-panel';
import {GetReportingPeriodsDocument} from '../../components/operations/graphql';
import {TargetingBaselineSummary} from '../../components/targeting/targeting-baseline-summary';
import {graphql} from '../../graphql/generated';

const GetTargetingBaselineDocument = graphql(/* GraphQL */ `
    query GetTargetingBaseline {
        getTargetingBaseline {
            id
            company_reporting_period {
                period_start
                period_end
            }
            ...TargetingBaselinePanel_TargetingBaseline
        }
        getReductionTarget {
            ...TargetingBaselinePanel_ReductionTarget
        }
        getReductionTargetVisualisation {
            ...TargetingBaselineSummary_ReductionTargetVisualisation
        }
    }
`);

const CreateBaselineReportingPeriodDocument = graphql(/* GraphQL */ `
    mutation CreateBaselineReportingPeriod($periodStart: Date!, $periodEnd: Date!) {
        createBaselineReportingPeriod(periodStart: $periodStart, periodEnd: $periodEnd) {
            ...CompanyReportingPeriod
        }
    }
`);

export function Targeting() {
    const {loading, error, data} = useQuery(GetTargetingBaselineDocument, {
        fetchPolicy: 'network-only',
    });
    const [
        createBaselineReportingPeriod,
        {loading: submitting, error: errorSubmitting, data: reportingPeriod},
    ] = useMutation(CreateBaselineReportingPeriodDocument);
    const navigate = useNavigate();
    React.useEffect(() => {
        if (reportingPeriod != null) {
            navigate('/baseline');
        }
    }, [reportingPeriod, navigate]);
    return (
        <Layout title="Targeting">
            <VStack>
                {loading && <Loading />}
                {error && <ErrorCard />}
                {data && data.getTargetingBaseline == null && (
                    <SetBaselinePanel
                        onCreateBaselineReportingPeriod={(periodStart, periodEnd) =>
                            createBaselineReportingPeriod({
                                variables: {periodStart, periodEnd},
                                refetchQueries: [{query: GetReportingPeriodsDocument}],
                            })
                        }
                        isSubmitting={submitting}
                        hasErrorSubmitting={errorSubmitting}
                    />
                )}
                {data && data.getTargetingBaseline != null && (
                    <TargetingBaselinePanel
                        targetingBaseline={data.getTargetingBaseline}
                        reductionTarget={data.getReductionTarget}
                    />
                )}
                <TargetingBaselineSummary
                    targetingBaseline={data?.getTargetingBaseline}
                    reductionTarget={data?.getReductionTarget}
                    reductionTargetVisualisation={data?.getReductionTargetVisualisation}
                />
            </VStack>
        </Layout>
    );
}
