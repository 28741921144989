import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {useIntercom} from 'react-use-intercom';

import appConfig from '../../config';
import {FinancialProviderConnectionStatus} from '../graphql/generated/graphql';
import {storeRedirectPath} from '../services/login-redirect';
import {useCurrentUserQueryData, UserContext, useUser} from './user-context';
import {termsAndConditionsRoute} from '../views/terms';
import {useFeatureFlag} from '../hooks/use-feature-flag';

type RenderNestedRoutesProps = {
    children: React.ReactNode;
};

export function RenderNestedRoutes({children}: RenderNestedRoutesProps) {
    return (
        <Route path="*">
            <Routes>{children}</Routes>
        </Route>
    );
}

export function LoggedIn({children}: RenderNestedRoutesProps) {
    const data = useCurrentUserQueryData();
    if (data == null) {
        storeRedirectPath();
        return <Navigate to="/login" replace />;
    } else {
        const {trackEvent} = useIntercom();
        trackEvent(`Last logged in date`);
        return (
            <UserContext value={data}>
                <RenderNestedRoutes>{children}</RenderNestedRoutes>
            </UserContext>
        );
    }
}

export function NotLoggedIn({children}: RenderNestedRoutesProps) {
    const data = useCurrentUserQueryData();

    if (data != null) {
        return <Navigate to="/" replace />;
    } else {
        return <RenderNestedRoutes>{children}</RenderNestedRoutes>;
    }
}

export function EmailVerified({children}: RenderNestedRoutesProps) {
    const user = useUser();
    if (user.isEmailVerified) {
        return <RenderNestedRoutes>{children}</RenderNestedRoutes>;
    } else {
        return <Navigate to="/unverified" replace />;
    }
}

export function TermsAndConditionsAccepted({children}: RenderNestedRoutesProps) {
    const user = useUser();
    if (user.terms_and_conditions_accepted) {
        return <RenderNestedRoutes>{children}</RenderNestedRoutes>;
    } else {
        return <Navigate to={termsAndConditionsRoute} replace />;
    }
}

export function HasActiveSubscription({children}: RenderNestedRoutesProps) {
    const {subscription} = useUser();
    if (!appConfig.featureFlags.subscriptions) {
        return <RenderNestedRoutes>{children}</RenderNestedRoutes>;
    }

    if (subscription == null) {
        return <Navigate to="/subscription" replace />;
    } else if (subscription.status === 'active' || subscription.status === 'cancelled') {
        return <RenderNestedRoutes>{children}</RenderNestedRoutes>;
    } else {
        return <Navigate to="/settings/subscription" replace />;
    }
}

export function HasNoDataUpload({children}: RenderNestedRoutesProps) {
    const user = useUser();

    if (user.financialProvider?.sync_method === 'data-upload') {
        return <Navigate to="/upload" replace />;
    } else {
        return <RenderNestedRoutes>{children}</RenderNestedRoutes>;
    }
}

export function HasNoApiSync({children}: RenderNestedRoutesProps) {
    const user = useUser();

    if (user.financialProvider?.sync_method === 'api-sync') {
        return <Navigate to="/sync" replace />;
    } else {
        return <RenderNestedRoutes>{children}</RenderNestedRoutes>;
    }
}

export function HasSage50Enabled({children}: RenderNestedRoutesProps) {
    const isSage50Enabled = useFeatureFlag('sage50');

    if (!isSage50Enabled) {
        return <Navigate to="/sync" replace />;
    } else {
        return <RenderNestedRoutes>{children}</RenderNestedRoutes>;
    }
}

export function HasTransactionCategorisationEnabled({children}: RenderNestedRoutesProps) {
    const isTransactionCategorisationFFEnabled = useFeatureFlag('new_categorisation_page');

    if (isTransactionCategorisationFFEnabled) {
        return <RenderNestedRoutes>{children}</RenderNestedRoutes>;
    }

    return <Navigate to="/dashboard" replace />;
}

export function WithConnectionStatus({
    children,
    statuses,
}: RenderNestedRoutesProps & {
    statuses: (FinancialProviderConnectionStatus | null)[];
}) {
    const status = useUser().financialProvider?.connection_status ?? null;

    if (statuses.includes(status)) {
        return <RenderNestedRoutes>{children}</RenderNestedRoutes>;
    }

    switch (status) {
        case 'ready':
            return <Navigate to="/dashboard" replace />;
        case 'missing_token':
            return <Navigate to="/settings/data-connection" replace />;
        case 'missing_tenant':
            return <Navigate to="/company-select" replace />;
        default:
            return <Navigate to="/sync" replace />;
    }
}
