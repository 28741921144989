import {CategorisationHierarchyItem} from '../../graphql/generated/graphql';

export type UserCategorisationHierarchyItem = {
    key: string;
    taxonomyKey: string;
    taxonomyTermKey: string;
    title: string;
    children: UserCategorisationHierarchyItem[];
    parent?: UserCategorisationHierarchyItem;
};

export function flattenHierarchyForTaxonomyKeys(
    hierarchies: UserCategorisationHierarchyItem[],
    keys: string[],
): UserCategorisationHierarchyItem[] {
    const result: UserCategorisationHierarchyItem[] = [];

    hierarchies.map((root) => {
        traverseChildren(root, (item) => {
            if (keys.includes(item.key) && !result.find(({key}) => key === item.key)) {
                result.push(item);
            }
        });
    });

    return result;
}

export function buildTaxonomyFromChild(
    hierarchy: UserCategorisationHierarchyItem,
    result: string[] = [],
): string[] {
    result.push(hierarchy.key);
    if (!hierarchy.parent) {
        return result;
    } else {
        return buildTaxonomyFromChild(hierarchy.parent, result);
    }
}

export function buildHierarchyFromTaxonomyTerm(
    hierarchy: UserCategorisationHierarchyItem[],
    term: string,
): string[] | undefined {
    for (const item of hierarchy) {
        if (item.key === term) {
            return findParentItem(item);
        }
        if (item.children.length) {
            const match = buildHierarchyFromTaxonomyTerm(item.children, term);
            if (match) return match;
        }
    }
}

function findParentItem(item: UserCategorisationHierarchyItem, acc: string[] = []): string[] {
    acc.push(item.key);
    if (!item.parent) {
        return acc;
    }
    return findParentItem(item.parent, acc);
}

export function getRootForAccountingTaxonomyTermKey(
    hierarchy: UserCategorisationHierarchyItem[],
    taxonomyTermKey: string,
) {
    let root: UserCategorisationHierarchyItem | undefined;

    hierarchy.forEach((taxonomy) => {
        traverseChildren(taxonomy, (item) => {
            if (item.key == `accounting/${taxonomyTermKey}`) {
                root = taxonomy;
            }
        });
    });

    return root;
}

export function getChildrenForTaxonomyKey(
    hierarchy: UserCategorisationHierarchyItem[],
    taxonomyKey: string,
) {
    const flattened = flattenHierarchyForTaxonomyKeys(hierarchy, [taxonomyKey]);
    return flattened.find((item) => item.key === taxonomyKey)?.children ?? [];
}

function traverseChildren(
    hierarchy: UserCategorisationHierarchyItem,
    cb: (hierarchy: UserCategorisationHierarchyItem) => void,
) {
    const queue: UserCategorisationHierarchyItem[] = [hierarchy];

    while (queue.length) {
        const current = queue.shift();

        if (current) {
            cb(current);

            if (current.children) {
                current.children.forEach((child) => queue.push(child));
            }
        }
    }
}

export function convertToUserCategorisationHierarchy(
    hierarchy: CategorisationHierarchyItem[],
): UserCategorisationHierarchyItem[] {
    // TODO look into whether we can safely remove the secndary mapping from mvp ui common
    return hierarchy.map((item) => convertItem(item));
}

function convertItem(
    item: CategorisationHierarchyItem,
    parent?: UserCategorisationHierarchyItem,
): UserCategorisationHierarchyItem {
    const [taxonomyKey, taxonomyTermKey] = item.key.split('/');
    const converted = {
        parent,
        key: item.key,
        taxonomyKey,
        taxonomyTermKey,
        title: item.name ?? taxonomyTermKey,
        children: [] as UserCategorisationHierarchyItem[],
    };
    if (item.children?.length) {
        converted.children = item.children.map((child) => convertItem(child, converted));
    }
    return converted;
}
