import React from 'react';

import {Paragraph, textColorClassnames, VStack} from '@sphericsio/design-system';

import {Layout} from '../components/layout';
import {Transactions} from '../components/suppliers/transactions';

export function Categorisation() {
    return (
        <Layout title="Categorise your transactions">
            <VStack full>
                <div className={textColorClassnames()}>
                    <Paragraph>
                        Sage Earth automatically categorises your transactions to determine the
                        emissions factor to use for each spend. We recommend reviewing these
                        regularly to edit/add categories. Any changes you make here will be
                        reflected in your carbon footprint and help train our model to make
                        automated categorisation more accurate in future.
                    </Paragraph>
                </div>
                <Transactions />
            </VStack>
        </Layout>
    );
}
