import React, {ReactNode} from 'react';
import Link from 'carbon-react/lib/components/link';
import Message from 'carbon-react/lib/components/message';
import * as Yup from 'yup';
import Bugsnag from '@bugsnag/js';
import {useMutation, useQuery} from '@apollo/client';
import {Navigate} from 'react-router';

import {Form, FilterableSelect, Option} from '@sphericsio/forms';
import {Button, DetailText, VStack} from '@sphericsio/design-system';
import {errored, getFirstErrorCode, promised, Promised, resolved} from '@sphericsio/mvp-ui-common';

import {HSplitLayout} from '../../components/layout/h-split';
import {PageHeader} from '../../components/page-header';
import {PosterLayout} from '../../components/onboarding/poster-layout';
import {PosterJoinTheMovement} from '../../components/onboarding/poster-join-the-movement';
import {ErrorCard} from '../../components/error-card';
import {graphql} from '../../graphql/generated';
import {FinancialProviderName} from '../../graphql/generated/graphql';
import {useUser} from '../../components/user-context';

const schema = Yup.object({
    account: Yup.string().required('Please select an account'),
});

export const GetTenantsDocument = graphql(/* GraphQL */ `
    query GetTenants($provider: FinancialProviderName!) {
        financialProviderTenants(provider: $provider) {
            id
            name
        }
    }
`);

export const SetTenantDocument = graphql(/* GraphQL */ `
    mutation SetTenant($provider: FinancialProviderName!, $tenantId: String!) {
        setFinancialProviderTenant(provider: $provider, tenantId: $tenantId) {
            id
        }
    }
`);

export function FinancialProviderCompanySelect() {
    const user = useUser();
    const provider = user.financialProvider?.financial_provider as FinancialProviderName;

    if (!provider) {
        return <Navigate to="/sync" replace />;
    }

    const {refetch, ...res} = useQuery(GetTenantsDocument, {variables: {provider}});
    const [setTenant, setTenantRes] = useMutation(SetTenantDocument);

    const tenants = promised(res)
        .map((data) => data.financialProviderTenants)
        .flatMap((tenants) =>
            tenants ? resolved(tenants) : errored(new Error("Provider doesn't have tenants")),
        );

    const submitStatus = promised(setTenantRes);

    if (submitStatus.data) {
        return (
            <Navigate
                to={`/${provider}/success`}
                state={{backgroundExecutionId: submitStatus.data.setFinancialProviderTenant?.id}}
            />
        );
    }

    return (
        <FinancialProviderCompanySelectUI
            provider={provider}
            tenants={tenants}
            onSubmit={(company) =>
                setTenant({
                    variables: {provider, tenantId: company},
                })
            }
            submitStatus={submitStatus}
            onReloadClick={refetch}
        />
    );
}

export type Tenant = {
    id: string;
    name: string;
};

export type UIProps = {
    provider: FinancialProviderName;
    tenants: Promised<Tenant[]>;
    submitStatus: Promised<unknown>;
    onSubmit: (id: string) => void;
    onReloadClick: () => void;
};

function providerName(provider: FinancialProviderName): string {
    switch (provider) {
        case 'sage':
            return 'Sage Business Cloud Accounting';
        case 'xero':
            return 'Xero';
        default:
            return 'your financial provider';
    }
}

function disconnectProviderLink(provider: FinancialProviderName): ReactNode | null {
    switch (provider) {
        case 'sage':
            return (
                <>
                    <Link href="https://accounts-extra.sageone.com/settings/" target="_blank">
                        Settings
                    </Link>{' '}
                    &gt;{' '}
                    <Link href="https://accounts-extra.sageone.com/apps" target="_blank">
                        apps and connections
                    </Link>{' '}
                    &gt;{' '}
                    <Link
                        href="https://central.uk.sageone.com/account/client_applications"
                        target="_blank"
                    >
                        add and manage apps.
                    </Link>
                </>
            );
        case 'xero':
            return (
                <>
                    <Link href="https://go.xero.com/Settings/" target="_blank">
                        Settings
                    </Link>{' '}
                    &gt;{' '}
                    <Link href="https://go.xero.com/Settings/OrganisationSettings/" target="_blank">
                        organisation settings
                    </Link>{' '}
                    &gt;{' '}
                    <Link href="https://go.xero.com/Settings/ConnectedApps/" target="_blank">
                        connected apps
                    </Link>
                </>
            );
        default:
            return null;
    }
}

export function FinancialProviderCompanySelectUI({
    provider,
    tenants,
    submitStatus,
    onSubmit: onCompanySelect,
    onReloadClick,
}: UIProps) {
    const onSubmit = (formValues: {account?: string}) => {
        if (!formValues.account) {
            // Shouldn't happen. Form validation should catch this.
            Bugsnag.notify(new Error('Invalid company select form submitted'));
        } else {
            onCompanySelect(formValues.account);
        }
    };

    const hasCannotChangeTenantError =
        submitStatus.error && getFirstErrorCode(submitStatus.error) === 'CANNOT_CHANGE_TENANT';

    const hasTerminalError = tenants.error || hasCannotChangeTenantError;
    const errorLocation = hasCannotChangeTenantError ? 'inline' : 'top-of-page';
    const disconnectLink = disconnectProviderLink(provider);
    return (
        <HSplitLayout cta="logout">
            <div className="h-full flex flex-col space-y-carbon-400">
                <Message
                    open={
                        !!(tenants.error || submitStatus.error) && errorLocation === 'top-of-page'
                    }
                    variant="error"
                >
                    {tenants.error && (
                        <ErrorCard
                            noStyle
                            error={tenants.error}
                            message={
                                <>
                                    We&apos;ve had a problem loading {providerName(provider)}{' '}
                                    accounts.{' '}
                                    <Link onClick={() => onReloadClick()}>
                                        Click here to try again.
                                    </Link>
                                </>
                            }
                        />
                    )}
                    {submitStatus.error && <ErrorCard noStyle error={submitStatus.error} />}
                </Message>
                <div className="flex flex-1 items-center">
                    <Form onSubmit={onSubmit} initialValues={{}} validationSchema={schema}>
                        <VStack spacingClass="space-y-carbon-400">
                            <PageHeader useHeading1 title="Please choose an account to sync" />
                            <FilterableSelect
                                name="account"
                                id="account"
                                label="Select account"
                                maxWidth="17rem"
                                error={
                                    hasCannotChangeTenantError &&
                                    'You have already chosen your account to sync. Please go to your dashboard to view your data.'
                                }
                                isLoading={tenants.loading}
                                disabled={!!tenants.error}
                                readOnly={hasTerminalError}
                                openOnFocus
                            >
                                {
                                    // FilterableSelect errors if the content is null/undefined when opening the popover,
                                    // but an empty array is fine.
                                    // We need to make sure to default to [] (and not use optional chaining) to avoid this error
                                    (tenants.data ?? []).map(({id, name}) => (
                                        <Option text={name} value={id} key={id} />
                                    ))
                                }
                            </FilterableSelect>
                            {disconnectLink !== null && (
                                <DetailText>
                                    You can disconnect from Sage Earth at any time via{' '}
                                    {providerName(provider)} by going to: {disconnectLink}
                                </DetailText>
                            )}
                            <div className="flex w-full flex-row justify-end">
                                <Button
                                    type="submit"
                                    isDisabled={hasTerminalError}
                                    isLoading={submitStatus.loading}
                                >
                                    Sync now
                                </Button>
                            </div>
                        </VStack>
                    </Form>
                </div>
            </div>
            <PosterLayout bgImage={{type: 'onboarding'}}>
                <PosterJoinTheMovement />
            </PosterLayout>
        </HSplitLayout>
    );
}
