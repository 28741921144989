import React from 'react';

import {VStack} from '@sphericsio/design-system';

import {ProfileEnergyContext} from './context';
import {ProfileSectionPanel} from '../section-panel';

export function ProfileEnergyIntro() {
    const ctx = React.useContext(ProfileEnergyContext);
    return (
        <VStack>
            <ProfileSectionPanel
                heading="Energy"
                type="pending"
                reportingPeriod={ctx.reportingPeriod}
                onPressStart={() => ctx.dispatch({type: 'start'})}
            >
                We need you to tell us about the energy your company used during this reporting
                period.
            </ProfileSectionPanel>
        </VStack>
    );
}
