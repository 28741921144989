import React from 'react';
import {useQuery} from '@apollo/client';
import {useLocation} from 'react-router';

import {HSplitLoading} from '../../../components/layout/h-split';
import {useExternalRedirect} from '../../../hooks/use-external-redirect';
import {graphql} from '../../../graphql/generated';
import {PosterJoinTheMovement} from '../../../components/onboarding/poster-join-the-movement';

export const xeroRedirectAfterLoginQuery = graphql(/* GraphQL */ `
    query GetXeroRedirectAfterLogin($callbackUrl: String!) {
        financialProviderLoginRedirect(
            callbackUrl: $callbackUrl
            provider: xero
            redirectPath: "/xero/app-store-signup-return"
        ) {
            url
        }
    }
`);

export function XeroAppStoreSignupReturn() {
    const externalRedirect = useExternalRedirect();
    const location = useLocation();
    const {error} = useQuery(xeroRedirectAfterLoginQuery, {
        variables: {
            callbackUrl: `${location.pathname}${location.search}`,
        },
        onCompleted(data) {
            externalRedirect(data.financialProviderLoginRedirect.url, true);
        },
    });
    return (
        <HSplitLoading error={error}>
            <PosterJoinTheMovement />
        </HSplitLoading>
    );
}
