import React from 'react';
import {useParams, Navigate} from 'react-router-dom';
import {useMutation, ApolloError} from '@apollo/client';

import {updateCurrentUser} from '@sphericsio/mvp-ui-common';

import {FullScreenError, FullScreenLoading} from '../components/full-screen';
import {CurrentUserFragmentDocument} from '../graphql/fragments';
import {graphql} from '../graphql/generated';

const VerifyEmailDocument = graphql(/* GraphQL */ `
    mutation VerifyEmail($id: ID!, $token: String!) {
        verifyEmail(id: $id, token: $token) {
            ...CurrentUser
        }
    }
`);

const ERROR_CODES: Record<string, string> = {
    UNKNOWN_VERIFICATION: 'Sorry, that link is invalid.',
    INVALID_TOKEN: 'Sorry, that link is invalid.',
    ALREADY_VERIFIED: "You've already verified your email.",
    VERIFICATION_EXPIRED: 'Sorry, that link has expired.',
};
function errorMessage(error: ApolloError) {
    const {
        graphQLErrors: [gqlError],
        networkError,
    } = error;
    if (gqlError && gqlError.extensions?.code === 'FORBIDDEN') {
        return ERROR_CODES[gqlError.message];
    } else if (networkError) {
        return 'Our service is currently having problems. Please try again later.';
    }
}

export function Verify() {
    const {id, token} = useParams();
    const [preMutation, setPreMutation] = React.useState(true);
    const [verifyEmail, {loading, error}] = useMutation(VerifyEmailDocument);

    React.useLayoutEffect(() => {
        if (id && token) {
            verifyEmail({
                variables: {id, token},
                update: (cache, data) => {
                    if (data.data?.verifyEmail != null) {
                        updateCurrentUser(
                            cache,
                            data.data?.verifyEmail,
                            CurrentUserFragmentDocument,
                        );
                    }
                },
            });
            setPreMutation(false);
        }
    }, []);

    if (loading || preMutation) {
        return <FullScreenLoading />;
    }

    if (error) {
        return <FullScreenError message={errorMessage(error)} buttonHref="/" />;
    }

    return <Navigate to="/" />;
}
