import React from 'react';
import classnames from 'classnames';

import {
    buildDataTableComponent,
    tableProps,
    Elements,
    TableTextAlignment,
} from '@sphericsio/mvp-ui-common';
export type {SortDirection} from '@sphericsio/mvp-ui-common';
import {
    bgColorClassnames,
    DetailText,
    outlineColorClassnames,
    textColorClassnames,
} from '@sphericsio/design-system';

function textAlignClassnames(textAlign?: TableTextAlignment) {
    return classnames({
        'text-left': textAlign === 'left' || textAlign == null,
        'text-center': textAlign === 'center',
        'text-right': textAlign === 'right',
    });
}

const Table: Elements['Table'] = ({children, ...props}) => {
    return (
        <table className="w-full border-separate" style={{borderSpacing: '0 0.5rem'}} {...props}>
            {children}
        </table>
    );
};

const THeadContext = React.createContext(false);
const THead: Elements['THead'] = (props) => {
    return (
        <THeadContext.Provider value={true}>
            <thead {...props} />
        </THeadContext.Provider>
    );
};

const THeader: Elements['THeader'] = ({children, textAlign, width, ...props}) => {
    return (
        <th
            className={classnames(
                'p-5 first:rounded-l-xl last:rounded-r-xl',
                textColorClassnames('white'),
                textAlignClassnames(textAlign),
                bgColorClassnames('table-header'),
            )}
            style={{width}}
            {...props}
        >
            <DetailText inherit>{children}</DetailText>
        </th>
    );
};

const TRow: Elements['TRow'] = (props) => {
    return <tr {...props} />;
};

const TData: Elements['TData'] = ({children, textAlign, width, skeleton, condensed, ...props}) => {
    return (
        <td
            className={classnames(
                'p-5',
                bgColorClassnames('white'),
                outlineColorClassnames(),
                textAlignClassnames(textAlign),
                {
                    'first:rounded-l-xl last:rounded-r-xl border-t border-b first:border-l last:border-r':
                        !condensed,
                    'border-t pt-3.5 pb-1.5 first:pl-1 last:pr-1': condensed,
                },
            )}
            style={{width}}
            {...props}
        >
            <div className="w-full">
                {skeleton ? (
                    <span
                        className={`inline-block w-3/4 ${textColorClassnames(
                            'transparent',
                        )} ${bgColorClassnames('white')}`}
                    >
                        &nbsp;
                    </span>
                ) : (
                    <DetailText inline={false}>{children}</DetailText>
                )}
            </div>
        </td>
    );
};

const TBody: Elements['TBody'] = (props) => <tbody {...props} />;

const elements = {Table, THead, TRow, THeader, TBody, TData};

export const DataTable = buildDataTableComponent(elements);

export {tableProps, Table, THead, TRow, THeader, TBody, TData};
