import React from 'react';

import {DetailText, Heading3, HighlightText, Link, VStack} from '@sphericsio/design-system';

import {PosterList, PosterListItem} from './poster-list';

export function PosterSicCodes() {
    return (
        <VStack>
            <Heading3 colour="white">
                <HighlightText>How to find</HighlightText> your SIC code(s):
            </Heading3>

            <PosterList>
                <PosterListItem style="number" index="01" header="Visit Companies House">
                    Search your full company name on companies house. You can do this by following
                    this{' '}
                    <Link
                        uppercase={false}
                        openInNewWindow
                        href="https://find-and-update.company-information.service.gov.uk"
                    >
                        <DetailText>
                            <HighlightText underline>link</HighlightText>
                        </DetailText>
                    </Link>
                    .
                </PosterListItem>
                <PosterListItem
                    style="number"
                    index="02"
                    header="Select your company from the search results"
                >
                    You will be presented with a list of companies that match the name you provided.
                    Select the correct details that match your company.
                </PosterListItem>
                <PosterListItem style="number" index="03" header="Locate your SIC code">
                    You can find a 5-digit code within your company overview. This may be labelled
                    ‘Nature of business (SIC)’
                </PosterListItem>
            </PosterList>
        </VStack>
    );
}
