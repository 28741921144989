import React from 'react';
import {useApolloClient} from '@apollo/client';
import {useIntercom} from 'react-use-intercom';

import {
    Button,
    CircleTick,
    DetailText,
    Heading3,
    HStack,
    LoadingSpinner,
    textColorClassnames,
    VStack,
} from '@sphericsio/design-system';
import {usePollQuery} from '@sphericsio/mvp-ui-common';

import {GetCurrentUserDocument} from '../user-context';
import {ErrorCard} from '../error-card';
import {CurrentUserFragmentDoc} from '../../graphql/generated/graphql';
import {useFragment} from '../../graphql/generated';

type SubscriptionSubmissionProps = {
    onError: () => void;
    onSuccess: () => void;
};
export function SubscriptionSubmission({onError, onSuccess}: SubscriptionSubmissionProps) {
    const {error, data, finished} = usePollQuery(
        GetCurrentUserDocument,
        (data) => {
            const user = useFragment(CurrentUserFragmentDoc, data.currentUser);
            return user?.subscription?.status === 'active';
        },
        {pollInterval: 4000, maxNumberOfTries: 5},
    );
    const {trackEvent} = useIntercom();

    const apollo = useApolloClient();
    React.useEffect(() => {
        if (data && finished) {
            apollo.writeQuery({
                query: GetCurrentUserDocument,
                data,
            });
        }
    });
    const pendingPayment =
        useFragment(CurrentUserFragmentDoc, data?.currentUser)?.subscription?.status === 'pending';

    React.useEffect(() => {
        if (!pendingPayment && finished && !error) {
            trackEvent('Subscription created');
        }
    }, [pendingPayment, finished, error]);

    return (
        <VStack align="start">
            <div className="text-left">
                {pendingPayment && finished && (
                    <VStack>
                        <DetailText inline={false}>
                            We&apos;re still waiting on your payment provider to confirm your
                            payment. Please come back later.
                        </DetailText>
                        <Button onPress={onError}>Close</Button>
                    </VStack>
                )}
                {pendingPayment && !finished && (
                    <VStack>
                        <DetailText>
                            We&apos;re waiting on your payment provider to confirm your payment
                        </DetailText>
                        <LoadingSpinner />
                    </VStack>
                )}
                {!pendingPayment && finished && !error && (
                    <VStack align="start" seperator large>
                        <HStack condensed>
                            <div className={`w-6 h-6 ${textColorClassnames('success')}`}>
                                <CircleTick invert />
                            </div>
                            <Heading3>Success!</Heading3>
                        </HStack>
                        <VStack align="start">
                            <DetailText>
                                Welcome to Sage Earth! Your subscription has started.
                            </DetailText>
                            <Button onPress={onSuccess}>Continue</Button>
                        </VStack>
                    </VStack>
                )}
            </div>
            {!pendingPayment && !finished && !error && (
                <div className="self-center">
                    <LoadingSpinner />
                </div>
            )}
            {error && <ErrorCard />}
        </VStack>
    );
}
