import {addDays, subMonths} from 'date-fns';

export function getStartDateFromEndDate(endDate: Date, period?: string) {
    switch (period) {
        case 'quarter':
            return addDays(subMonths(endDate, 3), 1);

        // eslint-disable-next-line no-fallthrough
        case 'year':
        default:
            return addDays(subMonths(endDate, 12), 1);
    }
}

export function getPeriodText(period?: string) {
    switch (period) {
        case 'quarter':
            return 'quarter';

        // eslint-disable-next-line no-fallthrough
        case 'year':
        default:
            return '12 months';
    }
}
