import React from 'react';

import {
    DataTableRef,
    relayConnectionQuery,
    RelayConnectionVariables,
} from '@sphericsio/mvp-ui-common';
import {DetailText, Modal} from '@sphericsio/design-system';

import {graphql} from '../../graphql/generated';
import {
    FetchCustomerTransactionsWithEmissionsQuery,
    TransactionFilterEnum,
    TransactionWithCategorisationMetadata,
} from '../../graphql/generated/graphql';
import {TransactionCategorisation} from '../categorisation/transaction/transaction-categorisation';
import {CategorisationContextProvider} from '../categorisation/context';
import {TransactionDetails} from './transaction-details';
import {CO2e} from '../carbon-amount';
import {ExcludeModal} from '../modals/exclude-modal';
import {DataTable} from '../table';
import {ExcludeTransactionContextProvider} from './exclude-transaction-context';
import {IncludeModal} from '../modals/include-modal';
import {TransactionFeedEmissions} from './transaction-emissions';
import {TransactionOverview} from './transaction-overview';

const FetchCustomerTransactionsWithEmissionsDocument = graphql(/* GraphQL */ `
    query FetchCustomerTransactionsWithEmissions(
        $first: Int
        $after: String
        $last: Int
        $before: String
        $orderDirection: SortDirection!
        $orderBy: TransactionsOrder!
        $filter: TransactionFilterEnum!
    ) {
        getCompanyTransactions(
            first: $first
            after: $after
            last: $last
            before: $before
            orderDirection: $orderDirection
            orderBy: $orderBy
            filter: $filter
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                node {
                    id
                    description
                    transaction_date
                    amount
                    merchant {
                        name
                    }
                    categorisation_metadata {
                        kg_co2e
                        taxonomy
                        excludeReason
                        isIdentifiedSpend
                        isIgnored
                    }
                }
            }
        }
    }
`);

type TransactionFeedProps = {
    filter: TransactionFilterEnum;
    onRefetch: () => void;
};
export const TransactionFeed: React.FC<TransactionFeedProps> = ({filter, onRefetch}) => {
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [currentTxn, setCurrentTxn] = React.useState<TransactionWithCategorisationMetadata>();
    const queryConfig = relayConnectionQuery(
        FetchCustomerTransactionsWithEmissionsDocument,
        (data: FetchCustomerTransactionsWithEmissionsQuery) => data.getCompanyTransactions,
        {filter} as Partial<RelayConnectionVariables>,
        'network-only',
    );
    const ref = React.useRef<DataTableRef>(null);
    const isExcludedView = filter === 'excluded';

    const onSaveSuccess = () => {
        ref.current?.refreshData();
        onRefetch();
    };

    const EmissionsHeader = () => {
        if (isExcludedView) {
            return null;
        }
        return (
            <div className="whitespace-nowrap">
                Emissions <span className="font-body">({<CO2e />})</span>
            </div>
        );
    };

    const TransactionCategorisationHeader = () => {
        return isExcludedView ? (
            <span className="whitespace-nowrap">Reason for exclusion</span>
        ) : (
            <>What did you purchase?</>
        );
    };

    const handleExcludeClick = (transaction: TransactionWithCategorisationMetadata) => {
        setShowModal(true);
        setCurrentTxn(transaction);
    };

    return (
        <ExcludeTransactionContextProvider
            onSaveSuccess={() => {
                setShowModal(false);
                onSaveSuccess();
            }}
        >
            <div className="flex flex-1 flex-col">
                {
                    <Modal
                        testId="transactionsInclusion"
                        isOpen={showModal}
                        onClose={() => setShowModal(false)}
                    >
                        {currentTxn &&
                            (currentTxn.categorisation_metadata.isIgnored ? (
                                <IncludeModal
                                    onCancel={() => setShowModal(false)}
                                    id={currentTxn.id}
                                    type={'financial_transaction'}
                                >
                                    <TransactionOverview
                                        transaction={currentTxn}
                                        borderColour="major-action"
                                    />
                                </IncludeModal>
                            ) : (
                                <ExcludeModal
                                    onCancel={() => setShowModal(false)}
                                    id={currentTxn.id}
                                    type={'financial_transaction'}
                                >
                                    <TransactionOverview
                                        transaction={currentTxn}
                                        borderColour="error"
                                    />
                                </ExcludeModal>
                            ))}
                    </Modal>
                }
                <CategorisationContextProvider>
                    <DataTable
                        ref={ref}
                        queryConfig={queryConfig}
                        columns={[
                            {
                                label: 'Transaction Details',
                                // eslint-disable-next-line react/display-name
                                render: (txn) => (
                                    <TransactionDetails
                                        amount={txn.amount}
                                        date={txn.transaction_date}
                                        merchantName={txn.merchant?.name}
                                        taxonomy={txn.categorisation_metadata.taxonomy ?? undefined}
                                        excluded={txn.categorisation_metadata.isIgnored}
                                    />
                                ),
                            },
                            {
                                label: 'Description',
                                key: 'description',
                                // eslint-disable-next-line react/display-name
                                renderValue: (value) => (
                                    <div className="min-w-60 flex flex-1">
                                        <DetailText colour="secondary">{value}</DetailText>
                                    </div>
                                ),
                            },
                            {
                                label: <TransactionCategorisationHeader />,
                                // eslint-disable-next-line react/display-name
                                render: (txn) => (
                                    <TransactionCategorisation
                                        id={txn.id}
                                        editable={!txn.categorisation_metadata.isIdentifiedSpend}
                                        taxonomy={txn.categorisation_metadata.taxonomy ?? undefined}
                                        excludeReason={
                                            txn.categorisation_metadata.excludeReason ?? undefined
                                        }
                                        isIgnored={txn.categorisation_metadata.isIgnored}
                                        onSaveSuccess={onSaveSuccess}
                                    />
                                ),
                            },
                            {
                                label: <EmissionsHeader />,
                                // eslint-disable-next-line react/display-name
                                render: (txn) => (
                                    <TransactionFeedEmissions
                                        transaction={txn as TransactionWithCategorisationMetadata}
                                        isExcludedView={isExcludedView}
                                        onClick={handleExcludeClick}
                                    />
                                ),
                            },
                        ]}
                        sortableFields={['transaction_date']}
                        initialSortDirection="desc"
                    />
                </CategorisationContextProvider>
            </div>
        </ExcludeTransactionContextProvider>
    );
};
