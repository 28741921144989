import React from 'react';
import {LinkProps as RouterLinkProps, useNavigate, useHref, Link} from 'react-router-dom';

import {
    ButtonLink as SystemButtonLink,
    ButtonLinkProps,
    LinkOutIcon,
    NavLink as SystemNavLink,
    NavLinkProps,
    useLink,
} from '@sphericsio/design-system';

export function ButtonLink(
    props: RouterLinkProps & Omit<ButtonLinkProps, 'children' | 'onPress' | 'href'>,
) {
    const {to, children, ...buttonLinkProps} = props;
    const href = useHref(to);
    const navigate = useNavigate();
    return (
        <SystemButtonLink href={href} onPress={() => navigate(href)} {...buttonLinkProps}>
            {children}
        </SystemButtonLink>
    );
}

export function TextButtonLink(props: RouterLinkProps) {
    return <Link {...props} className="font-heading text-sm uppercase text-center py-2" />;
}

export function NavLink(props: RouterLinkProps & Omit<NavLinkProps, 'onPress' | 'href'>) {
    const {to, children, ...buttonLinkProps} = props;
    const href = useHref(to);
    const navigate = useNavigate();
    return (
        <SystemNavLink href={href} onPress={() => navigate(href)} {...buttonLinkProps}>
            {children}
        </SystemNavLink>
    );
}

export function ExternalLink({to, children}: {to: string; children: React.ReactNode}) {
    const linkProps = useLink({
        href: to,
        openInNewWindow: true,
    });
    return (
        <a className="flex items-center space-x-2" {...linkProps}>
            <div className="w-4 h-4">
                <LinkOutIcon />
            </div>
            {children}
        </a>
    );
}
