import React from 'react';
import {useMutation} from '@apollo/client';
import classnames from 'classnames';

import {
    Button,
    CircleCross,
    CircleTick,
    DetailText,
    LoadingSpinner,
    textColorClassnames,
    VStack,
} from '@sphericsio/design-system';

import {FileUploadState, FileUploadStatus} from './state';
import {FileConfig} from '../../graphql/generated/graphql';
import {getAcceptedFileTypes} from './utils';
import {CircleIcon} from '../icons';
import {DeleteUploadDocument} from './requests';
import {FileUploadContext} from './context';
import {Bugsnag} from '../../util/bugsnag';
import {UploadContext} from '../file-upload-wizard/context';

type FileUploaderProps = {
    requestConfig: {
        accepts?: FileConfig[];
        exportSource?: string;
        uploadRequestType?: string;
    };
};
export function FileUploader({requestConfig}: FileUploaderProps) {
    const {addUploads, uploads} = React.useContext(FileUploadContext);
    const [files, setFiles] = React.useState<FileUploadState[]>([]);
    const hiddenInputRef = React.useRef<HTMLInputElement | null>(null);
    const {accepts, exportSource, uploadRequestType} = requestConfig;

    if (!accepts || !exportSource || !uploadRequestType) {
        return null;
    }

    const acceptedFileTypes = getAcceptedFileTypes(accepts);

    React.useEffect(() => {
        setFiles(uploads);
    }, [uploads]);

    return files.length === 0 ? (
        <>
            <Button onPress={() => hiddenInputRef.current?.click()} outline>
                Choose your file
            </Button>
            <input
                ref={hiddenInputRef}
                id={requestConfig.uploadRequestType}
                accept={acceptedFileTypes}
                type="file"
                name={requestConfig.uploadRequestType}
                onChange={(e) => {
                    if (e?.target?.files?.[0]) {
                        const file = {
                            status: 'queued',
                            file: e?.target?.files?.[0],
                            exportSource,
                            uploadRequestType,
                        } as FileUploadState;
                        addUploads([file]);
                    }
                }}
                style={{display: 'none'}}
            />
        </>
    ) : (
        <VStack>
            {files.map((req) => (
                <RequestQueueItem key={req.file.name} request={req} />
            ))}
        </VStack>
    );
}

function RequestQueueItem({request}: {request: FileUploadState}) {
    const {flow} = React.useContext(UploadContext);
    const {remove} = React.useContext(FileUploadContext);
    const [removeUpload, {loading: removeLoading, error: removeError, data}] =
        useMutation(DeleteUploadDocument);

    React.useEffect(() => {
        if (data?.deleteFileUpload.status && request.id) {
            remove({ids: [request.id]});
        }
    }, [data]);

    React.useEffect(() => {
        if (removeError) {
            // we remove file from FE even if there is an error but want to be aware of the issue
            Bugsnag.notify(removeError);
        }
    }, [removeError]);

    return (
        <DetailText>
            <VStack>
                <div
                    className={classnames('flex flex-row w-full justify-between items-center', {
                        'space-x-5': flow === 'onboarding',
                        'space-x-2': flow !== 'onboarding',
                    })}
                >
                    <div className="w-6 h-6">
                        <UploadStatus request={request} />
                    </div>
                    <div
                        className={classnames('break-words', {
                            'w-80': flow === 'onboarding',
                            'truncate text-left w-60': flow !== 'onboarding',
                        })}
                    >
                        {request.file.name}
                    </div>
                    {removeLoading && <LoadingSpinner />}
                    <div
                        onClick={() => {
                            if (!request.status || request.status === 'queued' || !request.id) {
                                remove({tempIds: [request.tempId]});
                            } else {
                                removeUpload({
                                    variables: {
                                        id: request.id,
                                    },
                                });
                            }
                        }}
                    >
                        <div className="cursor-pointer">
                            <DetailText colour="error">Remove</DetailText>
                        </div>
                    </div>
                </div>
            </VStack>
        </DetailText>
    );
}

function UploadStatus({request}: {request?: FileUploadState}) {
    if (!request) {
        return <UploadProgress />;
    }
    return <UploadProgress status={request.status} />;
}

function UploadProgress({status}: {status?: FileUploadStatus}) {
    if (!status) {
        return <CircleIcon />;
    }

    switch (status) {
        case 'uploadComplete':
            return (
                <div className={textColorClassnames('success')}>
                    <CircleTick invert />
                </div>
            );
        case 'queued':
            return <CircleIcon />;
        case 'uploadRequestFailed':
        case 'uploadFailed':
        case 'markUploadCompleteFailed':
            return (
                <div className={textColorClassnames('error')}>
                    <CircleCross />
                </div>
            );
        default:
            return <LoadingSpinner />;
    }
}
