import React from 'react';
import classnames from 'classnames';

import {DetailText, Heading1, HStack, textColorClassnames} from '@sphericsio/design-system';

import {ComparisonArrowIcon, MinusCircleIcon} from '../icons';
import {carbonAmountFormatter, CO2e} from '../carbon-amount';
import {DashboardPanel} from './dashboard-panel';

type ComparisonPanelType = 'carbon_emissions' | 'percent';
type PositiveComparisonDirection = 'more' | 'less';

function getIcon(
    value: number | undefined,
    comparisonValue: number | undefined,
    positiveComparisonDirection: PositiveComparisonDirection,
) {
    if (comparisonValue == null || value == null) {
        return (
            <div className="w-8 h-8">
                <MinusCircleIcon />
            </div>
        );
    }

    const valueIsMore = value > comparisonValue;
    return (
        <div
            className={classnames(
                'w-8 h-8',
                ((valueIsMore && positiveComparisonDirection === 'more') ||
                    (!valueIsMore && positiveComparisonDirection === 'less')) &&
                    textColorClassnames('success'),

                ((valueIsMore && positiveComparisonDirection === 'less') ||
                    (!valueIsMore && positiveComparisonDirection === 'more')) &&
                    textColorClassnames('error'),
                {
                    'transform rotate-180': valueIsMore,
                },
            )}
        >
            <ComparisonArrowIcon />
        </div>
    );
}

function getValue(
    type: ComparisonPanelType,
    missingValueContent: React.ReactNode,
    value?: number,
    comparisonValue?: number,
) {
    if (value == null) {
        return <DetailText inline={false}>{missingValueContent}</DetailText>;
    }
    if (type === 'carbon_emissions') {
        const {formattedAmount, unit} = carbonAmountFormatter({amount: value});
        return (
            <>
                <Heading1 element="span">{formattedAmount} </Heading1>
                <DetailText>
                    {unit} <CO2e />
                </DetailText>
            </>
        );
    } else if (type === 'percent' && comparisonValue != null) {
        const difference = value - comparisonValue;
        const percent = (difference / comparisonValue) * 100;
        return (
            <Heading1>
                {Math.abs(percent).toFixed(0)}% {percent > 0 ? 'above' : 'below'}
            </Heading1>
        );
    }
}
type ComparisonTooltip = {
    text: string;
    link?: string;
    linkText?: string;
};
type ComparisonPanelProps = {
    type: ComparisonPanelType;
    positiveComparisonDirection?: PositiveComparisonDirection;
    title: string;
    value?: number;
    comparisonValue?: number;
    missingValueContent?: React.ReactNode;
    intercomTarget?: string;
    tooltip?: ComparisonTooltip;
    children: React.ReactNode;
};
export function ComparisonPanel({
    type,
    title,
    value,
    children,
    comparisonValue,
    positiveComparisonDirection = 'less',
    missingValueContent = 'We could not calculate a value for this stat.',
    tooltip,
    intercomTarget,
}: ComparisonPanelProps) {
    return (
        <DashboardPanel
            title={title}
            intercomTarget={intercomTarget}
            footer={children}
            tooltip={tooltip}
        >
            <HStack>
                {getIcon(value, comparisonValue, positiveComparisonDirection)}
                <div className="flex-1">
                    {getValue(type, missingValueContent, value, comparisonValue)}
                </div>
            </HStack>
        </DashboardPanel>
    );
}
