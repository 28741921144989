import React from 'react';

import {VStack} from '@sphericsio/design-system';

import {ProfileGHGContext} from './context';
import {ProfileSectionPanel} from '../section-panel';

export function ProfileGHGIntro() {
    const ctx = React.useContext(ProfileGHGContext);
    return (
        <VStack>
            <ProfileSectionPanel
                heading="F-Gases"
                type="pending"
                reportingPeriod={ctx.reportingPeriod}
                onPressStart={() => ctx.dispatch({type: 'start'})}
            >
                We need you to tell us about the F-Gases your company used during this reporting
                period.
            </ProfileSectionPanel>
        </VStack>
    );
}
