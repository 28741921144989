import React, {useContext} from 'react';

import {UserCategorisationMenu} from '@sphericsio/mvp-ui-common';

import {CategorisationContext} from './context';
import {UserCategorisationHierarchyItem} from './hierarchy';
import {SaveStatus} from './categorisation';
import {useOnClickOutside} from '../../hooks/use-on-click-outside';

type EditCategoryProps = {
    children: React.ReactNode;
    status: SaveStatus;
    onStatusChange: (status: SaveStatus) => void;
    onSelected: (selected: UserCategorisationHierarchyItem | null) => void;
    canSelectAllLevels?: boolean;
};
export function EditCategory({
    children,
    status,
    onStatusChange,
    onSelected,
    canSelectAllLevels,
}: EditCategoryProps) {
    const [showMenu, setShowMenu] = React.useState<boolean>(false);
    const {hierarchy, setCategorisationMenuOpen} = useContext(CategorisationContext);

    const handleShowMenu = (showMenuValue: boolean) => {
        setShowMenu(showMenuValue);
        setCategorisationMenuOpen(showMenuValue);
    };

    const menuRef = useOnClickOutside(() => handleShowMenu(false));

    React.useEffect(() => {
        if (showMenu) {
            menuRef.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
        }
    }, [showMenu]);

    React.useEffect(() => {
        if (status === 'success') {
            onStatusChange('pending');
            onSelected(null);
        }
    }, [status]);

    return (
        <div className="relative w-min">
            <div
                className="cursor-pointer"
                onClick={() => {
                    handleShowMenu(!showMenu);
                    onStatusChange('pending');
                }}
            >
                {children}
            </div>
            {hierarchy && showMenu && (
                <div
                    data-testid="categorisation-menu"
                    ref={menuRef}
                    className="absolute top-10 z-10"
                >
                    <UserCategorisationMenu
                        data={hierarchy}
                        canSelectAllLevels={canSelectAllLevels}
                        onClose={() => handleShowMenu(false)}
                        onSelect={(item) => {
                            onSelected(item);
                            handleShowMenu(false);
                        }}
                    />
                </div>
            )}
        </div>
    );
}
