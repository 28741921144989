import React from 'react';

import {
    Button,
    DetailText,
    HStack,
    SuccessIcon,
    textColorClassnames,
    VStack,
} from '@sphericsio/design-system';

import {PageHeader} from '../../page-header';
import {UploadContext} from '../context';

export function SuccessMessage() {
    const {onClose} = React.useContext(UploadContext);
    return (
        <VStack>
            <PageHeader title="Success!" />
            <HStack>
                <div className={`w-6 h-6 ${textColorClassnames('success')}`}>
                    <SuccessIcon />
                </div>
                <DetailText>
                    Your uploads were successful. Your new data will now be visible.
                </DetailText>
            </HStack>
            <div className="self-end">
                {onClose && <Button onPress={() => onClose()}>Close</Button>}
            </div>
        </VStack>
    );
}
