import React from 'react';
import {useIntercom} from 'react-use-intercom';

import {useCurrentUserQueryData, User} from './user-context';

export function BootIntercom() {
    const userData = useCurrentUserQueryData();
    const intercom = useIntercom();

    const updateWithProps = (user?: User) => {
        if (!user) {
            return;
        }
        intercom.update({
            userId: user.id,
            email: user.email,
            name: user.name,
            createdAt: user.created_at,
            userHash: user.intercom_hash,
            company: {
                companyId: user.company_account_id,
                name: user.company_name,
            },
            customAttributes: {
                lead_company_user: user.isLeadCompanyUser,
                sage_pilot: user.feature_flags.includes('sage_pilot_q1_2023'),
            },
        });
    };

    React.useEffect(() => {
        if (userData !== null) {
            updateWithProps(userData);
        }
    }, [intercom, userData]);
    return null;
}
