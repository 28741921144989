import React from 'react';
import {useQuery} from '@apollo/client';

import {ProfileGHGContext} from './context';
import {CompletedProfileSection} from '../completed-profile-section';
import {GetGhgEmissionsForReportingPeriodDocument} from './graphql';

export function ProfileGHGComplete() {
    const ctx = React.useContext(ProfileGHGContext);
    const queryResult = useQuery(GetGhgEmissionsForReportingPeriodDocument, {
        variables: {id: ctx.reportingPeriod.id},
    });
    return <CompletedProfileSection section="ghg" queryResult={queryResult} />;
}
