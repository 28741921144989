import React from 'react';
import {useMutation, useQuery} from '@apollo/client';

import {
    ConfirmFinancialAccountCategorisationDocument,
    FetchFinancialAccountCountsDocument,
} from '../components/financial-accounts/graphql';

export function useConfirmAccount(afterSave: () => void) {
    const [save, {data, loading}] = useMutation(ConfirmFinancialAccountCategorisationDocument);
    const {refetch} = useQuery(FetchFinancialAccountCountsDocument, {
        fetchPolicy: 'network-only',
    });

    const saveAccount = (accountId: string) => {
        save({
            variables: {
                params: [accountId],
            },
        });
    };

    const refreshData = () => {
        refetch();
        if (afterSave) {
            afterSave();
        }
    };

    React.useEffect(() => {
        if (data?.confirmFinancialAccountCategorisation) {
            refreshData();
        }
    }, [data]);

    return {saveAccount, loading};
}
