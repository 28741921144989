import React from 'react';
import {useLazyQuery} from '@apollo/client';
import type {ResultOf} from '@graphql-typed-document-node/core';
import classnames from 'classnames';

import {
    TextButton,
    LoadingSpinner,
    Input,
    VStack,
    CircleTick,
    Heading5,
    DetailText,
    textColorClassnames,
} from '@sphericsio/design-system';

import {CloseCircleIcon} from '../icons';
import {ErrorCard} from '../error-card';
import {SubscriptionContext} from './context';
import {GetSubscriptionPromotionCodeDocument} from './graphql';

type PromoCodeStatusProps = {
    invalidCoupon?: boolean;
    promotionName?: string;
    loading?: boolean;
};
function PromoCodeStatus({
    invalidCoupon = false,
    promotionName,
    loading = false,
}: PromoCodeStatusProps) {
    let status = 'no_coupon';
    if (invalidCoupon) {
        status = 'invalid';
    }
    if (promotionName != null) {
        status = 'valid';
    }
    if (loading) {
        status = 'loading';
    }

    return (
        <div className="flex space-x-2 justify-start items-center">
            {(status === 'valid' || status === 'invalid') && (
                <div
                    className={classnames(
                        'w-6 h-6',
                        status === 'valid' && textColorClassnames('success'),
                        status === 'invalid' && textColorClassnames('error'),
                    )}
                >
                    {status === 'valid' && <CircleTick invert />}
                    {status === 'invalid' && <CloseCircleIcon invert />}
                </div>
            )}
            <DetailText colour={status === 'invalid' ? 'error' : 'secondary'}>
                {status === 'no_coupon' && 'No discount applied'}
                {status === 'invalid' && 'Promo code not valid'}
                {status === 'valid' && promotionName}
                {status === 'loading' && <LoadingSpinner />}
            </DetailText>
        </div>
    );
}

export type PromotionDetails = ResultOf<
    typeof GetSubscriptionPromotionCodeDocument
>['getSubscriptionPromotionalCode'];

type PromoCodeInputProps = {
    name: string;
    onApplyCode: (value: PromotionDetails) => void;
    onUnapplyCode: () => void;
};
export function PromoCodeInput({name, onApplyCode, onUnapplyCode}: PromoCodeInputProps) {
    const {promotion} = React.useContext(SubscriptionContext);
    const [value, setValue] = React.useState('');
    const [invalidCode, setInvalidCode] = React.useState(false);
    const [getPromoCode, {loading, error, data}] = useLazyQuery(
        GetSubscriptionPromotionCodeDocument,
    );
    React.useEffect(() => {
        if (error == null) {
            setInvalidCode(false);
        } else {
            setInvalidCode(error.message === 'INVALID_PROMOTION_CODE');
        }
    }, [error, setInvalidCode]);
    React.useEffect(() => {
        if (data != null && promotion == null) {
            onApplyCode(data.getSubscriptionPromotionalCode);
        }
    }, [data, onApplyCode]);
    return (
        <>
            <VStack condensed>
                <Heading5>Have a promotion code?</Heading5>
                <Input
                    name={name}
                    placeholder="Promo code"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    showLabel={false}
                    disableEnter={true}
                    icon={
                        <TextButton uppercase={false} isDisabled={value === '' || loading}>
                            Apply
                        </TextButton>
                    }
                    onPressIcon={(code) => {
                        onUnapplyCode();
                        getPromoCode({variables: {code}});
                    }}
                />
                <PromoCodeStatus
                    promotionName={promotion?.name ?? undefined}
                    invalidCoupon={invalidCode}
                    loading={loading}
                />
            </VStack>
            {error && !invalidCode && <ErrorCard />}
        </>
    );
}
