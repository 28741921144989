import React from 'react';
import {useRemark} from 'react-remark';

type MarkdownContentProps = {
    children: string;
};
export function MarkdownContent({children}: MarkdownContentProps) {
    const [reactContent, setMarkdownSource] = useRemark({
        rehypeReactOptions: {
            /* eslint-disable react/display-name */
            components: {
                ul: (props: any) => <ul className="list-disc" {...props} />,
                li: (props: any) => <li className="ml-4" {...props} />,
            },
            /* eslint-enable react/display-name */
        },
    });
    React.useEffect(() => {
        setMarkdownSource(children);
    }, [children, setMarkdownSource]);

    return reactContent;
}
