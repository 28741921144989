import React from 'react';
import classnames from 'classnames';
import {setHours} from 'date-fns';

import {
    ChevronDownIcon,
    ConditionalWrapper,
    DetailText,
    Grid,
    HStack,
    intercomTargetProps,
    SegmentTitle,
    Strong,
    SuccessIcon,
    VStack,
    divideColorClassnames,
    textColorClassnames,
    outlineColorClassnames,
    bgColorClassnames,
} from '@sphericsio/design-system';

import {FinancialDateFilter, FinancialDateFilterQuarter} from '../../graphql/generated/graphql';
import {useOnClickOutside} from '../../hooks/use-on-click-outside';
import {FinancialDateFilterContext, PickerContext} from './context';
import {IntervalTypes, INTERVAL_TYPE_CONFIG} from './config';
import {formatFinancialYear, formatQuarter, isSamePeriod, isYearSelected} from './utils';
import {CustomDateRangePicker, RangeProps} from './range-picker';
import {PickerButtons} from './picker-buttons';

function CustomDatesSelector() {
    const {setSelection, data} = React.useContext(FinancialDateFilterContext);
    const {setShowPicker} = React.useContext(PickerContext);

    const minDate = React.useMemo(() => {
        if (data?.getCompanyFinancialDateFilter?.minDate) {
            return setHours(new Date(data?.getCompanyFinancialDateFilter.minDate), 0);
        }
    }, [data?.getCompanyFinancialDateFilter?.minDate]);
    return (
        <div className="pt-3">
            <CustomDateRangePicker
                minDate={minDate}
                onChange={(selection: RangeProps) => {
                    setSelection({
                        type: IntervalTypes.custom,
                        periodStart: selection.startDate.toUTCString(),
                        periodEnd: selection.endDate.toUTCString(),
                    });
                    setShowPicker(false);
                }}
            />{' '}
        </div>
    );
}

function QuarterSelector() {
    const {
        data,
        setSelection,
        reportingPeriods,
        disableQuartersWithinBaselinePeriod,
        indicateBaselineYearInQuarter,
    } = React.useContext(FinancialDateFilterContext);
    const {setShowPicker} = React.useContext(PickerContext);

    const [financialYear, setFinancialYear] = React.useState<FinancialDateFilter | null>(
        data?.getCompanyFinancialDateFilter?.years[0] ?? null,
    );

    const [showYearSelector, setShowYearSelector] = React.useState(false);

    const [selectedQuarter, setSelectedQuarter] = React.useState<FinancialDateFilterQuarter>();

    const isQuarterSelected = (q: FinancialDateFilterQuarter) =>
        q.quarterNumber === selectedQuarter?.quarterNumber;

    const isQuarterReportingPeriodCompleted = (q: FinancialDateFilterQuarter) =>
        reportingPeriods.some(
            (rp) =>
                isSamePeriod({periodStart: rp.period_start, periodEnd: rp.period_end}, q) &&
                rp.isCompleted,
        );

    return (
        <VStack seperator align="center" full>
            {financialYear && (
                <div
                    onClick={() => setShowYearSelector(true)}
                    className="pb-2 pt-5 flex items-center justify-center"
                >
                    <HStack>
                        <DetailText>
                            <Strong>
                                {formatFinancialYear(
                                    financialYear?.periodStart,
                                    financialYear?.periodEnd,
                                    {full: true},
                                )}
                            </Strong>
                        </DetailText>

                        <div className={`w-5 h-5 ${textColorClassnames('secondary')}`}>
                            <ChevronDownIcon />
                        </div>
                    </HStack>
                </div>
            )}
            {!showYearSelector &&
                financialYear?.quarters.map((q) => (
                    <div
                        key={`quarter-${q.quarterNumber}`}
                        className="flex items-center justify-center"
                        onClick={() => {
                            if (
                                !(
                                    disableQuartersWithinBaselinePeriod &&
                                    financialYear.isBaselineYear
                                )
                            ) {
                                setSelectedQuarter(q);
                            }
                        }}
                    >
                        <div
                            className={classnames(
                                'py-2 px-3',
                                isQuarterSelected(q) &&
                                    `border-2 ${outlineColorClassnames(
                                        'minor-action',
                                    )} rounded-3xl`,
                            )}
                        >
                            <DetailText
                                colour={
                                    isQuarterSelected(q)
                                        ? 'minor-action'
                                        : disableQuartersWithinBaselinePeriod &&
                                            financialYear.isBaselineYear
                                          ? 'disabled'
                                          : 'default'
                                }
                            >
                                <ConditionalWrapper
                                    condition={isQuarterSelected(q)}
                                    wrapper={(children) => <Strong>{children}</Strong>}
                                >
                                    <ConditionalWrapper
                                        condition={
                                            !!(
                                                isQuarterReportingPeriodCompleted(q) ||
                                                (disableQuartersWithinBaselinePeriod &&
                                                    financialYear.isBaselineYearCompleted)
                                            )
                                        }
                                        wrapper={(children) => (
                                            <HStack>
                                                {children}{' '}
                                                <div
                                                    className={`w-6 h-6 pl-2 pb-1 ${textColorClassnames(
                                                        'success',
                                                    )}`}
                                                >
                                                    <SuccessIcon />
                                                </div>
                                            </HStack>
                                        )}
                                    >
                                        {formatQuarter(q.quarterNumber, {
                                            full: true,
                                            showBaselineYear:
                                                indicateBaselineYearInQuarter &&
                                                financialYear.isBaselineYear,
                                        })}
                                    </ConditionalWrapper>
                                </ConditionalWrapper>
                            </DetailText>
                        </div>
                    </div>
                ))}

            {showYearSelector && (
                <div className="pb-10 pt-3">
                    <Grid columns={3}>
                        {data?.getCompanyFinancialDateFilter?.years.map((y) => (
                            <div
                                key={`year-selector-${y.periodStart}`}
                                onClick={() => {
                                    setFinancialYear(y);
                                    setSelectedQuarter(undefined);
                                    setShowYearSelector(false);
                                }}
                            >
                                <div
                                    className={classnames(
                                        'flex justify-center py-2',
                                        isYearSelected(y, financialYear) &&
                                            `border-2 ${outlineColorClassnames(
                                                'minor-action',
                                            )} rounded-3xl`,
                                    )}
                                >
                                    <DetailText>
                                        <ConditionalWrapper
                                            condition={isYearSelected(y, financialYear) ?? false}
                                            wrapper={(children) => <Strong>{children}</Strong>}
                                        >
                                            {formatFinancialYear(y.periodStart, y.periodEnd)}
                                        </ConditionalWrapper>
                                    </DetailText>
                                </div>
                            </div>
                        ))}
                    </Grid>
                </div>
            )}
            {!showYearSelector && (
                <PickerButtons
                    isDisabled={!selectedQuarter}
                    onChange={() => {
                        if (selectedQuarter) {
                            setSelection({type: IntervalTypes.quarter, ...selectedQuarter});
                            setShowPicker(false);
                        }
                    }}
                />
            )}
        </VStack>
    );
}

function FinancialYearSelector() {
    const {data, setSelection} = React.useContext(FinancialDateFilterContext);
    const {setShowPicker} = React.useContext(PickerContext);

    const [selectedFinancialYear, setFinancialYear] = React.useState<FinancialDateFilter>();

    return (
        <VStack seperator align="center" full>
            {data?.getCompanyFinancialDateFilter?.years.map((y) => (
                <div
                    key={`${y.periodStart}-${y.periodEnd}`}
                    onClick={() => setFinancialYear(y)}
                    className="flex flex-row items-center justify-center"
                >
                    <div
                        className={classnames(
                            'px-3 py-2',
                            isYearSelected(y, selectedFinancialYear) &&
                                `border-2 ${outlineColorClassnames('minor-action')} rounded-3xl`,
                        )}
                    >
                        <DetailText
                            colour={
                                isYearSelected(y, selectedFinancialYear)
                                    ? 'minor-action'
                                    : 'default'
                            }
                        >
                            <ConditionalWrapper
                                condition={isYearSelected(y, selectedFinancialYear) ?? false}
                                wrapper={(children) => <Strong>{children}</Strong>}
                            >
                                {formatFinancialYear(y.periodStart, y.periodEnd)}
                            </ConditionalWrapper>
                        </DetailText>
                    </div>
                </div>
            ))}
            <PickerButtons
                isDisabled={!selectedFinancialYear}
                onChange={() => {
                    if (selectedFinancialYear) {
                        setSelection({...selectedFinancialYear, type: IntervalTypes.year});
                        setShowPicker(false);
                    }
                }}
            />
        </VStack>
    );
}
// eslint-disable-next-line react/display-name
const Picker = React.forwardRef<HTMLDivElement>((_props, ref) => {
    const {intervalTypesAvailable} = React.useContext(FinancialDateFilterContext);
    const [intervalType, setIntervalType] = React.useState(intervalTypesAvailable[0]);

    const isSelected = (type: IntervalTypes) => intervalType === type;

    return (
        <div ref={ref}>
            <div className="flex flex-row justify-evenly items-center">
                {intervalTypesAvailable.length > 1 &&
                    intervalTypesAvailable.map((label) => {
                        const config = INTERVAL_TYPE_CONFIG.find((c) => c.id === label);
                        if (config) {
                            return (
                                <div
                                    key={`picker-${config.id}`}
                                    className={classnames(
                                        'px-4 pb-3 pt-5',
                                        isSelected(config.id) &&
                                            `border-b-4 ${outlineColorClassnames(
                                                'minor-action',
                                            )} ${bgColorClassnames('minor-action')}`,
                                    )}
                                    onClick={() => setIntervalType(config.id)}
                                >
                                    <SegmentTitle>
                                        <div className="text-center">{label.toUpperCase()}</div>
                                    </SegmentTitle>
                                </div>
                            );
                        }
                    })}
            </div>
            <div className="pt-3">
                {intervalType === IntervalTypes.year && <FinancialYearSelector />}
                {intervalType === IntervalTypes.quarter && <QuarterSelector />}
                {intervalType === IntervalTypes.custom && <CustomDatesSelector />}
            </div>
        </div>
    );
});

export function DatePicker({intercomTarget}: {intercomTarget?: string}) {
    const {showPicker, setShowPicker} = React.useContext(PickerContext);
    const ref = useOnClickOutside(() => setShowPicker(false));
    const {data, loading, error, intervalType, intervalValue} = React.useContext(
        FinancialDateFilterContext,
    );

    const intervalTypeLabel = React.useMemo(
        () => INTERVAL_TYPE_CONFIG.find((c) => c.id === intervalType)?.label || '',
        [intervalType],
    );

    if (loading || error || !data?.getCompanyFinancialDateFilter) {
        return <></>;
    }
    return (
        <div data-testid="datePicker" {...intercomTargetProps(intercomTarget)}>
            <div
                className={classnames(
                    'items-center justify-center flex flex-row divide-x border rounded-xl',
                    bgColorClassnames('white'),
                    outlineColorClassnames(),
                    divideColorClassnames(),
                )}
                onClick={() => setShowPicker(!showPicker)}
            >
                <div className="w-44 h-full py-2 flex justify-center uppercase tracking-wider">
                    <SegmentTitle colour="secondary">
                        {intervalTypeLabel.toUpperCase()}
                    </SegmentTitle>
                </div>
                <div className="px-4 w-72 h-full py-2 flex flex-row items-center justify-end">
                    <div className="mr-auto pl-3">
                        <DetailText>{intervalValue}</DetailText>
                    </div>
                    <div className={`${textColorClassnames('secondary')} w-5 h-5`}>
                        <ChevronDownIcon />
                    </div>
                </div>
            </div>
            {showPicker && data && (
                <div className="relative">
                    <div className="absolute top-1 z-10 left-0 right-0">
                        <div className={`${bgColorClassnames('white')} rounded-lg px-2 pb-2`}>
                            <Picker ref={ref} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
