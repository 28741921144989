export enum IntervalTypes {
    year = 'years',
    quarter = 'quarters',
    custom = 'custom',
}

export const INTERVAL_TYPE_CONFIG = [
    {
        id: IntervalTypes.year,
        label: 'Financial year',
    },
    {
        id: IntervalTypes.quarter,
        label: 'Financial quarter',
    },
    {
        id: IntervalTypes.custom,
        label: 'Custom range',
    },
];

export type SelectedPeriod = {
    periodStart: string;
    periodEnd: string;
    quarterNumber?: number;
};

export type Selection = SelectedPeriod & {
    type: IntervalTypes;
};
