import {graphql} from '../../../graphql/generated';

export const GetPeopleEmissionsDocument = graphql(/* GraphQL */ `
    query GetPeopleEmissionsForReportingPeriod($id: ID!) {
        getCompanyReportingPeriodById(id: $id) {
            people {
                ...BenchmarkingProfileEntry
            }
            totalEmissionsForPeriod
        }
    }
`);

export const ModifyProfilePeopleDocument = graphql(/* GraphQL */ `
    mutation ModifyProfilePeople($params: ProfilePeopleInput!) {
        modifyProfilePeople(params: $params) {
            id
        }
    }
`);

export const GetProfilePeopleDocument = graphql(/* GraphQL */ `
    query GetProfilePeople {
        currentCompany {
            ...CompanyAddresses
        }
    }
`);

export const GetProfilePeopleEntryDocument = graphql(/* GraphQL */ `
    query GetProfilePeopleEntry($companyReportingPeriodId: ID!) {
        getProfilePeopleEntry(companyReportingPeriodId: $companyReportingPeriodId) {
            ...ProfilePeopleEntry
        }
    }
`);
