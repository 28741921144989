import React from 'react';
import {useNavigate} from 'react-router';

import {DetailText, Heading5, Link, LinkText, VStack} from '@sphericsio/design-system';

import {HSplitLayout} from '../../layout/h-split';
import {NavigationButtons} from '../../navigation-buttons';
import {PosterLayout} from '../../onboarding/poster-layout';
import {PosterForgotTemplate} from '../../onboarding/poster-forgot-template';
import {PageHeader} from '../../page-header';
import {UploadContext} from '../context';

export const UploadInstructions: React.FC = () => {
    const navigate = useNavigate();
    const {dispatch} = React.useContext(UploadContext);
    return (
        <HSplitLayout cta="logout" wide>
            <VStack large full>
                <PageHeader title="Upload from Sage50" />
                <div className="flex flex-col space-y-7">
                    <DetailText>
                        Follow the steps below to ensure you export your files correctly.
                    </DetailText>
                    <Heading5>
                        Before you start, please open our{' '}
                        <Link
                            href="https://help.earth.sage.com/en/articles/7202124-connecting-sage-50-to-sage-earth"
                            uppercase={false}
                            openInNewWindow
                        >
                            <LinkText>help guide.</LinkText>
                        </Link>
                    </Heading5>
                    <div className="flex flex-col space-y-2">
                        <DetailText>
                            1. Download your templates (found within our help guide).
                        </DetailText>
                        <DetailText>2. Read our help guide for instructions.</DetailText>
                        <DetailText>3. Export your data from Sage 50.</DetailText>
                        <DetailText>4. Upload your data to Sage Earth.</DetailText>
                    </div>
                </div>

                <div className="flex w-full flex-row justify-end pt-5">
                    <NavigationButtons
                        backOnClick={() => navigate('/sync')}
                        backColour="major-action"
                        nextOnClick={() => dispatch({type: 'requestCompanyDefaults'})}
                    />
                </div>
            </VStack>
            <PosterLayout bgImage={{type: 'onboarding'}}>
                <PosterForgotTemplate />
            </PosterLayout>
        </HSplitLayout>
    );
};
