import React from 'react';

export {ChevronUpIcon, ChevronDownIcon} from '@sphericsio/design-system';

export function NavCategorisationIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.8308 3.4L2.33757 12.9C2.13771 13.1 1.93785 13.4 1.93785 13.6L0.0392026 19C-0.0607263 19.3 0.0392026 19.6 0.23906 19.8C0.438918 19.9 0.638776 20 0.838634 20C0.938563 20 1.03849 20 1.03849 20L6.33472 18.2C6.63451 18.1 6.83437 18 7.03423 17.8L16.5275 8.3L11.8308 3.4ZM17.7266 7.1L19.0257 5.8C20.3248 4.5 20.3248 2.3 19.0257 1C18.4261 0.4 17.5268 0 16.6274 0C15.728 0 14.8287 0.4 14.2291 1L12.93 2.3L17.7266 7.1Z"
                fill="currentColor"
            />
        </svg>
    );
}

export function NavDashboardIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 512 512">
            <rect
                x="48"
                y="48"
                width="176"
                height="176"
                rx="20"
                ry="20"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
            />
            <rect
                x="288"
                y="48"
                width="176"
                height="176"
                rx="20"
                ry="20"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
            />
            <rect
                x="48"
                y="288"
                width="176"
                height="176"
                rx="20"
                ry="20"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
            />
            <rect
                x="288"
                y="288"
                width="176"
                height="176"
                rx="20"
                ry="20"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
            />
        </svg>
    );
}

export function NavSuppliersIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 512 512">
            <rect
                x="64"
                y="320"
                width="48"
                height="160"
                rx="8"
                ry="8"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
            />
            <rect
                x="288"
                y="224"
                width="48"
                height="256"
                rx="8"
                ry="8"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
            />
            <rect
                x="400"
                y="112"
                width="48"
                height="368"
                rx="8"
                ry="8"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
            />
            <rect
                x="176"
                y="32"
                width="48"
                height="448"
                rx="8"
                ry="8"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
            />
        </svg>
    );
}

export function NavProfileIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 512 512">
            <rect
                x="32"
                y="128"
                width="448"
                height="320"
                rx="48"
                ry="48"
                fill="none"
                stroke="currentColor"
                strokeLinejoin="round"
                strokeWidth="32"
            />
            <path
                d="M144 128V96a32 32 0 0132-32h160a32 32 0 0132 32v32M480 240H32M320 240v24a8 8 0 01-8 8H200a8 8 0 01-8-8v-24"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
            />
        </svg>
    );
}

export function NavOperationsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 22 22"
            fill="none"
        >
            <path
                d="M17.4626 7.90587C17.1807 8.18596 16.7995 8.34316 16.4021 8.34316C16.0047 8.34316 15.6234 8.18596 15.3415 7.90587L14.0961 6.6604C13.8157 6.37854 13.6583 5.99716 13.6583 5.59962C13.6583 5.20207 13.8157 4.8207 14.0961 4.53884L16.9151 1.71837C16.9207 1.713 16.9249 1.70634 16.9272 1.69897C16.9296 1.69161 16.9301 1.68377 16.9287 1.67616C16.9273 1.66854 16.9241 1.66139 16.9193 1.65534C16.9144 1.6493 16.9082 1.64454 16.9011 1.64149C15.0814 0.861494 12.7222 1.25899 11.224 2.74634C9.7906 4.16899 9.70904 6.30884 10.234 8.17633C10.3086 8.44178 10.3084 8.72268 10.2335 8.98803C10.1586 9.25338 10.0118 9.49287 9.80935 9.68008L1.99998 16.8121C1.76957 17.0164 1.58339 17.2656 1.45286 17.5445C1.32234 17.8234 1.25025 18.1261 1.24101 18.4339C1.23177 18.7417 1.28558 19.0481 1.39914 19.3343C1.51269 19.6205 1.6836 19.8805 1.90133 20.0983C2.11907 20.316 2.37904 20.4869 2.66527 20.6005C2.95149 20.714 3.25792 20.7678 3.56571 20.7586C3.8735 20.7493 4.17615 20.6772 4.45504 20.5467C4.73394 20.4162 4.9832 20.23 5.18748 19.9996L12.3997 12.1715C12.5845 11.9725 12.82 11.8275 13.0809 11.7521C13.3419 11.6767 13.6184 11.6737 13.8809 11.7435C15.7343 12.2399 17.8437 12.1466 19.2584 10.7502C20.7814 9.25024 21.11 6.59899 20.3717 5.08165C20.3684 5.07502 20.3635 5.06927 20.3575 5.06488C20.3516 5.06049 20.3446 5.05758 20.3373 5.05639C20.33 5.0552 20.3225 5.05576 20.3154 5.05804C20.3084 5.06031 20.3019 5.06422 20.2967 5.06946L17.4626 7.90587Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M3.5 19.25C3.91421 19.25 4.25 18.9142 4.25 18.5C4.25 18.0858 3.91421 17.75 3.5 17.75C3.08579 17.75 2.75 18.0858 2.75 18.5C2.75 18.9142 3.08579 19.25 3.5 19.25Z"
                fill="currentColor"
            />
        </svg>
    );
}

export function NavTargetingIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 512 512">
            <g fill="none" stroke="currentColor" strokeWidth="35.25" strokeMiterlimit="10">
                <path d="M491,256c0-129.7-105.3-235-235-235C126.3,21,21,126.3,21,256s105.3,235,235,235C385.7,491,491,385.8,491,256z" />
                <path d="M397,256c0-77.8-63.2-141-141-141s-141,63.2-141,141s63.2,141,141,141S397,333.9,397,256z" />
            </g>
            <path
                fill="currentColor"
                d="M312.4,256c0-31.1-25.3-56.4-56.4-56.4s-56.4,25.3-56.4,56.4s25.3,56.4,56.4,56.4S312.4,287.2,312.4,256z"
            />
        </svg>
    );
}

export function NavLogoutIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="100%" height="100%">
            <title>Exit</title>
            <path
                d="M320 176v-40a40 40 0 00-40-40H88a40 40 0 00-40 40v240a40 40 0 0040 40h192a40 40 0 0040-40v-40M384 176l80 80-80 80M191 256h273"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
            />
        </svg>
    );
}

export function ChevronLeftIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
    );
}

export function CalendarIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <rect
                fill="none"
                stroke="currentColor"
                strokeLinejoin="round"
                strokeWidth="32"
                x="48"
                y="80"
                width="416"
                height="384"
                rx="48"
            />
            <g fill="currentColor">
                <circle cx="296" cy="232" r="24" />
                <circle cx="376" cy="232" r="24" />
                <circle cx="296" cy="312" r="24" />
                <circle cx="376" cy="312" r="24" />
                <circle cx="136" cy="312" r="24" />
                <circle cx="216" cy="312" r="24" />
                <circle cx="136" cy="392" r="24" />
                <circle cx="216" cy="392" r="24" />
                <circle cx="296" cy="392" r="24" />
            </g>
            <path
                fill="none"
                stroke="currentColor"
                strokeLinejoin="round"
                strokeWidth="32"
                strokeLinecap="round"
                d="M128 48v32M384 48v32"
            />
            <path
                fill="none"
                stroke="currentColor"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M464 160H48"
            />
        </svg>
    );
}

export function CircleIcon({fill = false}: {fill?: boolean}) {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 35 35"
            fill={fill ? 'currentColor' : 'none'}
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="17.5" cy="17.5" r="16.75" stroke="currentColor" strokeWidth="1.5" />
        </svg>
    );
}

export function PlusCircleIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                fill="none"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="32"
            />
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M256 176v160M336 256H176"
            />
        </svg>
    );
}

export function MinusCircleIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                fill="none"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="32"
            />
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M336 256H176"
            />
        </svg>
    );
}

export function AvatarIcon({filled = false}: {filled?: boolean}) {
    const fillColor = filled ? 'currentColor' : 'none';

    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill={fillColor}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.8123 7.87305C18.5979 10.7644 16.406 13.123 13.9998 13.123C11.5935 13.123 9.39785 10.7649 9.1873 7.87305C8.96855 4.86523 11.1014 2.62305 13.9998 2.62305C16.8982 2.62305 19.031 4.91992 18.8123 7.87305Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.9998 16.625C9.24195 16.625 4.41305 19.25 3.51945 24.2047C3.41172 24.8019 3.74969 25.375 4.37477 25.375H23.6248C24.2504 25.375 24.5884 24.8019 24.4806 24.2047C23.5865 19.25 18.7576 16.625 13.9998 16.625Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
            />
        </svg>
    );
}

export function BellIcon({filled = false, showDot = false}: {filled?: boolean; showDot?: boolean}) {
    const fillColor = filled ? 'currentColor' : 'none';
    const notificationDot = showDot ? '#9DD76B' : 'none';

    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill={fillColor}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M25.0592 20.5916C23.5545 18.75 22.4922 17.8125 22.4922 12.7354C22.4922 8.08594 20.118 6.42949 18.1639 5.625C17.9043 5.51836 17.66 5.27344 17.5808 5.00684C17.2381 3.84023 16.2771 2.8125 14.9998 2.8125C13.7225 2.8125 12.7609 3.84082 12.4217 5.00801C12.3426 5.27754 12.0982 5.51836 11.8387 5.625C9.88222 6.43066 7.51035 8.08125 7.51035 12.7354C7.50742 17.8125 6.44511 18.75 4.94043 20.5916C4.31699 21.3545 4.86308 22.5 5.95351 22.5H24.0519C25.1365 22.5 25.6791 21.351 25.0592 20.5916Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.7499 22.498V23.4355C18.7499 24.4301 18.3548 25.3839 17.6515 26.0872C16.9483 26.7905 15.9944 27.1855 14.9999 27.1855C14.0053 27.1855 13.0515 26.7905 12.3482 26.0872C11.645 25.3839 11.2499 24.4301 11.2499 23.4355V22.498"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M25 9C25 11.2091 23.2091 13 21 13C18.7909 13 17 11.2091 17 9C17 6.79086 18.7909 5 21 5C23.2091 5 25 6.79086 25 9Z"
                fill={notificationDot}
            />
        </svg>
    );
}

export function StopIcon() {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="12"
                cy="12"
                r="11.25"
                fill="currentColor"
                stroke="white"
                strokeWidth="1.5"
            />
            <rect x="6" y="11" width="12" height="3" fill="white" />
        </svg>
    );
}

export function ComparisonArrowIcon() {
    return (
        <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.8216 20.9912L9.56932 16.0718C9.40252 15.9143 9.40252 15.6596 9.56932 15.5021L10.272 14.8386C10.4388 14.6811 10.7085 14.6811 10.8753 14.8386L15.1232 18.8097L19.3712 14.8386C19.538 14.6811 19.8077 14.6811 19.9745 14.8386L20.6772 15.5021C20.8439 15.6596 20.8439 15.9143 20.6772 16.0718L15.4249 20.9912C15.2581 21.1488 14.9884 21.1488 14.8216 20.9912Z"
                fill="currentColor"
            />
            <path d="M15.1233 18.9802V8.33203" stroke="currentColor" strokeWidth="2" />
            <circle cx="15" cy="15" r="14" stroke="currentColor" strokeWidth="2" />
        </svg>
    );
}

export function HelpIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.0002 19.75C15.385 19.75 19.7502 15.3848 19.7502 10C19.7502 4.61522 15.385 0.25 10.0002 0.25C4.61547 0.25 0.250244 4.61522 0.250244 10C0.250244 15.3848 4.61547 19.75 10.0002 19.75Z"
                fill="currentColor"
            />
            <path
                d="M10.2892 12.0582V11.7819C10.2892 11.1858 10.6963 11.0404 11.2488 10.8514C12.3683 10.4588 13.1971 9.4556 13.1971 8.04527C13.1971 6.30053 11.9467 5.22461 10.2165 5.22461C8.50083 5.22461 7.17773 6.31507 7.17773 8.30698H8.93701C8.96609 7.50731 9.56221 7.04204 10.1874 7.04204C10.8853 7.04204 11.3796 7.43461 11.3796 8.14704C11.3796 8.53961 11.147 9.00487 10.6236 9.22296L10.1002 9.42652C8.89339 9.92086 8.61714 10.6478 8.71892 11.7674L8.73346 12.0582H10.2892ZM9.50405 15.5476C10.1729 15.5476 10.7254 15.0533 10.7254 14.3699C10.7254 13.6866 10.1729 13.2068 9.50405 13.2068C8.84977 13.2068 8.29727 13.6866 8.29727 14.3699C8.29727 15.0533 8.84977 15.5476 9.50405 15.5476Z"
                fill="white"
            />
        </svg>
    );
}

export function ExclamationPointIcon() {
    return (
        <svg
            viewBox="0 0 27 27"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
        >
            <path
                d="M11.5559 21.7026C17.3858 21.7026 22.1118 17.0681 22.1118 11.3513C22.1118 5.63443 17.3858 1 11.5559 1C5.72604 1 1 5.63443 1 11.3513C1 17.0681 5.72604 21.7026 11.5559 21.7026Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.558 12.5781C10.5774 13.0892 10.9975 13.4936 11.509 13.4936C12.0205 13.4936 12.4405 13.0892 12.46 12.5781L12.7013 6.23862C12.7271 5.56231 12.1858 5 11.509 5C10.8322 5 10.2909 5.56231 10.3166 6.23862L10.558 12.5781ZM11.491 18C12.3174 18 13 17.3695 13 16.4979C13 15.6262 12.3174 15.0143 11.491 15.0143C10.6826 15.0143 10 15.6262 10 16.4979C10 17.3695 10.6826 18 11.491 18Z"
                fill="white"
            />
        </svg>
    );
}

export function CheckmarkCircleIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                fill="none"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="32"
            />
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M352 176L217.6 336 160 272"
            />
        </svg>
    );
}

export function CloseCircleIcon({invert = false}: {invert?: boolean}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                fill={invert ? 'currentColor' : 'none'}
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="32"
            />
            <path
                fill="none"
                stroke={invert ? 'white' : 'currentColor'}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M320 320L192 192M192 320l128-128"
            />
        </svg>
    );
}

export function NavGearIcon() {
    return (
        <svg
            viewBox="0 0 20 20"
            width="100%"
            height="100%"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.3,7.4C9.7,7.3,9.2,7.4,8.7,7.7C8.2,8,7.9,8.4,7.6,8.9c-0.2,0.5-0.3,1-0.2,1.6c0.1,0.5,0.3,1,0.7,1.4c0.4,0.4,0.9,0.6,1.4,0.7c0.5,0.1,1.1,0,1.6-0.2c0.5-0.2,0.9-0.6,1.2-1.1c0.3-0.5,0.4-1,0.3-1.6c-0.1-0.6-0.3-1.2-0.8-1.6C11.4,7.7,10.9,7.4,10.3,7.4L10.3,7.4z M16.6,10c0,0.3,0,0.6-0.1,0.9l1.9,1.5c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0,0.2-0.1,0.3l-1.8,3c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2,0-0.3,0l-1.8-0.7c-0.1,0-0.2-0.1-0.3,0c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.2-0.6,0.4-0.9,0.5c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3l-0.3,2c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.3,0.1H8.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.2-0.3l-0.3-2c0-0.1-0.1-0.2-0.1-0.3C7.3,16.1,7.3,16,7.2,16c-0.3-0.1-0.6-0.3-0.9-0.5c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0l-1.8,0.7c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.2-0.2l-1.8-3c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.2-0.3l1.6-1.2c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0.1-0.2,0-0.3c0-0.2,0-0.3,0-0.5c0-0.2,0-0.3,0-0.5c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.3L1.6,7.7C1.5,7.6,1.5,7.5,1.5,7.4c0-0.1,0-0.2,0.1-0.3l1.8-3C3.3,4,3.4,3.9,3.5,3.9c0.1,0,0.2,0,0.3,0l1.8,0.7c0.1,0,0.2,0.1,0.3,0c0.1,0,0.2-0.1,0.3-0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.1,0,0.2-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0.3-2c0-0.1,0.1-0.2,0.2-0.3C8,1.3,8.1,1.2,8.2,1.2h3.5c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.2,0.3l0.3,2c0,0.1,0.1,0.2,0.1,0.3C12.7,3.9,12.7,4,12.8,4c0.3,0.1,0.6,0.3,0.9,0.5c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0l1.8-0.7c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.2,0.2l1.8,3c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1.6,1.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3C16.6,9.7,16.6,9.8,16.6,10z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export function NavGBPIcon() {
    return (
        <svg
            viewBox="0 0 12 15"
            width="12px"
            height="15px"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.015 12.3864C10.015 12.8636 9.76692 13.1364 9.33835 13.1364H4.53759C4.26145 13.1364 4.03759 12.9125 4.03759 12.6364V9.77273C4.03759 9.49658 4.26145 9.27273 4.53759 9.27273H6.94361C7.21975 9.27273 7.44361 9.04887 7.44361 8.77273V8.40909C7.44361 8.13295 7.21975 7.90909 6.94361 7.90909H4.53759C4.26145 7.90909 4.03759 7.68523 4.03759 7.40909V4.93182C4.03759 2.88636 5.23308 1.79545 6.47368 1.79545C8.01332 1.79545 8.95254 2.7854 9.12793 4.2055C9.16178 4.47956 9.38175 4.70455 9.6579 4.70455H10.6203C10.8964 4.70455 11.1228 4.48 11.0991 4.20487C10.8777 1.62855 8.94809 0 6.69925 0C4.28571 0 2.05263 1.59091 2.05263 4.93182V7.40909C2.05263 7.68523 1.82877 7.90909 1.55263 7.90909H0.838346C0.562203 7.90909 0.338346 8.13295 0.338346 8.40909V8.77273C0.338346 9.04887 0.562204 9.27273 0.838346 9.27273H1.55263C1.82877 9.27273 2.05263 9.49658 2.05263 9.77273V12.6364C2.05263 12.9125 1.82877 13.1364 1.55263 13.1364H0.5C0.223858 13.1364 0 13.3602 0 13.6364V14.5C0 14.7761 0.223858 15 0.5 15H9.85714C11.2782 15 12 13.8182 12 12.3182V11.7045C12 11.4284 11.7761 11.2045 11.5 11.2045H10.515C10.2389 11.2045 10.015 11.4284 10.015 11.7045V12.3864Z"
                fill="currentColor"
            />
        </svg>
    );
}

export function NavHelpIcon() {
    return (
        <svg
            viewBox="0 0 20 20"
            width="100%"
            height="100%"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill="none"
                stroke="currentColor"
                strokeWidth="1.85"
                d="M5.9,6c0,0,0.1-1.4,1.4-2.6c0.8-0.7,1.8-0.9,2.7-0.9c0.8,0,1.5,0.1,2,0.3c0.7,0.4,2.2,1.2,2.2,3.1c0,2-1.3,2.9-2.7,3.8c-1.4,1-1.8,1.9-1.8,3"
            />
            <path
                fill="currentColor"
                d="M10,17.5c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4s-1.4,0.6-1.4,1.4S9.2,17.5,10,17.5z"
            />
        </svg>
    );
}

export function FileUploadIcon() {
    return (
        <svg
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M40.5 8.43896H13.5C10.7578 8.43896 8.96484 9.91553 8.4375 12.6577L5.0625 28.689V40.5015C5.0664 41.8429 5.60102 43.1283 6.54958 44.0769C7.49813 45.0254 8.78354 45.5601 10.125 45.564H43.875C45.2165 45.5601 46.5019 45.0254 47.4504 44.0769C48.399 43.1283 48.9336 41.8429 48.9375 40.5015V28.689L45.5625 12.6577C45.0352 9.81006 43.1367 8.43896 40.5 8.43896Z"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinejoin="round"
            />
            <path
                d="M5.0625 28.685H20.25"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.75 28.685H48.9375"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.25 28.685C20.25 30.4752 20.9612 32.1921 22.227 33.458C23.4929 34.7238 25.2098 35.435 27 35.435C28.7902 35.435 30.5071 34.7238 31.773 33.458C33.0388 32.1921 33.75 30.4752 33.75 28.685"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.1875 15.1884H38.8125"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.4995 21.9367H40.4995"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export function UploadIcon() {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.0002 17.2409H18.5627C21.1408 17.2409 23.2502 15.8717 23.2502 13.3222C23.2502 10.7726 20.7658 9.50326 18.7502 9.40341C18.3334 5.41623 15.422 2.99092 12.0002 2.99092C8.76579 2.99092 6.68267 5.13732 6.00017 7.26592C3.18767 7.5331 0.750168 9.32279 0.750168 12.2534C0.750168 15.184 3.28142 17.2409 6.37517 17.2409H9.00016"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.0002 11.9899L12.0002 8.98991L9.00017 11.9899"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.0001 21.0106V9.74093"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export function FileIcon() {
    return (
        <svg viewBox="0 0 29 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.5992 1.375H9.12516C5.74676 1.375 4.05756 1.375 3.00803 2.42453C1.9585 3.47407 1.9585 5.16327 1.9585 8.54167V26.4583C1.9585 29.8367 1.9585 31.5259 3.00803 32.5755C4.05756 33.625 5.74676 33.625 9.12516 33.625H19.8752C23.2536 33.625 24.9428 33.625 25.9923 32.5755C27.0418 31.5259 27.0418 29.8367 27.0418 26.4583V11.8176C27.0418 11.0853 27.0418 10.7191 26.9054 10.3898C26.7691 10.0606 26.5101 9.80165 25.9923 9.2838L19.133 2.42453C18.6152 1.90669 18.3563 1.64776 18.027 1.51138C17.6977 1.375 17.3316 1.375 16.5992 1.375Z"
                stroke="currentColor"
                strokeWidth="2.5"
            />
            <path
                d="M9.125 19.2917L19.875 19.2917"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
            />
            <path
                d="M9.125 26.4583L16.2917 26.4583"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
            />
            <path
                d="M16.2915 1.375V8.54167C16.2915 10.2309 16.2915 11.0755 16.8163 11.6002C17.341 12.125 18.1856 12.125 19.8748 12.125H27.0415"
                stroke="currentColor"
                strokeWidth="2.5"
            />
        </svg>
    );
}

export function BulbIcon() {
    return (
        <svg
            viewBox="0 0 21 21"
            width="100%"
            height="100%"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.4686 15.7506V14.7663C12.4686 13.5768 13.7623 12.4517 14.6015 11.6491C15.7844 10.5183 16.4061 8.99905 16.4061 7.21938C16.4061 3.93813 13.7922 1.31313 10.4999 1.31313C9.72367 1.31097 8.95467 1.46226 8.23712 1.75831C7.51956 2.05436 6.8676 2.48933 6.31872 3.0382C5.76985 3.58708 5.33488 4.23904 5.03883 4.9566C4.74278 5.67415 4.59149 6.44315 4.59365 7.21938C4.59365 8.93547 5.24211 10.5576 6.39834 11.6491C7.23301 12.437 8.53115 13.5645 8.53115 14.7663V15.7506"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.18747 19.6869H11.8125"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.53116 17.7194H12.4687"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.4999 15.75V10.5"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.0585 9.84326C12.0585 9.84326 11.1763 10.4995 10.4999 10.4995C9.82359 10.4995 8.94135 9.84326 8.94135 9.84326"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export function FlameIcon() {
    return (
        <svg
            viewBox="0 0 22 22"
            width="100%"
            height="100%"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.81244 13.7498C4.81244 9.75366 10.1406 6.65991 8.93744 2.06226C11.7734 2.06226 17.1874 6.18726 17.1874 13.7498C17.1874 15.3908 16.5355 16.9646 15.3752 18.125C14.2148 19.2854 12.641 19.9373 10.9999 19.9373C9.35891 19.9373 7.7851 19.2854 6.62471 18.125C5.46433 16.9646 4.81244 15.3908 4.81244 13.7498V13.7498Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.7501 15.8129C13.7501 18.2926 12.3751 19.2504 11.0001 19.2504C9.62506 19.2504 8.25006 18.2926 8.25006 15.8129C8.25006 13.3331 9.96881 12.1176 9.62506 10.3129C11.4297 10.3129 13.7501 13.3331 13.7501 15.8129Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export function CarIcon() {
    return (
        <svg
            viewBox="0 0 23 23"
            width="100%"
            height="100%"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.59378 10.0625L5.29093 6.10264C5.5672 5.45801 6.27112 5.03125 7.05771 5.03125H15.9424C16.7289 5.03125 17.4329 5.45801 17.7091 6.10264L19.4063 10.0625"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.4063 10.0634H3.59378V16.5321H19.4063V10.0634Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.03128 16.5321V17.9696H3.59378V16.5321"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.4062 16.5321V17.9696H17.9688V16.5321"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.46878 13.6572C6.86573 13.6572 7.18753 13.3354 7.18753 12.9385C7.18753 12.5415 6.86573 12.2197 6.46878 12.2197C6.07183 12.2197 5.75003 12.5415 5.75003 12.9385C5.75003 13.3354 6.07183 13.6572 6.46878 13.6572Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.5312 13.6572C16.9281 13.6572 17.2499 13.3354 17.2499 12.9385C17.2499 12.5415 16.9281 12.2197 16.5312 12.2197C16.1342 12.2197 15.8124 12.5415 15.8124 12.9385C15.8124 13.3354 16.1342 13.6572 16.5312 13.6572Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export function CashIcon() {
    return (
        <svg viewBox="0 0 22 18" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.06251 14.4377L19.9375 14.4377C20.3172 14.4377 20.625 14.1299 20.625 13.7502V4.12524C20.625 3.74555 20.3172 3.43774 19.9375 3.43774L2.06251 3.43774C1.68281 3.43774 1.37501 3.74555 1.37501 4.12524V13.7502C1.37501 14.1299 1.68281 14.4377 2.06251 14.4377Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
            <path
                d="M10.9999 12.3755C12.8984 12.3755 14.4374 10.8365 14.4374 8.93799C14.4374 7.03951 12.8984 5.50049 10.9999 5.50049C9.10146 5.50049 7.56244 7.03951 7.56244 8.93799C7.56244 10.8365 9.10146 12.3755 10.9999 12.3755Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.6249 6.87488C19.7132 6.87488 18.8389 6.51271 18.1942 5.86806C17.5495 5.2234 17.1874 4.34906 17.1874 3.43738"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.37497 6.87488C1.82639 6.87488 2.27339 6.78596 2.69044 6.61321C3.1075 6.44046 3.48645 6.18726 3.80565 5.86806C4.12485 5.54886 4.37805 5.16991 4.5508 4.75285C4.72355 4.3358 4.81247 3.8888 4.81247 3.43738"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.6249 11.0004C19.7132 11.0004 18.8389 11.3625 18.1942 12.0072C17.5495 12.6518 17.1874 13.5262 17.1874 14.4379"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.37497 11.0004C1.82639 11.0004 2.27339 11.0893 2.69044 11.262C3.1075 11.4348 3.48645 11.688 3.80565 12.0072C4.12485 12.3264 4.37805 12.7053 4.5508 13.1224C4.72355 13.5394 4.81247 13.9864 4.81247 14.4379"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export function CardIcon() {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.125 4.49992H4.87497C3.42522 4.49992 2.24997 5.67518 2.24997 7.12492V16.8749C2.24997 18.3247 3.42522 19.4999 4.87497 19.4999H19.125C20.5747 19.4999 21.75 18.3247 21.75 16.8749V7.12492C21.75 5.67518 20.5747 4.49992 19.125 4.49992Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.24997 9.00025H21.75"
                stroke="currentColor"
                strokeWidth="2.8125"
                strokeLinejoin="round"
            />
            <path
                d="M8.24988 14.0634H5.99988V15.0009H8.24988V14.0634Z"
                stroke="currentColor"
                strokeWidth="2.8125"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export function EyeIcon() {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.9841 5.25014C8.33067 5.25014 4.58301 7.36468 1.6327 11.5937C1.54849 11.7158 1.50234 11.86 1.50009 12.0083C1.49783 12.1566 1.53958 12.3022 1.62004 12.4267C3.88692 15.9751 7.58442 18.7501 11.9841 18.7501C16.336 18.7501 20.1094 15.9667 22.3805 12.4103C22.4591 12.2882 22.5009 12.1461 22.5009 12.0008C22.5009 11.8556 22.4591 11.7135 22.3805 11.5914C20.1043 8.07577 16.3032 5.25014 11.9841 5.25014Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 15.7499C14.0711 15.7499 15.75 14.0709 15.75 11.9999C15.75 9.92881 14.0711 8.24988 12 8.24988C9.92893 8.24988 8.25 9.92881 8.25 11.9999C8.25 14.0709 9.92893 15.7499 12 15.7499Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
            />
        </svg>
    );
}

export function EyeOffIcon() {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.2501 21.0005C20.1516 21.0006 20.054 20.9813 19.963 20.9435C19.872 20.9058 19.7894 20.8504 19.7199 20.7806L3.21994 4.28063C3.08522 4.13883 3.01123 3.95002 3.01373 3.75445C3.01624 3.55888 3.09504 3.37202 3.23334 3.23372C3.37164 3.09542 3.5585 3.01661 3.75407 3.01411C3.94964 3.01161 4.13845 3.0856 4.28025 3.22032L20.7802 19.7203C20.8851 19.8252 20.9564 19.9588 20.9853 20.1042C21.0143 20.2497 20.9994 20.4004 20.9427 20.5374C20.8859 20.6744 20.7899 20.7915 20.6666 20.874C20.5433 20.9564 20.3984 21.0004 20.2501 21.0005Z"
                fill="currentColor"
            />
            <path
                d="M11.984 18.0002C10.0392 18.0002 8.16371 17.4246 6.40964 16.2893C4.81261 15.258 3.37496 13.781 2.25183 12.0237V12.0199C3.18652 10.6807 4.21027 9.54819 5.30996 8.63507C5.3199 8.62675 5.32802 8.61646 5.33379 8.60485C5.33956 8.59324 5.34285 8.58055 5.34347 8.5676C5.34409 8.55465 5.34201 8.54171 5.33737 8.5296C5.33273 8.5175 5.32563 8.50648 5.31652 8.49726L4.38277 7.56491C4.36618 7.54818 4.34389 7.53832 4.32035 7.53727C4.29681 7.53623 4.27374 7.54409 4.25574 7.55929C3.08761 8.54366 2.0034 9.75116 1.01761 11.1649C0.848014 11.4083 0.754608 11.6967 0.749266 11.9933C0.743924 12.29 0.826888 12.5815 0.987614 12.8308C2.22558 14.7682 3.81886 16.3999 5.59449 17.5488C7.59371 18.844 9.74527 19.5002 11.984 19.5002C13.1924 19.4965 14.3923 19.2973 15.5371 18.9105C15.5522 18.9054 15.5658 18.8965 15.5765 18.8847C15.5872 18.8729 15.5947 18.8585 15.5983 18.843C15.602 18.8275 15.6015 18.8113 15.5971 18.7959C15.5928 18.7806 15.5845 18.7666 15.5732 18.7554L14.5617 17.7438C14.5384 17.7211 14.5096 17.7048 14.4781 17.6966C14.4466 17.6884 14.4136 17.6885 14.3821 17.6969C13.5987 17.8987 12.793 18.0006 11.984 18.0002Z"
                fill="currentColor"
            />
            <path
                d="M23.0081 11.184C21.7678 9.26585 20.1586 7.63648 18.3548 6.47163C16.3594 5.18163 14.1562 4.4996 11.9841 4.4996C10.7884 4.50172 9.60172 4.70503 8.47358 5.10101C8.45855 5.10624 8.4451 5.11521 8.4345 5.12708C8.42389 5.13894 8.41648 5.15331 8.41296 5.16883C8.40945 5.18436 8.40994 5.20052 8.41439 5.2158C8.41885 5.23107 8.42712 5.24497 8.43843 5.25617L9.44858 6.26632C9.4721 6.28944 9.5013 6.30594 9.53323 6.31417C9.56516 6.3224 9.5987 6.32207 9.63046 6.3132C10.3978 6.10562 11.1891 6.00018 11.9841 5.9996C13.8914 5.9996 15.7612 6.58226 17.5411 7.73398C19.1681 8.78398 20.6226 10.2596 21.7486 11.9996C21.7494 12.0007 21.7499 12.002 21.7499 12.0033C21.7499 12.0047 21.7494 12.006 21.7486 12.0071C20.9313 13.2938 19.9171 14.4442 18.743 15.4163C18.7329 15.4246 18.7247 15.4349 18.7188 15.4465C18.713 15.4582 18.7096 15.4709 18.7089 15.4839C18.7083 15.4969 18.7103 15.5099 18.715 15.5221C18.7196 15.5343 18.7268 15.5453 18.7359 15.5546L19.6687 16.4869C19.6852 16.5036 19.7074 16.5135 19.7308 16.5146C19.7543 16.5157 19.7773 16.508 19.7953 16.493C21.0487 15.4376 22.1337 14.1971 23.0128 12.8143C23.1682 12.5706 23.2503 12.2874 23.2495 11.9984C23.2487 11.7094 23.1649 11.4268 23.0081 11.184Z"
                fill="currentColor"
            />
            <path
                d="M11.9997 7.49934C11.6627 7.49916 11.3267 7.5369 10.998 7.61184C10.9814 7.61529 10.9661 7.62317 10.9536 7.63465C10.9411 7.64613 10.932 7.66078 10.9272 7.67704C10.9224 7.69329 10.9221 7.71055 10.9263 7.72697C10.9305 7.74339 10.9391 7.75835 10.9512 7.77028L16.2288 13.0465C16.2407 13.0586 16.2557 13.0672 16.2721 13.0714C16.2885 13.0756 16.3058 13.0753 16.3221 13.0705C16.3383 13.0657 16.353 13.0566 16.3644 13.0441C16.3759 13.0316 16.3838 13.0162 16.3872 12.9997C16.5375 12.3406 16.5374 11.6562 16.3868 10.9972C16.2363 10.3382 15.9392 9.72162 15.5177 9.19319C15.0962 8.66476 14.5611 8.23808 13.952 7.94484C13.343 7.6516 12.6757 7.49933 11.9997 7.49934Z"
                fill="currentColor"
            />
            <path
                d="M7.77091 10.9533C7.75899 10.9413 7.74402 10.9327 7.7276 10.9285C7.71118 10.9243 7.69392 10.9246 7.67767 10.9294C7.66141 10.9342 7.64676 10.9433 7.63528 10.9558C7.6238 10.9683 7.61592 10.9836 7.61247 11.0002C7.44248 11.7429 7.46382 12.5165 7.67451 13.2487C7.88519 13.9809 8.27832 14.6475 8.81704 15.1863C9.35577 15.725 10.0224 16.1181 10.7546 16.3288C11.4868 16.5395 12.2604 16.5608 13.0031 16.3908C13.0197 16.3874 13.035 16.3795 13.0475 16.368C13.06 16.3566 13.0691 16.3419 13.0739 16.3256C13.0787 16.3094 13.0791 16.2921 13.0748 16.2757C13.0706 16.2593 13.062 16.2443 13.05 16.2324L7.77091 10.9533Z"
                fill="currentColor"
            />
        </svg>
    );
}

export function LockIcon() {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 13C4 11.1144 4 10.1716 4.58579 9.58579C5.17157 9 6.11438 9 8 9H16C17.8856 9 18.8284 9 19.4142 9.58579C20 10.1716 20 11.1144 20 13V15C20 17.8284 20 19.2426 19.1213 20.1213C18.2426 21 16.8284 21 14 21H10C7.17157 21 5.75736 21 4.87868 20.1213C4 19.2426 4 17.8284 4 15V13Z"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <path
                d="M16 8V7C16 4.79086 14.2091 3 12 3V3C9.79086 3 8 4.79086 8 7V8"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <circle cx="12" cy="15" r="2" fill="currentColor" />
        </svg>
    );
}

export function ShareIcon() {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 14.251C7.24264 14.251 8.25 13.2436 8.25 12.001C8.25 10.7583 7.24264 9.75096 6 9.75096C4.75736 9.75096 3.75 10.7583 3.75 12.001C3.75 13.2436 4.75736 14.251 6 14.251Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18 7.50047C19.2426 7.50047 20.25 6.49311 20.25 5.25047C20.25 4.00783 19.2426 3.00047 18 3.00047C16.7574 3.00047 15.75 4.00783 15.75 5.25047C15.75 6.49311 16.7574 7.50047 18 7.50047Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18 21.0005C19.2426 21.0005 20.25 19.9931 20.25 18.7505C20.25 17.5078 19.2426 16.5005 18 16.5005C16.7574 16.5005 15.75 17.5078 15.75 18.7505C15.75 19.9931 16.7574 21.0005 18 21.0005Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.96094 13.1034L16.0394 17.6475"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.0394 6.35389L7.96094 10.8979"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
