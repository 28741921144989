import React from 'react';

import {DetailText, VStack} from '@sphericsio/design-system';

import {CompleteBaselinePanel} from './complete-baseline-panel';
import {TargetingPanel} from './targeting-panel';
import {DateDisplay} from '../date-display';
import {FragmentType, useFragment} from '../../graphql/generated';
import {
    TargetingBaselinePanelReductionTargetFragmentDocument,
    TargetingBaselinePanelTargetingBaselineFragmentDocument,
} from './fragments';

type TargetingBaselinePanelProps = {
    targetingBaseline: FragmentType<typeof TargetingBaselinePanelTargetingBaselineFragmentDocument>;
    reductionTarget?: FragmentType<
        typeof TargetingBaselinePanelReductionTargetFragmentDocument
    > | null;
};
export function TargetingBaselinePanel(props: TargetingBaselinePanelProps) {
    const targetingBaseline = useFragment(
        TargetingBaselinePanelTargetingBaselineFragmentDocument,
        props.targetingBaseline,
    );
    const reductionTarget = useFragment(
        TargetingBaselinePanelReductionTargetFragmentDocument,
        props.reductionTarget,
    );
    return (
        <VStack>
            <DetailText inline={false}>
                Your baseline year:{' '}
                <DateDisplay>{targetingBaseline.company_reporting_period.period_start}</DateDisplay>{' '}
                to{' '}
                <DateDisplay>{targetingBaseline.company_reporting_period.period_end}</DateDisplay>
            </DetailText>
            {!targetingBaseline.company_reporting_period.isCompleted && <CompleteBaselinePanel />}
            {targetingBaseline.company_reporting_period.isCompleted && reductionTarget == null && (
                <TargetingPanel />
            )}
        </VStack>
    );
}
