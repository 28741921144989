import React from 'react';

import {VStack} from '@sphericsio/design-system';

import {HSplitLayout} from '../../components/layout/h-split';
import {PageHeader} from '../../components/page-header';
import {ButtonLink} from '../../components/link';
import {PosterLayout} from '../../components/onboarding/poster-layout';
import {PosterJoinTheMovement} from '../../components/onboarding/poster-join-the-movement';

export function ForgotPasswordSuccess() {
    return (
        <HSplitLayout cta="login">
            <VStack>
                <PageHeader
                    title="Forgot your password?"
                    subtitle="We've sent you an email, please check and follow the instructions."
                />

                <div className="flex flex-row items-start pt-5">
                    <ButtonLink to="/legacy-login">Back to Login</ButtonLink>
                </div>
            </VStack>
            <PosterLayout>
                <PosterJoinTheMovement />
            </PosterLayout>
        </HSplitLayout>
    );
}
