import {Route} from 'react-router-dom';
import React from 'react';

import {AccountingRoutes} from '../routes';
import {XeroAppStoreSignup} from './app-store-signup';
import {NotLoggedIn} from '../../../components/route-guard';
import {XeroAppStoreSignupReturn} from './app-store-signup-return';

export function XeroRoutes() {
    return (
        <AccountingRoutes provider="xero">
            <NotLoggedIn>
                {/*
                    This route is accessed by following a link in the Xero App Store.
                    It redirects the user back to Xero's identity provider (OIDC flow)...
                */}
                <Route path="app-store-signup">
                    <XeroAppStoreSignup />
                </Route>
                {/*
                    ...then Xero redirects back here on successful login.
                    We then look up the profile of the Xero user, and use it to redirect to the Sage ID login / sign up flow.
                */}
                <Route path="app-store-signup-return">
                    <XeroAppStoreSignupReturn />
                </Route>
            </NotLoggedIn>
        </AccountingRoutes>
    );
}
