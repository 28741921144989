import {clamp} from 'lodash';

import {chartDimensions, chartMargins, chartPaddings, warningIcon} from './style';

export type ChartDimensions = {
    // Width including padding
    width: number;
    // Height including padding
    height: number;
    // Width excluding padding (i.e. just the barchart area without axes)
    innerWidth: number;
    // Height excluding padding (i.e. just the barchart area without axes)
    innerHeight: number;
};

export function getDimensions(width: number): ChartDimensions {
    const {minHeight, maxHeight, targetAspectRatio} = chartDimensions;

    const innerWidth = width - chartMargins.left - chartMargins.right;
    const innerHeight = clamp(innerWidth / targetAspectRatio, minHeight, maxHeight);
    const height = innerHeight + chartMargins.top + chartMargins.bottom;

    return {width, height, innerWidth, innerHeight};
}

export function getYScale(innerHeight: number, maxValue: number) {
    /*
     * We want the graph to be scaled such that we have just enough space
     * to fit in the largest bar with a warning icon on top.
     * This should all fit within the y-axis.
     * The axis stops at the end of the provided domain (i.e. input value range),
     * and thus we need to adjust our domain such that it is large enough for the warning icon to fit in it.
     *
     * To do so, we:
     * 1. Calculate the height (in pixels) of the tallest bar
     */
    const maxBarHeight = innerHeight - warningIcon.boundingBoxHeight;
    // 2. Calculate what ratio that is in relation to the entire chart.
    //    E.g. the tallest bar could be 0.9 times the height of the chart (meaning 10% is left for the warning icon)
    const barToChartRatio = maxBarHeight / innerHeight;
    // 3. Adjust the top end of the domain accordingly.
    const maxChartValue = maxValue / barToChartRatio;
    return {
        type: 'linear' as const,
        domain: [0, maxChartValue],
    };
}

export const xScale = {
    type: 'band' as const,
    domain: [1, 2, 3, 4],
    paddingInner: chartPaddings.relativeInner,
    paddingOuter: chartPaddings.relativeOuter,
};
