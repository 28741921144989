import React from 'react';
import {useIntercom} from 'react-use-intercom';

import {Button, DetailText, Heading5, LinkText, VStack} from '@sphericsio/design-system';

import config from '../../config';
import {PageHeader} from '../components/page-header';
import {FinancialProviderButtons} from '../components/financial-provider-buttons';
import {HSplitLayout} from '../components/layout/h-split';
import {PosterSecurityProtocols} from '../components/onboarding/poster-security-protocols';
import {PosterLayout} from '../components/onboarding/poster-layout';
import {SubscriptionsModal} from '../components/subscription-modal';
import {useUser} from '../components/user-context';
import {ErrorCard} from '../components/error-card';

const FinancialProviderSync: React.FC = () => {
    const {show, trackEvent} = useIntercom();
    const user = useUser();
    const spreadsheetUploadsEnabled = config.featureFlags.spreadsheetUploads;
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [hasActiveSubscription, setHasActiveSubscription] = React.useState<boolean>(false);
    const [provider, setProvider] = React.useState<string>();

    const hasPendingSubscription = user.subscription?.status === 'pending';

    React.useEffect(() => {
        if (!user.lastSync) {
            trackEvent('Email Verified');
        }
    }, [user]);

    return (
        <>
            <HSplitLayout wide cta="logout">
                <VStack large>
                    <PageHeader
                        title="Connect your data"
                        subtitle="In order to take the first step to net zero, we need to understand your business spending and convert this into carbon emissions."
                    />
                    <VStack>
                        <Heading5>Choose your method:</Heading5>
                        <DetailText>
                            Securely connect to your accounting platform
                            {spreadsheetUploadsEnabled &&
                                ', or upload a spreadsheet of your transactions,'}{' '}
                            and we do everything else!
                        </DetailText>
                        <div className="py-10">
                            <FinancialProviderButtons
                                forLogin={false}
                                hasActiveSubscription={hasActiveSubscription}
                                hasPendingSubscription={hasPendingSubscription}
                                selectedProvider={provider}
                                onButtonClick={(provider: string) => {
                                    if (user.subscription?.status === 'active') {
                                        setHasActiveSubscription(true);
                                        setProvider(provider);
                                    } else {
                                        setShowModal(true);
                                        setProvider(provider);
                                    }
                                }}
                            />
                        </div>
                        {hasPendingSubscription && !showModal && (
                            <>
                                <ErrorCard message="We're still waiting for your payment provider to confirm your payment. If you have been waiting for more than 15 minutes, please contact us for help." />
                                <Button onPress={() => show()}>Contact Support</Button>
                            </>
                        )}
                    </VStack>
                    <DetailText>
                        Use another platform?{' '}
                        <LinkText>
                            <span className="cursor-pointer" onClick={() => show()}>
                                Send us a message
                            </span>
                        </LinkText>{' '}
                        to request integration support.
                    </DetailText>
                </VStack>

                <PosterLayout>
                    <PosterSecurityProtocols />
                </PosterLayout>
            </HSplitLayout>
            <SubscriptionsModal
                show={showModal}
                onClose={() => setShowModal(false)}
                onError={() => setShowModal(false)}
                onSuccess={() => {
                    setShowModal(false);
                    setHasActiveSubscription(true);
                }}
            />
        </>
    );
};

export {FinancialProviderSync};
