import React from 'react';

import {ProfilePeopleIntro} from './intro';
import {ProfilePeopleContext} from './context';
import {ProfileReportingPeriodOpen} from '../reporting-period-open';
import {ProfilePeoplePlacesRequired} from './places-required';
import {ProfilePeopleEmployees} from './employees';
import {ProfilePeoplePlaces} from './places';
import {ProfilePeopleComplete} from './complete';
import {ConstrainWidthContainer} from '../../constrain-width-container';

export function ProfilePeopleWizard() {
    const {currentStep, reportingPeriod, dispatch, addresses, submitting, error} =
        React.useContext(ProfilePeopleContext);

    return (
        <div>
            {currentStep === 'intro' && <ProfilePeopleIntro />}
            {currentStep === 'completed' && <ProfilePeopleComplete />}
            {currentStep === 'places' && (
                <ProfilePeoplePlaces
                    submitting={submitting}
                    addresses={addresses}
                    reportingPeriod={reportingPeriod}
                    onNext={(placesPercentages, workingFromHomePercent) =>
                        dispatch({
                            type: 'setPlacesPercentages',
                            payload: {
                                placesPercentages,
                                workingFromHomePercent,
                            },
                        })
                    }
                />
            )}
            <ConstrainWidthContainer>
                {currentStep === 'places_required' && <ProfilePeoplePlacesRequired />}
                {currentStep === 'employee_count' && (
                    <ProfilePeopleEmployees
                        error={error}
                        submitting={submitting}
                        reportingPeriod={reportingPeriod}
                        onNext={(values) => {
                            dispatch({
                                type: 'setEmployeeCount',
                                payload: parseFloat(values.employees),
                            });
                        }}
                    />
                )}
                {currentStep === 'period_open' && (
                    <ProfileReportingPeriodOpen reportingPeriodEnd={reportingPeriod.period_end} />
                )}
            </ConstrainWidthContainer>
        </div>
    );
}
