import {ApolloClient, HttpLink, InMemoryCache, from, ApolloLink} from '@apollo/client';
import {relayStylePagination} from '@apollo/client/utilities';
import {onError} from '@apollo/client/link/error';

import {clearCurrentUser} from '@sphericsio/mvp-ui-common';

import {achievementsLink} from '../graphql/achievements';
import config from '../../config';
import {storeRedirectPath} from './login-redirect';
import {TypedTypePolicies} from '../graphql/generated/graphql';

let links: ApolloLink[] = [
    onError((error) => {
        const unauthenticatedErrors = error.graphQLErrors?.filter(
            (gqlError) => gqlError.extensions?.code === 'UNAUTHENTICATED',
        );

        if (unauthenticatedErrors != null && unauthenticatedErrors.length > 0) {
            storeRedirectPath();
            clearCurrentUser(client.cache);
        }
    }),
    new HttpLink({
        uri: `${config.apiHost}/graphql`,
        credentials: 'include',
    }),
];

if (config.featureFlags.achievements) {
    links = [achievementsLink, ...links];
}

const typePolicies: TypedTypePolicies = {
    Query: {
        fields: {
            getCompanyMerchantsEmissionsOverview: relayStylePagination([
                'from',
                'to',
                'orderDirection',
            ]),
            getCompanyTransactions: relayStylePagination(['filter', 'orderDirection']),
            getFinancialAccountsForCompany: relayStylePagination(['orderDirection']),
            getTransactionsByFinancialAccount: relayStylePagination(['orderDirection', 'id']),
            getTransactionsByMerchant: relayStylePagination([
                'merchantId',
                'from',
                'to',
                'orderDirection',
            ]),
        },
    },
    Merchant: {
        fields: {
            transactions: relayStylePagination(['orderBy', 'orderDirection']),
        },
    },
};

export const client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies,
    }),
    link: from(links),
});
