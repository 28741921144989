import React from 'react';

import {Background, Text, Button, ButtonLabel} from '@sphericsio/design-system';

type NavigationButtonsProps = {
    backOnClick: () => void;
    nextOnClick: () => void;
    skipOnClick?: () => void;
    backLabel?: string;
    backColour?: Text;
    nextLabel?: string;
    nextBgColour?: Background;
    nextToDisabled?: boolean;
    hideBack?: boolean;
    hideSkip?: boolean;
    nextIntercomTarget?: string;
    loading?: boolean;
    skipLabel?: string;
};
export function NavigationButtons({
    backLabel,
    backColour = 'minor-action',
    backOnClick,
    nextOnClick,
    skipOnClick,
    nextLabel,
    nextBgColour = 'major-action',
    skipLabel,
    nextToDisabled = false,
    hideBack,
    hideSkip,
    nextIntercomTarget,
    loading = false,
}: NavigationButtonsProps) {
    return (
        <div className="flex flex-start items-center space-x-10">
            {!hideBack && (
                <span className="whitespace-nowrap">
                    <span className="cursor-pointer" onClick={() => backOnClick()}>
                        <ButtonLabel colour={backColour}>{backLabel ?? 'Back'}</ButtonLabel>
                    </span>
                </span>
            )}

            <Button
                isDisabled={nextToDisabled}
                isLoading={loading}
                onPress={() => nextOnClick()}
                bg={nextBgColour}
                intercomTarget={nextIntercomTarget}
            >
                {nextLabel ?? 'Next'}
            </Button>
            {!hideSkip && skipOnClick && (
                <Button outline onPress={skipOnClick}>
                    {skipLabel ?? 'Skip'}
                </Button>
            )}
        </div>
    );
}
