import React from 'react';
import classnames from 'classnames';

import {
    LoadingSpinner,
    getCategorisationTheme,
    DetailText,
    ConditionalWrapper,
    outlineColorClassnames,
    bgColorClassnames,
} from '@sphericsio/design-system';

import {useCategoryTheme} from '../../../hooks/categorisation';
import {flattenHierarchyForTaxonomyKeys, UserCategorisationHierarchyItem} from '../hierarchy';
import {CategorisationContext} from '../context';
import {ErrorCard} from '../../error-card';
import {CategoryRow, ExcludedCategorisation, SaveStatus} from '../categorisation';
import {EditCategory} from '../edit-categorisation';
import {SaveCategorisation} from './save-transaction-categorisation';
import {ExcludeTransactionContext} from '../../financial-transactions/exclude-transaction-context';

export function AddCategoryButton() {
    const {categorisationMenuOpen} = React.useContext(CategorisationContext);
    return (
        <div
            className={classnames(
                'flex flex-row items-center py-1.5 px-4 rounded-full whitespace-nowrap',
                !categorisationMenuOpen && `border-2 ${outlineColorClassnames('major-action')}`,
                categorisationMenuOpen && bgColorClassnames('major-action'),
            )}
        >
            <DetailText
                size="small"
                colour={categorisationMenuOpen ? 'white' : 'major-action'}
                bold
            >
                Add category
            </DetailText>
        </div>
    );
}

type TransactionCategorisationProps = {
    taxonomy?: string[];
    id: string;
    excludeReason?: string | null;
    isIgnored: boolean;
    editable: boolean;
    onSaveSuccess: () => void;
};
export function TransactionCategorisation({
    id,
    taxonomy,
    excludeReason,
    isIgnored,
    editable = true,
    onSaveSuccess,
}: TransactionCategorisationProps) {
    const [status, setStatus] = React.useState<SaveStatus>('pending');
    const [selected, setSelected] = React.useState<UserCategorisationHierarchyItem | null>(null);
    const {loading, error, hierarchy} = React.useContext(CategorisationContext);
    const {reasons: _} = React.useContext(ExcludeTransactionContext);

    const data = React.useMemo(() => {
        if (taxonomy && hierarchy) {
            return flattenHierarchyForTaxonomyKeys(hierarchy, taxonomy);
        }
    }, [taxonomy, hierarchy]);

    const theme = useCategoryTheme(data);

    React.useEffect(() => {
        if (status === 'success') {
            onSaveSuccess();
        }
    }, [status]);

    const content = React.useMemo(() => {
        if (status === 'saving' || loading) {
            return <LoadingSpinner />;
        }
        if (status === 'error' || error) {
            return <ErrorCard />;
        }

        if (isIgnored) {
            const reason = excludeReason ? excludeReason.replace('ignored/', '') : '';
            return (
                <ExcludedCategorisation
                    theme={getCategorisationTheme('excluded')}
                    reason={reason}
                />
            );
        }

        if (!data?.length) {
            return (
                <EditCategory status={status} onStatusChange={setStatus} onSelected={setSelected}>
                    <AddCategoryButton />
                </EditCategory>
            );
        }

        return (
            <CategoryRow
                categorisation={data}
                editable={editable}
                theme={theme}
                status={status}
                onStatusChange={setStatus}
                onSelected={setSelected}
            />
        );
    }, [status, data]);

    return (
        <ConditionalWrapper
            condition={status !== 'success'}
            wrapper={(children) => (
                <SaveCategorisation
                    id={id}
                    selected={selected}
                    status={status}
                    onStatusChange={setStatus}
                >
                    {children}
                </SaveCategorisation>
            )}
        >
            {content}
        </ConditionalWrapper>
    );
}
