import React from 'react';

import {
    DetailText,
    Grid,
    Heading1,
    HStack,
    Panel,
    SegmentSubheader,
    textColorClassnames,
    Tooltip,
    VStack,
} from '@sphericsio/design-system';

import {carbonAmountFormatter, CO2e} from '../carbon-amount';
import {ResponsiveScopeGraph} from '../dashboard/scope-graph';
import {ResponsiveDashboardTotalEmissionsGraph} from '../dashboard/total-emissions-graph';
import {FragmentType, useFragment} from '../../graphql/generated';
import {HelpIcon} from '../icons';
import {
    TargetingBaselinePanelReductionTargetFragmentDocument,
    TargetingBaselinePanelTargetingBaselineFragmentDocument,
    TargetingBaselineSummaryReductionTargetVisualisationFragmentDocument,
} from './fragments';

type TotalCarbonAmountProps = {
    amount: number;
};
function TotalCarbonAmount({amount}: TotalCarbonAmountProps) {
    const {formattedAmount, unit} = carbonAmountFormatter({amount});
    return (
        <div className="flex items-center space-x-2">
            <Heading1 element="span">{formattedAmount}</Heading1>
            <DetailText>
                {unit} <CO2e />
            </DetailText>
        </div>
    );
}

function getReductionTargetLabel(reductionTarget: any) {
    switch (reductionTarget.reduction_target_type) {
        case 'net_zero_2030':
            return 'Net zero 2030';

        case 'net_zero_2040':
            return 'Net zero 2040';

        case 'net_zero_2050':
            return 'Net zero 2050';

        default:
            return 'Unknown target';
    }
}

type TargetingBaselineSummaryProps = {
    targetingBaseline?: FragmentType<
        typeof TargetingBaselinePanelTargetingBaselineFragmentDocument
    > | null;
    reductionTarget?: FragmentType<
        typeof TargetingBaselinePanelReductionTargetFragmentDocument
    > | null;
    reductionTargetVisualisation?: FragmentType<
        typeof TargetingBaselineSummaryReductionTargetVisualisationFragmentDocument
    > | null;
};
export function TargetingBaselineSummary(props: TargetingBaselineSummaryProps) {
    const scopeTooltip = {
        text: 'Scoping is a concept used to categorise the greenhouse gas (GHG) emissions of a business according to their source and the degree of direct control it has over them.',
        link: 'https://help.earth.sage.com/en/articles/5519279-what-are-scopes-1-2-and-3-and-why-is-this-important-to-understanding-my-carbon-impact',
        linkText: 'How Sage Earth works out your scope 1, 2 and 3 emissions',
    };
    const targetTooltip = {
        text: 'Net zero is a target state which the IPCC defines as that point when man-made emissions of greenhouse gases (GHGs) to the atmosphere are balanced by man-made removals over a specified period.',
        link: 'https://help.earth.sage.com/en/articles/5571854-how-to-set-a-target',
        linkText: 'How to set a target using Sage Earth',
    };

    const targetingBaseline = useFragment(
        TargetingBaselinePanelTargetingBaselineFragmentDocument,
        props.targetingBaseline,
    );
    const reductionTarget = useFragment(
        TargetingBaselinePanelReductionTargetFragmentDocument,
        props.reductionTarget,
    );
    const reductionTargetVisualisation = useFragment(
        TargetingBaselineSummaryReductionTargetVisualisationFragmentDocument,
        props.reductionTargetVisualisation,
    );

    return (
        <Grid columns={2}>
            <Grid columns={1}>
                <Panel>
                    <VStack>
                        <SegmentSubheader>Your baseline year carbon footprint</SegmentSubheader>
                        <HStack>
                            {!targetingBaseline || !targetingBaseline.scope_co2e_breakdown ? (
                                <Heading1>Unavailable</Heading1>
                            ) : (
                                <TotalCarbonAmount
                                    amount={targetingBaseline.scope_co2e_breakdown.total_kg_co2e}
                                />
                            )}
                        </HStack>
                    </VStack>
                </Panel>
                <Panel>
                    <div className="relative">
                        <div
                            className={`absolute top-0 right-0 w-6 h-6 ${textColorClassnames(
                                'tooltip',
                            )}`}
                        >
                            <Tooltip
                                tooltipText={targetTooltip.text}
                                linkHref={targetTooltip.link}
                                linkText={targetTooltip.linkText}
                                place="left"
                                align="end"
                            >
                                <HelpIcon />
                            </Tooltip>
                        </div>
                    </div>
                    <VStack>
                        <SegmentSubheader>Your committed target</SegmentSubheader>
                        <Heading1>
                            {reductionTarget == null
                                ? 'No target set'
                                : getReductionTargetLabel(reductionTarget)}
                        </Heading1>
                    </VStack>
                </Panel>
                <Panel>
                    <VStack>
                        <SegmentSubheader>Reduction needed to reach target:</SegmentSubheader>
                        <Heading1>
                            {reductionTarget == null
                                ? '-'
                                : `${reductionTarget.yearly_percent_reduction}% per year`}
                        </Heading1>
                    </VStack>
                </Panel>
            </Grid>
            <Panel border flex>
                <VStack full>
                    <SegmentSubheader>Your baseline scope breakdown</SegmentSubheader>
                    <div className="flex flex-1">
                        <ResponsiveScopeGraph
                            data={
                                !targetingBaseline || !targetingBaseline.scope_co2e_breakdown
                                    ? undefined
                                    : [
                                          targetingBaseline.scope_co2e_breakdown.scope_1_kg_co2e,
                                          targetingBaseline.scope_co2e_breakdown.scope_2_kg_co2e,
                                          targetingBaseline.scope_co2e_breakdown.scope_3_kg_co2e,
                                      ]
                            }
                        />
                    </div>
                </VStack>
                <div className="relative">
                    <div
                        className={`absolute top-0 right-0 w-6 h-6 ${textColorClassnames(
                            'tooltip',
                        )}`}
                    >
                        <Tooltip
                            tooltipText={scopeTooltip.text}
                            linkHref={scopeTooltip.link}
                            linkText={scopeTooltip.linkText}
                            place="left"
                        >
                            <HelpIcon />
                        </Tooltip>
                    </div>
                </div>
            </Panel>
            <div className="col-span-2 h-96 flex">
                <Panel border flex>
                    <VStack full>
                        <SegmentSubheader>Progress against target</SegmentSubheader>
                        <DetailText colour="secondary">
                            Measured in <CO2e />
                        </DetailText>

                        <div className="flex flex-1">
                            <ResponsiveDashboardTotalEmissionsGraph
                                data={reductionTargetVisualisation?.emissions}
                                loading={false}
                                initialPeriodTargetKgCo2e={
                                    reductionTargetVisualisation?.initialPeriodTargetKgCo2e
                                }
                                targetCompletionYear={reductionTarget?.target_completion_year}
                                targetStartDate={reductionTargetVisualisation?.periodStart}
                            />
                        </div>
                    </VStack>
                </Panel>
            </div>
        </Grid>
    );
}
