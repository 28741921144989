import React from 'react';
import {format} from 'date-fns';

import {
    DataTableRef,
    relayConnectionQuery,
    RelayConnectionVariables,
} from '@sphericsio/mvp-ui-common';
import {
    DetailText,
    Paragraph,
    PlusIcon,
    MinusIcon,
    VStack,
    HStack,
    CategorisationThemeIcon,
    CategoryTheme,
    textColorClassnames,
} from '@sphericsio/design-system';

import {carbonAmountFormatter, CO2e} from '../carbon-amount';
import {graphql} from '../../graphql/generated';
import {DataList} from '../data-list';
import {useCategoryTheme, usePrepareCategory} from '../../hooks/categorisation';
import {MerchantTransactionsList} from '../financial-transactions/merchant-transactions-list';
import {CategorisationContextProvider} from '../categorisation/context';
import {UserCategorisationHierarchyItem} from '../categorisation/hierarchy';
import {MerchantCategorisationByCompany} from '../categorisation/merchant/merchant-categorisation';
import {
    FetchCustomerMerchantEmissionsQuery,
    MerchantCategorisationMetadata,
} from '../../graphql/generated/graphql';

const FetchCustomerMerchantEmissionsDocument = graphql(/* GraphQL */ `
    query FetchCustomerMerchantEmissions(
        $first: Int
        $after: String
        $last: Int
        $before: String
        $orderDirection: SortDirection!
        $orderBy: EmissionsOrder!
        $from: Date!
        $to: Date!
    ) {
        getCompanyMerchantsEmissionsOverview(
            first: $first
            after: $after
            last: $last
            before: $before
            orderDirection: $orderDirection
            orderBy: $orderBy
            from: $from
            to: $to
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                node {
                    kg_co2e
                    txn_count
                    merchant_id
                    merchant {
                        name
                    }
                    categorisation_metadata {
                        taxonomy_key
                        taxonomy_term_key
                    }
                }
            }
        }
    }
`);

type HighestEmittingSuppliersProps = {
    from: Date;
    to: Date;
};
export function HighestEmittingSuppliers({from, to}: HighestEmittingSuppliersProps) {
    const [currentMerchant, setCurrentMerchant] = React.useState<string>();
    const [showTransactions, setShowTransactions] = React.useState<boolean>(false);
    const merchantTableRef = React.useRef<DataTableRef>(null);

    const queryConfig = relayConnectionQuery(
        FetchCustomerMerchantEmissionsDocument,
        (data: FetchCustomerMerchantEmissionsQuery) => data.getCompanyMerchantsEmissionsOverview,
        {
            from: format(from, 'yyyy-MM-dd'),
            to: format(to, 'yyyy-MM-dd'),
            orderBy: 'kg_co2e',
        } as Partial<RelayConnectionVariables>,
        'network-only',
    );

    const handleExpand = (merchantId: string) => {
        if (currentMerchant !== merchantId) {
            setCurrentMerchant(merchantId);
            setShowTransactions(true);
        } else {
            setCurrentMerchant(undefined);
            setShowTransactions(!showTransactions);
        }
    };

    const refreshData = () => {
        merchantTableRef.current?.refreshData();
    };

    const MerchantRow = ({
        id,
        name,
        categorisationMetadata,
        txnCount,
        kgCO2e,
    }: {
        id: string;
        name: string;
        categorisationMetadata: MerchantCategorisationMetadata;
        txnCount: number;
        kgCO2e: number;
    }) => {
        const data = usePrepareCategory(
            `${categorisationMetadata.taxonomy_key}/${categorisationMetadata.taxonomy_term_key}`,
        );
        const theme = useCategoryTheme(data);

        return (
            <VStack>
                <div className="flex flex-row justify-between items-center">
                    <MerchantDetails name={name} txn_count={txnCount} theme={theme} />
                    <MerchantCategorisation name={name} id={id} data={data} theme={theme} />
                    <MerchantEmissionsWithExpand amount={kgCO2e} merchantId={id} />
                </div>
                {currentMerchant == id && (
                    <MerchantTransactionsList
                        merchantId={currentMerchant}
                        merchantCategorisation={categorisationMetadata}
                        to={to}
                        from={from}
                        onRefetch={refreshData}
                    />
                )}
            </VStack>
        );
    };

    const MerchantEmissionsWithExpand = ({
        amount,
        merchantId,
    }: {
        amount: number;
        merchantId: string;
    }) => {
        const {formattedAmount, unit} = carbonAmountFormatter({amount});

        return (
            <div className="flex flex-row items-center space-x-5 whitespace-nowrap">
                <DetailText bold>
                    {formattedAmount} {unit}
                </DetailText>
                <div
                    className={`${textColorClassnames('tooltip')} w-6 h-6 cursor-pointer`}
                    onClick={() => handleExpand(merchantId)}
                >
                    {showTransactions && currentMerchant === merchantId ? (
                        <MinusIcon />
                    ) : (
                        <PlusIcon />
                    )}
                </div>
            </div>
        );
    };

    const MerchantDetails = ({
        name,
        txn_count,
        theme,
    }: {
        name: string;
        txn_count: number;
        theme: CategoryTheme;
    }) => {
        return (
            <div className="flex flex-row space-x-5 w-40">
                <VStack>
                    <HStack>
                        <CategorisationThemeIcon theme={theme} />
                        <div className="flex flex-col">
                            <DetailText bold>{name}</DetailText>
                            <DetailText colour="secondary">{txn_count} transactions</DetailText>
                        </div>
                    </HStack>
                </VStack>
            </div>
        );
    };

    const MerchantCategorisation = ({
        id,
        name,
        data,
        theme,
    }: {
        id: string;
        name: string;
        data?: UserCategorisationHierarchyItem[];
        theme: CategoryTheme;
    }) => {
        return (
            <div className="flex flex-row w-60">
                <MerchantCategorisationByCompany
                    id={id}
                    name={name}
                    data={data}
                    theme={theme}
                    onSaveSuccess={refreshData}
                />
            </div>
        );
    };

    const headerData = [
        {key: 'supplier', value: <>Supplier</>},
        {key: 'category', value: <>Category</>},
        {
            key: 'kg_co2e',
            value: (
                <>
                    Emissions <span className="font-body">({<CO2e />})</span>
                </>
            ),
        },
    ];

    return (
        <VStack>
            <Paragraph>
                <span className={textColorClassnames()}>
                    This page shows you which category of expenditure Sage Earth has automatically
                    applied to each of your suppliers. Please review to ensure accuracy, and edit if
                    they are incorrect or missing.
                </span>
            </Paragraph>
            <div className="flex flex-1 flex-col">
                <DataList
                    ref={merchantTableRef}
                    idField="merchant_id"
                    sortableFields={['kg_co2e']}
                    initialSortDirection={'desc'}
                    queryConfig={queryConfig}
                    headerData={headerData}
                >
                    {(merchant, _index) => (
                        <CategorisationContextProvider>
                            <MerchantRow
                                name={merchant.merchant.name}
                                id={merchant.merchant_id}
                                categorisationMetadata={merchant.categorisation_metadata}
                                txnCount={merchant.txn_count}
                                kgCO2e={merchant.kg_co2e}
                            />
                        </CategorisationContextProvider>
                    )}
                </DataList>
            </div>
        </VStack>
    );
}
