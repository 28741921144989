import React from 'react';
import * as Yup from 'yup';
import {ApolloError} from '@apollo/client';

import {HStack, VStack} from '@sphericsio/design-system';
import {Form, Input, Button} from '@sphericsio/forms';

import {TabPageHeader} from '../../page-header';
import {ErrorCard} from '../../error-card';
import {ReportingPeriodDates} from '../reporting-period-dates';
import {CompanyReportingPeriod} from '../../../graphql/generated/graphql';

const FORM_SCHEMA = Yup.object().shape({
    employees: Yup.number()
        .required('This field is required')
        .typeError('This field must be a number')
        .integer('This field must be an integer'),
});
type ProfilePeopleEmployeesProps = {
    onNext: (values: {employees: string}) => void;
    reportingPeriod: CompanyReportingPeriod;
    error?: ApolloError;
    submitting?: boolean;
    employees?: string;
};

export function ProfilePeopleEmployees({
    onNext,
    reportingPeriod,
    error,
    submitting,
    employees = '',
}: ProfilePeopleEmployeesProps) {
    const subtitle = <ReportingPeriodDates reportingPeriod={reportingPeriod} />;
    return (
        <VStack testId="employeesForm">
            <TabPageHeader
                title="Please estimate the number of employees that work for you"
                subtitle={subtitle}
            />
            <Form
                initialValues={{employees}}
                validationSchema={FORM_SCHEMA}
                onSubmit={(values) => onNext(values)}
            >
                <Input name="employees" placeholder="Number of employees" />
                {error && <ErrorCard />}
                <HStack>
                    <Button isLoading={submitting} type="submit">
                        Save and continue
                    </Button>
                </HStack>
            </Form>
        </VStack>
    );
}
