import {FileConfig, UploadRequestType} from '../../graphql/generated/graphql';

export function getAcceptedFileTypes(fileConfig: FileConfig[]) {
    const configArray = fileConfig.map((config: FileConfig) => {
        if (config.extension === 'xlsx') {
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        } else {
            return config.extension;
        }
    });
    return configArray.join(', ');
}

export function getUploadRequestName(type: UploadRequestType) {
    switch (type) {
        case 'financial_transactions':
            return 'Spend Data';
        case 'profit_and_loss':
            return 'Profit and Loss';
    }
}
