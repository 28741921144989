import React from 'react';
import {useLazyQuery} from '@apollo/client';
import * as Yup from 'yup';

import {HStack, VStack, DropdownList, DetailText, TextButton} from '@sphericsio/design-system';
import {Form, Input, Button} from '@sphericsio/forms';

import {ErrorCard} from '../../error-card';
import {PostcodeSchema, formatPostcode} from './postcode';
import {graphql} from '../../../graphql/generated';

const LookupAddressDocument = graphql(/* GraphQL */ `
    query LookupAddress($postcode: String!) {
        lookupAddress(postcode: $postcode)
    }
`);

const FORM_SCHEMA = Yup.object().shape({
    postcode: PostcodeSchema().required('Postcode is required'),
});

type ProfilePlacesAddressLookupProps = {
    onNext: (address?: string, postcode?: string) => void;
};
export function ProfilePlacesAddressLookup({onNext}: ProfilePlacesAddressLookupProps) {
    const [lookupAddress, {loading, error, data}] = useLazyQuery(LookupAddressDocument);
    const [chosenPostcode, setChosenPostcode] = React.useState('');

    return (
        <div>
            <VStack testId="addressLookupForm">
                <Form
                    initialValues={{postcode: ''}}
                    onSubmit={({postcode}) => {
                        setChosenPostcode(postcode);
                        lookupAddress({variables: {postcode}});
                    }}
                    validationSchema={FORM_SCHEMA}
                >
                    <HStack align="start">
                        <Input name="postcode" placeholder="Enter your postcode" />
                        <div className="mt-2.5">
                            <Button isLoading={loading} type="submit">
                                Find Address
                            </Button>
                        </div>
                    </HStack>
                    <HStack>
                        <DetailText inline={false}>Can’t find your address?</DetailText>
                        <TextButton onPress={() => onNext()}>Enter Manually</TextButton>
                    </HStack>
                    {error && <ErrorCard message="There was an error searching for your address" />}
                </Form>
                {data && (
                    <DropdownList
                        items={data.lookupAddress}
                        renderItem={(address) => <DetailText>{address}</DetailText>}
                        onClickItem={(address) => onNext(address, formatPostcode(chosenPostcode))}
                    />
                )}
            </VStack>
        </div>
    );
}
