import React, {useContext} from 'react';
import classnames from 'classnames';

import {
    getDefaultCategorisationTheme,
    getCategorisationTheme,
    CategorisationThemeIcon,
    DetailText,
    ConditionalWrapper,
    textColorClassnames,
} from '@sphericsio/design-system';

import {Money} from '../../graphql/scalars';
import {DateDisplay} from '../date-display';
import {MoneyDisplay} from '../money';
import {CategorisationContext} from '../categorisation/context';
import {flattenHierarchyForTaxonomyKeys} from '../categorisation/hierarchy';

type TransactionDetailsProps = {
    date: string;
    amount: Money;
    merchantName?: string;
    taxonomy?: string[];
    excluded?: boolean;
};
export function TransactionDetails({
    date,
    merchantName,
    amount,
    taxonomy,
    excluded,
}: TransactionDetailsProps) {
    const {hierarchy} = useContext(CategorisationContext);

    const theme = React.useMemo(() => {
        if (excluded) {
            return getCategorisationTheme('excluded');
        }
        if (hierarchy && taxonomy) {
            const txnHierarchy = flattenHierarchyForTaxonomyKeys(hierarchy, taxonomy);
            const rootTaxonomy = txnHierarchy?.find((t) => t.taxonomyKey === 'general');
            return getCategorisationTheme(rootTaxonomy?.taxonomyTermKey ?? 'default');
        } else {
            return getDefaultCategorisationTheme();
        }
    }, [hierarchy && taxonomy]);

    return (
        <div
            className={classnames(
                'flex flex-row space-x-5',
                excluded && textColorClassnames('excluded'),
            )}
        >
            <CategorisationThemeIcon theme={theme} />
            <div className="flex flex-col space-y-2 shrink">
                <DetailText bold>{merchantName}</DetailText>
                <TransactionDateAndAmonut date={date} amount={amount} />
            </div>
        </div>
    );
}

type TransactionDateAndAmountProps = {
    date: string;
    amount: Money;
    dateEmphasis?: boolean;
    gray?: boolean;
};
export function TransactionDateAndAmonut({
    date,
    amount,
    dateEmphasis = false,
    gray = false,
}: TransactionDateAndAmountProps) {
    return (
        <DetailText colour={gray ? 'secondary' : 'default'}>
            <div className="flex flex-row divide-x">
                <div className="pr-2 w-16 min-w-min text-right">
                    <ConditionalWrapper
                        condition={dateEmphasis}
                        wrapper={(children) => <DetailText bold>{children}</DetailText>}
                    >
                        <DateDisplay short>{date}</DateDisplay>
                    </ConditionalWrapper>
                </div>
                <div className="pl-2 text-left">
                    <MoneyDisplay money={amount} />
                </div>
            </div>
        </DetailText>
    );
}
