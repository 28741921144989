import React from 'react';

import {
    Button,
    DetailText,
    Heading2,
    HStack,
    Panel,
    TextButton,
    VStack,
} from '@sphericsio/design-system';

import {formatPostcode} from './postcode';
import {DateDisplay} from '../../date-display';
import {CompanyAddress} from '../../../graphql/generated/graphql';

type ProfilePlacesReviewProps = {
    addresses: CompanyAddress[];
    onAddAnother: () => void;
    onEditAddress: (addressId: string) => void;
    onNext?: () => void;
};
export function ProfilePlacesReview({
    addresses,
    onAddAnother,
    onEditAddress,
    onNext,
}: ProfilePlacesReviewProps) {
    return (
        <VStack testId="yourAddressesForm">
            <Heading2>Your addresses</Heading2>
            <DetailText>
                <VStack>
                    {addresses.map((address) => (
                        <Panel flex key={address.id}>
                            <HStack full>
                                <div className="flex-1 whitespace-pre-line">
                                    {address.address.split('\n').join(', ')}{' '}
                                    {formatPostcode(address.postcode)}
                                </div>
                                <div className="w-48 text-center">
                                    <DetailText>{address.building_type?.label}</DetailText>
                                </div>
                                <div className="w-48 text-center">
                                    Added on <DateDisplay>{address.created_at}</DateDisplay>
                                </div>
                                <div className="px-5">
                                    <TextButton noPadding onPress={() => onEditAddress(address.id)}>
                                        Edit
                                    </TextButton>
                                </div>
                            </HStack>
                        </Panel>
                    ))}
                </VStack>
            </DetailText>
            <div>
                <TextButton onPress={onAddAnother}>Add Another</TextButton>
                {onNext && <Button onPress={onNext}>Continue</Button>}
            </div>
        </VStack>
    );
}
