import React from 'react';

import {Border, DetailText, outlineColorClassnames} from '@sphericsio/design-system';

import {TransactionWithCategorisationMetadata} from '../../graphql/generated/graphql';
import {carbonAmountFormatter} from '../carbon-amount';
import {TransactionDateAndAmonut} from './transaction-details';

type TransactionOverviewProps = {
    transaction: TransactionWithCategorisationMetadata;
    borderColour: Border;
};
export function TransactionOverview({transaction, borderColour}: TransactionOverviewProps) {
    return (
        <div
            className={`flex flex-col items-start w-full justify-between border ${outlineColorClassnames(
                borderColour,
            )}/10 p-5 rounded`}
        >
            <DetailText bold inline={false}>
                {transaction.merchant?.name}
            </DetailText>
            <TransactionDateAndAmonut
                date={transaction.transaction_date}
                amount={transaction.amount}
            />
            {transaction.categorisation_metadata.kg_co2e && (
                <FormattedCarbonAmount kgCo2e={transaction.categorisation_metadata.kg_co2e} />
            )}
        </div>
    );
}

function FormattedCarbonAmount({kgCo2e}: {kgCo2e: number}) {
    const {formattedAmount, unit} = carbonAmountFormatter({amount: kgCo2e ?? 0});
    return (
        <DetailText bold>
            {formattedAmount} {unit}
        </DetailText>
    );
}
