import React from 'react';

import {
    DetailText,
    Heading2,
    Paragraph,
    Strong,
    getCategorisationTheme,
    textColorClassnames,
} from '@sphericsio/design-system';

import {NavigationButtons} from '../../navigation-buttons';
import {CategoryRowReadOnly} from '../read-only-categorisation';
import {CategorisationContext} from '../context';
import {
    flattenHierarchyForTaxonomyKeys,
    buildTaxonomyFromChild,
    UserCategorisationHierarchyItem,
} from '../hierarchy';

type VerifyCategorisationModalProps = {
    onCancel: () => void;
    onConfirm: () => void;
    selected: UserCategorisationHierarchyItem | null;
    name: string;
};

export function VerifyCategorisationModal({
    onCancel,
    onConfirm,
    selected,
    name,
}: VerifyCategorisationModalProps) {
    const {hierarchy} = React.useContext(CategorisationContext);

    const confirmChange = () => {
        onConfirm();
        onCancel();
    };

    const data = React.useMemo(() => {
        if (selected && hierarchy) {
            const taxonomy = buildTaxonomyFromChild(selected);
            return flattenHierarchyForTaxonomyKeys(hierarchy, taxonomy);
        } else {
            return [];
        }
    }, [hierarchy, selected]);

    const rootTaxonomy = data?.find((t) => t.taxonomyKey === 'general');

    return (
        <DetailText>
            <div className="flex flex-col items-start">
                <Heading2>You are applying a new category to this merchant.</Heading2>
                <div className="divide-y">
                    <div className="py-5">
                        <Paragraph>
                            <span className={textColorClassnames('error')}>
                                The merchant category you have selected does not correspond to
                                previous transactional categorisations.
                            </span>
                        </Paragraph>
                    </div>
                    <div className="flex flex-col items-start">
                        <div className="pt-5">
                            <Strong>{name}</Strong>
                        </div>
                        <div className="py-5">
                            <CategoryRowReadOnly
                                categorisation={data}
                                theme={getCategorisationTheme(
                                    rootTaxonomy?.taxonomyTermKey ?? 'default',
                                )}
                            />
                        </div>
                    </div>
                    <div className="py-5 flex flex-col items-start">
                        <Strong>Please confirm you would like to apply this categorisation.</Strong>
                        <div className="py-2">
                            <Paragraph>
                                This will not affect any of the individual transactions you have
                                already categorised.
                            </Paragraph>
                        </div>
                    </div>
                </div>
                <NavigationButtons
                    backLabel="Cancel"
                    backOnClick={() => onCancel()}
                    nextLabel="Confirm"
                    nextOnClick={() => confirmChange()}
                />
            </div>
        </DetailText>
    );
}
