import {loadStripe} from '@stripe/stripe-js/pure';
import type {Stripe} from '@stripe/stripe-js';

import config from '../../config';

let stripePromise: Promise<Stripe | null> | undefined;
export function initStripe() {
    if (stripePromise == null) {
        stripePromise = loadStripe(config.stripe.apiKey);
    }
    return stripePromise;
}
