import React from 'react';
import {generatePath, useNavigate} from 'react-router';
import {useLazyQuery} from '@apollo/client';
import {format, isSameDay} from 'date-fns';

import {
    Alert,
    DetailText,
    Heading2,
    HStack,
    Panel,
    SegmentSubheader,
    textColorClassnames,
    Tooltip,
    VStack,
} from '@sphericsio/design-system';

import {Layout} from '../components/layout';
import {ErrorCard} from '../components/error-card';
import {ResponsiveScopeGraph} from '../components/dashboard/scope-graph';
import {DashboardTopActivities} from '../components/dashboard/top-activities';
import {Loading} from '../components/loading';
import {IndustryComparisonPanel} from '../components/dashboard/industry-comparison-panel';
import {TargetComparisonPanel} from '../components/dashboard/target-comparison-panel';
import {CarbonImpactComparisonPanel} from '../components/dashboard/carbon-impact-comparison-panel';
import {InitialSyncBlocker} from '../components/dashboard/initial-sync-blocker';
import {CO2e} from '../components/carbon-amount';
import {ButtonLink} from '../components/link';
import {HelpIcon} from '../components/icons';
import {graphql} from '../graphql/generated';
import {DatePicker} from '../components/picker/date-picker';
import {
    FinancialDateFilterContext,
    FinancialDateFilterContextProvider,
    PickerContextProvider,
} from '../components/picker/context';
import {getStep} from './operations/utils';
import {useUser} from '../components/user-context';
import {formatDateForDisplay} from '../components/date-display';
import {
    CarbonFootprintTrendChart,
    CarbonFootprintTrendChartProps,
} from '../components/dashboard/carbon-footprint-trend-chart';
import {parseCarbonFootprintTrend} from '../graphql/parsers/carbon-footprint-trend';
import {YoYCarbonFootprint} from '../graphql/generated/graphql';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const YearlyFootprintFragment = graphql(/* GraphQL */ `
    fragment YearlyFootprintFragment on YearlyFootprint {
        financialYear {
            startInclusive
            endInclusive
        }
        quarters {
            kg_co2e
            hasOperationalData
        }
    }
`);

const DashboardDocument = graphql(/* GraphQL */ `
    query Dashboard($from: Date!, $to: Date!) {
        dashboard(from: $from, to: $to) {
            industryCarbonIntensity
            carbonFootprintYoY {
                previousYear {
                    ...YearlyFootprintFragment
                }
                currentYear {
                    ...YearlyFootprintFragment
                }
            }
            scopeBreakdown {
                scope_1_kg_co2e
                scope_2_kg_co2e
                scope_3_kg_co2e
            }
            totalEmissionsKgCo2e
            previousPeriodTotalEmissionsKgCo2e
            topEmittingActivities(quantity: 3) {
                kgCo2e
                taxonomyTerm {
                    id
                    name
                    description
                }
            }
            target {
                target_completion_year
                yearly_percent_reduction
            }
            alerts {
                alertType
                periodStart
                periodEnd
            }
        }
    }
`);

const DashboardGetCompanyCarbonIntensityDocument = graphql(/* GraphQL */ `
    query DashboardGetCompanyCarbonIntensity($from: Date!, $to: Date!) {
        getCompanyCarbonIntensity(from: $from, to: $to)
    }
`);

const DATE_FORMAT = 'yyyy-MM-dd';

function getRangeForMissingPeriod(periodStart: string, periodEnd: string) {
    if (isSameDay(new Date(periodStart), new Date(periodEnd))) {
        return `for ${formatDateForDisplay(new Date(periodStart))}`;
    } else {
        return `from ${formatDateForDisplay(new Date(periodStart))} to ${formatDateForDisplay(
            new Date(periodEnd),
        )}`;
    }
}

function DashboardContent() {
    const {selectedPeriod} = React.useContext(FinancialDateFilterContext);
    const user = useUser();
    const navigate = useNavigate();

    const timeRangeParams = React.useMemo(() => {
        if (selectedPeriod) {
            return {
                from: format(new Date(selectedPeriod.periodStart), DATE_FORMAT),
                to: format(new Date(selectedPeriod.periodEnd), DATE_FORMAT),
            };
        }
    }, [selectedPeriod]);

    const [fetchDashboard, {error, data}] = useLazyQuery(DashboardDocument, {
        fetchPolicy: 'cache-and-network',
    });
    const [
        fetchIntensity,
        {loading: intensityLoading, error: intensityError, data: companyIntensity},
    ] = useLazyQuery(DashboardGetCompanyCarbonIntensityDocument, {
        fetchPolicy: 'cache-and-network',
    });

    const chartProps: CarbonFootprintTrendChartProps | null = React.useMemo(() => {
        if (!data) {
            return null;
        }
        return parseCarbonFootprintTrend(data.dashboard.carbonFootprintYoY as YoYCarbonFootprint);
    }, [data]);

    React.useEffect(() => {
        if (timeRangeParams != null) {
            fetchDashboard({variables: timeRangeParams});
            fetchIntensity({variables: timeRangeParams});
        }
    }, [timeRangeParams, fetchDashboard, fetchIntensity]);

    const alert = React.useMemo(() => {
        if (data?.dashboard.alerts && data.dashboard.alerts.length > 0) {
            // we only display the first one
            const firstAlert = data?.dashboard.alerts[0];
            if (
                firstAlert.alertType === 'NoTransactionalData' &&
                user.financialProvider?.sync_method === 'data-upload'
            ) {
                return {
                    onPress: () => navigate('/settings/data-connection'),
                    type: 'error' as const,
                    title: 'Missing spend data.',
                    subtitle: `You are missing spend data ${getRangeForMissingPeriod(
                        firstAlert.periodStart,
                        firstAlert.periodEnd,
                    )}, therefore your footprint is likely to be incomplete.`,
                };
            } else if (firstAlert.alertType === 'NoOperationalData') {
                const temp = {
                    type: 'info' as const,
                    title: 'Missing operational data.',
                    subtitle:
                        'You have operational data missing from this period, therefore your footprint may not be accurate.',
                };
                if (firstAlert.periodEnd && firstAlert.periodStart) {
                    return {
                        onPress: () =>
                            navigate(
                                generatePath('/operations/:periodStart/:periodEnd/:page', {
                                    periodStart: firstAlert.periodStart as string,
                                    periodEnd: firstAlert.periodEnd as string,
                                    page: getStep(),
                                }),
                            ),
                        ...temp,
                    };
                } else {
                    return;
                }
            } else {
                return;
            }
        }
    }, [data?.dashboard.alerts]);

    const scopeTooltip = {
        text: 'Scoping is a concept used to categorise greenhouse gas emissions according to their source. Simply put, each scope reflects the degree of control the organisation has over the different sources of its emissions.',
        link: 'https://help.earth.sage.com/en/articles/5519279-what-are-scopes-1-2-and-3-and-why-is-this-important-to-understanding-my-carbon-impact',
        linkText: 'What you need to know about scope',
    };
    const topEmittingTooltip = {
        text: 'This list shows your total carbon footprint divided between the accounting codes we’ve identified from your data. This is to help you understand your carbon footprint in terms that you already understand.',
        link: 'https://help.earth.sage.com/en/articles/5517263-what-exactly-does-sage-earth-do-with-my-accounting-data-once-integrated',
        linkText: 'How we use your accounting data',
    };

    return (
        <VStack large>
            {error && <ErrorCard />}

            <VStack>
                <HStack>
                    <div className="flex flex-1">
                        <Heading2>Explore your footprint</Heading2>
                    </div>
                    <PickerContextProvider>
                        <DatePicker intercomTarget="Dashboard: Period Select" />
                    </PickerContextProvider>
                </HStack>

                {!data && <Loading />}
                {data && (
                    <>
                        {alert && (
                            <Alert
                                title={alert.title}
                                subtitle={alert.subtitle}
                                key={alert.title}
                                type={alert.type}
                                onPress={alert.onPress}
                                intercomTarget="Dashboard: Alert"
                            />
                        )}
                        <VStack intercomTarget="Dashboard: Content">
                            <HStack align="stretch">
                                <CarbonImpactComparisonPanel
                                    title="Your total carbon footprint"
                                    value={data.dashboard.totalEmissionsKgCo2e}
                                    comparisonValue={
                                        data.dashboard.previousPeriodTotalEmissionsKgCo2e ??
                                        undefined
                                    }
                                    intercomTarget="Dashboard: Carbon Footprint"
                                />
                                <IndustryComparisonPanel
                                    title="Your carbon intensity"
                                    value={companyIntensity?.getCompanyCarbonIntensity ?? undefined}
                                    loading={intensityLoading}
                                    error={intensityError}
                                    comparisonValue={data.dashboard.industryCarbonIntensity}
                                    intercomTarget="Dashboard: Against Your Industry"
                                />
                                <TargetComparisonPanel
                                    title="Your net-zero target"
                                    targetCompletionYear={
                                        data.dashboard.target?.target_completion_year
                                    }
                                    yearlyPercentReduction={
                                        data.dashboard.target?.yearly_percent_reduction
                                    }
                                    intercomTarget="Dashboard: Targets"
                                />
                            </HStack>
                            <HStack align="stretch">
                                <Panel border flex intercomTarget="Dashboard: Scope Breakdown">
                                    <VStack full align="stretch">
                                        <SegmentSubheader>Your scope breakdown</SegmentSubheader>
                                        <DetailText colour="secondary">
                                            Measured in <CO2e />
                                        </DetailText>
                                        <div className="flex flex-1">
                                            <ResponsiveScopeGraph
                                                data={[
                                                    data.dashboard.scopeBreakdown.scope_1_kg_co2e,
                                                    data.dashboard.scopeBreakdown.scope_2_kg_co2e,
                                                    data.dashboard.scopeBreakdown.scope_3_kg_co2e,
                                                ]}
                                            />
                                        </div>
                                    </VStack>
                                    <div className="relative">
                                        <div
                                            className={`absolute top-0 right-0 w-6 h-6 ${textColorClassnames(
                                                'tooltip',
                                            )}`}
                                        >
                                            <Tooltip
                                                tooltipText={scopeTooltip.text}
                                                linkHref={scopeTooltip.link}
                                                linkText={scopeTooltip.linkText}
                                            >
                                                <HelpIcon />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </Panel>
                                <Panel
                                    border
                                    flex
                                    intercomTarget="Dashboard: Top Emitting Activities"
                                >
                                    <VStack full condensed>
                                        <SegmentSubheader>
                                            Your top emitting activities
                                        </SegmentSubheader>
                                        <DetailText colour="secondary">
                                            Measured in <CO2e />
                                        </DetailText>
                                        <div className="h-80 overflow-scroll">
                                            <DashboardTopActivities
                                                childTaxonomyQuantity={6}
                                                data={data.dashboard.topEmittingActivities.map(
                                                    (item) => ({
                                                        kgCo2e: item.kgCo2e,
                                                        taxonomyTermKey: item.taxonomyTerm.id,
                                                        activityName: item.taxonomyTerm.name,
                                                        activityDescription:
                                                            item.taxonomyTerm.description ??
                                                            undefined,
                                                    }),
                                                )}
                                            />
                                        </div>
                                        {timeRangeParams != null && (
                                            <div className="flex items-center justify-center">
                                                <span className="max-w-min whitespace-nowrap">
                                                    <ButtonLink
                                                        textColor="button"
                                                        outlineColor="button"
                                                        outline
                                                        to={`/activities/${timeRangeParams.from}/${timeRangeParams.to}`}
                                                    >
                                                        Show more
                                                    </ButtonLink>
                                                </span>
                                            </div>
                                        )}
                                    </VStack>
                                    <div className="relative">
                                        <div
                                            className={`absolute top-0 right-0 w-6 h-6 ${textColorClassnames(
                                                'tooltip',
                                            )}`}
                                        >
                                            <Tooltip
                                                tooltipText={topEmittingTooltip.text}
                                                linkHref={topEmittingTooltip.link}
                                                linkText={topEmittingTooltip.linkText}
                                                place="top"
                                                align="end"
                                            >
                                                <HelpIcon />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </Panel>
                            </HStack>
                        </VStack>
                    </>
                )}
            </VStack>
            <hr />
            <Panel border intercomTarget="Dashboard: Carbon Footprint Trend">
                {chartProps != null && <CarbonFootprintTrendChart {...chartProps} />}
                {error != null && <ErrorCard />}
            </Panel>
        </VStack>
    );
}

export function Dashboard() {
    return (
        <Layout title="Your Carbon Impact">
            <InitialSyncBlocker>
                <FinancialDateFilterContextProvider>
                    <DashboardContent />
                </FinancialDateFilterContextProvider>
            </InitialSyncBlocker>
        </Layout>
    );
}
