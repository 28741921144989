import type {ResultOf} from '@graphql-typed-document-node/core';

import {graphql} from '../../../graphql/generated';

export const ProfileEnergyFragmentDocument = graphql(/* GraphQL */ `
    fragment ProfileEnergyEntry on ProfileEnergy {
        id
        electricity_kwh
        gas_kwh
        buildingOccupancies {
            percent_occupied
            address {
                id
                address
                postcode
            }
        }
        confirmedSpend {
            id
            merchant_name
            amount
            transactions {
                id
                categorisation_metadata {
                    energyTariff
                }
            }
        }
    }
`);
export type ProfileEnergyFragment = ResultOf<typeof ProfileEnergyFragmentDocument>;
