import {graphql} from '../../../graphql/generated';

export const ProfileVehiclesFragmentDocument = graphql(/* GraphQL */ `
    fragment ProfileVehiclesEntry on ProfileVehicles {
        id
        fuel_quantity
        confirmedSpend {
            id
            merchant_name
            amount
            transactions {
                id
            }
        }
    }
`);
