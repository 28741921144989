import React from 'react';
import {useQuery} from '@apollo/client';
import {useStripe} from '@stripe/react-stripe-js';
import {useNavigate} from 'react-router';

import {Layout} from '../../components/layout';
import {graphql} from '../../graphql/generated';
import {Loading} from '../../components/loading';
import {ErrorCard} from '../../components/error-card';

const GetSubscriptionAuthenticationParamsDocument = graphql(/* GraphQL */ `
    query GetSubscriptionAuthenticationParams {
        getSubscriptionAuthenticationParams {
            client_secret
            failure_reason
        }
    }
`);

function useSubscriptionAuthenticationParams() {
    const {loading, error, data} = useQuery(GetSubscriptionAuthenticationParamsDocument);
    const [clientSecret, setClientSecret] = React.useState<string>();
    const navigate = useNavigate();
    React.useEffect(() => {
        if (data == null) {
            return;
        }

        const params = data.getSubscriptionAuthenticationParams;
        if (params == null) {
            return navigate('..');
        }

        if (params.failure_reason !== 'requires_action') {
            return navigate('/settings/subscription');
        }

        if (params.client_secret == null) {
            return navigate('/settings/subscription');
        }

        setClientSecret(params.client_secret);
    });

    return {loading, error, clientSecret};
}

export function SubscriptionAuthenticate() {
    const {loading: queryLoading, error, clientSecret} = useSubscriptionAuthenticationParams();
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string>();
    const [submitted, setSubmitted] = React.useState(false);
    const navigate = useNavigate();
    const stripe = useStripe();
    React.useEffect(() => {
        if (stripe != null && clientSecret != null && !submitted) {
            setLoading(true);
            setSubmitted(true);
            stripe.confirmCardPayment(clientSecret).then((result) => {
                if (result.error) {
                    setLoading(false);
                    setErrorMessage(result.error.message);
                } else {
                    navigate('/settings/subscription');
                }
            });
        }
    }, [stripe, clientSecret, loading, setLoading, setSubmitted, setErrorMessage, navigate]);
    return (
        <Layout
            title="Your Subscription"
            subtitle="We need to authenticate your card. You should be shown a prompt from your bank."
        >
            {(loading || queryLoading) && <Loading />}
            {(error || errorMessage) && <ErrorCard message={errorMessage} />}
        </Layout>
    );
}
