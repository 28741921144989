import React from 'react';

import {CurrentUserFragmentDocument} from '../graphql/fragments';
import {useFragment} from '../graphql/generated';

export function buildValueContext<T>() {
    const context = React.createContext<T | undefined>(undefined);
    function ValueContextProvider({children, value}: React.PropsWithChildren<{value: T}>) {
        return <context.Provider value={value}>{children}</context.Provider>;
    }
    function useValueContext() {
        const value = React.useContext(context);
        if (value == null) {
            throw new Error('useValueContext must be used within a Provider');
        }
        return useFragment(CurrentUserFragmentDocument, value);
    }

    return [useValueContext, ValueContextProvider] as const;
}
