import {ApolloLink} from '@apollo/client';

import {Achievement} from './generated/graphql';

type AchievementCommand = {
    action: 'unlock' | 'progress';
    amount?: number;
    criteriaValue?: number;
    unlock: boolean;
};

type AchievementUpdate = Achievement & AchievementCommand;

type GQLResponse = {
    data?: any;
    extensions?: {
        achievements?: AchievementUpdate[];
    };
};

type AchievementSubscriber = (achievements: AchievementUpdate[]) => void;

const subscribers: AchievementSubscriber[] = [];

export const addSubscriber = (subscriber: AchievementSubscriber) => {
    subscribers.push(subscriber);
    return () => {
        subscribers.splice(subscribers.indexOf(subscriber), 1);
    };
};

export const achievementsLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((value: GQLResponse) => {
        if (value.extensions == null) {
            return value;
        }

        const {achievements} = value.extensions;
        if (achievements == null) {
            return value;
        }

        subscribers.forEach((subscriber) => subscriber(achievements));

        return value;
    });
});
