import React from 'react';
import classnames from 'classnames';

import {HStack, Button} from '@sphericsio/design-system';

type PickerButtonsProps = {
    onClearSelection?: () => void;
    onChange: () => void;
    isDisabled?: boolean;
};

export function PickerButtons({onClearSelection, onChange, isDisabled}: PickerButtonsProps) {
    return (
        <div className="pb-5 pt-2 pr-2.5">
            <HStack align="end" justify="end">
                {onClearSelection && (
                    <div className="-mr-10">
                        <Button
                            bg="transparent"
                            isDisabled={isDisabled}
                            textColor="default"
                            small
                            onPress={onClearSelection}
                        >
                            Clear selection
                        </Button>
                    </div>
                )}
                <div className={classnames(!onClearSelection && 'ml-auto')}>
                    <Button small isDisabled={isDisabled} onPress={onChange}>
                        Apply
                    </Button>
                </div>
            </HStack>
        </div>
    );
}
