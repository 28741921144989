import React from 'react';
import {format} from 'date-fns';

import {
    DataTableRef,
    relayConnectionQuery,
    RelayConnectionVariables,
} from '@sphericsio/mvp-ui-common';
import {DetailText, textColorClassnames} from '@sphericsio/design-system';

import {graphql} from '../../graphql/generated';
import {
    GetMerchantTransactionsWithEmissionsQuery,
    MerchantCategorisationMetadata,
} from '../../graphql/generated/graphql';
import {TransactionDateAndAmonut} from './transaction-details';
import {TransactionCategorisation} from '../categorisation/transaction/transaction-categorisation';
import {CategorisationContextProvider} from '../categorisation/context';
import {DataTable} from '../table';
import {FormattedTransactionEmissions} from './transaction-emissions';
import {ExcludeTransactionContextProvider} from './exclude-transaction-context';

const GetMerchantTransactionsWithEmissionsDocument = graphql(/* GraphQL */ `
    query GetMerchantTransactionsWithEmissions(
        $first: Int
        $after: String
        $last: Int
        $before: String
        $orderDirection: SortDirection!
        $orderBy: TransactionsOrder!
        $to: Date!
        $from: Date!
        $merchantId: String!
        $showExcluded: Boolean
    ) {
        getTransactionsByMerchant(
            first: $first
            after: $after
            last: $last
            before: $before
            orderDirection: $orderDirection
            orderBy: $orderBy
            from: $from
            to: $to
            merchantId: $merchantId
            showExcluded: $showExcluded
        ) {
            pageInfo {
                hasNextPage
                endCursor
                hasPreviousPage
                startCursor
            }
            edges {
                node {
                    id
                    description
                    transaction_date
                    amount
                    merchant {
                        name
                    }
                    categorisation_metadata {
                        excludeReason
                        isIdentifiedSpend
                        kg_co2e
                        taxonomy
                        isIgnored
                    }
                }
            }
        }
    }
`);

type MerchantTransactionsProps = {
    merchantId: string;
    merchantCategorisation: MerchantCategorisationMetadata;
    to: Date;
    from: Date;
    onRefetch: () => void;
};

export function MerchantTransactionsList({
    merchantId,
    merchantCategorisation,
    to,
    from,
    onRefetch,
}: MerchantTransactionsProps) {
    const txnTableRef = React.useRef<DataTableRef>(null);

    React.useEffect(() => {
        txnTableRef.current?.refreshData();
    }, [merchantCategorisation]);

    const queryConfig = relayConnectionQuery(
        GetMerchantTransactionsWithEmissionsDocument,
        (data: GetMerchantTransactionsWithEmissionsQuery) => data.getTransactionsByMerchant,
        {
            merchantId,
            from: format(from, 'yyyy-MM-dd'),
            to: format(to, 'yyyy-MM-dd'),
            orderDirection: 'desc',
            orderBy: 'transaction_date',
            showExcluded: true,
        } as Partial<RelayConnectionVariables>,
        'network-only',
    );

    const onSaveSuccess = () => {
        txnTableRef.current?.refreshData();
        onRefetch();
    };

    return (
        <ExcludeTransactionContextProvider onSaveSuccess={onSaveSuccess}>
            <CategorisationContextProvider>
                <DataTable
                    ref={txnTableRef}
                    queryConfig={queryConfig}
                    sortableFields={['transaction_date']}
                    initialSortDirection="desc"
                    condensed
                    header={false}
                    columns={[
                        {
                            label: 'Transaction Details',
                            // eslint-disable-next-line react/display-name
                            render: (txn) => (
                                <div className="w-32 text-center">
                                    <TransactionDateAndAmonut
                                        date={txn.transaction_date}
                                        amount={txn.amount}
                                        dateEmphasis
                                        gray={txn.categorisation_metadata.isIgnored}
                                    />
                                </div>
                            ),
                        },
                        {
                            label: 'Description',
                            key: 'description',
                            // eslint-disable-next-line react/display-name
                            renderValue: (description) => (
                                <div className="min-w-60 flex flex-1">
                                    <DetailText colour="secondary">{description}</DetailText>
                                </div>
                            ),
                        },
                        {
                            label: 'Categorisation',
                            // eslint-disable-next-line react/display-name
                            render: (txn) => (
                                <TransactionCategorisation
                                    id={txn.id}
                                    editable={!txn.categorisation_metadata.isIdentifiedSpend}
                                    taxonomy={txn.categorisation_metadata.taxonomy ?? undefined}
                                    excludeReason={txn.categorisation_metadata.excludeReason}
                                    isIgnored={txn.categorisation_metadata.isIgnored}
                                    onSaveSuccess={onSaveSuccess}
                                />
                            ),
                        },
                        {
                            label: 'Emissions',
                            // eslint-disable-next-line react/display-name
                            render: (txn) => (
                                <span className={textColorClassnames('secondary')}>
                                    {txn.categorisation_metadata.kg_co2e != null ? (
                                        <FormattedTransactionEmissions
                                            amount={txn.categorisation_metadata.kg_co2e ?? 0}
                                        />
                                    ) : (
                                        '-'
                                    )}
                                </span>
                            ),
                        },
                    ]}
                />
            </CategorisationContextProvider>
        </ExcludeTransactionContextProvider>
    );
}
