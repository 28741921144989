import {AnyScaleBand} from '@visx/shape/lib/types';
import {DataContext, DataContextType} from '@visx/xychart';
import React, {useContext, useMemo} from 'react';
import {scaleBand} from '@visx/scale';

import {barMargins} from './style';
import {Series} from './types';

type BarCenterOffset = Record<Series, number>;
export const BarCenterOffsetContext = React.createContext<BarCenterOffset>({
    current: 0,
    previous: 0,
});

/**
 * The Bar Center Offset Provider provides the horizontal distance in px between
 * the center of the Bar Group and the center of the Bars.
 * If there's only 1 bar, then this value will be 0.
 */
export function BarCenterOffsetProvider({
    seriesCount,
    children,
}: {
    seriesCount: 1 | 2;
    children: React.ReactNode;
}) {
    const offset = useBarCenterOffset(seriesCount);
    const offsetRecord = useMemo(() => ({current: offset, previous: -offset}), [offset]);
    return (
        <BarCenterOffsetContext.Provider value={offsetRecord}>
            {children}
        </BarCenterOffsetContext.Provider>
    );
}

function useBarCenterOffset(seriesCount: 1 | 2): number {
    const {xScale} = useContext(DataContext) as DataContextType<AnyScaleBand, any, any>;

    return useMemo(() => {
        if (seriesCount === 1 || !xScale) {
            return 0;
        }

        const barGroupWidth = xScale.bandwidth();

        /**
         * We unfortunately need to re-implement some of the calculations that visx does,
         * since it doesn't expose the positioning of the elements over an api.
         *
         * Fortunately, most of the grunt work is done through d3 utilities, which we can reuse.
         *
         * "Visually", it looks like this:
         *
         *      bar center offset
         *          v------v
         *
         * |   |----x----| x |---------|   |
         * |   |         |   |         |   |
         * |pad|   bar   |pad|   bar   |pad|
         * |   |         |   |         |   |
         * ---------------------------------
         *
         * ^-------------------------------^
         *           barGroupWidth
         *
         *     ^-------------^
         *           step
         *
         *
         * The offset that we're looking for is half a padding and half a bar.
         * Since step is a full padding + bar, the offset is simply the step / 2
         */
        const barGroupScale = scaleBand<string>({
            domain: ['previous', 'current'],
            range: [0, barGroupWidth],
            padding: barMargins.relativeBetween,
        });

        return barGroupScale.step() / 2;
    }, [xScale, seriesCount]);
}
