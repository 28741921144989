import React from 'react';
import {ApolloError, useQuery} from '@apollo/client';

import {Button, DetailText, HStack, Slider, VStack} from '@sphericsio/design-system';

import {Loading} from '../../loading';
import {ErrorCard} from '../../error-card';
import {TabPageHeader} from '../../page-header';
import {ReportingPeriodDates} from '../reporting-period-dates';
import {EditProfileEnergyOccupancy} from './types';
import {CompanyReportingPeriod} from '../../../graphql/generated/graphql';
import {GetCompanyBuildingsDocument} from './graphql';

type ProfileEnergyBuildingOccupancyProps = {
    reportingPeriod: CompanyReportingPeriod;
    submitError?: ApolloError;
    submitting?: boolean;
    onNext: (value: Record<string, number>) => void;
    buildingOccupancyAnswers?: EditProfileEnergyOccupancy[];
};
const DEFAULT_BUILDING_OCCUPANCY = 100;
export function ProfileEnergyBuildingOccupancy({
    submitError,
    submitting,
    onNext,
    reportingPeriod,
    buildingOccupancyAnswers = [],
}: ProfileEnergyBuildingOccupancyProps) {
    const [buildingOccupancies, setBuildingOccupancies] = React.useState<Record<string, number>>();
    const {loading, error, data} = useQuery(GetCompanyBuildingsDocument);
    React.useEffect(() => {
        if (data != null) {
            const states = data.currentCompany.addresses.reduce(
                (memo, address) => {
                    const originalOccupancy = buildingOccupancyAnswers.find(
                        (bo) => bo.address.id === address.id,
                    );
                    return {
                        ...memo,
                        [address.id]:
                            originalOccupancy?.percent_occupied ?? DEFAULT_BUILDING_OCCUPANCY,
                    };
                },
                {} as Record<string, number>,
            );
            setBuildingOccupancies(states);
        }
    }, [data, setBuildingOccupancies]);

    return (
        <div>
            {loading && <Loading />}
            {error && <ErrorCard />}
            {data && buildingOccupancies && (
                <VStack>
                    <TabPageHeader
                        title="What percentage of your buildings do you occupy?"
                        subtitle="If you use a shared office or warehouse, tell us roughly how much you use"
                    />
                    <DetailText inline={false}>
                        <ReportingPeriodDates reportingPeriod={reportingPeriod} />
                    </DetailText>
                    <div className="flex-1">
                        {data.currentCompany.addresses.map((address) => (
                            <HStack key={address.id}>
                                <div className="flex-1">
                                    <DetailText inline={false}>
                                        <div>{address.address}</div>
                                        <div>{address.postcode}</div>
                                    </DetailText>
                                </div>
                                <div className="w-64">
                                    <Slider
                                        value={buildingOccupancies[address.id]}
                                        onChange={(value) =>
                                            setBuildingOccupancies((state) => ({
                                                ...state,
                                                [address.id]: value,
                                            }))
                                        }
                                        renderValue={(value) => <DetailText>{value}%</DetailText>}
                                    />
                                </div>
                            </HStack>
                        ))}
                    </div>
                    {submitError && <ErrorCard />}
                    <div>
                        <Button
                            onPress={() => onNext(buildingOccupancies)}
                            type="submit"
                            isLoading={submitting}
                        >
                            Save and continue
                        </Button>
                    </div>
                </VStack>
            )}
        </div>
    );
}
