import React from 'react';
import {useQuery} from '@apollo/client';

import {
    DetailText,
    divideColorClassnames,
    HStack,
    LoadingSpinner,
    VStack,
} from '@sphericsio/design-system';

import {useFragment} from '../../../graphql/generated';
import {ReportingPeriodContext} from '../reporting-period';
import {ErrorCard} from '../../error-card';
import {MoneyDisplay} from '../../money';
import {GetProfileGHGDocument} from './graphql';
import {ProfileGHGFragmentDocument} from './fragments';

export function GHGProfileAnswers() {
    const reportingPeriod = React.useContext(ReportingPeriodContext);
    const {
        loading,
        error,
        data: res,
    } = useQuery(GetProfileGHGDocument, {
        variables: {companyReportingPeriodId: reportingPeriod.id},
    });

    const data = useFragment(ProfileGHGFragmentDocument, res?.getProfileGHG);

    if (error) {
        return <ErrorCard />;
    }

    if (loading) {
        return <LoadingSpinner />;
    }

    const showToppedUpSection = () => {
        return (
            data?.topped_up !== null &&
            (data?.owned_appliences !== null ||
                (data?.confirmedSpend && data?.confirmedSpend.length > 0))
        );
    };

    return (
        <VStack>
            <div className={`divide-y ${divideColorClassnames()}`}>
                {data?.owned_appliences !== null && (
                    <div className="py-5">
                        <VStack>
                            <DetailText bold>
                                Do you own or operate any fridges, air conditioning and/or fire
                                suppressant systems?
                            </DetailText>
                            <DetailText>{data?.owned_appliences ? 'Yes' : `No`}</DetailText>
                        </VStack>
                    </div>
                )}
                {showToppedUpSection() && (
                    <div className="py-5">
                        <VStack>
                            <DetailText bold>
                                Have you had any fridges, air conditioning and/or fire supressant
                                systems topped up in this period?
                            </DetailText>
                            <DetailText>{data?.topped_up ? 'Yes' : `No`}</DetailText>
                        </VStack>
                    </div>
                )}
                {data?.confirmedSpend && data?.confirmedSpend.length > 0 && (
                    <div className="py-5">
                        <DetailText bold>Confirmed energy transactions</DetailText>
                        {data.confirmedSpend.map((s) => {
                            return (
                                <div key={s.id} className="py-5">
                                    <HStack>
                                        <div className="w-40">
                                            <DetailText bold>{s.merchant_name}</DetailText>
                                        </div>
                                        <DetailText>
                                            {s.transactions.length} transactions
                                        </DetailText>
                                        <div className="px-3">
                                            {s.amount && (
                                                <DetailText>
                                                    <MoneyDisplay money={s.amount} />
                                                </DetailText>
                                            )}
                                        </div>
                                    </HStack>
                                </div>
                            );
                        })}
                    </div>
                )}
                {data?.gasEntries && data.gasEntries.length > 0 && (
                    <div className="py-5">
                        <DetailText bold>F-Gases consumed</DetailText>
                        {data.gasEntries.map((g) => {
                            return (
                                <div key={g.gas_type} className="py-5">
                                    <HStack>
                                        <div className="w-60">
                                            <DetailText>{g.gas_type}</DetailText>
                                        </div>
                                        <DetailText>{g.kg} kg</DetailText>
                                    </HStack>
                                </div>
                            );
                        })}
                    </div>
                )}
                {data?.confirmedSpend &&
                    data.confirmedSpend.length > 0 &&
                    data.spend_split_percent && (
                        <div className="py-5">
                            <VStack>
                                <DetailText bold>
                                    Percentage of your spend spent on gases.
                                </DetailText>
                                <DetailText>
                                    {data.spend_split_percent ? (
                                        <>{data.spend_split_percent} %</>
                                    ) : (
                                        <> I don&apos;t know</>
                                    )}
                                </DetailText>
                            </VStack>
                        </div>
                    )}
            </div>
        </VStack>
    );
}
