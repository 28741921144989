import React from 'react';

import {
    CategorisationThemeIcon,
    CategoryTheme,
    DetailText,
    getCategorisationTheme,
    getDefaultCategorisationTheme,
    HStack,
    LinkText,
    VStack,
} from '@sphericsio/design-system';

import {carbonAmountFormatter} from '../carbon-amount';
import {CategorisationContext, CategorisationContextProvider} from '../categorisation/context';
import {
    getChildrenForTaxonomyKey,
    getRootForAccountingTaxonomyTermKey,
    UserCategorisationHierarchyItem,
} from '../categorisation/hierarchy';
import {CategoryPill} from '../categorisation/categorisation';
import {ExternalLink} from '../link';

const RESOURCE_LINKS: Record<string, string> = {
    'staff-travel-national':
        'https://help.earth.sage.com/en/articles/6337126-business-travel-mitigation-solutions',
    'staff-travel-international':
        ' https://help.earth.sage.com/en/articles/6337126-staff-travel-international-mitigation-solutions',
    'professional-services':
        'https://help.earth.sage.com/en/articles/6325642-professional-services-mitigation-solutions',
    'construction-materials':
        'https://help.earth.sage.com/en/articles/6337180-construction-materials-mitigation-solutions',
    energy: 'https://help.earth.sage.com/en/articles/6337207-energy-mitigation-solutions',
    'motor-vehicles':
        'https://help.earth.sage.com/en/articles/6337263-motor-vehicles-mitigation-solutions',
    'employee-commuting-homeworking':
        'https://help.earth.sage.com/en/articles/6325770-employee-commuting-homeworking-mitigation-solutions',
};

type TaxonomyListProps = {
    childTaxonomyQuantity: number;
    taxonomyItems: UserCategorisationHierarchyItem[];
    theme: CategoryTheme;
};
function TaxonomyList({taxonomyItems, childTaxonomyQuantity, theme}: TaxonomyListProps) {
    return (
        <div className="flex flex-row flex-wrap items-center">
            {taxonomyItems.map((child, index) => {
                return (
                    <div key={child.key} className="mt-2 mr-3">
                        {index == childTaxonomyQuantity ? (
                            <DetailText>+{taxonomyItems.length - index} more</DetailText>
                        ) : index > childTaxonomyQuantity ? null : (
                            <CategoryPill theme={theme} text={child.title} />
                        )}
                    </div>
                );
            })}
        </div>
    );
}
type TopActivitiesProps = {
    childTaxonomyQuantity?: number;
    data: {
        taxonomyTermKey: string;
        activityName: string;
        activityDescription?: string;
        kgCo2e: number;
    }[];
};
export function TopActivities({data, childTaxonomyQuantity = 50}: TopActivitiesProps) {
    const {hierarchy} = React.useContext(CategorisationContext);

    const getTaxonomyList = (taxonomyTermKey: string) => {
        return hierarchy
            ? getChildrenForTaxonomyKey(hierarchy, `accounting/${taxonomyTermKey}`)
            : [];
    };

    const getThemeForActivity = (taxonomyTermKey: string) => {
        const defaultTheme = getDefaultCategorisationTheme();
        if (hierarchy) {
            const root = getRootForAccountingTaxonomyTermKey(hierarchy, taxonomyTermKey);
            return root ? getCategorisationTheme(root.taxonomyTermKey) : defaultTheme;
        }
        return defaultTheme;
    };

    return (
        <VStack seperator large>
            {data.map((item, index) => {
                const theme = getThemeForActivity(item.taxonomyTermKey);
                const resourceLink = RESOURCE_LINKS[item.taxonomyTermKey];
                const {formattedAmount, unit} = carbonAmountFormatter({
                    amount: item.kgCo2e,
                });

                return (
                    <HStack key={index} align="start">
                        <div className="h-6 w-6">
                            <CategorisationThemeIcon theme={theme} />
                        </div>
                        <VStack full condensed>
                            <DetailText bold>{item.activityName}</DetailText>
                            <TaxonomyList
                                theme={theme}
                                taxonomyItems={getTaxonomyList(item.taxonomyTermKey)}
                                childTaxonomyQuantity={childTaxonomyQuantity}
                            />
                            {resourceLink && (
                                <DetailText>
                                    <span className="pt-2 text-action-major-500">
                                        <ExternalLink to={resourceLink}>
                                            <LinkText>Reduce your impact</LinkText>
                                        </ExternalLink>
                                    </span>{' '}
                                </DetailText>
                            )}
                        </VStack>
                        <DetailText bold>
                            {formattedAmount} {unit}
                        </DetailText>
                    </HStack>
                );
            })}
        </VStack>
    );
}

type DashboardTopActivitiesProps = {
    childTaxonomyQuantity?: number;
    data: {
        taxonomyTermKey: string;
        activityName: string;
        activityDescription?: string;
        kgCo2e: number;
    }[];
};
export function DashboardTopActivities(props: DashboardTopActivitiesProps) {
    return (
        <CategorisationContextProvider>
            <TopActivities {...props} />
        </CategorisationContextProvider>
    );
}
