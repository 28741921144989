/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {BarGroup, BarSeries, GlyphSeries, XYChart} from '@visx/xychart';
import {Text} from '@visx/text';
import React, {useContext, useMemo, useState} from 'react';

import {SvgIcon} from '@sphericsio/design-system';

import {displayInTonnes} from '../../carbon-amount';
import {XAxisQuarter, YAxisEmissions} from '../../graphs/axis';
import {BarCenterOffsetContext, BarCenterOffsetProvider} from './bar-center-offset';
import {
    axisLabelMargins,
    barMargins,
    barRadius,
    chartMargins,
    chartTheme,
    colors,
    pendingLabelFont,
    warningIcon,
} from './style';
import {Tooltip} from './tooltip';
import {Series, SeriesData, SeriesDataItem} from './types';
import {getDimensions, getYScale, xScale} from './scales';

type ChartProps = {
    width: number;
    currentYearData: SeriesData;
    previousYearData: SeriesData | null;
    maxValue: number;
};

export function CarbonFootprintBarChart({
    width,
    currentYearData,
    previousYearData,
    maxValue,
}: ChartProps) {
    const [selectedQuarter, setSelectedQuarter] = useState<null | number>(null);
    const {height, innerHeight} = useMemo(() => getDimensions(width), [width]);
    const yScale = useMemo(() => getYScale(innerHeight, maxValue), [innerHeight, maxValue]);
    const useTonnes = displayInTonnes(maxValue);

    return (
        <XYChart
            width={width}
            height={height}
            xScale={xScale}
            yScale={yScale}
            theme={chartTheme}
            margin={chartMargins}
        >
            <XAxisQuarter tickLabelProps={xAxisTickLabelProps} />
            <YAxisEmissions useTonnes={useTonnes} tickLabelProps={yAxisTickLabelProps} />

            <BarGroup
                padding={barMargins.relativeBetween}
                enableEvents={true}
                onPointerMove={({datum}: {datum: SeriesDataItem}) => {
                    setSelectedQuarter(datum.quarter);
                }}
                onPointerOut={() => setSelectedQuarter(null)}
            >
                {
                    // There's some shenanigans going on here with Visx, which requires an unconventional code style:
                    // - BarGroup needs to have it's BarSeries as direct children, and breaks with wrapper components.
                    //   Therefore, we must use an ordinary function as wrapper instead, which directly returns BarSeries components
                    // - BarGroup is typed to require `JSX.Element` as children, which doesn't allow for nulls, but we need nulls for conditional rendering of "previousYear".
                    //   In practice however, nulls are actually supported just fine, so we cheat by using `!`
                }
                {
                    carbonFootprintSeries({
                        series: 'previous',
                        data: previousYearData,
                        colour: colors.previousYear,
                        selectedQuarter,
                    })!
                }
                {
                    carbonFootprintSeries({
                        series: 'current',
                        data: currentYearData,
                        colour: colors.currentYear,
                        selectedQuarter,
                    })!
                }
            </BarGroup>

            <BarCenterOffsetProvider seriesCount={previousYearData ? 2 : 1}>
                <NoOperationalDataIcons
                    data={previousYearData}
                    series="previous"
                    selectedQuarter={selectedQuarter}
                />
                <NoOperationalDataIcons
                    data={currentYearData}
                    series="current"
                    selectedQuarter={selectedQuarter}
                />

                <PendingLabels data={currentYearData} />
            </BarCenterOffsetProvider>

            <Tooltip currentYear={currentYearData} previousYear={previousYearData} />
        </XYChart>
    );
}

type CarbonFootprintSeriesProps = {
    series: Series;
    data: SeriesData | null;
    colour: string;
    selectedQuarter: null | number;
};
function carbonFootprintSeries({
    data,
    series,
    colour,
    selectedQuarter,
}: CarbonFootprintSeriesProps) {
    if (data === null) {
        return null;
    }
    return (
        <BarSeries
            data={data}
            dataKey={`${series}-bars`}
            xAccessor={xAccessor}
            yAccessor={yAccessor}
            colorAccessor={useMemo(
                () =>
                    ({quarter}: SeriesDataItem) =>
                        adjustColourForHover(colour, quarter, selectedQuarter),
                [colour, selectedQuarter],
            )}
            enableEvents={true}
            radius={barRadius}
            radiusTop
        />
    );
}

function NoOperationalDataIcons({
    data,
    series,
    selectedQuarter,
}: {
    series: Series;
    data: SeriesData | null;
    selectedQuarter: null | number;
}) {
    const offset = useContext(BarCenterOffsetContext)[series];
    if (data === null) {
        return null;
    }

    return (
        <GlyphSeries
            data={data}
            dataKey={`${series}-glyphs`}
            xAccessor={xAccessor}
            yAccessor={yAccessor}
            renderGlyph={({x, y, datum}) => {
                const {dataPoint, quarter} = datum;
                if (!dataPoint || dataPoint.hasOperationalData) {
                    return null;
                }
                const fill = adjustColourForHover(colors.warning, quarter, selectedQuarter);
                // Note that in SVG, the y value of a text is the y-coordinate of the baseline, i.e. the bottom
                return (
                    <SvgIcon
                        type="warning"
                        x={x + offset}
                        y={y - barMargins.top}
                        fontSize={warningIcon.size}
                        fill={fill}
                        textAnchor="middle"
                    />
                );
            }}
        />
    );
}

function PendingLabels({data}: {data: SeriesData}) {
    const offset = React.useContext(BarCenterOffsetContext).current;
    return (
        <GlyphSeries
            data={data}
            dataKey={`pending-glyphs`}
            xAccessor={xAccessor}
            yAccessor={yAccessor}
            renderGlyph={({x, y, datum}) => {
                const {dataPoint} = datum;
                if (!dataPoint) {
                    return (
                        <Text
                            x={x + offset}
                            y={y - barMargins.top}
                            textAnchor="end"
                            verticalAnchor="middle"
                            angle={90}
                            fill={colors.pending}
                            {...pendingLabelFont}
                        >
                            Pending...
                        </Text>
                    );
                }
                return null;
            }}
        />
    );
}

const xAxisTickLabelProps = () => ({dy: axisLabelMargins.xAxis});
const yAxisTickLabelProps = () => ({dx: -axisLabelMargins.yAxis});
function xAccessor({quarter}: SeriesDataItem) {
    return quarter;
}
function yAccessor({dataPoint}: SeriesDataItem) {
    return dataPoint?.kg_co2e || 0;
}

function adjustColourForHover(
    desiredColour: string,
    quarter: number,
    selectedQuarter: number | null,
): string {
    if (!selectedQuarter || selectedQuarter === quarter) {
        return desiredColour;
    }
    return colors.blur;
}
