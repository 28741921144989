import React from 'react';
import {useNavigate} from 'react-router-dom';

import {
    HStack,
    VStack,
    Strong,
    Pill,
    DetailText,
    SegmentTitle,
    ButtonLabel,
} from '@sphericsio/design-system';

import {ReportingPeriodContext} from './reporting-period';
import {CompanyReportingPeriod} from '../../graphql/generated/graphql';

type ProfileHeaderPillProps = {
    id: string;
    canBeCompleted?: boolean;
    isCompleted: boolean;
    isSelected: boolean;
    children: string;
};
function ProfileHeaderPill({
    id,
    isCompleted,
    isSelected,
    canBeCompleted = true,
    children,
}: ProfileHeaderPillProps) {
    const navigate = useNavigate();
    return (
        <Pill
            small
            fillWhenSelected
            isSelected={isSelected}
            fullRounded={false}
            onChange={() => navigate(`../${id}`)}
            intercomTarget={`Company Profile: ${children} Section Button`}
        >
            <>
                <ButtonLabel colour={isSelected ? 'text-selected' : 'default'}>
                    {children}
                </ButtonLabel>
                <DetailText colour="secondary">
                    {isCompleted ? 'Complete' : canBeCompleted ? 'Pending' : <span>&nbsp;</span>}
                </DetailText>
            </>
        </Pill>
    );
}

const ORGANISATION_SECTIONS = [
    {
        id: 'places',
        label: 'Places',
    },
    {
        id: 'people',
        label: 'People',
        isCompleted: (reportingPeriod: CompanyReportingPeriod) => reportingPeriod.people != null,
    },
];

const ACTIVITY_SECTIONS = [
    {
        id: 'vehicles',
        label: 'Vehicles',
        isCompleted: (reportingPeriod: CompanyReportingPeriod) => reportingPeriod.vehicles != null,
    },
    {
        id: 'energy',
        label: 'Energy',
        isCompleted: (reportingPeriod: CompanyReportingPeriod) => reportingPeriod.energy != null,
    },
    {
        id: 'ghg',
        label: 'F-Gases',
        isCompleted: (reportingPeriod: CompanyReportingPeriod) => reportingPeriod.ghg != null,
    },
];

const SUMMARY_SECTION = {
    id: 'summary',
    label: 'Summary',
};

const ALL_SECTIONS = [SUMMARY_SECTION, ...ORGANISATION_SECTIONS, ...ACTIVITY_SECTIONS];

type OperationsHeaderProps = {
    page: string;
};
export function OperationsHeader({page}: OperationsHeaderProps) {
    const reportingPeriod = React.useContext(ReportingPeriodContext);
    const selectedSection = ALL_SECTIONS.find((section) => section.id === page);
    if (selectedSection == null) {
        return null;
    }

    return (
        <div className="overflow-x-auto">
            <HStack>
                {reportingPeriod.isCompleted && (
                    <VStack>
                        <Strong>Summary</Strong>
                        <HStack>
                            <ProfileHeaderPill
                                id={SUMMARY_SECTION.id}
                                isSelected={selectedSection === SUMMARY_SECTION}
                                canBeCompleted={false}
                                isCompleted={false}
                            >
                                {SUMMARY_SECTION.label}
                            </ProfileHeaderPill>
                        </HStack>
                    </VStack>
                )}
                <VStack>
                    <div className="uppercase">
                        <SegmentTitle colour="secondary">Your organisation</SegmentTitle>
                    </div>
                    <HStack>
                        {ORGANISATION_SECTIONS.map((section) => (
                            <ProfileHeaderPill
                                key={section.id}
                                id={section.id}
                                isSelected={selectedSection === section}
                                canBeCompleted={section.isCompleted != null}
                                isCompleted={
                                    section.isCompleted == null
                                        ? false
                                        : section.isCompleted(reportingPeriod)
                                }
                            >
                                {section.label}
                            </ProfileHeaderPill>
                        ))}
                    </HStack>
                </VStack>
                <VStack>
                    <div className="uppercase">
                        <SegmentTitle colour="secondary">Your activities</SegmentTitle>
                    </div>
                    <HStack>
                        {ACTIVITY_SECTIONS.map((section) => (
                            <ProfileHeaderPill
                                key={section.id}
                                id={section.id}
                                isSelected={selectedSection === section}
                                isCompleted={section.isCompleted(reportingPeriod)}
                            >
                                {section.label}
                            </ProfileHeaderPill>
                        ))}
                    </HStack>
                </VStack>
            </HStack>
        </div>
    );
}
