import React from 'react';
import {range} from 'lodash';
import {useMutation} from '@apollo/client';

import {
    ButtonLabel,
    CircleTick,
    DetailText,
    HStack,
    LoadingSpinner,
    SelectInput,
    SelectInputReadonly,
    SelectListItem,
    textColorClassnames,
    VStack,
} from '@sphericsio/design-system';

import {ErrorCard} from '../../error-card';
import {CompanyFinancialYearEnd} from '../../../graphql/generated/graphql';
import {GetCompanyDefaultsDocument} from '../graphql';
import {graphql} from '../../../graphql/generated';

const SetCompanyFinancialYearEndDocument = graphql(/* GraphQL */ `
    mutation setCompanyFinancialYearEnd($day: Int!, $month: Int!) {
        setCompanyFinancialYearEnd(day: $day, month: $month) {
            day
            month
        }
    }
`);

const MONTH_NAMES = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

type FinancialYearEndStatus = 'pending' | 'saving' | 'success' | 'failure';
type FinancialYearEndProps = {
    financialYearEnd?: CompanyFinancialYearEnd | null;
    canEdit: boolean;
};
export function FinancialYearEnd({financialYearEnd, canEdit}: FinancialYearEndProps) {
    const [status, setStatus] = React.useState<FinancialYearEndStatus>();
    const [submit, {error, data}] = useMutation(SetCompanyFinancialYearEndDocument, {
        refetchQueries: [{query: GetCompanyDefaultsDocument}],
    });
    const [day, setDay] = React.useState<number | undefined>(financialYearEnd?.day);
    const [month, setMonth] = React.useState<number | undefined>(financialYearEnd?.month);

    const dayItems = range(1, 32).reduce((acc, value) => {
        return [...acc, {id: value.toString(), label: value.toString()}];
    }, [] as SelectListItem[]);
    const monthItems = MONTH_NAMES.reduce((acc, value, index) => {
        return [...acc, {id: (index + 1).toString(), label: value.toString()}];
    }, [] as SelectListItem[]);

    React.useEffect(() => {
        if (financialYearEnd && financialYearEnd.day && financialYearEnd.month) {
            setStatus('success');
        } else {
            setStatus('pending');
        }
    }, [financialYearEnd]);

    React.useEffect(() => {
        if (!financialYearEnd && day && month) {
            save();
        }
    }, [day, month]);

    React.useEffect(() => {
        if (data?.setCompanyFinancialYearEnd && !error) {
            setStatus('success');
        }
    }, [data]);

    React.useEffect(() => {
        if (error) {
            setStatus('failure');
        }
    }, [error]);

    const save = () => {
        if (day && month) {
            setStatus('saving');
            submit({variables: {day, month}});
        }
    };

    return (
        <VStack>
            <DetailText bold>
                Please confirm when your financial year ends for accounting purposes.
            </DetailText>
            {status === 'saving' && <LoadingSpinner />}
            {status === 'failure' && error && <ErrorCard error={error} />}
            {status === 'success' && month && day && (
                <HStack>
                    <SelectInputReadonly name="yearEndDay" value={day?.toString()} />
                    <SelectInputReadonly name="yearEndDay" value={monthItems[month - 1].label} />
                    <div className={`w-6 h-6 ${textColorClassnames('success')}`}>
                        <CircleTick />
                    </div>
                    {canEdit && (
                        <div className="cursor-pointer" onClick={() => setStatus('pending')}>
                            <ButtonLabel size="medium" colour="minor-action">
                                Edit
                            </ButtonLabel>
                        </div>
                    )}
                </HStack>
            )}
            {status === 'pending' && (
                <HStack>
                    <SelectInput
                        name="yearEndDay"
                        label="yearEndDay"
                        onChange={(selected) => setDay(parseInt(selected.label))}
                        items={dayItems}
                        value={dayItems.find((item) => parseInt(item.id) == day)}
                        placeholder="Select day"
                    />
                    <SelectInput
                        name="yearEndMonth"
                        label="yearEndMonth"
                        onChange={(selected) => setMonth(parseInt(selected.id))}
                        items={monthItems}
                        value={monthItems[(month ?? 0) - 1]}
                        placeholder="Select month"
                    />
                    {financialYearEnd && (
                        <div className="cursor-pointer" onClick={() => save()}>
                            <ButtonLabel size="medium" colour="minor-action">
                                Save
                            </ButtonLabel>
                        </div>
                    )}
                </HStack>
            )}
        </VStack>
    );
}
