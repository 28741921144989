import React from 'react';

import {VStack} from '@sphericsio/design-system';

import disconnected from '../../images/disconnected.svg';

export function PosterDisconnected() {
    return (
        <VStack align="center">
            <img src={disconnected} />
        </VStack>
    );
}
