import {useUser} from '../components/user-context';

export type FeatureFlag =
    | 'new_categorisation_page'
    | 'sage50'
    | 'sage_pilot_q1_2023'
    | 'free_for_life';

export const useFeatureFlag = (featureFlag: FeatureFlag): boolean => {
    const user = useUser();
    return user.feature_flags.includes(featureFlag);
};
