import React from 'react';
import {useQuery} from '@apollo/client';

import {ProfileVehiclesContext} from './context';
import {CompletedProfileSection} from '../completed-profile-section';
import {GetVehiclesEmissionsDocument} from './graphql';

export function ProfileVehiclesComplete() {
    const ctx = React.useContext(ProfileVehiclesContext);
    const queryResult = useQuery(GetVehiclesEmissionsDocument, {
        variables: {id: ctx.reportingPeriod.id},
    });
    return <CompletedProfileSection section="vehicles" queryResult={queryResult} />;
}
