import {format, isFuture, isSameDay} from 'date-fns';

import {FinancialDateFilter} from '../../graphql/generated/graphql';
import {IntervalTypes, SelectedPeriod} from './config';

type FormatOptions = {
    full?: boolean;
    showBaselineYear?: boolean;
};

export function isSamePeriod(period1: SelectedPeriod, period2: SelectedPeriod) {
    return (
        isSameDay(new Date(period1.periodStart), new Date(period2.periodStart)) &&
        isSameDay(new Date(period1.periodEnd), new Date(period2.periodEnd))
    );
}

export function findQuarterNumber(period: SelectedPeriod, quarters: SelectedPeriod[]) {
    const matchingQuarter = quarters.find((q) => isSamePeriod(period, q));
    return matchingQuarter?.quarterNumber ?? 1;
}

export function formatFinancialYear(start: string, end: string, options: FormatOptions = {}) {
    const period = options.full ? 'Financial Year' : 'FY';
    return `${period} ${format(new Date(start), 'yyyy')} - ${format(new Date(end), 'yy')}`;
}

export function formatQuarter(quarterNumber?: number, options: FormatOptions = {}) {
    const period = options.full ? 'Quarter ' : 'Q';
    const baselineYear = options.showBaselineYear ? ' (Baseline Year)' : '';
    return `${period}${quarterNumber}${baselineYear}`;
}

export function isYearSelected(filter: FinancialDateFilter, current?: FinancialDateFilter | null) {
    return (
        current &&
        current.periodStart === filter.periodStart &&
        current.periodEnd === filter.periodEnd
    );
}

export function periodIsValid(
    filter: FinancialDateFilter[],
    {periodStart, periodEnd}: SelectedPeriod,
    type: IntervalTypes,
) {
    switch (type) {
        case IntervalTypes.year:
            return filter.some((fy) => isSamePeriod(fy, {periodStart, periodEnd}));
        case IntervalTypes.quarter:
            return filter.some((fy) =>
                fy.quarters.some((q) => isSamePeriod(q, {periodStart, periodEnd})),
            );
        case IntervalTypes.custom:
            return !isFuture(new Date(periodEnd));
    }
}
