import React from 'react';
import {capitalize} from 'lodash';
import {useLazyQuery, useQuery} from '@apollo/client';
import {isBefore, subDays} from 'date-fns';

import {
    Alert,
    ConditionalWrapper,
    DetailText,
    Heading2,
    Modal,
    NavBar,
    NavBarItem,
    Strong,
    VStack,
} from '@sphericsio/design-system';

import {DataUploadSettings} from '../../components/settings/data-uploads';
import {CloudSyncSettings} from '../../components/settings/cloud-sync';
import {ConnectionContext, ConnectionContextProvider} from './connection-context';
import {getUploadRequestName} from '../../components/file-uploader/utils';
import {
    FetchLastBackgroundExecutionDocument,
    GetDataUploadsDocument,
} from '../../components/settings/graphql';
import {
    FetchLastBackgroundExecutionQuery,
    UploadRequestType,
} from '../../graphql/generated/graphql';
import {FileUploadWizard} from '../../components/file-upload-wizard';

function getSyncType(syncType: string) {
    switch (syncType.toLowerCase()) {
        case 'sage50_upload':
            return 'sage50';
        default:
            return syncType;
    }
}

function DataConnectionSettings() {
    const {currentSync, config, currentReportType, setCurrentReportType} =
        React.useContext(ConnectionContext);
    const [showModal, setShowModal] = React.useState(false);

    const {loading, error, data, refetch} = useQuery(GetDataUploadsDocument, {
        fetchPolicy: 'network-only',
        variables: {
            status: 'completed',
        },
    });
    const [getLastBackgroundExecution, {data: lastBE, refetch: refetchLastBackgroundExecution}] =
        useLazyQuery(FetchLastBackgroundExecutionDocument, {
            fetchPolicy: 'network-only',
        });

    React.useEffect(() => {
        if (currentSync?.sync_method === 'data-upload') {
            getLastBackgroundExecution();
        }
    }, [currentSync]);

    const alert = React.useMemo(() => {
        const lastExecution = lastBE?.fetchLastBackgroundExecution;
        if (currentSync?.sync_method === 'data-upload' && lastExecution) {
            if (!lastExecution?.completed_at) {
                return {
                    type: 'info' as const,
                    title: 'Your most recent data is still uploading.',
                    subtitle:
                        'Your file contains large amounts of data and therefore is taking longer to upload and validate. Please check back later.',
                };
            } else if (lastExecution.upload_errors && lastExecution.upload_errors.length > 0) {
                const upload_request_type =
                    lastExecution.upload_errors[0].file_upload_request.upload_request_type;
                if (upload_request_type === currentReportType) {
                    return {
                        type: 'error' as const,
                        title: 'Your most recent data upload failed',
                        subtitle:
                            'We found errors within your file. Please check these errors and re-upload your file.',
                        onPress: () => setShowModal(true),
                        buttonLabel: 'View errors',
                    };
                }
            } else if (lastExecution.status === 'failed') {
                return {
                    type: 'error' as const,
                    title: 'Your most recent data upload failed',
                    subtitle:
                        'An unexpected error occurred when trying to upload your file. Please try again.',
                };
            }
        }
    }, [currentSync, currentReportType, lastBE?.fetchLastBackgroundExecution]);

    const onClose = () => {
        setShowModal(false);
        refetchLastBackgroundExecution && refetchLastBackgroundExecution();
        refetch();
    };

    const showErrors =
        lastBE?.fetchLastBackgroundExecution?.upload_errors &&
        lastBE.fetchLastBackgroundExecution.upload_errors.length > 0 &&
        currentReportType ===
            lastBE?.fetchLastBackgroundExecution.upload_errors[0].file_upload_request
                .upload_request_type;

    return (
        <>
            {currentReportType && config?.dataExportSource && (
                <Modal isOpen={showModal} onClose={onClose}>
                    <FileUploadWizard
                        provider={config?.dataExportSource}
                        flow="upload"
                        uploadErrors={
                            showErrors
                                ? (lastBE?.fetchLastBackgroundExecution?.upload_errors ?? [])
                                : []
                        }
                        requestType={currentReportType as UploadRequestType}
                        onClose={onClose}
                    />
                </Modal>
            )}
            <div className="flex flex-row space-x-5">
                {config && (
                    <NavBar>
                        {config.supports.map((report) => (
                            <NavBarItem
                                key={report.requestType}
                                isSelected={currentReportType === report.requestType}
                                onClick={() => setCurrentReportType(report.requestType)}
                            >
                                <DetailText>
                                    <ConditionalWrapper
                                        condition={currentReportType === report.requestType}
                                        wrapper={(children) => <Strong>{children}</Strong>}
                                    >
                                        {getUploadRequestName(report.requestType)}
                                    </ConditionalWrapper>
                                </DetailText>
                            </NavBarItem>
                        ))}
                    </NavBar>
                )}
                <VStack full>
                    {currentSync && (
                        <div className="pt-8 pb-3">
                            <Heading2>
                                Manage your {capitalize(getSyncType(currentSync.sync_type))} data
                            </Heading2>
                        </div>
                    )}
                    {alert && (
                        <Alert
                            type={alert.type}
                            title={alert.title}
                            subtitle={alert.subtitle}
                            onPress={alert.onPress}
                        />
                    )}
                    {currentSync && currentSync?.sync_method === 'data-upload' ? (
                        <DataUploadSettings
                            disableUploads={areUploadsDisabled(
                                lastBE?.fetchLastBackgroundExecution,
                            )}
                            loading={loading}
                            error={error}
                            setShowModal={setShowModal}
                            data={data?.fileUploads}
                        />
                    ) : (
                        <CloudSyncSettings />
                    )}
                </VStack>
            </div>
        </>
    );
}

function areUploadsDisabled(
    lastBackgroundExecution:
        | FetchLastBackgroundExecutionQuery['fetchLastBackgroundExecution']
        | undefined,
): boolean {
    if (!lastBackgroundExecution) {
        // unexpected - user shouldn't be able to reach this screen without starting a data upload.
        return true;
    }
    if (lastBackgroundExecution.completed_at) {
        // last upload has completed
        return false;
    }
    const lastBackgroundExecutionUpdatedAt = new Date(lastBackgroundExecution.updated_at);
    const oneDayAgo = subDays(new Date(), 1);
    if (isBefore(lastBackgroundExecutionUpdatedAt, oneDayAgo)) {
        // last upload has not changed in 24 hours, assume it has failed and permit user to start a new upload
        return false;
    }
    // last upload has been running for less than 24 hours, assume it is still in progress
    return true;
}

export function DataConnectionSettingsWrapper() {
    return (
        <ConnectionContextProvider>
            <DataConnectionSettings />
        </ConnectionContextProvider>
    );
}
