import React from 'react';
import {getYear} from 'date-fns';
import {ApolloError} from '@apollo/client';

import {
    DetailText,
    Heading2,
    VStack,
    HStack,
    Button,
    SegmentSubheader,
} from '@sphericsio/design-system';

import {DateDisplay, parseDateString} from '../date-display';
import {CarbonAmount} from '../carbon-amount';
import {ErrorCard} from '../error-card';

const TARGETS = [
    {
        targetYear: 2030,
        reductionTargetType: 'net_zero_2030',
    },
    {
        targetYear: 2040,
        reductionTargetType: 'net_zero_2040',
    },
    {
        targetYear: 2050,
        reductionTargetType: 'net_zero_2050',
    },
];

type SetTargetFormProps = {
    total_kg_co2e: number;
    baseline_period_start: string;
    baseline_period_end: string;
    onSubmit: (reductionTargetType: string) => void;
    isLoading: boolean;
    error?: ApolloError;
};
export function SetTargetForm({
    total_kg_co2e,
    baseline_period_start,
    baseline_period_end,
    onSubmit,
    isLoading,
    error,
}: SetTargetFormProps) {
    const [selectedReductionTarget, setSelectedReductionTarget] = React.useState<string>();
    const baselineYear = getYear(parseDateString(baseline_period_end));
    const calculateYearlyReduction = (netZeroYear: number) => {
        const yearsLeft = netZeroYear - baselineYear;
        return Math.ceil(100 / yearsLeft);
    };
    const submitForm = (e: React.FormEvent) => {
        e.preventDefault();
        if (selectedReductionTarget == null) {
            return;
        }

        onSubmit(selectedReductionTarget);
    };
    return (
        <VStack>
            <DetailText>
                Baseline period (<DateDisplay>{baseline_period_start}</DateDisplay> -{' '}
                <DateDisplay>{baseline_period_end}</DateDisplay>):{' '}
                <CarbonAmount amount={total_kg_co2e} />
            </DetailText>
            <Heading2>What long term goal would you like to set?</Heading2>
            <form onSubmit={submitForm}>
                <VStack>
                    {TARGETS.map(({targetYear, reductionTargetType}) => (
                        <HStack key={reductionTargetType}>
                            <input
                                type="radio"
                                id={reductionTargetType}
                                checked={selectedReductionTarget === reductionTargetType}
                                onChange={() => setSelectedReductionTarget(reductionTargetType)}
                            />
                            <label htmlFor={reductionTargetType} className="space-y">
                                <SegmentSubheader>Net-Zero by {targetYear}</SegmentSubheader>
                                <DetailText inline={false}>
                                    (This means a {calculateYearlyReduction(targetYear)}% yearly
                                    reduction)
                                </DetailText>
                            </label>
                        </HStack>
                    ))}
                    <div>
                        <Button
                            type="submit"
                            isLoading={isLoading}
                            isDisabled={selectedReductionTarget == null}
                        >
                            Set Target
                        </Button>
                    </div>
                    {error && <ErrorCard />}
                </VStack>
            </form>
        </VStack>
    );
}
