import {useQuery, useMutation} from '@apollo/client';
import {useField} from 'formik';
import {uniq} from 'lodash';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useIntercom} from 'react-use-intercom';
import * as Yup from 'yup';

import {
    Chip,
    FormControl,
    FormErrorMessage,
    VStack,
    CloseIcon,
    Heading5,
    DetailText,
} from '@sphericsio/design-system';
import {Form, Input, Button} from '@sphericsio/forms';

import {ErrorCard} from '../../components/error-card';
import {HSplitLayout} from '../../components/layout/h-split';
import {Loading} from '../../components/loading';
import {PosterLayout} from '../../components/onboarding/poster-layout';
import {PosterSicCodes} from '../../components/onboarding/poster-sic-codes';
import {PageHeader} from '../../components/page-header';
import {SicCodeSearchInput} from '../../components/sic-code-search-input';
import {graphql} from '../../graphql/generated';

export const GetCompanyDocument = graphql(/* GraphQL */ `
    query GetCompany {
        currentCompany {
            id
            name
            syncType
            sicCodes {
                sic_code
                description
            }
        }
    }
`);

export const ConfirmCompanyDetailsDocument = graphql(/* GraphQL */ `
    mutation ConfirmCompanyDetails($name: String!, $sicCodes: [String!]!) {
        confirmCompanyDetails(name: $name, sicCodes: $sicCodes) {
            ...CurrentUser
        }
    }
`);

function EditSicCodes() {
    const [field, meta, helpers] = useField<{sic_code: string; description: string}[]>('sicCodes');
    return (
        <FormControl isInvalid={meta.error != null}>
            <SicCodeSearchInput
                name="sicCodes"
                placeholder="Industry Classification"
                onChooseItem={(item) => {
                    if (item == null) {
                        return;
                    }
                    helpers.setValue([...field.value, item]);
                    return false;
                }}
            />
            <div className="flex flex-wrap" data-testid="sic-code-list">
                {field.value.map((sicCode) => (
                    <div key={sicCode.sic_code} className="mr-2.5 mt-2.5">
                        <Chip>
                            <div className="flex space-x-2 px-2 items-center">
                                <DetailText size="small" inherit>
                                    {sicCode.sic_code} - {sicCode.description}
                                </DetailText>
                                <div
                                    className="w-4 h-4 cursor-pointer"
                                    onClick={() => {
                                        helpers.setValue(field.value.filter((v) => v !== sicCode));
                                    }}
                                >
                                    <CloseIcon />
                                </div>
                            </div>
                        </Chip>
                    </div>
                ))}
            </div>
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    );
}

const FORM_SCHEMA = Yup.object({
    name: Yup.string().required('This field is required'),
    sicCodes: Yup.array(Yup.object({sic_code: Yup.string(), description: Yup.string()})).min(
        1,
        'Please choose at least one SIC Code',
    ),
});

export function CompanyDetails() {
    const {loading, error, data} = useQuery(GetCompanyDocument);
    const [submit, {loading: submitting, error: errorSubmitting, data: submitResponse}] =
        useMutation(ConfirmCompanyDetailsDocument);
    const {trackEvent} = useIntercom();
    const navigate = useNavigate();

    if (submitResponse != null) {
        navigate('/');
    }

    return (
        <HSplitLayout cta="logout">
            <VStack>
                <PageHeader
                    title="Please provide us with SIC codes for your business."
                    subtitle="Sage Earth calculates your carbon footprint based on carbon emissions factors for each industry."
                />
                <Heading5>If you have more than once SIC code, please enter them all.</Heading5>
                {loading && <Loading />}
                {error && <ErrorCard />}
                {data && (
                    <Form
                        validationSchema={FORM_SCHEMA}
                        initialValues={{
                            name: data.currentCompany.name ?? '',
                            sicCodes: data.currentCompany.sicCodes,
                        }}
                        testId="company-details-form"
                        onSubmit={(values) => {
                            submit({
                                variables: {
                                    name: values.name,
                                    sicCodes: uniq(
                                        values.sicCodes.map((sicCode) => sicCode.sic_code),
                                    ),
                                },
                            }).then(() => trackEvent('Company SIC code entered'));
                        }}
                    >
                        <Input name="name" placeholder="Company name" />
                        <VStack>
                            <EditSicCodes />
                            {errorSubmitting && <ErrorCard />}
                        </VStack>
                        <div className="pb-24">{/* spacer for sic code dropdown */}</div>
                        <div className="flex w-full flex-row justify-end">
                            <Button type="submit" testId="submit-btn" isLoading={submitting}>
                                Confirm
                            </Button>
                        </div>
                    </Form>
                )}
            </VStack>
            <PosterLayout>
                <PosterSicCodes />
            </PosterLayout>
        </HSplitLayout>
    );
}
