import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {Elements} from '@stripe/react-stripe-js';

import sofiaProFont from '@sphericsio/design-system/src/fonts/Sofia Pro Regular.otf';

import {SubscriptionAuthenticate} from './authenticate';
import {NotFound} from '../not-found';
import {initStripe} from '../../services/stripe';
import {SubscriptionCard} from './card';
import {Subscription} from '.';

export function SubscriptionRoutes() {
    const stripePromise = initStripe();

    return (
        <Elements
            stripe={stripePromise}
            options={{fonts: [{src: `url(${sofiaProFont})`, family: 'Sofia Pro Regular'}]}}
        >
            <Routes>
                <Route path="authenticate" element={<SubscriptionAuthenticate />} />
                <Route path="card" element={<SubscriptionCard />} />
                <Route path="" element={<Subscription />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Elements>
    );
}
