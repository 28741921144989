import React from 'react';

import {LoadingSpinner, ConditionalWrapper, HStack, CategoryTheme} from '@sphericsio/design-system';

import {CategoryRow, ExcludedCategorisation, SaveStatus} from '../categorisation';
import {UserCategorisationHierarchyItem} from '../hierarchy';
import {CategorisationContext} from '../context';
import {ErrorCard} from '../../error-card';
import {EditCategory} from '../edit-categorisation';
import {SaveFinancialAccountCategorisation} from './save-financial-account-categorisation';
import {AddCategoryButton} from '../transaction/transaction-categorisation';

function AddFinancialAccountCategoryButton() {
    return (
        <HStack condensed>
            <AddCategoryButton />
        </HStack>
    );
}

type FinancialAccountCategorisationProps = {
    data?: UserCategorisationHierarchyItem[];
    theme: CategoryTheme;
    id: string;
    editable?: boolean;
    excludeCategory?: string | null;
    onSaveSuccess: () => void;
};

export function FinancialAccountCategorisation({
    id,
    data,
    theme,
    excludeCategory,
    editable = true,
    onSaveSuccess,
}: FinancialAccountCategorisationProps) {
    const [status, setStatus] = React.useState<SaveStatus>('pending');
    const [selected, setSelected] = React.useState<UserCategorisationHierarchyItem | null>(null);
    const {loading, error} = React.useContext(CategorisationContext);

    React.useEffect(() => {
        if (status === 'success') {
            onSaveSuccess();
        }
    }, [status]);

    const content = React.useMemo(() => {
        if (status === 'saving' || loading) {
            return <LoadingSpinner />;
        }
        if (status === 'error' || error) {
            return <ErrorCard />;
        }

        if (excludeCategory) {
            return <ExcludedCategorisation theme={theme} reason={excludeCategory} />;
        }

        if (!data?.length) {
            return (
                <EditCategory
                    status={status}
                    onStatusChange={setStatus}
                    onSelected={setSelected}
                    canSelectAllLevels={true}
                >
                    <AddFinancialAccountCategoryButton />
                </EditCategory>
            );
        }

        return (
            <CategoryRow
                categorisation={data}
                editable={editable}
                theme={theme}
                status={status}
                onStatusChange={setStatus}
                onSelected={setSelected}
                canSelectAllLevels={true}
            />
        );
    }, [status, data, loading, error]);

    return (
        <ConditionalWrapper
            condition={status !== 'success'}
            wrapper={(children) => (
                <SaveFinancialAccountCategorisation
                    id={id}
                    selected={selected}
                    status={status}
                    onStatusChange={setStatus}
                >
                    {children}
                </SaveFinancialAccountCategorisation>
            )}
        >
            {content}
        </ConditionalWrapper>
    );
}
