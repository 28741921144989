import React from 'react';
import classnames from 'classnames';

import {
    ChevronRightIcon,
    CategoryTheme,
    DetailText,
    PadlockIcon,
    HStack,
    Tooltip,
    ButtonLabel,
    outlineColorClassnames,
    bgColorClassnames,
} from '@sphericsio/design-system';

import {UserCategorisationHierarchyItem} from './hierarchy';
import {EditCategory} from './edit-categorisation';
import {ExcludeTransactionContext} from '../financial-transactions/exclude-transaction-context';

type CategoryPillProps = {
    text: string;
    theme: CategoryTheme;
    testId?: string;
};

export function CategoryPill({text, theme, testId}: CategoryPillProps) {
    return (
        <div
            data-testid={testId}
            className={classnames(
                'py-1 px-3 text-center outline-none max-w-max rounded-full whitespace-nowrap',
                theme.key === 'excluded' && `border ${outlineColorClassnames('excluded')}`,
                theme.key !== 'excluded' && bgColorClassnames('minor-action'),
            )}
        >
            <DetailText size="small" colour={theme.key === 'excluded' ? 'excluded' : 'default'}>
                {text}
            </DetailText>
        </div>
    );
}

type CategoryListItemProps = {
    title: string;
    theme: CategoryTheme;
    last: boolean;
    testId?: string;
};

export function CategoryListItem({title, theme, last, testId}: CategoryListItemProps) {
    return (
        <React.Fragment>
            <CategoryPill theme={theme} text={title} testId={testId} />
            {!last && (
                <div className="w-3 h-3 flex self-center">
                    <ChevronRightIcon />
                </div>
            )}
        </React.Fragment>
    );
}

export function ExcludedCategorisation({theme, reason}: {theme: CategoryTheme; reason: string}) {
    const {reasons} = React.useContext(ExcludeTransactionContext);
    const result = reasons?.find(({id}) => id == reason);

    return (
        <HStack condensed>
            <div className="flex flex-row flex-start space-x-1 items-center">
                {!result ? (
                    <CategoryListItem theme={theme} title={'Excluded'} last={true} />
                ) : (
                    <>
                        <CategoryListItem theme={theme} title={'Excluded'} last={false} />
                        <CategoryListItem theme={theme} title={result.label} last={true} />
                    </>
                )}
            </div>
        </HStack>
    );
}

type CategoryRowProps = {
    categorisation: UserCategorisationHierarchyItem[];
    theme: CategoryTheme;
    status: SaveStatus;
    editable: boolean;
    onStatusChange: (status: SaveStatus) => void;
    onSelected: (selected: UserCategorisationHierarchyItem | null) => void;
    canSelectAllLevels?: boolean;
};
export function CategoryRow({
    categorisation,
    theme,
    status,
    editable,
    canSelectAllLevels,
    onStatusChange,
    onSelected,
}: CategoryRowProps) {
    const terms = categorisation.map(({title, key}, index) => (
        <CategoryListItem
            title={title}
            theme={theme}
            key={`category-${key}`}
            last={index === categorisation.length - 1}
            testId={`category-level-${index + 1}`}
        />
    ));
    return (
        <HStack condensed>
            <div className="flex flex-row flex-start space-x-1 items-center">{terms}</div>
            {editable ? (
                <EditCategory
                    status={status}
                    onStatusChange={onStatusChange}
                    onSelected={onSelected}
                    canSelectAllLevels={canSelectAllLevels}
                >
                    <ButtonLabel size="medium" colour="minor-action">
                        Edit
                    </ButtonLabel>
                </EditCategory>
            ) : (
                <div className="relative">
                    <Tooltip
                        place="top"
                        align="end"
                        header="Why is this locked?"
                        tooltipText="The category for this transaction has been locked as you already selected this in the operations section."
                    >
                        <div className="w-6 h-6 flex items-center justify-center">
                            <div className="w-3 h-3">
                                <PadlockIcon />
                            </div>
                        </div>
                    </Tooltip>
                </div>
            )}
        </HStack>
    );
}

export type SaveStatus = 'pending' | 'saving' | 'success' | 'error';
