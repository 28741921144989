import React from 'react';

import {CompanyReportingPeriod} from '../../graphql/generated/graphql';
import {formatDateString} from '../date-display';

type ReportingPeriodDatesProps = {
    reportingPeriod: Pick<CompanyReportingPeriod, 'period_start' | 'period_end'>;
};
export function ReportingPeriodDates({reportingPeriod}: ReportingPeriodDatesProps) {
    return (
        <>
            Answer for the period between {formatDateString(reportingPeriod.period_start)} and{' '}
            {formatDateString(reportingPeriod.period_end)}.
        </>
    );
}
