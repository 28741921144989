import React from 'react';

import {ButtonLink, Heading1, VStack} from '@sphericsio/design-system';

export function PosterForgotTemplate() {
    return (
        <VStack>
            <Heading1>
                For instructions and to download your templates, visit our help guide.
            </Heading1>
            <div className="w-56 pt-7">
                <ButtonLink
                    openInNewWindow
                    href="https://help.earth.sage.com/en/articles/7202124-connecting-sage-50-to-sage-earth"
                >
                    Visit help guide
                </ButtonLink>
            </div>
        </VStack>
    );
}
