import React from 'react';
import classnames from 'classnames';

import {
    CircleTick,
    DetailText,
    Heading2,
    HStack,
    textColorClassnames,
    VStack,
} from '@sphericsio/design-system';

import {NavigationButtons} from '../../navigation-buttons';
import {UploadContext} from '../context';
import {HSplitLayout} from '../../layout/h-split';
import {PosterLayout} from '../../onboarding/poster-layout';
import {FileUploader} from '../../file-uploader';
import {
    DataExportSource,
    DataExportSourceConfig,
    FileConfig,
} from '../../../graphql/generated/graphql';
import {ErrorCard} from '../../error-card';
import {FileUploadContext, FileUploadContextProvider} from '../../file-uploader/context';
import {PageHeader} from '../../page-header';
import {getUploadRequestName} from '../../file-uploader/utils';
import {PosterForgotTemplate} from '../../onboarding/poster-forgot-template';
import {DateDisplay} from '../../date-display';
import {FileUploadState} from '../../file-uploader/state';

function UploadItem({
    supportedFileTypes,
    exportSource,
}: {
    supportedFileTypes: FileConfig[];
    exportSource: DataExportSource;
}) {
    const {uploadRequestType, flow} = React.useContext(UploadContext);
    if (uploadRequestType) {
        return (
            <div
                className={classnames('flex flex-row items-center', {
                    'space-x-5 desktop-large:space-x-10': flow === 'onboarding',
                    'space-x-2 desktop-large:space-x-5': flow !== 'onboarding',
                })}
            >
                <div className="flex-1 w-40">
                    <DetailText bold>
                        {getUploadRequestName(uploadRequestType).toUpperCase()}
                    </DetailText>
                </div>
                <FileUploader
                    requestConfig={{
                        exportSource,
                        uploadRequestType,
                        accepts: supportedFileTypes,
                    }}
                />
            </div>
        );
    } else {
        return <></>;
    }
}

function UploadError({upload}: {upload: FileUploadState}) {
    if (upload.error) {
        return <ErrorCard message="Something went wrong with your file upload." />;
    } else {
        return <></>;
    }
}

function Uploads({exportSourceConfig}: {exportSourceConfig: DataExportSourceConfig}) {
    const {dispatch, uploadRequestType, flow, previousSpendData, uploadInProgress} =
        React.useContext(UploadContext);
    const {uploads, perform, completed} = React.useContext(FileUploadContext);

    const [supportedFileTypes, setSupportedFileTypes] = React.useState<FileConfig[]>([]);
    const [canProceed, setCanProceed] = React.useState<boolean>(!uploadInProgress);
    const [canSkip, setCanSkip] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (exportSourceConfig) {
            const report = exportSourceConfig.supports.find(
                (v) => v.requestType === uploadRequestType,
            );
            if (report) {
                setSupportedFileTypes(report.supportedFileTypes);
            }
        }
    }, [exportSourceConfig]);

    React.useEffect(() => {
        if (uploadRequestType === 'profit_and_loss' && flow === 'onboarding') {
            setCanSkip(true);
        }
    }, [uploadRequestType]);

    React.useEffect(() => {
        if (uploads.length > 0) {
            setCanProceed(true);
        }
    }, [uploads]);

    const title = React.useMemo(
        () =>
            uploadRequestType === 'financial_transactions'
                ? 'Upload your spend data'
                : 'Upload your profit and loss',
        [uploadRequestType],
    );

    const subtitle = React.useMemo(
        () =>
            flow === 'onboarding'
                ? uploadRequestType === 'financial_transactions'
                    ? 'You must upload your spend data to continue.'
                    : 'Uploading your profit and loss will allow us to make more accourate calculations, however this is optional.'
                : '',
        [uploadRequestType],
    );

    const nextLabel = React.useMemo(() => {
        if (uploads.length > 0 && uploads[0].status === 'uploadComplete') {
            return 'Continue';
        } else {
            return 'Upload file';
        }
    }, [uploads]);

    const backButton =
        previousSpendData.length > 0
            ? {
                  label: 'I don’t want to upload additional data',
                  onClick: () => dispatch({type: 'uploadSkipped'}),
              }
            : {
                  label: 'Back',
                  onClick: () => dispatch({type: 'requestCompanyDefaults'}),
              };

    return (
        <VStack large full seperator>
            <div
                className={classnames({
                    'pb-10': flow === 'onboarding',
                    'pb-2': flow !== 'onboarding',
                })}
            >
                {flow === 'onboarding' && <PageHeader title={title} subtitle={subtitle} />}
                {flow !== 'onboarding' && <Heading2>{title}</Heading2>}
                {uploadRequestType === 'profit_and_loss' && (
                    <div className="pt-5">
                        <DetailText>Please note we only accept values in GBP.</DetailText>
                    </div>
                )}
                {flow === 'onboarding' &&
                    uploadRequestType === 'financial_transactions' &&
                    previousSpendData.length > 0 && (
                        <div className="pt-5">
                            <DetailText bold>
                                You have uploaded spend data for the following dates:
                            </DetailText>
                            <div className="pt-5">
                                <VStack>
                                    {previousSpendData.map((ps) => (
                                        <HStack key={ps.id}>
                                            <div
                                                className={`w-6 h-6 ${textColorClassnames(
                                                    'success',
                                                )}`}
                                            >
                                                <CircleTick invert />
                                            </div>
                                            <div className="flex flex-col space-y-1">
                                                <DetailText>
                                                    {ps.request.filename}.
                                                    {ps.request.file_extension}
                                                </DetailText>
                                                <DetailText>
                                                    <DateDisplay>
                                                        {ps.transaction_upload?.period_start ??
                                                            ps.turnover_upload
                                                                ?.coverage_from_date ??
                                                            ''}
                                                    </DateDisplay>{' '}
                                                    -{' '}
                                                    <DateDisplay>
                                                        {ps.transaction_upload?.period_end ??
                                                            ps.turnover_upload?.coverage_to_date ??
                                                            ''}
                                                    </DateDisplay>
                                                </DetailText>
                                            </div>
                                        </HStack>
                                    ))}
                                </VStack>
                            </div>
                        </div>
                    )}
            </div>
            <div className="py-5 space-y-3">
                {supportedFileTypes && (
                    <UploadItem
                        supportedFileTypes={supportedFileTypes}
                        exportSource={exportSourceConfig.dataExportSource}
                    />
                )}
            </div>
            <div
                className={classnames('pt-5', {
                    'flex flex-col items-start': flow !== 'onboarding',
                })}
            >
                <div
                    className={classnames({
                        'break-words text-left w-96': flow !== 'onboarding',
                    })}
                >
                    {uploads.map((upload) => (
                        <UploadError upload={upload} key={`error=${upload.tempId}`} />
                    ))}
                </div>

                <div
                    className={classnames('flex w-full flex-row justify-end', {
                        'pt-10': flow === 'onboarding',
                        'pt-2 self-end': flow !== 'onboarding',
                    })}
                >
                    <NavigationButtons
                        backColour="major-action"
                        backOnClick={backButton.onClick}
                        hideBack={flow !== 'onboarding' || uploadRequestType === 'profit_and_loss'}
                        backLabel={backButton.label}
                        nextOnClick={() =>
                            completed && uploads.length > 0
                                ? dispatch({
                                      type: 'filesUploaded',
                                      payload: {uploads},
                                  })
                                : perform(uploads)
                        }
                        nextToDisabled={!canProceed}
                        nextLabel={nextLabel}
                        hideSkip={!canSkip}
                        skipOnClick={() => {
                            dispatch({type: 'uploadSkipped'});
                        }}
                    />
                </div>
            </div>
        </VStack>
    );
}

export const Uploader: React.FC = () => {
    const {exportSourceConfig, flow} = React.useContext(UploadContext);

    if (!exportSourceConfig) {
        return <ErrorCard />;
    }
    if (flow !== 'onboarding') {
        return (
            <FileUploadContextProvider>
                <Uploads exportSourceConfig={exportSourceConfig} />
            </FileUploadContextProvider>
        );
    }
    return (
        <HSplitLayout cta="logout" wide>
            <FileUploadContextProvider>
                <Uploads exportSourceConfig={exportSourceConfig} />
            </FileUploadContextProvider>
            <PosterLayout bgImage={{type: 'onboarding'}}>
                <PosterForgotTemplate />
            </PosterLayout>
        </HSplitLayout>
    );
};
