import {NotifiableError} from '@bugsnag/js';
import {useEffect} from 'react';

import {Bugsnag} from '../util/bugsnag';

export function useErrorNotifier(
    reportError: boolean,
    error: NotifiableError | undefined,
    messageRef: React.RefObject<HTMLElement>,
) {
    // - We're accessing the DOM (through `textRef`), hence this MUST run in a `useEffect`.
    // - We only want to retrigger this when the _effective_ error message changes.
    //   Hence, we use `error ?? textRef.current` as dependency, and not these values individually.
    //   If we'd pass them individually then we can trigger the effect even though the effective value didn't change.
    useEffect(() => {
        if (!reportError) {
            return;
        }
        if (error) {
            Bugsnag.notify(error);
        } else {
            const msg = messageRef.current?.textContent;
            if (msg) {
                Bugsnag.notify({name: 'ErrorCardError', message: msg});
            }
        }
    }, [error ?? messageRef.current]);
}
