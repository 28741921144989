import {graphql} from '../../graphql/generated';

export const CreateBaselineReportingPeriodDocument = graphql(/* GraphQL */ `
    mutation CreateBaselineReportingPeriod($periodStart: Date!, $periodEnd: Date!) {
        createBaselineReportingPeriod(periodStart: $periodStart, periodEnd: $periodEnd) {
            ...CompanyReportingPeriod
        }
    }
`);

export const CreateOperationsReportingPeriodDocument = graphql(/* GraphQL */ `
    mutation CreateOperationsReportingPeriod($periodStart: Date!, $periodEnd: Date!) {
        createOperationsReportingPeriod(periodStart: $periodStart, periodEnd: $periodEnd) {
            ...CompanyReportingPeriod
        }
    }
`);

export const ValidateReportingPeriodDocument = graphql(/* GraphQL */ `
    query ValidateReportingPeriod($from: Date!, $to: Date!) {
        validateReportingPeriod(from: $from, to: $to) {
            isValid
        }
    }
`);
