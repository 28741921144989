import React from 'react';
import {useNavigate} from 'react-router-dom';

import {VStack} from '@sphericsio/design-system';

import {HSplitLayout} from '../../components/layout/h-split';
import {PosterLayout} from '../../components/onboarding/poster-layout';
import {PosterNonProfit} from '../../components/onboarding/poster-non-profit';
import {SubscriptionWizard} from '../../components/subscription-modal/wizard';

export function Subscription() {
    const navigate = useNavigate();
    return (
        <HSplitLayout wide cta="logout">
            <VStack>
                <SubscriptionWizard
                    onClose={() => navigate('/login/accounting')}
                    onError={() => navigate('/login/accounting')}
                    onSuccess={() => navigate('/')}
                />
            </VStack>
            <PosterLayout>
                <PosterNonProfit />
            </PosterLayout>
        </HSplitLayout>
    );
}
