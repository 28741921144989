import React from 'react';

import {VStack} from '@sphericsio/design-system';

import {ProfilePeopleContext} from './context';
import {ProfileSectionPanel} from '../section-panel';

export function ProfilePeopleIntro() {
    const ctx = React.useContext(ProfilePeopleContext);
    return (
        <VStack>
            <ProfileSectionPanel
                heading="People"
                type="pending"
                reportingPeriod={ctx.reportingPeriod}
                onPressStart={() => ctx.dispatch({type: 'start'})}
            >
                We need you to tell us about the people that worked for you during this reporting
                period.
            </ProfileSectionPanel>
        </VStack>
    );
}
