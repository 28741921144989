import React from 'react';
import {useQuery, useMutation} from '@apollo/client';
import {capitalize} from 'lodash';
import classnames from 'classnames';

import {
    Button,
    DetailText,
    Heading2,
    VStack,
    intercomTargetProps,
    HStack,
    Modal,
    SubNavItem,
    Panel,
    textColorClassnames,
    LoadingSpinner,
} from '@sphericsio/design-system';

import {graphql} from '../../graphql/generated';
import {Loading} from '../loading';
import {ErrorCard} from '../error-card';
import {DateDisplay} from '../date-display';
import {FinancialProviderSyncButton} from '../financial-provider-sync-button';
import {
    FinancialProviderName,
    FinancialProviderSync,
    SettingsDocument,
} from '../../graphql/generated/graphql';
import {NavigationButtons} from '../navigation-buttons';
import {CircleIcon, ExclamationPointIcon} from '../icons';
import {GetCurrentUserDocument, useUser} from '../user-context';
import {ConnectionContext} from '../../views/settings/connection-context';

const GetConnectedOrgNameDocument = graphql(/* GraphQL */ `
    query GetConnectedOrgName($provider: FinancialProviderName!) {
        connectedFinancialProviderOrgName(provider: $provider)
    }
`);

const DisconnectFinancialProviderDocument = graphql(/* GraphQL */ `
    mutation DisconnectFinancialProvider($provider: FinancialProviderName!) {
        disconnectFinancialProvider(provider: $provider) {
            id
            sync_type
            sync_status
            created_at
        }
    }
`);

function XeroConnectedOrgName() {
    const {loading, data} = useQuery(GetConnectedOrgNameDocument, {variables: {provider: 'xero'}});
    return (
        <>
            {loading && <LoadingSpinner size={16} />}
            {data && data.connectedFinancialProviderOrgName != null && (
                <DetailText>{data.connectedFinancialProviderOrgName}</DetailText>
            )}
        </>
    );
}

type DisconnectFinancialProviderButtonProps = {
    provider: FinancialProviderName;
    intercomTarget?: string;
    onCancel: () => void;
};
const DisconnectFinancialProviderModal: React.FC<DisconnectFinancialProviderButtonProps> = ({
    provider,
    intercomTarget,
    onCancel,
}) => {
    const [disconnectProvider, {data, error, loading}] = useMutation(
        DisconnectFinancialProviderDocument,
        {
            variables: {provider},
        },
    );

    React.useEffect(() => {
        if (data) {
            onCancel();
        }
    }, [data]);

    return (
        <DetailText>
            <div className="flex flex-col items-start space-y-10">
                <Heading2>Are you sure you would like to disconnect your sync?</Heading2>
                <HStack align="start">
                    <div className={`w-6 h-6 ${textColorClassnames('error')}`}>
                        <ExclamationPointIcon />
                    </div>
                    <div className="w-96 whitespace-normal">
                        <div className="text-left">
                            <DetailText>
                                Disconnecting from your accounting platform means we will no longer
                                be able to use your data to calculate your carbon footprint.
                            </DetailText>
                        </div>
                    </div>
                </HStack>
                {error && (
                    <DetailText colour="secondary">
                        There&apos;s been an issue with disconnecting your financial provider.
                    </DetailText>
                )}
                <NavigationButtons
                    backLabel="Cancel"
                    backOnClick={() => onCancel()}
                    backColour="secondary"
                    nextLabel="Disconnect"
                    nextBgColour="error"
                    loading={loading}
                    nextOnClick={() =>
                        disconnectProvider({
                            refetchQueries: [
                                {query: SettingsDocument},
                                {query: GetCurrentUserDocument},
                            ],
                        })
                    }
                    nextIntercomTarget={intercomTarget}
                />
            </div>
        </DetailText>
    );
};

type ReconnectFinancialProviderButtonProps = {
    provider: string;
};
const ReconnectFinancialProviderButton: React.FC<ReconnectFinancialProviderButtonProps> = ({
    provider,
}) => {
    const user = useUser();
    return (
        <FinancialProviderSyncButton
            financialProviderName={provider}
            hasActiveSubscription={user.subscription?.status === 'active'}
            hasPendingSubscription={user.subscription?.status === 'pending'}
            forLogin={false}
        >
            {(onClick) => <Button onPress={onClick}>Reconnect</Button>}
        </FinancialProviderSyncButton>
    );
};

function formatSyncStatus(status: string) {
    if (status === 'fatal_failed') {
        return 'Disconnected';
    }

    return capitalize(status);
}

type ProviderDetailsSync = Omit<FinancialProviderSync, 'sync_method'>;

function ProviderDetails() {
    const user = useUser();
    const {syncs} = React.useContext(ConnectionContext);
    const [currentSync, setCurrentSync] = React.useState<ProviderDetailsSync>(syncs[0]);
    const [showModal, setShowModal] = React.useState(false);

    const isCurrentSyncActive = React.useMemo(
        () => syncs[0].id === currentSync.id,
        [currentSync, syncs],
    );

    const isCurrentSync = (id: string) => id === currentSync.id;

    return (
        <div>
            <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                <DisconnectFinancialProviderModal
                    provider={currentSync.sync_type as FinancialProviderName}
                    onCancel={() => setShowModal(false)}
                    intercomTarget={isCurrentSyncActive ? 'Settings: Disconnect Button' : undefined}
                />
            </Modal>
            <VStack>
                <HStack align="start">
                    {syncs.map((sync, index) => (
                        <SubNavItem
                            key={sync.id}
                            {...intercomTargetProps(
                                index === 0 ? 'Settings: Sync Status' : undefined,
                            )}
                            onChange={() => setCurrentSync(sync)}
                            isSelected={isCurrentSync(sync.id)}
                        >
                            <div className="flex flex-col">
                                <DetailText bold>{capitalize(sync.sync_type)}</DetailText>
                                {sync.sync_type === 'xero' && <XeroConnectedOrgName />}
                            </div>
                        </SubNavItem>
                    ))}
                    <Panel border>
                        <div className="px-3 py-1">
                            <div className="pb-10 tracking-wider">
                                <DetailText bold>SYNCS</DetailText>
                            </div>
                            <VStack full seperator>
                                <div className="pb-2">
                                    <div className="flex flex-row">
                                        <div className="w-1/3">
                                            <DetailText>Sync status</DetailText>
                                        </div>
                                        <DetailText>Last synced</DetailText>
                                    </div>
                                </div>
                                <div className="pt-2">
                                    <HStack>
                                        <div className="w-1/3 flex flex-row items-center space-x-3">
                                            <div
                                                className={classnames(
                                                    `w-3 h-3 ${
                                                        currentSync.sync_status === 'fatal_failed'
                                                            ? textColorClassnames('error')
                                                            : textColorClassnames('major-action')
                                                    }`,
                                                )}
                                            >
                                                <CircleIcon fill />
                                            </div>
                                            <DetailText bold>
                                                {formatSyncStatus(currentSync.sync_status)}
                                            </DetailText>
                                        </div>

                                        <div className="w-1/3">
                                            <DetailText>
                                                <DateDisplay>{currentSync.created_at}</DateDisplay>
                                            </DetailText>
                                        </div>
                                        <div className="w-1/3 flex flex-row justify-end">
                                            <div>
                                                {user.financialProvider?.connection_status ===
                                                'missing_token' ? (
                                                    <DetailText>Disconnected</DetailText>
                                                ) : currentSync.sync_status === 'fatal_failed' ? (
                                                    <ReconnectFinancialProviderButton
                                                        provider={currentSync.sync_type}
                                                    />
                                                ) : (
                                                    <Button
                                                        bg="error"
                                                        onPress={() => setShowModal(true)}
                                                    >
                                                        Disconnect
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </HStack>
                                </div>
                            </VStack>
                        </div>
                    </Panel>
                </HStack>
            </VStack>
        </div>
    );
}

export function CloudSyncSettings() {
    const {loading, error, syncs} = React.useContext(ConnectionContext);
    if (loading) {
        return <Loading />;
    } else if (error) {
        return <ErrorCard />;
    } else if (!syncs || syncs.length === 0) {
        return <div>You don&apos;t have an active sync with any accounting platform.</div>;
    } else {
        return <ProviderDetails />;
    }
}
