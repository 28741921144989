import React from 'react';
import {useQuery} from '@apollo/client';

import {ProfilePeopleContext} from './context';
import {CompletedProfileSection} from '../completed-profile-section';
import {GetPeopleEmissionsDocument} from './graphql';

export function ProfilePeopleComplete() {
    const ctx = React.useContext(ProfilePeopleContext);
    const queryResult = useQuery(GetPeopleEmissionsDocument, {
        variables: {id: ctx.reportingPeriod.id},
    });
    return <CompletedProfileSection section="people" queryResult={queryResult} />;
}
