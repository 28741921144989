import React from 'react';

import type {CompanyReportingPeriod, ProfileEntry} from '../../graphql/generated/graphql';

type BaseReportingPeriod = Pick<
    CompanyReportingPeriod,
    'id' | 'period_start' | 'period_end' | 'isOpen'
>;

type ProfileSections = 'energy' | 'vehicles' | 'people' | 'ghg';

export type ReportingPeriodForSection<T extends ProfileSections> = BaseReportingPeriod & {
    [K in T]?: Pick<ProfileEntry, 'completed_at'> | null;
};

// TODO investigate this placeholder
export const reportingPeriodPlaceholder = {
    id: '-1',
    period_start: '',
    period_end: '',
    isOpen: true,
    isCompleted: false,
    isBaselineReportingPeriod: false,
    company_account_id: '',
    totalEmissionsForPeriod: 0,
};

export const ReportingPeriodContext = React.createContext<CompanyReportingPeriod>(
    reportingPeriodPlaceholder,
);
