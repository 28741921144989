import React from 'react';
import {v4} from 'uuid';
import {cloneDeep} from 'lodash';

import {FileUploadState} from './state';
import {useFileUploader} from './uploader';

type QueueStatus = 'pending' | 'ready' | 'uploading' | 'complete' | 'failed';
type FileUploadContextValue = {
    addUploads: (items: FileUploadState[]) => string[];
    remove: (v: {tempIds?: string[]; ids?: string[]}) => void;
    perform: (items: FileUploadState[]) => void;
    uploads: FileUploadState[];
    completed: boolean;
};

export const FileUploadContext = React.createContext<FileUploadContextValue>({
    addUploads: () => {
        return [];
    },
    remove: () => {
        //do nothing
    },
    perform: () => {
        // do nothing
    },
    uploads: [],
    completed: false,
});

type FileUploadContextProviderProps = {
    children: React.ReactNode;
};

// uploads - only mutable by user action (addition or deletion)
// queue - when uploading, to show progress, mutable by programmatic action
export function FileUploadContextProvider({children}: FileUploadContextProviderProps) {
    const [uploads, setUploads] = React.useState<FileUploadState[]>([]);
    const [queue, setQueue] = React.useState<FileUploadState[]>([]);
    const [queueStatus, setQueueStatus] = React.useState<QueueStatus>('pending');
    const [completed, setComplete] = React.useState<boolean>(false);

    const [uploadFile, {data: activeRequest}] = useFileUploader();

    const addUploads = (items: FileUploadState[]) => {
        const newUploadsItemIds: string[] = [];
        const newUploads = items.map((item) => {
            const tempId = v4();
            newUploadsItemIds.push(tempId);
            return {...item, tempId};
        }, {});
        setUploads([...uploads, ...newUploads]);
        return newUploadsItemIds;
    };

    const remove = ({tempIds, ids}: {tempIds?: string[]; ids?: string[]}) => {
        if (tempIds && tempIds?.length > 0) {
            setUploads(uploads.filter((upload) => !tempIds.includes(upload.tempId)));
        }
        if (ids) {
            setUploads(
                uploads.filter((upload) => {
                    if (upload.id) {
                        return !ids.includes(upload.id);
                    } else {
                        return true;
                    }
                }),
            );
        }
    };

    const perform = () => {
        setQueue(uploads.filter((file) => file.status === 'queued'));
        setQueueStatus('ready');
    };

    React.useEffect(
        () => setComplete(uploads.every((upload) => upload.status === 'uploadComplete')),
        [uploads],
    );

    React.useEffect(() => {
        if (queueStatus === 'ready') {
            if (queue.length) {
                const updatedQueue = queue;
                const next = updatedQueue.shift();
                setQueue(updatedQueue);

                if (next != null) {
                    setQueueStatus('uploading');
                    uploadFile(next);
                }
            } else {
                setQueueStatus('complete');
            }
        }
    }, [queueStatus]);

    React.useEffect(() => {
        if (queueStatus === 'uploading' && uploads.length > 0) {
            const upload = uploads.find((upload) => upload.tempId === activeRequest.tempId);
            if (upload && activeRequest.status !== upload.status) {
                const updated = cloneDeep(uploads).map((upload) => {
                    if (upload.tempId === activeRequest.tempId) {
                        return activeRequest;
                    } else {
                        return upload;
                    }
                });
                setUploads(updated);

                if (activeRequest.status === 'uploadComplete') {
                    setQueueStatus('ready');
                }
            }
        }
    }, [activeRequest, uploads]);

    return (
        <FileUploadContext.Provider
            value={{
                addUploads,
                remove,
                uploads,
                perform,
                completed,
            }}
        >
            {children}
        </FileUploadContext.Provider>
    );
}
