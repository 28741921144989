import React from 'react';

import {DetailText} from '@sphericsio/design-system';

import {FinancialYear} from './types';
import {colors} from './style';
import {formatYear} from './util';
import {NoOperationalDataIcon} from './no-operational-data-icon';

type ChartLegendProps = {
    currentYear: FinancialYear;
    previousYear?: FinancialYear;
    left: number;
};

export function Legend({currentYear, previousYear, left}: ChartLegendProps) {
    return (
        <div className="flex flex-row items-center" style={{marginLeft: left}}>
            {previousYear && (
                <LegendItem
                    icon={<Square colour={colors.previousYear} />}
                    label={`FY ${formatYear(previousYear)}`}
                />
            )}
            <LegendItem
                icon={<Square colour={colors.currentYear} />}
                label={`FY ${formatYear(currentYear)}`}
            />
            <LegendItem
                icon={<NoOperationalDataIcon fontSize="medium" bgSize="extra-small" />}
                label="Missing operational data"
            />
        </div>
    );
}

function Square({colour}: {colour: string}) {
    return <div className="w-6 h-6 rounded" style={{backgroundColor: colour}} />;
}

function LegendItem({icon, label}: {icon: JSX.Element; label: string}) {
    return (
        <>
            {icon}
            <div className="ml-3 mr-12">
                <DetailText>{label}</DetailText>
            </div>
        </>
    );
}
