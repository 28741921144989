import React from 'react';

import {DetailText, Heading5, HStack, VStack} from '@sphericsio/design-system';

import {moneyAsValue, MoneyDisplay} from '../money';
import {SubscriptionProduct} from './products';
import {Money} from '../../graphql/scalars';
import {PromotionDetails} from './promo-code-input';

function NoValueDisplay() {
    return <DetailText colour="secondary">-</DetailText>;
}

type MoneyDisplayWithIntervalProps = {amount: Money; isMonthly: boolean};
function MoneyDisplayWithInterval({amount, isMonthly}: MoneyDisplayWithIntervalProps) {
    return (
        <>
            <MoneyDisplay money={amount} /> /{isMonthly ? 'mo' : 'yr'}
        </>
    );
}

type VatBreakdownProps = {
    selectedProduct?: SubscriptionProduct;
    promotion?: PromotionDetails;
};
export function VatBreakdown({selectedProduct, promotion}: VatBreakdownProps) {
    const isDiscounted =
        promotion != null &&
        selectedProduct != null &&
        (promotion.applies_to_products == null ||
            promotion.applies_to_products.includes(selectedProduct.productId));

    const isMonthly = React.useMemo(
        () => selectedProduct?.recurringPeriod === 'month' ?? false,
        [selectedProduct],
    );
    const currency = React.useMemo(() => selectedProduct?.amount[0], [selectedProduct]);

    const numberAsMoney = (amount: number) => {
        const [wholeAmount, decimalAmount] = amount.toString().split('.');
        return [currency, wholeAmount, decimalAmount ?? '00'] as Money;
    };

    const values = React.useMemo(() => {
        if (selectedProduct) {
            const amountAsNumber = moneyAsValue(selectedProduct.amount);
            const vat = amountAsNumber * 0.2;
            const subTotal = amountAsNumber + vat;

            return {
                amount: amountAsNumber,
                vat,
                subTotal,
            };
        }
    }, [selectedProduct]);

    const discount = React.useMemo(() => {
        if (!isDiscounted || !promotion?.percent_off || !values?.subTotal) {
            return;
        }

        return values?.subTotal * (promotion.percent_off / 100);
    }, [isDiscounted, promotion, values]);

    const total = React.useMemo(() => {
        if (!values) {
            return;
        }

        if (!discount) {
            return values.subTotal;
        }

        return values.subTotal - discount;
    }, [discount, values]);

    return (
        <VStack full condensed>
            <HStack full>
                <Heading5 colour="secondary">Subtotal</Heading5>
                {values ? (
                    <DetailText colour="secondary">
                        <MoneyDisplayWithInterval
                            amount={numberAsMoney(values.amount)}
                            isMonthly={isMonthly}
                        />
                    </DetailText>
                ) : (
                    <NoValueDisplay />
                )}
            </HStack>
            <HStack full>
                <Heading5 colour="secondary">VAT (20%)</Heading5>
                {values ? (
                    <DetailText colour="secondary">
                        <MoneyDisplayWithInterval
                            amount={numberAsMoney(values.vat)}
                            isMonthly={isMonthly}
                        />
                    </DetailText>
                ) : (
                    <NoValueDisplay />
                )}
            </HStack>
            {discount && (
                <HStack full>
                    <Heading5 colour="error">Discount applied</Heading5>
                    <DetailText colour="error">
                        -<MoneyDisplay money={numberAsMoney(discount)} />
                    </DetailText>
                </HStack>
            )}

            <HStack full>
                <Heading5>Billed now</Heading5>
                {total ? (
                    <Heading5>
                        <MoneyDisplayWithInterval
                            amount={numberAsMoney(total)}
                            isMonthly={isMonthly}
                        />
                    </Heading5>
                ) : (
                    <NoValueDisplay />
                )}
            </HStack>
        </VStack>
    );
}
