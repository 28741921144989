import React from 'react';

import {
    CircleCross,
    CircleIcon,
    CircleTick,
    LoadingSpinner,
    textColorClassnames,
} from '@sphericsio/design-system';

import {useBackgroundExecutionStatus} from '../../../hooks/background-execution';
import {ExecutionStatus} from './queue';

export function useProcessingProgress(executionId: string) {
    const {error, data, finished} = useBackgroundExecutionStatus(executionId, {
        pollInterval: 5000,
        maxNumberOfTries: 12,
    });

    const failed = data?.fetchBackgroundExecution?.status === 'failed';
    const complete = data?.fetchBackgroundExecution?.completed_at !== null;

    return {finished, complete, failed, error} as const;
}

export function ProcessingStatusIcon({
    executionId,
    onStatusChange,
}: {
    executionId: string;
    onStatusChange: (status: ExecutionStatus) => void;
}) {
    const {finished, complete, failed, error} = useProcessingProgress(executionId);

    React.useEffect(() => {
        if (finished && !failed) {
            complete ? onStatusChange('complete') : onStatusChange('ongoing');
        }
    }, [finished, complete]);

    React.useEffect(() => {
        if (error || failed) {
            onStatusChange('failed');
        }
    }, [error, failed]);

    if (failed || error) {
        return (
            <div className={`w-6 h-6 ${textColorClassnames('error')}`}>
                <CircleCross />
            </div>
        );
    }

    if (finished) {
        return complete ? (
            <div className={`w-6 h-6 ${textColorClassnames('success')}`}>
                <CircleTick invert />
            </div>
        ) : (
            <div className={`w-6 h-6 ${textColorClassnames('minor-action')}`}>
                <CircleIcon />
            </div>
        );
    }

    return <LoadingSpinner />;
}
