import React from 'react';
import {ApolloError, useQuery} from '@apollo/client';

import {GetCategorisationHierarchyDocument} from './graphql';
import {convertToUserCategorisationHierarchy, UserCategorisationHierarchyItem} from './hierarchy';

type CategorisationContextValue = {
    hierarchy?: UserCategorisationHierarchyItem[];
    loading: boolean;
    error?: ApolloError;
    categorisationMenuOpen: boolean;
    setCategorisationMenuOpen: (menuOpen: boolean) => void;
};

export const CategorisationContext = React.createContext<CategorisationContextValue>({
    loading: false,
    categorisationMenuOpen: false,
    setCategorisationMenuOpen: () => {
        // placeholder
    },
});

type CategorisationContextProviderProps = {
    children: React.ReactNode;
};
export function CategorisationContextProvider({children}: CategorisationContextProviderProps) {
    const {loading, error, data} = useQuery(GetCategorisationHierarchyDocument);
    const [categorisationMenuOpen, setCategorisationMenuOpen] = React.useState<boolean>(false);

    const hierarchy = React.useMemo(() => {
        if (data?.getCategorisationHierarchy) {
            return convertToUserCategorisationHierarchy(data.getCategorisationHierarchy);
        }
    }, [data]);

    return (
        <CategorisationContext.Provider
            value={{
                hierarchy,
                loading,
                error,
                categorisationMenuOpen,
                setCategorisationMenuOpen,
            }}
        >
            {children}
        </CategorisationContext.Provider>
    );
}
