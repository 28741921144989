import React from 'react';

import {ProfileGHGContextProvider} from './context';
import {ProfileGHGWizard} from './wizard';

export function ProfileGHG() {
    return (
        <ProfileGHGContextProvider>
            <ProfileGHGWizard />
        </ProfileGHGContextProvider>
    );
}
