import React from 'react';
import {ApolloError} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import {ReportingPeriodContext, reportingPeriodPlaceholder} from '../../reporting-period';
import {CompanyReportingPeriod, ProfileVehicles} from '../../../../graphql/generated/graphql';
import {EditProfileConfirmedSpend} from '../../types';
import {useModifySection} from '../../use-modify-section';
import {ModifyProfileVehiclesDocument} from '../graphql';

type EditProfileVehiclesWizardStep = 'start' | 'transactions' | 'fuel_quantity' | 'owned_vehicles';

type EditProfileVehiclesState = {
    transactions: Record<string, boolean>;
    fuelQuantity?: number;
    currentStep: EditProfileVehiclesWizardStep;
    shouldSubmit: boolean;
};

type EditProfileVehiclesAction =
    | {type: 'start'}
    | {
          type: 'identifyTransactions';
          payload: Record<string, boolean>;
      }
    | {
          type: 'setFuelQuantity';
          payload?: number;
      };

function useVehiclesReducer() {
    const initialState: EditProfileVehiclesState = {
        transactions: {},
        currentStep: 'transactions',
        shouldSubmit: false,
    };

    return React.useReducer(
        (
            state: EditProfileVehiclesState,
            action: EditProfileVehiclesAction,
        ): EditProfileVehiclesState => {
            switch (action.type) {
                case 'start':
                    return {
                        ...state,
                        currentStep: 'transactions',
                    };

                case 'identifyTransactions':
                    return {
                        ...state,
                        transactions: action.payload,
                        currentStep: 'fuel_quantity',
                    };

                case 'setFuelQuantity': {
                    return {
                        ...state,
                        fuelQuantity: action.payload,
                        shouldSubmit: true,
                    };
                }
            }
        },
        initialState,
    );
}

type EditProfileVehiclesContextValue = {
    dispatch: (action: EditProfileVehiclesAction) => void;
    currentStep: EditProfileVehiclesWizardStep;
    reportingPeriod: CompanyReportingPeriod;
    answers: EditProfileVehicles;
    submitting?: boolean;
    error?: ApolloError;
};

export const EditProfileVehiclesContext = React.createContext<EditProfileVehiclesContextValue>({
    dispatch: () => {
        // placeholder
    },
    answers: {
        id: '',
        fuel_quantity: 0,
        confirmedSpend: [],
    },
    reportingPeriod: reportingPeriodPlaceholder,
    currentStep: 'start',
});

type EditProfileVehicles = Pick<ProfileVehicles, 'id' | 'fuel_quantity'> & {
    confirmedSpend: EditProfileConfirmedSpend[];
};

type EditProfileVehiclesContextProviderProps = {
    children: React.ReactNode;
    answers: EditProfileVehicles;
};
export function EditProfileVehiclesContextProvider({
    children,
    answers,
}: EditProfileVehiclesContextProviderProps) {
    const reportingPeriod = React.useContext(ReportingPeriodContext);
    const [state, dispatch] = useVehiclesReducer();
    const navigate = useNavigate();
    const [submit, {loading, error, finished}] = useModifySection(ModifyProfileVehiclesDocument, [
        'GetProfileVehicle',
    ]);
    const reportingPeriodId = reportingPeriod.id;

    React.useEffect(() => {
        if (state.shouldSubmit) {
            const fuelSpendTransactionIds = Object.entries(state.transactions).map(
                ([id, isRelatedSpend]) => ({id, isRelatedSpend}),
            );
            submit({
                variables: {
                    params: {
                        companyReportingPeriodId: reportingPeriodId,
                        fuelSpendTransactionIds,
                        fuelQuantity: state.fuelQuantity,
                    },
                },
            });
        }
    }, [submit, reportingPeriodId, state]);

    React.useEffect(() => {
        if (finished) {
            navigate('../summary');
        }
    }, [finished]);

    return (
        <EditProfileVehiclesContext.Provider
            value={{
                dispatch,
                currentStep: state.currentStep,
                reportingPeriod,
                answers,
                submitting: loading,
                error: error,
            }}
        >
            {children}
        </EditProfileVehiclesContext.Provider>
    );
}
