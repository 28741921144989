import React from 'react';
import {ApolloError} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import {CompanyReportingPeriod, ProfileGhg} from '../../../../graphql/generated/graphql';
import {ReportingPeriodContext, reportingPeriodPlaceholder} from '../../reporting-period';
import {EditProfileConfirmedSpend} from '../../types';
import {useModifySection} from '../../use-modify-section';
import {ModifyProfileGHGDocument} from '../graphql';

type EditProfileGHGWizardStep =
    | 'suggested'
    | 'own'
    | 'topped_up'
    | 'gas_quantities'
    | 'spend_split';

type GHGGasQuantityEntry = {
    gasType: string;
    kg: number;
};

type EditProfileGHGState = {
    transactions: Record<string, boolean>;
    transactionSpend?: number;
    ownedAppliances?: boolean;
    toppedUp?: boolean;
    gasQuantites: GHGGasQuantityEntry[];
    spendSplitPercent?: number;
    currentStep: EditProfileGHGWizardStep;
    shouldSubmit: boolean;
};

type EditProfileGHGAction =
    | {type: 'start'}
    | {
          type: 'identifyTransactions';
          payload: {transactions: Record<string, boolean>; totalSpend: number};
      }
    | {type: 'skipIdentifyTransactions'}
    | {type: 'setOwnedAppliancesInPeriod'; payload: boolean}
    | {type: 'setToppedUpInPeriod'; payload: boolean}
    | {type: 'setGasQuantities'; payload: GHGGasQuantityEntry[]}
    | {type: 'skipGasQuantites'}
    | {type: 'setSpendSplit'; payload: number};

function useGHGReducer() {
    function hasIdentifiedTransactions(state: EditProfileGHGState) {
        const identifiedTransactionsCount = Object.values(state.transactions).filter(
            (value) => value,
        ).length;

        return identifiedTransactionsCount > 0;
    }

    const initialState: EditProfileGHGState = {
        transactions: {},
        gasQuantites: [],
        shouldSubmit: false,
        currentStep: 'suggested',
    };

    return React.useReducer(
        (state: EditProfileGHGState, action: EditProfileGHGAction): EditProfileGHGState => {
            switch (action.type) {
                case 'start':
                    return {
                        ...state,
                        currentStep: 'suggested',
                    };

                case 'identifyTransactions':
                    return {
                        ...state,
                        transactions: action.payload.transactions,
                        transactionSpend: action.payload.totalSpend,
                        currentStep: 'topped_up',
                    };

                case 'skipIdentifyTransactions':
                    return {
                        ...state,
                        transactions: {},
                        transactionSpend: undefined,
                        currentStep: 'own',
                    };

                case 'setOwnedAppliancesInPeriod':
                    return {
                        ...state,
                        ownedAppliances: action.payload,
                        shouldSubmit: !action.payload,
                        currentStep: action.payload ? 'topped_up' : state.currentStep,
                    };

                case 'setToppedUpInPeriod':
                    return {
                        ...state,
                        toppedUp: action.payload,
                        shouldSubmit: !action.payload,
                        currentStep: action.payload ? 'gas_quantities' : state.currentStep,
                    };

                case 'setGasQuantities':
                    return {
                        ...state,
                        gasQuantites: action.payload,
                        shouldSubmit: true,
                    };

                case 'skipGasQuantites':
                    if (hasIdentifiedTransactions(state)) {
                        return {
                            ...state,
                            currentStep: 'spend_split',
                        };
                    } else {
                        return state;
                    }

                case 'setSpendSplit':
                    return {
                        ...state,
                        spendSplitPercent: action.payload,
                        shouldSubmit: true,
                    };
            }
        },
        initialState,
    );
}

export type EditProfileGHGContextValue = {
    dispatch: (action: EditProfileGHGAction) => void;
    getTransactionSpend: () => number;
    currentStep: EditProfileGHGWizardStep;
    reportingPeriod: CompanyReportingPeriod;
    answers: EditProfileGHG;
    submitting?: boolean;
    error?: ApolloError;
};

export const EditProfileGHGContext = React.createContext<EditProfileGHGContextValue>({
    dispatch: () => {
        // placeholder
    },
    answers: {
        confirmedSpend: [],
        gasEntries: [],
    },
    getTransactionSpend: () => 0,
    reportingPeriod: reportingPeriodPlaceholder,
    currentStep: 'suggested',
});

type EditProfileGHG = Pick<
    ProfileGhg,
    'gasEntries' | 'owned_appliences' | 'spend_split_percent' | 'topped_up'
> & {
    confirmedSpend: EditProfileConfirmedSpend[];
};

type ProfileGHGContextProviderProps = {
    children: React.ReactNode;
    answers: EditProfileGHG;
};
export function EditProfileGHGContextProvider({children, answers}: ProfileGHGContextProviderProps) {
    const reportingPeriod = React.useContext(ReportingPeriodContext);
    const [state, dispatch] = useGHGReducer();
    const navigate = useNavigate();
    const [submit, {loading, error, finished}] = useModifySection(ModifyProfileGHGDocument, [
        'GetProfileGHG',
    ]);
    const reportingPeriodId = reportingPeriod.id;

    React.useEffect(() => {
        if (state.shouldSubmit) {
            const ghgTransactionIds = Object.entries(state.transactions).map(
                ([id, isRelatedSpend]) => ({
                    id,
                    isRelatedSpend,
                }),
            );
            submit({
                variables: {
                    params: {
                        companyReportingPeriodId: reportingPeriodId,
                        ghgTransactionIds,
                        gasQuantities: state.gasQuantites,
                        ownedAppliences: state.ownedAppliances,
                        toppedUp: state.toppedUp,
                        spendSplitPercent: state.spendSplitPercent,
                    },
                },
            });
        }
    }, [reportingPeriodId, state]);

    React.useEffect(() => {
        if (finished) {
            navigate('../summary');
        }
    }, [finished]);

    return (
        <EditProfileGHGContext.Provider
            value={{
                dispatch,
                getTransactionSpend: () => {
                    return state.transactionSpend ?? 0;
                },
                answers,
                currentStep: state.currentStep,
                reportingPeriod,
                submitting: loading,
                error,
            }}
        >
            {children}
        </EditProfileGHGContext.Provider>
    );
}
