import React from 'react';

import {
    DataTableRef,
    relayConnectionQuery,
    RelayConnectionVariables,
} from '@sphericsio/mvp-ui-common';
import {DetailText} from '@sphericsio/design-system';

import {graphql} from '../../graphql/generated';
import {TransactionDateAndAmonut} from '../financial-transactions/transaction-details';
import {DataTable} from '../table';
import {GetTransactionsByFinancialAccountQuery} from '../../graphql/generated/graphql';

export const GetTransactionsByFinancialAccount = graphql(/* GraphQL */ `
    query GetTransactionsByFinancialAccount(
        $first: Int
        $after: String
        $last: Int
        $before: String
        $orderDirection: SortDirection!
        $orderBy: TransactionsOrder!
        $id: String!
        $showExcluded: Boolean
    ) {
        getTransactionsByFinancialAccount(
            first: $first
            after: $after
            last: $last
            before: $before
            orderDirection: $orderDirection
            orderBy: $orderBy
            id: $id
            showExcluded: $showExcluded
        ) {
            pageInfo {
                hasNextPage
                endCursor
                hasPreviousPage
                startCursor
            }
            edges {
                node {
                    id
                    description
                    transaction_date
                    amount
                    merchant {
                        name
                    }
                }
            }
        }
    }
`);

type FinancialAccountTransactionsProps = {
    accountId: string;
};

export function FinancialAccountTransactionsList({accountId}: FinancialAccountTransactionsProps) {
    const txnTableRef = React.useRef<DataTableRef>(null);

    const queryConfig = relayConnectionQuery(
        GetTransactionsByFinancialAccount,
        (data: GetTransactionsByFinancialAccountQuery) => data.getTransactionsByFinancialAccount,
        {
            id: accountId,
            orderDirection: 'desc',
            orderBy: 'transaction_date',
            showExcluded: true,
        } as Partial<RelayConnectionVariables>,
        'network-only',
    );

    return (
        <DataTable
            ref={txnTableRef}
            queryConfig={queryConfig}
            sortableFields={['transaction_date']}
            initialSortDirection="desc"
            condensed
            header={false}
            pageSize={3}
            columns={[
                {
                    label: 'Transaction Details',
                    // eslint-disable-next-line react/display-name
                    render: (txn) => (
                        <div className="w-32 text-left">
                            <DetailText colour="secondary">
                                <span>{txn.merchant?.name}</span>
                                <TransactionDateAndAmonut
                                    date={txn.transaction_date}
                                    amount={txn.amount}
                                />
                            </DetailText>
                        </div>
                    ),
                },
                {
                    label: 'Description',
                    key: 'description',
                    // eslint-disable-next-line react/display-name
                    renderValue: (description) => (
                        <div className="w-60 flex flex-1">
                            <DetailText colour="secondary">{description}</DetailText>
                        </div>
                    ),
                },
            ]}
        />
    );
}
