import React from 'react';
import {Navigate, useNavigate} from 'react-router-dom';

import {LoadingSpinner} from '@sphericsio/design-system';

import {UploadContext} from './context';
import {Uploader} from './uploader';
import {UploadProcessing} from './processing';
import {useUser} from '../user-context';
import {UploadWarning} from './warning';
import {HSplitLayout} from '../layout/h-split';
import {PosterLayout} from '../onboarding/poster-layout';
import {CompanyDefaults} from './company-defaults';
import {UploadInstructions} from './instructions';
import {SuccessMessage} from './success';
import {CheckBackLater} from './processing/check-back-later';
import {PosterJoinTheMovement} from '../onboarding/poster-join-the-movement';
import {UploadErrors} from './uploader/upload-errors';

function Loading() {
    const {flow} = React.useContext(UploadContext);

    if (flow === 'upload') {
        return <LoadingSpinner />;
    } else {
        return (
            <HSplitLayout cta="logout">
                <div className="flex flex-1 justify-center items-center">
                    <LoadingSpinner />
                </div>
                <PosterLayout bgImage={{type: 'onboarding'}}>
                    <PosterJoinTheMovement />
                </PosterLayout>
            </HSplitLayout>
        );
    }
}
export function UploadWizard() {
    const {currentStep, hasTurnoverData, flow} = React.useContext(UploadContext);
    const user = useUser();
    const navigate = useNavigate();

    if (hasTurnoverData && flow === 'onboarding') {
        navigate('/');
    }

    switch (currentStep) {
        case 'instructions':
            return <UploadInstructions />;

        case 'companyDefaults':
            return <CompanyDefaults />;

        case 'upload':
            return <Uploader />;

        case 'processing':
            return <UploadProcessing />;

        case 'checkBackLater':
            return <CheckBackLater />;

        case 'showUploadErrors':
            return <UploadErrors />;

        case 'warning':
            return <UploadWarning />;

        case 'companyOnboarding':
            return (
                <Navigate
                    to={user.isCompanyOnboarded ? '/dashboard' : '/onboarding/company-details'}
                />
            );

        case 'showSuccessScreen':
            return <SuccessMessage />;

        case 'showLoading':
        default:
            return <Loading />;
    }
}
