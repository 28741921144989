import React from 'react';

import {ProfileEnergyIntro} from './intro';
import {ProfileEnergyContext} from './context';
import {ProfileEnergySuggested} from './suggested';
import {ProfileEnergyConsumption} from './consumption';
import {ProfileEnergyComplete} from './complete';
import {ProfileEnergyBuildingOccupancy} from './building-occupancy';
import {ProfileReportingPeriodOpen} from '../reporting-period-open';
import {ConstrainWidthContainer} from '../../constrain-width-container';
import {SuggestedEnergyTransactionActionPayload} from './types';

export function ProfileEnergyWizard() {
    const {currentStep, reportingPeriod, dispatch, error, submitting} =
        React.useContext(ProfileEnergyContext);

    return (
        <div>
            <ConstrainWidthContainer>
                {currentStep === 'consumption' && (
                    <ProfileEnergyConsumption
                        onNext={(payload: {electricityKwh: number; gasKwh: number}) => {
                            dispatch({
                                type: 'setEnergyConsumptions',
                                payload,
                            });
                        }}
                        onSkip={() => dispatch({type: 'skipEnergyConsumption'})}
                        error={error}
                        submitting={submitting}
                        reportingPeriod={reportingPeriod}
                    />
                )}
                {currentStep === 'building_occupancy' && (
                    <ProfileEnergyBuildingOccupancy
                        submitError={error}
                        submitting={submitting}
                        reportingPeriod={reportingPeriod}
                        onNext={(payload: Record<string, number>) =>
                            dispatch({type: 'setBuildingOccupancy', payload})
                        }
                    />
                )}
                {currentStep === 'period_open' && (
                    <ProfileReportingPeriodOpen reportingPeriodEnd={reportingPeriod.period_end} />
                )}
            </ConstrainWidthContainer>
            {currentStep === 'intro' && <ProfileEnergyIntro />}
            {currentStep === 'suggested' && (
                <ProfileEnergySuggested
                    reportingPeriod={reportingPeriod}
                    onNext={(payload: SuggestedEnergyTransactionActionPayload) =>
                        dispatch({type: 'identifyTransactions', payload})
                    }
                />
            )}
            {currentStep === 'completed' && <ProfileEnergyComplete />}
        </div>
    );
}
