import React from 'react';

import {ComparisonPanel} from './comparison-panel';

type IndustryComparisonPanelProps = {
    title: string;
    value?: number;
    comparisonValue?: number;
    intercomTarget?: string;
};
export function CarbonImpactComparisonPanel({
    title,
    value,
    comparisonValue,
    intercomTarget,
}: IndustryComparisonPanelProps) {
    const tooltip = {
        text: 'Carbon dioxide equivalent (CO2e) is the metric used to compare the global warming potential of different greenhouse gases (GHGs). It works by converting other gases into the equivalent amount of carbon dioxide.',
        linkText: 'How to interpret your carbon footprint',
        link: 'https://help.earth.sage.com/en/articles/5519315-what-is-a-carbon-dioxide-equivalent-co2e-and-why-is-this-the-best-representation-of-my-total-greenhouse-gas-emissions',
    };
    const comparison = comparisonValue === 0 ? undefined : comparisonValue;
    let content;

    if (comparison == null) {
        content = 'You do not have enough data in the previous period to show a comparison.';
    } else if (value != null) {
        const percentDifference = (value / comparison) * 100;
        const higher = percentDifference > 100;
        content = `This is ${Math.round(
            higher ? percentDifference - 100 : 100 - percentDifference,
        )}% ${higher ? 'higher' : 'lower'} than your last period.`;
    }
    return (
        <ComparisonPanel
            title={title}
            value={value}
            comparisonValue={comparison}
            type="carbon_emissions"
            tooltip={tooltip}
            intercomTarget={intercomTarget}
        >
            {content}
        </ComparisonPanel>
    );
}
