import {useMutation} from '@apollo/client';
import React from 'react';

import {FileUploadRequestDocument, MarkUploadCompleteDocument, useUploadFile} from './requests';
import {FileUploadState, useFileUploadReducer} from './state';

export function useFileUploader() {
    const [requestUpload, {loading: requesting, error: requestError, data: uploadRequest}] =
        useMutation(FileUploadRequestDocument);
    const [
        uploadFile,
        {
            loading: uploading,
            error: uploadError,
            uploadPercent,
            complete: uploadComplete,
            success: uploadSuccess,
        },
    ] = useUploadFile();
    const [
        markUploadComplete,
        {loading: completing, error: completeError, data: markUploadCompletedResponse},
    ] = useMutation(MarkUploadCompleteDocument);

    const [state, dispatch] = useFileUploadReducer();

    const perform = React.useCallback((uploadRequest: FileUploadState) => {
        dispatch({type: 'queueFile', payload: uploadRequest});
    }, []);

    React.useEffect(() => {
        if (state.status === 'queued') {
            const {file, uploadRequestType, exportSource} = state;
            dispatch({type: 'uploadRequested'});
            requestUpload({
                variables: {
                    filename: file.name,
                    mimeType: file.type,
                    uploadType: uploadRequestType,
                    exportSource,
                },
            });
        }
    }, [state, requestUpload, dispatch]);

    React.useEffect(() => {
        if (uploadRequest != null) {
            const {uploadUrl, id} = uploadRequest.requestFileUpload;
            dispatch({type: 'uploadStarted', payload: {uploadUrl, id}});
            uploadFile(uploadUrl, state.file);
        } else if (requestError) {
            dispatch({type: 'uploadRequestFailure', payload: requestError});
        }
    }, [uploadRequest, requestError, dispatch]);

    React.useEffect(() => {
        if (state.status !== 'uploading') {
            return;
        }
        if (uploadComplete && uploadSuccess && state.id) {
            dispatch({type: 'uploadSuccess'});
            markUploadComplete({variables: {id: state.id}});
        } else if (uploadComplete && !uploadSuccess) {
            dispatch({type: 'uploadFailure', payload: uploadError});
        } else if (uploadPercent != null) {
            dispatch({type: 'uploadProgressed', payload: uploadPercent});
        }
    }, [state, uploadPercent, uploadComplete, dispatch, markUploadComplete]);

    React.useEffect(() => {
        if (markUploadCompletedResponse != null) {
            dispatch({type: 'markUploadCompleteSuccess'});
        }
        if (completeError) {
            dispatch({type: 'markUploadCompleteFailure', payload: completeError});
        }
    }, [markUploadCompletedResponse, completeError, dispatch]);

    const loading = requesting || uploading || completing;
    const error = requestError || completeError || uploadError;

    return [perform, {loading, error, data: state}] as const;
}
