import React from 'react';
import {useQuery} from '@apollo/client';
import {format, parseISO} from 'date-fns';

import {intercomTargetProps, SelectInput, SelectListItem} from '@sphericsio/design-system';

import {graphql} from '../graphql/generated';
import {getStartDateFromEndDate} from '../services/utils/date';
import {ErrorCard} from './error-card';
import {formatDateString} from './date-display';

const GetLatestTransactionDocument = graphql(/* GraphQL */ `
    query GetLatestTransaction {
        getLatestTransaction {
            transaction_date
        }
    }
`);

const DATE_FORMAT = 'yyyy-MM-dd';
function buildSelectItems(timeRange: TimeRange) {
    if (timeRange == null) {
        return [];
    }

    const {end} = timeRange;
    const yearStart = getStartDateFromEndDate(end, 'year');
    const quarterStart = getStartDateFromEndDate(end, 'quarter');
    const endLabel = formatDateString(format(end, DATE_FORMAT), false);
    return [
        {
            id: 'year',
            start: yearStart,
            end,
            label: `${formatDateString(format(yearStart, DATE_FORMAT), false)} - ${endLabel}`,
        },
        {
            id: 'quarter',
            start: quarterStart,
            end,
            label: `${formatDateString(format(quarterStart, DATE_FORMAT), false)} - ${endLabel}`,
        },
    ];
}

type TimeRange = {
    start: Date;
    end: Date;
};
export type PeriodSelectItem = SelectListItem & TimeRange;
type PeriodSelectProps = {
    period?: string;
    label?: string;
    onSelect?: (selectedPeriod: PeriodSelectItem) => void;
    intercomTarget?: string;
};
export function PeriodSelect({period, label, onSelect, intercomTarget}: PeriodSelectProps) {
    const {loading, error, data} = useQuery(GetLatestTransactionDocument, {
        fetchPolicy: 'network-only',
    });
    const [selected, setSelected] = React.useState<SelectListItem>();
    const timeRange = React.useMemo(() => {
        if (data == null) {
            return undefined;
        }
        const end = parseISO(data.getLatestTransaction.transaction_date);
        const start = getStartDateFromEndDate(end, period);
        return {start, end};
    }, [data, period]);

    const selectItems = React.useMemo(() => {
        if (timeRange == null) {
            return [];
        }
        return buildSelectItems(timeRange);
    }, [timeRange]);

    React.useEffect(() => {
        if (period && selectItems.length && !selected) {
            const selectedPeriod = selectItems.find(({id}) => id == period);
            setSelected(selectedPeriod);

            if (onSelect && selectedPeriod) {
                onSelect(selectedPeriod);
            }
        }
    }, [period, selectItems, selected]);

    const handleSelected = (selected: SelectListItem) => {
        const selectedPeriod = selectItems.find(({id}) => id == selected.id);
        setSelected(selectedPeriod);

        if (onSelect && selectedPeriod) {
            onSelect(selectedPeriod);
        }
    };

    if (error) {
        return <ErrorCard />;
    }

    return (
        <div className="w-64" {...intercomTargetProps(intercomTarget)}>
            <SelectInput
                label={label ?? 'period-select'}
                name="period"
                onChange={(period) => handleSelected(period)}
                items={selectItems}
                value={selected}
                isLoading={loading}
            />
        </div>
    );
}
