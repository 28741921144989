import React from 'react';
import {useQuery} from '@apollo/client';

import {DetailText, HStack, LoadingSpinner, VStack} from '@sphericsio/design-system';

import {useFragment} from '../../../graphql/generated';
import {ReportingPeriodContext} from '../reporting-period';
import {ErrorCard} from '../../error-card';
import {MoneyDisplay} from '../../money';
import {GetProfileVehicleDocument} from './graphql';
import {ProfileVehiclesFragmentDocument} from './fragments';

export function VehicleProfileAnswers() {
    const reportingPeriod = React.useContext(ReportingPeriodContext);
    const {
        loading,
        error,
        data: res,
    } = useQuery(GetProfileVehicleDocument, {
        variables: {companyReportingPeriodId: reportingPeriod.id},
    });

    if (error) {
        return <ErrorCard />;
    }

    if (loading) {
        return <LoadingSpinner />;
    }

    const data = useFragment(ProfileVehiclesFragmentDocument, res?.getProfileVehicle);

    return (
        <VStack seperator>
            <div className="py-5">
                <VStack>
                    <DetailText bold>Fuel consumption</DetailText>
                    <DetailText>{data?.fuel_quantity || `-`} Litres</DetailText>
                </VStack>
            </div>
            <div className="py-5">
                <DetailText bold>Confirmed vehicle transactions</DetailText>
                {data?.confirmedSpend.length === 0 && (
                    <div className="py-5">
                        <DetailText>No confirmed transactions for this section.</DetailText>
                    </div>
                )}
                {data?.confirmedSpend.map((s) => {
                    return (
                        <div key={s.id} className="py-5">
                            <HStack>
                                <div className="w-40">
                                    <DetailText bold>{s.merchant_name}</DetailText>
                                </div>
                                <DetailText>{s.transactions.length} transactions</DetailText>
                                <div className="px-3">
                                    {s.amount && (
                                        <DetailText>
                                            <MoneyDisplay money={s.amount} />
                                        </DetailText>
                                    )}
                                </div>
                            </HStack>
                        </div>
                    );
                })}
            </div>
        </VStack>
    );
}
