import * as React from 'react';
import {useQuery} from '@apollo/client';

import {ErrorCard} from '../../../error-card';
import {Loading} from '../../../loading';
import {ReportingPeriodContext} from '../../reporting-period';
import {EditProfilePeopleContextProvider} from './context';
import {EditProfilePeopleWizard} from './wizard';
import {GetCompanyAddressesDocument} from '../../places/graphql';
import {GetProfilePeopleEntryDocument} from '../graphql';
import {ProfilePeopleEntryFragmentDocument} from '../fragments';
import {useFragment} from '../../../../graphql/generated';

export function OperationsEditPeopleSection() {
    const reportingPeriod = React.useContext(ReportingPeriodContext);
    const {loading, error, data} = useQuery(GetCompanyAddressesDocument);
    const {
        loading: answersLoading,
        error: answersError,
        data: res,
    } = useQuery(GetProfilePeopleEntryDocument, {
        variables: {companyReportingPeriodId: reportingPeriod.id},
    });

    const answers = useFragment(ProfilePeopleEntryFragmentDocument, res?.getProfilePeopleEntry);

    return (
        <div>
            {(loading || answersLoading) && <Loading />}
            {(error || answersError) && <ErrorCard />}
            {data && answers && (
                <EditProfilePeopleContextProvider company={data.currentCompany} answers={answers}>
                    <EditProfilePeopleWizard />
                </EditProfilePeopleContextProvider>
            )}
        </div>
    );
}
