import React from 'react';
import {ApolloProvider} from '@apollo/client';
import {IntercomProvider} from 'react-use-intercom';
import {MatomoProvider} from '@datapunt/matomo-tracker-react';
import {BrowserRouter} from 'react-router-dom';

import {System} from '@sphericsio/design-system';

import {Routes} from './routes';
import {client} from './services/graphql';
import config from '../config';
import {CurrentUserQueryDataProvider} from './components/user-context';
import {ErrorBoundary, RecordBugsnagUser} from './util/bugsnag';
import {matomo} from './services/matomo';
import {BootIntercom} from './components/intercom';
import './index.css';
import {RewardfulProvider} from './components/rewardful';
import {FullScreenError} from './components/full-screen';

// MatomoProvider assumes a legacy version of `React.FC` which automatically adds the children props.
// The latest React.FC doesn't do that anymore however, so we'll need to patch it.
const PatchedMatomoProvider = MatomoProvider as React.FC<
    React.ComponentProps<typeof MatomoProvider> & {children: React.ReactNode}
>;

const App: React.FC = () => {
    return (
        <ErrorBoundary FallbackComponent={() => <FullScreenError />}>
            <PatchedMatomoProvider value={matomo}>
                <ApolloProvider client={client}>
                    <CurrentUserQueryDataProvider>
                        <RecordBugsnagUser />
                        <IntercomProvider appId={config.intercom.appId} autoBoot>
                            <BootIntercom />
                            <RewardfulProvider>
                                <System>
                                    <BrowserRouter>
                                        <Routes />
                                    </BrowserRouter>
                                </System>
                            </RewardfulProvider>
                        </IntercomProvider>
                    </CurrentUserQueryDataProvider>
                </ApolloProvider>
            </PatchedMatomoProvider>
        </ErrorBoundary>
    );
};

export {App};
