import React from 'react';
import {ParentSize} from '@visx/responsive';
import {Group} from '@visx/group';
import {curveMonotoneX as _curve, curveLinear} from '@visx/curve';
import {RectClipPath} from '@visx/clip-path';
import {Axis, AnimatedLineSeries, XYChart, Tooltip, buildChartTheme} from '@visx/xychart';
import {Text} from '@visx/text';
import {format, endOfYear, setYear} from 'date-fns';
import {interpolateNumber} from 'd3-interpolate';

import {DetailText, HStack, Panel, Preset} from '@sphericsio/design-system';

import {TimeseriesPoint} from '../../graphql/scalars';
import {CarbonAmount} from '../carbon-amount';
import {parseDateString} from '../date-display';
import {YAxisEmissions, XAxisYearMonth} from '../graphs/axis';

const theme = buildChartTheme({
    backgroundColor: Preset.theme.extend.colors.yang[100],
    colors: [Preset.theme.extend.colors.graph.totalEmissions],
    gridColor: Preset.theme.extend.colors.yin[65],
    gridColorDark: Preset.theme.extend.colors.yin[65],
    tickLength: 0,
});

function generateRandomSeries() {
    const data: TimeseriesPoint[] = [];
    for (let i = 0; i < 12; i++) {
        data.push([i, Math.random() * 20]);
    }
    return data;
}

function axisInTonnes(data: TimeseriesPoint[]) {
    return Math.max(...data.map((d) => d[1])) >= 1_000_000;
}

type DashboardTotalEmissionsGraphProps = {
    width: number;
    height: number;
    loading: boolean;
    data?: TimeseriesPoint[];
    initialPeriodTargetKgCo2e?: number;
    targetStartDate?: string;
    targetCompletionYear?: number;
};
export function DashboardTotalEmissionsGraph({
    width,
    height,
    data,
    loading,
    initialPeriodTargetKgCo2e,
    targetStartDate,
    targetCompletionYear,
}: DashboardTotalEmissionsGraphProps) {
    const [graphData, setGraphData] = React.useState(data);
    React.useEffect(() => {
        if (loading) {
            setGraphData(generateRandomSeries());
            const intervalId = setInterval(() => setGraphData(generateRandomSeries()), 1000);
            return () => clearInterval(intervalId);
        } else {
            setGraphData(data ?? []);
        }
    }, [loading, setGraphData, data]);

    const useTonnes = React.useMemo<boolean>(() => {
        if (data && data.length > 0) {
            return axisInTonnes(data);
        } else {
            return false;
        }
    }, [data]);

    const targetLineData = React.useMemo<TimeseriesPoint[] | null>(() => {
        if (
            initialPeriodTargetKgCo2e != null &&
            targetStartDate != null &&
            targetCompletionYear != null &&
            data &&
            data.length > 0
        ) {
            const targetStart = parseDateString(targetStartDate).valueOf();
            const targetEnd = endOfYear(setYear(new Date(), targetCompletionYear)).valueOf();
            const range = targetEnd - targetStart;

            const startPointTimestamp = Math.max(data[0][0], targetStart);
            const startPointTimestampValue = (startPointTimestamp - targetStart) / range;
            const endPointTimestamp = Math.min(data[data.length - 1][0], targetEnd);
            const endPointTimestampValue = (endPointTimestamp - targetStart) / range;
            const interpolator = interpolateNumber(initialPeriodTargetKgCo2e, 0);

            return [
                [startPointTimestamp, interpolator(startPointTimestampValue)],
                [endPointTimestamp, interpolator(endPointTimestampValue)],
            ];
        } else {
            return null;
        }
    }, [initialPeriodTargetKgCo2e, targetStartDate, targetCompletionYear, data]);

    const margin = {top: 10, right: 20, bottom: 30, left: 50};

    if (graphData == null || graphData.length === 0) {
        return (
            <XYChart
                theme={theme}
                height={height}
                margin={{top: 10, left: 10, bottom: 10, right: 10}}
                xScale={{type: 'linear'}}
                yScale={{type: 'linear'}}
            >
                <Axis
                    orientation="bottom"
                    stroke={Preset.theme.extend.colors.utility.major[75]}
                    strokeWidth={1}
                    hideTicks={true}
                    tickFormat={() => ''}
                />
                <Axis
                    orientation="left"
                    stroke={Preset.theme.extend.colors.utility.major[75]}
                    strokeWidth={1}
                    hideTicks={true}
                    tickFormat={() => ''}
                />
                <AnimatedLineSeries
                    dataKey="empty"
                    data={[{value: 0}, {value: 1}]}
                    xAccessor={({value}) => value}
                    yAccessor={({value}) => value}
                    fill="transparent"
                    stroke="transparent"
                />
                <Text
                    fontFamily={Preset.theme.fontFamily.body.join(', ')}
                    x={width / 2}
                    y={height / 2}
                >
                    No data
                </Text>
            </XYChart>
        );
    }

    const accessors = {
        xAccessor: (d: TimeseriesPoint) => d[0],
        yAccessor: (d: TimeseriesPoint) => d[1],
    };

    return (
        <XYChart
            theme={theme}
            height={height}
            margin={margin}
            xScale={{type: 'time', clamp: true}}
            yScale={{type: 'linear'}}
        >
            <RectClipPath
                id="graph_clip"
                x={margin.left}
                width={width - margin.left}
                height={height - margin.bottom}
            />

            <XAxisYearMonth numTicks={loading ? 0 : graphData.length} />
            <YAxisEmissions useTonnes={useTonnes} numTicks={loading ? 0 : 5} />

            <Group clipPath={`url(#graph_clip)`}>
                <AnimatedLineSeries
                    dataKey="data"
                    data={graphData}
                    curve={_curve}
                    strokeWidth={loading ? 15 : 4}
                    {...accessors}
                />
                {targetLineData && !loading && (
                    <AnimatedLineSeries
                        enableEvents={false}
                        dataKey="target"
                        data={targetLineData}
                        curve={curveLinear}
                        strokeWidth={1}
                        strokeDasharray={8}
                        stroke={Preset.theme.extend.colors.graph.totalEmissions}
                        {...accessors}
                    />
                )}
            </Group>
            {!loading && (
                <Tooltip<TimeseriesPoint>
                    showDatumGlyph
                    unstyled
                    applyPositionStyle
                    detectBounds
                    glyphStyle={{
                        stroke: theme.colors[0],
                        radius: 8,
                        strokeWidth: 4,
                        fill: Preset.theme.extend.colors.yang[100],
                    }}
                    renderTooltip={({tooltipData}) => {
                        if (tooltipData == null || tooltipData.nearestDatum == null) {
                            return null;
                        }
                        return (
                            <Panel>
                                <DetailText inline={false}>
                                    <HStack>
                                        <DetailText>
                                            {format(
                                                accessors.xAccessor(tooltipData.nearestDatum.datum),
                                                'MMM yy',
                                            )}
                                        </DetailText>
                                        <div>
                                            <CarbonAmount
                                                amount={accessors.yAccessor(
                                                    tooltipData.nearestDatum.datum,
                                                )}
                                            />
                                        </div>
                                    </HStack>
                                </DetailText>
                            </Panel>
                        );
                    }}
                />
            )}
        </XYChart>
    );
}

export function ResponsiveDashboardTotalEmissionsGraph(
    props: Omit<DashboardTotalEmissionsGraphProps, 'width' | 'height'>,
) {
    return (
        <ParentSize>
            {(parent) => (
                <DashboardTotalEmissionsGraph
                    width={parent.width}
                    height={parent.height}
                    {...props}
                />
            )}
        </ParentSize>
    );
}
