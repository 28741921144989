import {graphql} from '../../graphql/generated';

export const GetAvailableFinancialDataPeriodsDocument = graphql(/* GraphQL */ `
    query GetAvailableFinancialDataPeriods {
        getAvailableFinancialDataPeriods {
            start
            end
        }
    }
`);

export const GetCompanyDefaultsDocument = graphql(/* GraphQL */ `
    query GetCompanyDefaults {
        getCompanyDefaultCurrencyCode {
            code
        }
        getCompanyFinancialYearEnd {
            day
            month
        }
    }
`);

export const DataExportSourcesConfigDocument = graphql(/* GraphQL */ `
    query dataExportSourcesConfig {
        dataExportSourcesConfig {
            dataExportSource
            displayName
            supports {
                requestType
                supportedFileTypes {
                    extension
                    mimeType
                }
                options {
                    dateRangeSpecifiedInFile
                }
            }
        }
    }
`);
