import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import {
    DetailText,
    HomeIcon,
    VStack,
    HStack,
    ButtonLabel,
    ChevronUpIcon,
    ChevronDownIcon,
    divideColorClassnames,
    outlineColorClassnames,
    textColorClassnames,
    bgColorClassnames,
} from '@sphericsio/design-system';

import {ReportingPeriodContext} from './reporting-period';
import {Loading} from '../loading';
import {ErrorCard} from '../error-card';
import {carbonAmountFormatter, CO2e} from '../carbon-amount';
import {BulbIcon, CarIcon, CashIcon, FlameIcon} from '../icons';
import {
    OperationsSummaryEmissionsDocument,
    OperationsSummaryEmissionsFragment,
    OperationsSummaryEmissionsFragmentDocument,
} from './graphql';
import {PeopleProfileAnswers} from './people/people-profile-answers';
import {EnergyProfileAnswers} from './energy/energy-profile-answers';
import {GHGProfileAnswers} from './ghg/ghg-profile-answers';
import {VehicleProfileAnswers} from './vehicles/vehicle-profile-answers';
import {useFragment} from '../../graphql/generated';

type Section = {
    id: string;
    label: string;
    emissions: {
        rawAmount: number;
        formattedAmount: string;
        unit: string;
    };
};
type SectionConfig = {
    icon: JSX.Element;
};

const SECTION_CONFIG = {
    people: {
        icon: <HomeIcon />,
    },
    energy: {
        icon: <BulbIcon />,
    },
    ghg: {
        icon: <FlameIcon />,
    },
    vehicles: {
        icon: <CarIcon />,
    },
    supplyChain: {
        icon: <CashIcon />,
    },
} as Record<string, SectionConfig>;

function RowExpansion({id}: {id: string}) {
    switch (id) {
        case 'people':
            return <PeopleProfileAnswers />;
        case 'energy':
            return <EnergyProfileAnswers />;
        case 'vehicles':
            return <VehicleProfileAnswers />;
        case 'ghg':
            return <GHGProfileAnswers />;
        default:
            return <div>Please come back later.</div>;
    }
}

function Row({label, id, emissions}: Section) {
    const navigate = useNavigate();
    const [isExpanded, handleExpand] = React.useState(false);
    const [canExpand, setCanExpand] = React.useState(true);
    const [canEdit, setCanEdit] = React.useState(false);

    React.useEffect(() => {
        if (id) {
            setCanExpand(id !== 'supplyChain');
        }
    }, [id, setCanExpand]);

    React.useEffect(() => {
        if (id) {
            setCanEdit(id !== 'supplyChain');
        }
    }, [id, setCanEdit]);

    const editSection = (id: string) => {
        switch (id) {
            case 'people':
                navigate(`../edit-people`);
                return;
            case 'energy':
                navigate(`../edit-energy`);
                return;
            case 'ghg':
                navigate(`../edit-ghg`);
                return;
            case 'vehicles':
                navigate(`../edit-vehicles`);
                return;
            default:
                return;
        }
    };

    const {icon} = SECTION_CONFIG[id];
    return (
        <VStack testId={id}>
            <div
                className={`divide-y ${divideColorClassnames()} ${bgColorClassnames(
                    'white',
                )} rounded-lg px-5 border ${outlineColorClassnames()}`}
            >
                <div className="flex items-center flex-row justify-between py-5 space-x-5">
                    <div className="w-40">
                        <HStack>
                            <div
                                className={`w-9 h-9 rounded-full flex items-center justify-center ${bgColorClassnames(
                                    'table-header',
                                )}`}
                            >
                                <div className="w-5 h-5 flex self-center">{icon}</div>
                            </div>
                            <div className="flex-1">
                                <DetailText bold>{label}</DetailText>
                            </div>
                        </HStack>
                    </div>

                    <div className="w-60 flex items-center justify-between">
                        <HStack condensed align="start">
                            <div className="w-36 self-center">
                                <DetailText bold>
                                    {`${emissions.formattedAmount} ${emissions.unit}`}
                                </DetailText>
                            </div>
                            {canEdit && (
                                <div
                                    className="cursor-pointer self-center"
                                    onClick={() => editSection(id)}
                                >
                                    <ButtonLabel size="medium" colour="minor-action">
                                        Edit
                                    </ButtonLabel>
                                </div>
                            )}
                            {canExpand && (
                                <div
                                    className={`w-5 h-5 cursor-pointer ${textColorClassnames(
                                        'secondary',
                                    )}`}
                                    onClick={() => handleExpand(!isExpanded)}
                                >
                                    {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                </div>
                            )}
                        </HStack>
                    </div>
                </div>
                {isExpanded && (
                    <div>
                        <RowExpansion id={id} />
                    </div>
                )}
            </div>
        </VStack>
    );
}
type DataRowsProps = {
    data: Section[];
};
function DataRows({data}: DataRowsProps) {
    return (
        <div className="space-y-2 col-span-4 rounded-l-lg items-stretch">
            {data.map(({label, id, emissions}) => (
                <Row key={`${id}-emissions`} id={id} label={label} emissions={emissions} />
            ))}
        </div>
    );
}
function HeaderRow() {
    return (
        <div
            className={`col-span-4 ${bgColorClassnames('table-header')} ${textColorClassnames(
                'white',
            )} rounded-lg`}
        >
            <div className="flex flex-row justify-between items-center p-4">
                <div className="flex-1 pl-2">
                    <DetailText bold inherit>
                        Activity
                    </DetailText>
                </div>
                <div className="w-64">
                    <DetailText bold inherit>
                        Emissions (<CO2e />)
                    </DetailText>
                </div>
            </div>
        </div>
    );
}
function generateSectionsEmissionsData(data: OperationsSummaryEmissionsFragment) {
    const emissions = useFragment(OperationsSummaryEmissionsFragmentDocument, data);
    const {energy, people, vehicles, ghg, totalEmissionsForPeriod} = emissions;

    const sections = [
        {
            id: 'energy',
            label: 'Energy',
            emissions: carbonAmountFormatter({amount: energy?.kgCo2e ?? 0}),
        },
        {
            id: 'people',
            label: 'People',
            emissions: carbonAmountFormatter({amount: people?.kgCo2e ?? 0}),
        },
        {
            id: 'vehicles',
            label: 'Vehicles',
            emissions: carbonAmountFormatter({amount: vehicles?.kgCo2e ?? 0}),
        },
        {
            id: 'ghg',
            label: 'F-gases',
            emissions: carbonAmountFormatter({amount: ghg?.kgCo2e ?? 0}),
        },
    ] as Section[];

    const supplyChain = sections.reduce(
        (memo, section) => (section.emissions ? memo - section.emissions.rawAmount : memo),
        totalEmissionsForPeriod,
    );

    sections.push({
        id: 'supplyChain',
        label: 'Supply chain',
        emissions: carbonAmountFormatter({amount: supplyChain}),
    });
    return sections;
}
function OperationsSummaryContent() {
    const reportingPeriod = React.useContext(ReportingPeriodContext);
    const {loading, error, data} = useQuery(OperationsSummaryEmissionsDocument, {
        variables: {id: reportingPeriod.id},
        fetchPolicy: 'network-only',
    });

    const emissionsData = React.useMemo(() => {
        return data ? generateSectionsEmissionsData(data.getCompanyReportingPeriodById) : [];
    }, [data]);

    return (
        <VStack>
            <DetailText>
                <div className="grid grid-cols-4 auto-cols-min space-y-2">
                    <HeaderRow />
                    {loading && <Loading />}
                    {error && <ErrorCard />}
                    {emissionsData && <DataRows data={emissionsData} />}
                </div>
            </DetailText>
        </VStack>
    );
}

export function OperationsSummary() {
    const reportingPeriod = React.useContext(ReportingPeriodContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!reportingPeriod.isCompleted) {
            navigate('../places');
        }
    }, [reportingPeriod]);

    return <OperationsSummaryContent />;
}
