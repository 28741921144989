import React from 'react';
import {CardElement, useElements} from '@stripe/react-stripe-js';
import {useField} from 'formik';
import classNames from 'classnames';

import {
    bgColorClassnames,
    FormControl,
    FormErrorMessage,
    outlineColorClassnames,
    Preset,
} from '@sphericsio/design-system';

type CardInputProps = {
    name: string;
};
export function CardInput({name}: CardInputProps) {
    const [, meta, helpers] = useField({name});
    const elements = useElements();
    const [isFocused, setFocused] = React.useState(false);
    React.useEffect(() => {
        if (elements == null) {
            return;
        }

        const cardEl = elements.getElement(CardElement);
        if (cardEl == null) {
            return;
        }

        cardEl.on('change', (event) => {
            helpers.setTouched(true);
            helpers.setError(event.error?.message);
            helpers.setValue(event.complete);
        });

        cardEl.on('focus', () => {
            setFocused(true);
        });

        cardEl.on('blur', () => {
            setFocused(false);
        });

        return () => {
            cardEl.off('change');
        };
    }, [elements]);

    return (
        <FormControl isInvalid={meta.error != null}>
            <div
                className={classNames(
                    'rounded-xl border py-2.5 px-4',
                    bgColorClassnames('white'),
                    meta.error != null && `${outlineColorClassnames('error')} ring-red-400`,
                    {
                        'ring-1': isFocused,
                        'border-solid': isFocused && meta.error == null,
                        'ring-solid': meta.error == null,
                    },
                )}
            >
                <CardElement
                    options={{
                        classes: {base: 'w-full'},
                        style: {
                            base: {
                                fontFamily: Preset.theme.fontFamily.body.join(', '),
                                fontSize: '14px',
                                fontSmoothing: 'antialiased',
                                '::placeholder': {color: Preset.theme.extend.colors.yin[90]},
                            },
                        },
                    }}
                />
            </div>
            {meta.error && <FormErrorMessage>{meta.error}</FormErrorMessage>}
        </FormControl>
    );
}
