import React from 'react';
import {useQuery} from '@apollo/client';

import {ProfilePeopleContextProvider} from './context';
import {Loading} from '../../loading';
import {ErrorCard} from '../../error-card';
import {ProfilePeopleWizard} from './wizard';
import {GetProfilePeopleDocument} from './graphql';

export function ProfilePeople() {
    const {loading, error, data} = useQuery(GetProfilePeopleDocument);
    return (
        <div>
            {loading && <Loading />}
            {error && <ErrorCard />}
            {data && (
                <ProfilePeopleContextProvider company={data.currentCompany}>
                    <ProfilePeopleWizard />
                </ProfilePeopleContextProvider>
            )}
        </div>
    );
}
