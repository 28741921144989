import React from 'react';
import {useMutation, useQuery} from '@apollo/client';
import * as Yup from 'yup';
import {useElements, useStripe} from '@stripe/react-stripe-js';
import {useNavigate} from 'react-router';

import {Panel} from '@sphericsio/design-system';
import {Button, Form, Input} from '@sphericsio/forms';

import {Layout} from '../../components/layout';
import {graphql, useFragment} from '../../graphql/generated';
import {Loading} from '../../components/loading';
import {ErrorCard} from '../../components/error-card';
import {CardInput} from '../../components/subscription/card-input';
import {ConstrainWidthContainer} from '../../components/constrain-width-container';
import {CurrentUserFragmentDocument} from '../../graphql/fragments';

const GetSubscriptionCardParamsDocument = graphql(/* GraphQL */ `
    query GetSubscriptionCardParams {
        getSubscriptionAuthenticationParams {
            client_secret
            failure_reason
        }
    }
`);

const UpdateDefaultSubscriptionPaymentMethodDocument = graphql(/* GraphQL */ `
    mutation UpdateDefaultSubscriptionPaymentMethod($stripePaymentMethodId: String!) {
        updateDefaultSubscriptionPaymentMethod(stripePaymentMethodId: $stripePaymentMethodId) {
            ...CurrentUser
        }
    }
`);

function useSubscriptionAuthenticationParams() {
    const {loading, error, data} = useQuery(GetSubscriptionCardParamsDocument);
    const [clientSecret, setClientSecret] = React.useState<string>();
    const navigate = useNavigate();
    React.useEffect(() => {
        if (data == null) {
            return;
        }

        const params = data.getSubscriptionAuthenticationParams;
        if (params == null) {
            return navigate('..');
        }

        if (params.failure_reason !== 'requires_payment_method') {
            return navigate('/settings/subscription');
        }

        if (params.client_secret == null) {
            return navigate('/settings/subscription');
        }

        setClientSecret(params.client_secret);
    });

    return {loading, error, clientSecret};
}

const formSchema = Yup.object({
    cardholderName: Yup.string().required("Please enter the cardholder's name"),
    cardInput: Yup.boolean().isTrue('Please enter your card details'),
});

const initialValues = {
    cardholderName: '',
    cardInput: false,
};

export function SubscriptionCard() {
    const {loading: queryLoading, error, clientSecret} = useSubscriptionAuthenticationParams();
    const [updateDefaultSubscriptionPaymentMethod, {error: updateError, data: updateData}] =
        useMutation(UpdateDefaultSubscriptionPaymentMethodDocument);
    const navigate = useNavigate();
    React.useEffect(() => {
        const res = useFragment(
            CurrentUserFragmentDocument,
            updateData?.updateDefaultSubscriptionPaymentMethod,
        );
        if (res && res.id != null) {
            navigate('/settings/subscription');
        }
    }, [updateData?.updateDefaultSubscriptionPaymentMethod, navigate]);
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string>();
    React.useEffect(() => {
        if (updateError) {
            setLoading(false);
            setErrorMessage(
                'We could not update your default payment details. Please contact support',
            );
        }
    }, [updateError, setLoading]);
    const elements = useElements();
    const stripe = useStripe();
    return (
        <Layout title="Your Subscription" subtitle="Please enter your card details.">
            {queryLoading && <Loading />}
            {error && <ErrorCard />}
            {clientSecret && (
                <ConstrainWidthContainer>
                    <Form
                        initialValues={initialValues}
                        validationSchema={formSchema}
                        onSubmit={async (values) => {
                            if (stripe == null || elements == null) {
                                return;
                            }

                            const cardEl = elements.getElement('card');
                            if (cardEl == null) {
                                return;
                            }
                            setLoading(true);

                            try {
                                const result = await stripe.confirmCardPayment(clientSecret, {
                                    payment_method: {
                                        card: cardEl,
                                        billing_details: {
                                            name: values.cardholderName,
                                        },
                                    },
                                    setup_future_usage: 'off_session',
                                });
                                if (result.error) {
                                    setLoading(false);
                                    setErrorMessage(result.error.message);
                                } else if (result.paymentIntent.payment_method != null) {
                                    // FIXME type casting
                                    updateDefaultSubscriptionPaymentMethod({
                                        variables: {
                                            stripePaymentMethodId: result.paymentIntent
                                                .payment_method as string,
                                        },
                                    });
                                } else {
                                    navigate('/settings/subscription');
                                }
                            } catch (err) {
                                setErrorMessage('Something went wrong.');
                                setLoading(false);
                            }
                        }}
                    >
                        <Panel bg="minor-action">
                            <Input name="cardholderName" placeholder="Cardholder name" />
                            <CardInput name="cardInput" />
                        </Panel>
                        {errorMessage && <ErrorCard message={errorMessage} />}
                        <div className="pt-5 flex flex-row justify-end">
                            <Button type="submit" isLoading={loading}>
                                Add new card
                            </Button>
                        </div>
                    </Form>
                </ConstrainWidthContainer>
            )}
        </Layout>
    );
}
