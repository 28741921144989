import Bugsnag from '@bugsnag/js';
import {merge} from 'lodash';

import {
    CarbonFootprintTrendChartProps,
    YearlyEmissions,
} from '../../components/dashboard/carbon-footprint-trend-chart';
import {YoYCarbonFootprint} from '../generated/graphql';

type GqlData = Pick<YoYCarbonFootprint, 'previousYear' | 'currentYear'>;
export function parseCarbonFootprintTrend({
    previousYear,
    currentYear,
}: GqlData): CarbonFootprintTrendChartProps {
    return {
        previousYear: previousYear ? validateYear(previousYear) : null,
        currentYear: validateYear(currentYear),
    };
}

type WithoutUndefined<T> = T extends undefined ? never : T;
type GqlQuarter = WithoutUndefined<GqlData['currentYear']['quarters'][0]>;
function validateYear(year: GqlData['currentYear']): YearlyEmissions {
    let quarters = year.quarters;
    // Normally we expect the BE to send us exactly 4 quarters, but we cannot guarantee this statically.
    // (GQL doesn't support fixed-length arrays, or tuples).
    // We therefore code defensively here: if we get a different number of quarters, we log an error,
    // and patch the object such that it'll work with the graph.
    if (quarters.length !== 4) {
        Bugsnag.notify(new Error(`Expected 4 quarters in a year, but received ${quarters.length}`));
        quarters = merge([null, null, null, null], year.quarters).slice(0, 4);
    }
    return {
        quarters: quarters as [GqlQuarter, GqlQuarter, GqlQuarter, GqlQuarter],
        financialYear: year.financialYear,
    };
}
