import React from 'react';
import classnames from 'classnames';

import {
    RelayConnectionVariables,
    RelayConnectionQuery,
    PageInfo,
    useSortableDataTable,
    RelayOrderVariables,
    SortDirection,
} from '@sphericsio/mvp-ui-common';
import {
    bgColorClassnames,
    ChevronDownIcon,
    ChevronUpIcon,
    DetailText,
    HStack,
    List,
    ListItem,
    LoadingSpinner,
    textColorClassnames,
} from '@sphericsio/design-system';

import {ErrorCard} from './error-card';

export type DataListRef = {
    refreshData: () => void;
};

type NonNullableRequired<T> = Required<{
    [P in keyof T]: NonNullable<T[P]>;
}>;

function hasPrevious(
    pageInfo?: Partial<PageInfo>,
): pageInfo is NonNullableRequired<Pick<PageInfo, 'hasPreviousPage' | 'startCursor'>> {
    return pageInfo?.hasPreviousPage === true && pageInfo?.startCursor != null;
}

function hasNext(
    pageInfo?: Partial<PageInfo>,
): pageInfo is NonNullableRequired<Pick<PageInfo, 'hasNextPage' | 'endCursor'>> {
    return pageInfo?.hasNextPage === true && pageInfo?.endCursor != null;
}

type HeaderData = {
    key: string;
    value: React.ReactNode;
};

type DataListProps<R, V extends RelayOrderVariables<string> & RelayConnectionVariables, T> = {
    queryConfig: RelayConnectionQuery<R, V, T>;
    idField?: keyof T;
    onClickRow?: (row: T) => void;
    pageSize?: number;
    children: (row: T, index: number) => React.ReactNode;
    sortableFields: V['orderBy'][];
    initialSortDirection?: SortDirection;
    headerData?: HeaderData[];
};

function buildDataList() {
    function DataListWithRef<
        R,
        V extends RelayConnectionVariables & RelayOrderVariables<string>,
        T,
    >(props: DataListProps<R, V, T>, ref: React.Ref<DataListRef>) {
        const {
            queryConfig,
            idField = 'id',
            pageSize = 10,
            sortableFields,
            initialSortDirection,
            headerData,
            children,
        } = props;

        const {
            loading,
            error,
            tableData,
            pageInfo,
            onLoadNext,
            onLoadPrevious,
            onChangeSort,
            onRefetch,
            orderDirection,
        } = useSortableDataTable(queryConfig, pageSize, sortableFields, initialSortDirection);

        React.useImperativeHandle(ref, () => ({
            refreshData: onRefetch,
        }));

        return (
            <>
                <List>
                    {hasPrevious(pageInfo) && (
                        <ListItem>
                            <div onClick={() => onLoadPrevious(pageInfo.startCursor)}>
                                <DetailText>Load previous...</DetailText>
                            </div>
                        </ListItem>
                    )}
                    {headerData && (
                        <HeaderRow
                            data={headerData}
                            orderDirection={orderDirection}
                            sortableFields={sortableFields}
                            onChangeSort={onChangeSort}
                        />
                    )}
                    {error && <ErrorCard />}
                    {loading && (
                        <ListItem>
                            <HStack>
                                <LoadingSpinner />
                                <DetailText>Loading...</DetailText>
                            </HStack>
                        </ListItem>
                    )}
                    {
                        <>
                            <List>
                                {tableData.rows.map((row, index) => (
                                    <ListItem key={row.rowKey(idField as keyof T)}>
                                        {children(row.row, index)}
                                    </ListItem>
                                ))}
                            </List>

                            {hasNext(pageInfo) && (
                                <ListItem>
                                    <div
                                        className="cursor-pointer"
                                        onClick={() => onLoadNext(pageInfo.endCursor)}
                                    >
                                        <DetailText>Load next...</DetailText>
                                    </div>
                                </ListItem>
                            )}
                        </>
                    }
                </List>
            </>
        );
    }
    return React.forwardRef(DataListWithRef);
}

export const DataList = buildDataList();

type HeaderRowProps<V extends RelayOrderVariables<string>> = {
    data: HeaderData[];
    sortableFields: V['orderBy'][];
    orderDirection?: SortDirection;
    onChangeSort: (key: string) => void;
};

function HeaderRow<V extends RelayOrderVariables<string>>({
    data,
    sortableFields,
    orderDirection,
    onChangeSort,
}: HeaderRowProps<V>) {
    const isSortable = (key: string) => {
        return sortableFields.includes(key);
    };
    return (
        <div
            className={classnames(
                'flex flex-row justify-between items-center mb-2 rounded-xl p-5',
                bgColorClassnames('table-header'),
                textColorClassnames('white'),
            )}
        >
            {data.map(({key, value}) => (
                <span key={key}>
                    <DetailText bold colour="white">
                        {value}
                    </DetailText>
                    {isSortable(key) && (
                        <div
                            className="w-4 h-4 inline-block ml-5 mt-2"
                            onClick={() => onChangeSort(key)}
                        >
                            {orderDirection == 'desc' ? <ChevronDownIcon /> : <ChevronUpIcon />}
                        </div>
                    )}
                </span>
            ))}
        </div>
    );
}
