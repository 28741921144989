import React from 'react';
import {useQuery} from '@apollo/client';

import {Filter} from '../filters';
import {FetchFinancialAccountCountsDocument} from './graphql';
import {FinancialAccountsFilterEnum} from '../../graphql/generated/graphql';

type FinancialAccountFiltersContextValue = {
    filter: FinancialAccountsFilterEnum;
    refetch: () => void;
    remainingAccountsToConfirm: number;
    filterOptions: Filter[];
    setFilter: (filter: FinancialAccountsFilterEnum) => void;
};

export const FinancialAccountFiltersContext =
    React.createContext<FinancialAccountFiltersContextValue>({
        filter: 'all',
        refetch: () => {
            return;
        },
        remainingAccountsToConfirm: 0,
        filterOptions: [],
        setFilter: () => {
            return;
        },
    });

type FinancialAccountFiltersContextProviderProps = {
    children: React.ReactNode;
    initialFilter?: FinancialAccountsFilterEnum;
};
export function FinancialAccountFiltersContextProvider({
    children,
    initialFilter = 'all',
}: FinancialAccountFiltersContextProviderProps) {
    const [filterOptions, setFilterOptions] = React.useState<Filter[]>([]);
    const [filter, setFilter] = React.useState<FinancialAccountsFilterEnum>(initialFilter);
    const [remainingAccountsToConfirm, setRemainingAccountsCount] = React.useState<number>(0);

    const {error, data, refetch} = useQuery(FetchFinancialAccountCountsDocument, {
        fetchPolicy: 'network-only',
    });

    React.useEffect(() => {
        if (data?.getFinancialAccountFilterCounts) {
            const filters = data.getFinancialAccountFilterCounts.map((f) => {
                if (f.filter === 'system_categorised') {
                    return {
                        ...f,
                        tooltipText:
                            'Account code categories that have been suggested by Sage Earth and require user confirmation.',
                    };
                } else if (f.filter === 'uncategorised') {
                    return {
                        ...f,
                        tooltipText:
                            'Account codes that could not be categorised by Sage Earth and require a user categorisation.',
                    };
                } else {
                    return f;
                }
            });
            setFilterOptions(filters);
        }
    }, [data?.getFinancialAccountFilterCounts]);

    React.useEffect(() => {
        if (data?.getFinancialAccountFilterCounts && !error) {
            let res = 0;
            data.getFinancialAccountFilterCounts.forEach((c) => {
                if (c.filter === 'uncategorised' || c.filter === 'system_categorised') {
                    res += Number(c.count);
                }
            });
            setRemainingAccountsCount(res);
        }
    }, [data?.getFinancialAccountFilterCounts, error]);

    return (
        <FinancialAccountFiltersContext.Provider
            value={{
                setFilter,
                filter,
                filterOptions,
                remainingAccountsToConfirm,
                refetch,
            }}
        >
            {children}
        </FinancialAccountFiltersContext.Provider>
    );
}
