import React from 'react';

import {getCategorisationTheme, getDefaultCategorisationTheme} from '@sphericsio/design-system';

import {CategorisationContext} from '../components/categorisation/context';
import {
    buildHierarchyFromTaxonomyTerm,
    flattenHierarchyForTaxonomyKeys,
    UserCategorisationHierarchyItem,
} from '../components/categorisation/hierarchy';

export function usePrepareCategory(key: string) {
    const {hierarchy} = React.useContext(CategorisationContext);

    const category = React.useMemo(() => {
        if (hierarchy && key) {
            const taxonomy = buildHierarchyFromTaxonomyTerm(hierarchy, key) || [];
            return flattenHierarchyForTaxonomyKeys(hierarchy, taxonomy);
        }
    }, [hierarchy, key]);
    return category;
}

export function useCategoryTheme(
    category: UserCategorisationHierarchyItem[] | undefined,
    isExcluded = false,
) {
    const theme = React.useMemo(() => {
        if (isExcluded) {
            return getCategorisationTheme('excluded');
        }
        if (category) {
            const rootTaxonomy = category?.find((t) => t.taxonomyKey === 'general');
            return getCategorisationTheme(rootTaxonomy?.taxonomyTermKey ?? 'default');
        } else {
            return getDefaultCategorisationTheme();
        }
    }, [category]);
    return theme;
}
