import React from 'react';

import {
    ChevronRightIcon,
    DetailText,
    HighlightText,
    Link,
    SubHeading,
    textColorClassnames,
    VStack,
} from '@sphericsio/design-system';

import onboardingImage from '../../images/onboarding.svg';

function PosterHeading({heading}: Pick<PosterLayoutProps, 'heading'>) {
    return (
        <HighlightText>
            <SubHeading uppercase>{heading}</SubHeading>
        </HighlightText>
    );
}

function PosterFooter({footer}: Pick<PosterLayoutProps, 'footer'>) {
    return (
        <div className="flex content-center">
            <DetailText bold colour="white">
                {footer}
            </DetailText>
        </div>
    );
}
function PosterLink({link}: Pick<PosterLayoutProps, 'link'>) {
    if (!link) {
        return null;
    }

    return (
        <Link href={link.href} uppercase={false} openInNewWindow>
            <div className={`${textColorClassnames('white')} flex content-center`}>
                {link.anchorText}
                <div className="w-6 h-6 ml-2">
                    <ChevronRightIcon />
                </div>
            </div>
        </Link>
    );
}

type BackgroundImageType = 'onboarding';

function getBackgroundImage(type: BackgroundImageType) {
    switch (type) {
        case 'onboarding':
            return onboardingImage;
        default:
            return onboardingImage;
    }
}
type PosterLink = {href: string; anchorText: string};
type PosterBgImage = {type: BackgroundImageType};
type PosterLayoutProps = {
    heading?: string;
    footer?: React.ReactNode;
    link?: PosterLink;
    bgImage?: PosterBgImage;
    children: React.ReactNode;
};
export function PosterLayout(props: PosterLayoutProps) {
    const {heading, link, footer, children, bgImage} = props;

    return (
        <div
            className="flex flex-1 px-10 items-center justify-start bg-onboarding"
            style={{
                backgroundImage: bgImage ? `url(${getBackgroundImage(bgImage.type)})` : '',
                backgroundPosition: 'right bottom',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <div className="px-5">
                <VStack xlarge>
                    {heading && <PosterHeading {...props} />}
                    <div className="w-full max-w-md">{children}</div>
                    {link && <PosterLink {...props} />}
                    {footer && <PosterFooter {...props} />}
                </VStack>
            </div>
        </div>
    );
}
