import React from 'react';

import {Layout} from '../../components/layout';
import {SubscriptionSettings} from '../../components/subscription/settings';

export function CompanySubscriptionSettings() {
    return (
        <Layout title="Your Subscription">
            <SubscriptionSettings />
        </Layout>
    );
}
