import React from 'react';
import {ApolloError} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import {ReportingPeriodContext, reportingPeriodPlaceholder} from '../../reporting-period';
import {
    EditProfileEnergyConfirmedSpend,
    EditProfileEnergyOccupancy,
    SuggestedEnergyTransactionActionPayload,
} from '../types';
import {CompanyReportingPeriod, ProfileEnergy} from '../../../../graphql/generated/graphql';
import {useModifySection} from '../../use-modify-section';
import {ModifyProfileEnergyDocument} from '../graphql';

type EditProfileEnergyWizardStep = 'suggested' | 'consumption' | 'building_occupancy' | 'completed';

type EditProfileEnergyState = {
    transactions: SuggestedEnergyTransactionActionPayload;
    electricityKwh?: number;
    gasKwh?: number;
    buildingOccupancy: Record<string, number>;
    currentStep: EditProfileEnergyWizardStep;
    shouldSubmit: boolean;
};

type EditProfileEnergyAction =
    | {type: 'start'}
    | {
          type: 'identifyTransactions';
          payload: SuggestedEnergyTransactionActionPayload;
      }
    | {
          type: 'setEnergyConsumptions';
          payload: {electricityKwh: number; gasKwh: number};
      }
    | {
          type: 'skipEnergyConsumption';
      }
    | {
          type: 'setBuildingOccupancy';
          payload: Record<string, number>;
      };

function useEnergyReducer() {
    const hasEnergyTransactions = (state: EditProfileEnergyState) => {
        const values = Object.values(state.transactions);
        return values.length > 0 && values.find((value) => value) != null;
    };

    const initialState: EditProfileEnergyState = {
        transactions: {},
        buildingOccupancy: {},
        currentStep: 'suggested',
        shouldSubmit: false,
    };
    return React.useReducer(
        (
            state: EditProfileEnergyState,
            action: EditProfileEnergyAction,
        ): EditProfileEnergyState => {
            switch (action.type) {
                case 'start':
                    return {
                        ...state,
                        currentStep: 'suggested',
                    };

                case 'identifyTransactions':
                    return {
                        ...state,
                        transactions: action.payload,
                        currentStep: 'consumption',
                    };

                case 'setEnergyConsumptions':
                    return {
                        ...state,
                        electricityKwh: action.payload.electricityKwh,
                        gasKwh: action.payload.gasKwh,
                        shouldSubmit: true,
                    };

                case 'skipEnergyConsumption':
                    if (hasEnergyTransactions(state)) {
                        return {
                            ...state,
                            shouldSubmit: true,
                        };
                    } else {
                        return {
                            ...state,
                            currentStep: 'building_occupancy',
                        };
                    }

                case 'setBuildingOccupancy':
                    return {
                        ...state,
                        buildingOccupancy: action.payload,
                        shouldSubmit: true,
                    };
            }
        },
        initialState,
    );
}

type EditProfileEnergyContextValue = {
    dispatch: (action: EditProfileEnergyAction) => void;
    currentStep: EditProfileEnergyWizardStep;
    reportingPeriod: CompanyReportingPeriod;
    answers: EditProfileEnergy;
    submitting?: boolean;
    error?: ApolloError;
};

export const EditProfileEnergyContext = React.createContext<EditProfileEnergyContextValue>({
    dispatch: () => {
        // placeholder
    },
    reportingPeriod: reportingPeriodPlaceholder,
    answers: {
        confirmedSpend: [],
        buildingOccupancies: [],
    },
    currentStep: 'suggested',
});

type EditProfileEnergy = Pick<ProfileEnergy, 'electricity_kwh' | 'gas_kwh'> & {
    confirmedSpend: EditProfileEnergyConfirmedSpend[];
    buildingOccupancies: EditProfileEnergyOccupancy[];
};

type EditProfileEnergyContextProviderProps = {
    children: React.ReactNode;
    answers: EditProfileEnergy;
};
export function EditProfileEnergyContextProvider({
    children,
    answers,
}: EditProfileEnergyContextProviderProps) {
    const reportingPeriod = React.useContext(ReportingPeriodContext);
    const [state, dispatch] = useEnergyReducer();
    const navigate = useNavigate();
    const [submit, {loading, error, finished}] = useModifySection(ModifyProfileEnergyDocument, [
        'GetProfileEnergy',
    ]);
    const reportingPeriodId = reportingPeriod.id;
    React.useEffect(() => {
        if (state.shouldSubmit) {
            submit({
                variables: {
                    params: {
                        energySpendTransactionIds: Object.entries(state.transactions).map(
                            ([transactionId, tarrifType]) => ({
                                id: transactionId,
                                tarrif: tarrifType,
                            }),
                        ),
                        electricityKwh: state.electricityKwh,
                        gasKwh: state.gasKwh,
                        buildingOccupancies: Object.entries(state.buildingOccupancy).map(
                            ([id, occupancyPercent]) => ({
                                id,
                                occupancyPercent,
                            }),
                        ),
                        companyReportingPeriodId: reportingPeriodId,
                    },
                },
            });
        }
    }, [submit, reportingPeriodId, state]);

    React.useEffect(() => {
        if (finished) {
            navigate('../summary');
        }
    }, [finished]);

    return (
        <EditProfileEnergyContext.Provider
            value={{
                dispatch,
                currentStep: state.currentStep,
                reportingPeriod,
                answers,
                submitting: loading,
                error,
            }}
        >
            {children}
        </EditProfileEnergyContext.Provider>
    );
}
