import React from 'react';
import {generatePath, useLocation} from 'react-router';

import {CompanyReportingPeriodFragment} from '../../graphql/generated/graphql';

export function generatePathForReportingPeriodAndPage(
    period: Pick<CompanyReportingPeriodFragment, 'period_start' | 'period_end'>,
    page: string,
    isBaselineView?: boolean,
) {
    return generatePath(
        `/${isBaselineView ? 'baseline' : 'operations'}/:periodStart/:periodEnd/:page`,
        {
            periodStart: period.period_start,
            periodEnd: period.period_end,
            page,
        },
    );
}

export function getStep(page?: string, isCompleted = false) {
    const defaultPage = isCompleted ? 'summary' : 'places';
    if (page === 'summary' || page === 'places') {
        return defaultPage;
    }
    return page ?? defaultPage;
}

export function useExpectedPath(
    period: CompanyReportingPeriodFragment | undefined,
    page: string,
    isBaselineView?: boolean,
) {
    const location = useLocation();
    const step = getStep(page, period?.isCompleted ?? false);
    return React.useMemo(() => {
        if (period == null) {
            return location.pathname;
        }
        return generatePathForReportingPeriodAndPage(period, step, isBaselineView);
    }, [period, page, isBaselineView]);
}
