import React from 'react';
import {useQuery} from '@apollo/client';

import {HSplitLoading} from '../components/layout/h-split';
import {graphql} from '../graphql/generated';
import {PosterJoinTheMovement} from '../components/onboarding/poster-join-the-movement';
import {useExternalRedirect} from '../hooks/use-external-redirect';

export const authorisationUrlQuery = graphql(/* GraphQL */ `
    query GetAuthorisationUrl {
        authorisationUrl {
            url
        }
    }
`);

export function ExternalIdentityProvider() {
    const externalRedirect = useExternalRedirect();
    const {error} = useQuery(authorisationUrlQuery, {
        onCompleted(data) {
            externalRedirect(data.authorisationUrl.url, true);
        },
    });
    return (
        <HSplitLoading error={error} callToAction="signup">
            <PosterJoinTheMovement />
        </HSplitLoading>
    );
}
