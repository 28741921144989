import tokens from '@sage/design-tokens/js/base/es6';
import {buildChartTheme} from '@visx/xychart';

import {Preset} from '@sphericsio/design-system';

export const colors = {
    previousYear: '#8DF79F',
    currentYear: '#00884A',
    deltaBg: '#8FF8B7',
    deltaFg: '#00522C',
    bg: Preset.theme.extend.colors.yang[100],
    grid: Preset.theme.extend.colors.yin[65],
    // Note that these colors will be used in svg, where CSS vars don't work.
    // Therefore we need to use the hex values directly (from design tokens)
    warning: tokens.colorsSemanticCaution500,
    blur: tokens.colorsUtilityMajor075,
    pending: tokens.colorsUtilityYin065,
};

export const chartTheme = buildChartTheme({
    backgroundColor: colors.bg,
    colors: [colors.previousYear, colors.currentYear],
    gridColor: colors.grid,
    gridColorDark: colors.grid,
    tickLength: 0,
});

export const chartDimensions = {
    targetAspectRatio: 4,
    minHeight: 150,
    maxHeight: 400,
    // No explicit width, as this is based on the container
};

// Note that the margins here are in relation to the chart axes, not the SVG. This means that:
// - y-axis labels fall within the `left` margin
// - x-axis labels fall within the `bottom` margin
// - The top y-axis label falls partially within the `top` margin
// - `left` margin is the distance from the y-axis to the edge of the container
// - `bottom` margin is the distance from the x-axis to the Legend
export const chartMargins = {
    top: 40,
    left: 70,
    right: 0,
    bottom: 85,
};

export const chartPaddings = {
    // These are not pixel values, but some ratios that visx uses to calculate the actual margins
    relativeInner: 0.2,
    relativeOuter: 0.02,
};

export const axisLabelMargins = {
    xAxis: 27,
    yAxis: 18,
};

export const barMargins = {
    top: 12, // in px
    relativeBetween: 0.05, // some ratio interpreted by d3. Eye-balled.
};

export const barRadius = 8;

const warningIconSize = 20;
export const warningIcon = {
    size: warningIconSize,
    boundingBoxHeight: warningIconSize + barMargins.top,
};

export const pendingLabelFont = {
    fontSize: tokens.fontSizes100,
    fontFamily: tokens.fontFamiliesDefault,
    fontWeight: tokens.fontWeights400,
};

export const tooltipLegendLineWidth = 3;
