import React from 'react';
import {useQuery} from '@apollo/client';

import {ProfileEnergyContext} from './context';
import {CompletedProfileSection} from '../completed-profile-section';
import {GetEnergyEmissionsForReportingPeriodDocument} from './graphql';

export function ProfileEnergyComplete() {
    const ctx = React.useContext(ProfileEnergyContext);
    const queryResult = useQuery(GetEnergyEmissionsForReportingPeriodDocument, {
        variables: {id: ctx.reportingPeriod.id},
    });
    return <CompletedProfileSection section="energy" queryResult={queryResult} />;
}
