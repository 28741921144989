import React from 'react';
import {useQuery} from '@apollo/client';

import {useFragment} from '../../../graphql/generated';
import {ProfilePlacesIntro} from './intro';
import {ProfilePlacesSuggestedAddresses} from './suggested';
import {Loading} from '../../loading';
import {ErrorCard} from '../../error-card';
import {ProfilePlacesReview} from './review';
import {ProfilePlacesAddAddress} from './add-address';
import {ProfilePlacesBuildingTypes} from './building-types';
import {ConstrainWidthContainer} from '../../constrain-width-container';
import {GetCompanyAddressesDocument} from './graphql';
import {CompanyAddressesFragmentDocument} from './fragments';

type ProfilePlacesWizardStep =
    | 'unknown'
    | 'intro'
    | 'suggested_addresses'
    | 'add_address'
    | 'review'
    | 'building_types';
export function ProfilePlacesWizard() {
    const [step, setStep] = React.useState<ProfilePlacesWizardStep>('unknown');
    const [selectedAddressId, setSelectedAddressId] = React.useState<string>();
    const {loading, error, data} = useQuery(GetCompanyAddressesDocument);

    const addresses = useFragment(
        CompanyAddressesFragmentDocument,
        data?.currentCompany,
    )?.addresses;

    React.useEffect(() => {
        if (addresses == null) {
            return;
        }

        if (step !== 'unknown') {
            return;
        }

        if (addresses.length === 0) {
            setStep('intro');
        } else {
            setStep('review');
        }
    }, [addresses, setStep]);

    const nextStep = () => {
        setSelectedAddressId(undefined);
        setStep((value) => {
            if (value === 'intro') {
                return 'suggested_addresses';
            }
            if (value === 'suggested_addresses') {
                return addresses && addresses.length === 0 ? 'add_address' : 'building_types';
            }
            if (value === 'add_address') {
                return 'review';
            }
            if (value === 'building_types') {
                return 'review';
            }

            return value;
        });
    };

    return (
        <div>
            {step === 'review' && data != null && (
                <ProfilePlacesReview
                    addresses={addresses ?? []}
                    onAddAnother={() => setStep('add_address')}
                    onEditAddress={(addressId) => {
                        setSelectedAddressId(addressId);
                        setStep('building_types');
                    }}
                />
            )}
            <ConstrainWidthContainer>
                {loading && <Loading />}
                {error && <ErrorCard />}
                {step === 'intro' && <ProfilePlacesIntro onNext={nextStep} />}
                {step === 'suggested_addresses' && (
                    <ProfilePlacesSuggestedAddresses onNext={nextStep} />
                )}

                {step === 'add_address' && <ProfilePlacesAddAddress onNext={nextStep} />}
                {step === 'building_types' && data != null && (
                    <ProfilePlacesBuildingTypes
                        addresses={addresses ?? []}
                        selectedAddressId={selectedAddressId}
                        onNext={nextStep}
                    />
                )}
            </ConstrainWidthContainer>
        </div>
    );
}
