import React from 'react';
import {useMutation} from '@apollo/client';

import {Modal} from '@sphericsio/design-system';

import {
    SetCompanyMerchantCategorisationDocument,
    VerifyMerchantCategorisationByCompanyDocument,
} from '../graphql';
import {useBackgroundExecutionStatus} from '../../../hooks/background-execution';
import {UserCategorisationHierarchyItem} from '../hierarchy';
import {SaveStatus} from '../categorisation';
import {VerifyCategorisationModal} from './verify-merchant-recategorisation-modal';

function useSaveCategorisation() {
    const [save, {loading, error, data}] = useMutation(SetCompanyMerchantCategorisationDocument);
    const poll = useBackgroundExecutionStatus();
    const backgroundExecutionId = React.useMemo(() => {
        return data?.setCompanyMerchantCategorisation.id;
    }, [data]);

    React.useEffect(() => {
        if (backgroundExecutionId != null && !poll.called) {
            poll.startPolling({id: backgroundExecutionId});
        }
    }, [backgroundExecutionId, poll]);

    if (poll.called) {
        return [
            save,
            {
                loading: poll.loading || !poll.finished,
                error: poll.error,
                data: poll.data,
                finished: poll.finished,
            },
        ] as const;
    } else {
        return [save, {loading, error, data, finished: false}] as const;
    }
}

type SaveCategorisationProps = {
    id: string;
    name: string;
    selected: UserCategorisationHierarchyItem | null;
    status: SaveStatus;
    onStatusChange: (status: SaveStatus) => void;
    children?: React.ReactNode;
};
export function SaveCategorisation({
    id,
    name,
    selected,
    status,
    onStatusChange,
    children,
}: SaveCategorisationProps) {
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [verify, {data}] = useMutation(VerifyMerchantCategorisationByCompanyDocument);
    const [confirmedSelection, setConfirmSelection] = React.useState<boolean>(false);
    const [save, {loading, error, finished}] = useSaveCategorisation();

    function proceedWithSave(selected: UserCategorisationHierarchyItem) {
        save({
            variables: {
                id,
                taxonomyKey: selected?.taxonomyKey,
                taxonomyTermKey: selected?.taxonomyTermKey,
            },
        });
        onStatusChange('saving');
    }

    React.useEffect(() => {
        if (selected && status === 'pending') {
            verify({
                variables: {
                    merchantId: id,
                    taxonomyKey: selected?.taxonomyKey,
                    taxonomyTermKey: selected?.taxonomyTermKey,
                },
            });
        }
    }, [selected]);

    React.useEffect(() => {
        if (selected) {
            if (
                data &&
                data?.verifyMerchantCategorisationByCompany.isTransactionUserCategorisationDifferent
            ) {
                setShowModal(true);
            }
            if (
                data &&
                !data?.verifyMerchantCategorisationByCompany
                    .isTransactionUserCategorisationDifferent
            ) {
                proceedWithSave(selected);
            }
        }
    }, [data, selected]);

    React.useEffect(() => {
        if (confirmedSelection && selected && status === 'pending') {
            proceedWithSave(selected);
        }
    }, [confirmedSelection]);

    React.useEffect(() => {
        if (finished && !error) {
            onStatusChange('success');
        }
        if (loading && status !== 'saving') {
            onStatusChange('saving');
        }
        if (error || (finished && error)) {
            onStatusChange('error');
        }
    }, [loading, error, finished]);

    return (
        <div>
            {
                <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                    <VerifyCategorisationModal
                        selected={selected}
                        name={name}
                        onCancel={() => setShowModal(false)}
                        onConfirm={() => setConfirmSelection(true)}
                    />
                </Modal>
            }
            <div className="relative">{children}</div>
        </div>
    );
}
