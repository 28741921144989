import React from 'react';
import {useQuery} from '@apollo/client';

import {
    DetailText,
    divideColorClassnames,
    HStack,
    LoadingSpinner,
    VStack,
} from '@sphericsio/design-system';

import {ReportingPeriodContext} from '../reporting-period';
import {ErrorCard} from '../../error-card';
import {MoneyDisplay} from '../../money';
import {GetProfileEnergyDocument} from './graphql';
import {useFragment} from '../../../graphql/generated';
import {ProfileEnergyFragmentDocument} from './fragments';

export function EnergyProfileAnswers() {
    const reportingPeriod = React.useContext(ReportingPeriodContext);
    const {
        loading,
        error,
        data: res,
    } = useQuery(GetProfileEnergyDocument, {
        variables: {companyReportingPeriodId: reportingPeriod.id},
        fetchPolicy: 'network-only',
    });

    if (error) {
        return <ErrorCard />;
    }

    if (loading) {
        return <LoadingSpinner />;
    }

    const data = useFragment(ProfileEnergyFragmentDocument, res?.getProfileEnergy);

    return (
        <VStack>
            <div className={`divide-y ${divideColorClassnames()}`}>
                {data?.buildingOccupancies && data?.buildingOccupancies.length > 0 && (
                    <div className="py-5">
                        <DetailText bold>Building occupancy</DetailText>
                        {data?.buildingOccupancies.map((b) => (
                            <div className="py-2" key={b.address.id}>
                                <HStack>
                                    <div className="flex-1">
                                        <DetailText inline={false}>
                                            <div>{b.address.address}</div>
                                            <div>{b.address.postcode}</div>
                                        </DetailText>
                                    </div>
                                    <div>{b.percent_occupied}%</div>
                                </HStack>
                            </div>
                        ))}
                    </div>
                )}
                <div className="py-5">
                    <VStack>
                        <DetailText bold>Electricity consumption</DetailText>
                        <DetailText>{data?.electricity_kwh || `-`} kWh</DetailText>
                    </VStack>
                </div>
                <div className="py-5">
                    <VStack>
                        <DetailText bold>Gas consumption</DetailText>
                        <DetailText>{data?.gas_kwh || `-`} kWh</DetailText>
                    </VStack>
                </div>
                {data?.confirmedSpend && data?.confirmedSpend.length > 0 && (
                    <div className="py-5">
                        <DetailText bold>Confirmed energy transactions</DetailText>
                        {data.confirmedSpend.map((s) => {
                            const energyTariff = s.transactions.find(
                                (t) => !!t.categorisation_metadata?.energyTariff,
                            )?.categorisation_metadata.energyTariff;
                            return (
                                <div key={s.id} className="py-5">
                                    <HStack>
                                        <div className="w-40">
                                            <DetailText bold>{s.merchant_name}</DetailText>
                                        </div>
                                        <DetailText>
                                            {s.transactions.length} transactions
                                        </DetailText>
                                        <div className="w-20">
                                            <DetailText>
                                                {energyTariff === 'electricity'
                                                    ? 'Electricity'
                                                    : energyTariff === 'gas'
                                                      ? 'Gas'
                                                      : energyTariff === 'dual_fuel'
                                                        ? 'Dual fuel'
                                                        : ''}
                                            </DetailText>
                                        </div>
                                        <div className="px-3">
                                            {s.amount && (
                                                <DetailText>
                                                    <MoneyDisplay money={s.amount} />
                                                </DetailText>
                                            )}
                                        </div>
                                    </HStack>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </VStack>
    );
}
