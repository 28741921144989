import {graphql} from '../../../graphql/generated';

export const GetProfileGHGDocument = graphql(/* GraphQL */ `
    query GetProfileGHG($companyReportingPeriodId: ID!) {
        getProfileGHG(companyReportingPeriodId: $companyReportingPeriodId) {
            ...ProfileGHGEntry
        }
    }
`);

export const GetGhgEmissionsForReportingPeriodDocument = graphql(/* GraphQL */ `
    query GetGhgEmissionsForReportingPeriod($id: ID!) {
        getCompanyReportingPeriodById(id: $id) {
            ghg {
                ...BenchmarkingProfileEntry
            }
            totalEmissionsForPeriod
        }
    }
`);

export const ModifyProfileGHGDocument = graphql(/* GraphQL */ `
    mutation ModifyProfileGHG($params: ProfileGHGInput!) {
        modifyProfileGHG(params: $params) {
            id
        }
    }
`);

export const GetGhgListDocument = graphql(/* GraphQL */ `
    query GetGhgList {
        getGHGList
    }
`);

export const GetSuggestedGhgSpendsDocument = graphql(/* GraphQL */ `
    query GetSuggestedGhgSpends($from: Date!, $to: Date!) {
        getSuggestedGHGTransactions(from: $from, to: $to) {
            id
            merchant_name
            transactions {
                id
                description
                amount
                transaction_date
            }
        }
    }
`);
