import React from 'react';
import {Navigate} from 'react-router-dom';

import {FullScreenError, FullScreenLoading} from '../components/full-screen';
import {useRefreshedUser} from '../components/user-context';
import {CurrentUserFragmentDocument} from '../graphql/fragments';
import {useFragment} from '../graphql/generated';
import {termsAndConditionsRoute} from './terms';

export function Index() {
    const {loading, error, data} = useRefreshedUser();

    if (loading) {
        return <FullScreenLoading />;
    }

    if (error) {
        return <FullScreenError />;
    }

    const user = useFragment(CurrentUserFragmentDocument, data?.currentUser);

    if (user == null) {
        return <Navigate to="/login" />;
    }

    if (!user.isEmailVerified) {
        return <Navigate to="/unverified" />;
    }

    if (!user.terms_and_conditions_accepted) {
        return <Navigate to={termsAndConditionsRoute} />;
    }

    if (user.lastSync == null) {
        return <Navigate to="/sync" />;
    }

    if (
        user.financialProvider?.financial_provider === 'sage50_upload' &&
        (!user.hasTransactions ||
            user.financialProvider.sync_status === 'InitialSyncInProgress' ||
            user.financialProvider.sync_status == 'PreInitialSync')
    ) {
        return <Navigate to="/sage50" />;
    }

    if (!user.isCompanyOnboarded) {
        return <Navigate to="/onboarding/company-details" />;
    }

    return <Navigate to="/dashboard" />;
}
