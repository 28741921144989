import React from 'react';

import {
    DetailText,
    Panel,
    VStack,
    Tooltip,
    Placement,
    SegmentSubheader,
    textColorClassnames,
    Align,
} from '@sphericsio/design-system';

import {HelpIcon} from '../icons';

type DashboardTooltip = {
    text: string;
    link?: string;
    linkText?: string;
    place?: Placement;
    align?: Align;
};
type DashboardPanelProps = {
    title: string;
    intercomTarget?: string;
    children: React.ReactNode;
    footer?: React.ReactNode;
    tooltip?: DashboardTooltip;
    helpLink?: string;
};
export function DashboardPanel({
    title,
    children,
    footer,
    helpLink,
    tooltip,
    intercomTarget,
}: DashboardPanelProps) {
    return (
        <Panel flex intercomTarget={intercomTarget}>
            <div className="relative flex flex-1">
                <VStack full>
                    <SegmentSubheader>{title}</SegmentSubheader>
                    <div className="flex flex-1">{children}</div>
                    {footer && (
                        <DetailText inline={false} colour="secondary">
                            {footer}
                        </DetailText>
                    )}
                </VStack>
                {tooltip && (
                    <div
                        className={`absolute top-0 right-0 w-6 h-6 ${textColorClassnames(
                            'tooltip',
                        )}`}
                    >
                        <Tooltip
                            tooltipText={tooltip.text}
                            linkHref={tooltip.link}
                            linkText={tooltip.linkText}
                            place={tooltip.place}
                            align={tooltip.align}
                        >
                            <HelpIcon />
                        </Tooltip>
                    </div>
                )}
                {helpLink && !tooltip && (
                    <a href={helpLink} target="_blank" rel="noopener noreferrer">
                        <div
                            className={`absolute top-0 right-0 w-6 h-6 ${textColorClassnames(
                                'tooltip',
                            )}`}
                        >
                            <HelpIcon />
                        </div>
                    </a>
                )}
            </div>
        </Panel>
    );
}
