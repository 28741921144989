import React from 'react';

import {Heading1, HighlightText} from '@sphericsio/design-system';

export function PosterNonProfit() {
    return (
        <Heading1>
            Are you a non-profit? Sage Earth will
            <HighlightText> always be free </HighlightText>
            to your business, get in touch to arrange free access.
        </Heading1>
    );
}
