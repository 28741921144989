import React from 'react';
import {useLocation, Navigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';

import {updateCurrentUser} from '@sphericsio/mvp-ui-common';

import {FullScreenError, FullScreenLoading} from '../../components/full-screen';
import {CurrentUserFragmentDocument} from '../../graphql/fragments';
import {AccountingComponentProps} from './types';
import {graphql} from '../../graphql/generated';

const FinancialProviderLoginCallbackDocument = graphql(/* GraphQL */ `
    mutation FinancialProviderLoginCallback($params: String!, $provider: IdentityProviderName!) {
        financialProviderLoginCallback(params: $params, provider: $provider) {
            ...CurrentUser
        }
    }
`);

export function AccountingLoginCallback({provider}: AccountingComponentProps) {
    const location = useLocation();
    const [preMutation, setPreMutation] = React.useState(true);
    const [providerCallback, {loading, error}] = useMutation(
        FinancialProviderLoginCallbackDocument,
    );

    React.useLayoutEffect(() => {
        providerCallback({
            variables: {
                params: `${location.pathname}${location.search}`,
                provider,
            },
            update: (cache, data) => {
                if (data.data?.financialProviderLoginCallback != null) {
                    updateCurrentUser(
                        cache,
                        data.data?.financialProviderLoginCallback,
                        CurrentUserFragmentDocument,
                    );
                }
            },
        });
        setPreMutation(false);
    }, []);

    if (loading || preMutation) {
        return <FullScreenLoading />;
    }

    if (error) {
        return (
            <FullScreenError
                message="Sorry, we can't log you in right now."
                buttonHref={`/${provider}/login`}
            />
        );
    }

    return <Navigate to="/" />;
}
