import React from 'react';

import {Heading3, HighlightText, VStack} from '@sphericsio/design-system';

import {EyeIcon, EyeOffIcon, LockIcon} from '../icons';
import {PosterList, PosterListItem} from './poster-list';

export function PosterSecurityProtocols() {
    return (
        <VStack>
            <Heading3 colour="white">
                We adhere to the <HighlightText>highest</HighlightText> security protocols:
            </Heading3>

            <PosterList>
                <PosterListItem
                    style="image"
                    image={<EyeIcon />}
                    header="Only ‘read-only’ access to your accounting data"
                >
                    We can’t make changes to your accounts.
                </PosterListItem>
                <PosterListItem
                    style="image"
                    image={<LockIcon />}
                    header="We don’t store your bank details"
                >
                    We do not store any sensitive bank details on our platform, only data on your
                    expenditure.
                </PosterListItem>

                <PosterListItem
                    style="image"
                    image={<EyeOffIcon />}
                    header="Disconnect your accounting data at any time"
                >
                    You can easily disconnect your cloud accounting platform from our system with a
                    single click. Once disconnected, we no longer have any access to your accounting
                    data.
                </PosterListItem>
            </PosterList>
        </VStack>
    );
}
