import React from 'react';

import {ProfilePlacesAddAddressForm} from './add-address-form';
import {ProfilePlacesAddressLookup} from './address-lookup';

type SelectedAddress = {
    address: string;
    postcode: string;
};
type ProfilePlacesAddAddressProps = {
    onNext: () => void;
};
export function ProfilePlacesAddAddress({onNext}: ProfilePlacesAddAddressProps) {
    const [view, setView] = React.useState<'lookup' | 'add'>('lookup');
    const [selectedAddress, setSelectedAddress] = React.useState<SelectedAddress>();

    return (
        <>
            {view === 'lookup' && (
                <ProfilePlacesAddressLookup
                    onNext={(address, postcode) => {
                        if (address != null && postcode != null) {
                            setSelectedAddress({address, postcode});
                        }
                        setView('add');
                    }}
                />
            )}
            {view === 'add' && (
                <ProfilePlacesAddAddressForm
                    address={selectedAddress}
                    onAddAnother={() => {
                        setSelectedAddress(undefined);
                        setView('lookup');
                    }}
                    onNext={onNext}
                />
            )}
        </>
    );
}
