import {graphql} from '../../graphql/generated';

export const TargetingBaselinePanelTargetingBaselineFragmentDocument = graphql(/* GraphQL */ `
    fragment TargetingBaselinePanel_TargetingBaseline on TargetingBaseline {
        company_reporting_period {
            isCompleted
            period_start
            period_end
        }
        scope_co2e_breakdown {
            scope_1_kg_co2e
            scope_2_kg_co2e
            scope_3_kg_co2e
            total_kg_co2e
        }
    }
`);
export const TargetingBaselinePanelReductionTargetFragmentDocument = graphql(/* GraphQL */ `
    fragment TargetingBaselinePanel_ReductionTarget on ReductionTarget {
        reduction_target_type
        yearly_percent_reduction
        target_completion_year
    }
`);

export const TargetingBaselineSummaryReductionTargetVisualisationFragmentDocument = graphql(
    /* GraphQL */ `
        fragment TargetingBaselineSummary_ReductionTargetVisualisation on ReductionTargetVisualisation {
            periodStart
            emissions
            initialPeriodTargetKgCo2e
        }
    `,
);
