import React from 'react';

import {Header, HighlightText} from '@sphericsio/design-system';

export function PosterUploadFullYear() {
    return (
        <Header colour="white">
            We recommend to start by uploading a{' '}
            <HighlightText>full financial years worth of data</HighlightText> if you can.
        </Header>
    );
}
