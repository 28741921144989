import React from 'react';

import {
    bgColorClassnames,
    DetailText,
    Heading2,
    outlineColorClassnames,
    SelectInput,
    VStack,
} from '@sphericsio/design-system';

import {DateDisplay, formatDateString} from '../date-display';
import {CompanyReportingPeriod} from '../../graphql/generated/graphql';

type PeriodDisplayProps = {
    start: string;
    end: string;
};
function PeriodDisplay({start, end}: PeriodDisplayProps) {
    return (
        <div
            className={`border ${outlineColorClassnames()} w-80 flex justify-center rounded-lg ${bgColorClassnames(
                'white',
            )} px-10 py-1`}
        >
            <DetailText>
                <DateDisplay>{start}</DateDisplay> - <DateDisplay>{end}</DateDisplay>
            </DetailText>
        </div>
    );
}

type ReportingPeriodDisplayProps = {
    reportingPeriods: CompanyReportingPeriod[];
    currentReportingPeriod: CompanyReportingPeriod;
    page: string;
    onChangeReportingPeriod: (reportingPeriod: CompanyReportingPeriod) => void;
};
export function ReportingPeriodDisplay({
    reportingPeriods,
    currentReportingPeriod,
    page,
    onChangeReportingPeriod,
}: ReportingPeriodDisplayProps) {
    const items = reportingPeriods
        .filter((period) => !period.isOpen)
        .map((period) => ({
            id: period.id,
            label: `${formatDateString(period.period_start)} - ${formatDateString(
                period.period_end,
            )}`,
        }));
    const value = items.find((item) => item.id === currentReportingPeriod.id);

    return (
        <VStack>
            {page === 'summary' && (
                <Heading2>
                    {currentReportingPeriod.isBaselineReportingPeriod ? 'Baseline ' : 'Operations '}
                    Summary
                </Heading2>
            )}
            {items.length > 1 ? (
                <div className="w-80 mr-2.5">
                    <SelectInput
                        name="reportingPeriod"
                        onChange={({id}) => {
                            const period = reportingPeriods.find((item) => item.id === id);
                            if (period != null) {
                                onChangeReportingPeriod(period);
                            }
                        }}
                        items={items}
                        value={value}
                    />
                </div>
            ) : (
                <PeriodDisplay
                    start={currentReportingPeriod.period_start}
                    end={currentReportingPeriod.period_end}
                />
            )}
        </VStack>
    );
}
