import React from 'react';
import {Navigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';

import {VStack} from '@sphericsio/design-system';

import {HSplitLayout} from '../../components/layout/h-split';
import {ForgotPasswordForm} from '../../components/forgot-password-form';
import {PageHeader} from '../../components/page-header';
import {graphql} from '../../graphql/generated';
import {PosterLayout} from '../../components/onboarding/poster-layout';
import {PosterJoinTheMovement} from '../../components/onboarding/poster-join-the-movement';

const ResetPasswordRequestDocument = graphql(/* GraphQL */ `
    mutation ResetPasswordRequest($email: String!) {
        resetPasswordRequest(email: $email)
    }
`);

export function ForgotPassword() {
    const [submit, {loading, error, data}] = useMutation(ResetPasswordRequestDocument);

    if (data != null) {
        return <Navigate to="./success" />;
    }
    return (
        <HSplitLayout cta="login">
            <VStack>
                <PageHeader
                    title="Forgot your password?"
                    subtitle="Enter your email and we will send you instructions to reset your password."
                />

                <ForgotPasswordForm
                    submitting={loading}
                    error={error}
                    onSubmit={(email) => {
                        submit({
                            variables: {email},
                        });
                    }}
                />
            </VStack>
            <PosterLayout>
                <PosterJoinTheMovement />
            </PosterLayout>
        </HSplitLayout>
    );
}
