import React from 'react';

import {VStack} from '@sphericsio/design-system';

import {TabPageHeader} from '../page-header';
import {formatDateString} from '../date-display';

type ProfileReportingPeriodOpenProps = {
    reportingPeriodEnd: string;
};
export function ProfileReportingPeriodOpen({reportingPeriodEnd}: ProfileReportingPeriodOpenProps) {
    const subtitle = `Please come back after ${formatDateString(
        reportingPeriodEnd,
    )} when you have reconciled all of your financial data for the quarter.`;
    return (
        <VStack>
            <TabPageHeader title="Please wait for the quarter to finish" subtitle={subtitle} />
        </VStack>
    );
}
