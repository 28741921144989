import {graphql} from '../../graphql/generated';

export const GetSubscriptionDataDocument = graphql(/* GraphQL */ `
    query GetSubscriptionData {
        listSubscriptionProducts {
            ...SubscriptionProducts_SubscriptionProduct
        }
    }
`);

export const SubscriptionProductsSubscriptionProductFragmentDocument = graphql(/* GraphQL */ `
    fragment SubscriptionProducts_SubscriptionProduct on SubscriptionProduct {
        productId
        priceId
        amount
        name
        recurringPeriod
    }
`);

export const GetSubscriptionPromotionCodeDocument = graphql(/* GraphQL */ `
    query GetSubscriptionPromotionalCode($code: String!) {
        getSubscriptionPromotionalCode(code: $code) {
            id
            name
            percent_off
            amount_off
            duration
            duration_in_months
            applies_to_products
        }
    }
`);

export const CreateSubscriptionDocument = graphql(/* GraphQL */ `
    mutation CreateSubscription($priceId: String!, $stripePaymentMethodId: String!, $code: String) {
        createSubscription(
            priceId: $priceId
            stripePaymentMethodId: $stripePaymentMethodId
            code: $code
        ) {
            clientSecret
            type
        }
    }
`);
