import React from 'react';
import {useMatch, Link} from 'react-router-dom';
import classnames from 'classnames';

import {
    bgColorClassnames,
    outlineColorClassnames,
    textColorClassnames,
    VStack,
} from '@sphericsio/design-system';

import {User, useUser} from './user-context';
import {
    NavCategorisationIcon,
    NavDashboardIcon,
    NavOperationsIcon,
    NavProfileIcon,
    NavSuppliersIcon,
    NavTargetingIcon,
} from './icons';
import {TopHeader} from './top-header';
import {ButtonLink, NavLink} from './link';
import loggedOutBg from '../images/logged-out-bg.jpg';
import {useFeatureFlag} from '../hooks/use-feature-flag';
import {SageEarthLogo} from './logo';

type NavItemProps = {
    to: string;
    icon: React.ReactNode;
    children: string;
    exact?: boolean;
    intercomTarget?: string;
};

function NavItem({to, exact, icon, intercomTarget, children}: NavItemProps) {
    const active = useMatch({path: to, end: exact == null ? false : exact});
    return (
        <div
            className={classnames(
                'w-full pl-5 py-2.5 hover:bg-action-major-500',
                active && bgColorClassnames('nav-item'),
            )}
        >
            <NavLink to={to} icon={icon} intercomTarget={intercomTarget}>
                {children}
            </NavLink>
        </div>
    );
}

type LoggedInLayoutProps = {
    children: React.ReactNode;
    currentUser: User;
    title?: string;
    subtitle?: React.ReactNode;
    tooltip?: React.ReactNode;
    backLink?: string;
    backLinkText?: string;
    separator?: boolean;
    hasNavBar?: boolean;
};

const dashboard = {
    to: '/dashboard',
    icon: <NavDashboardIcon />,
    intercomTarget: 'Navbar: Dashboard',
    text: 'Dashboard',
};
const categorisation = {
    to: '/categorisation',
    icon: <NavCategorisationIcon />,
    intercomTarget: 'Navbar: Categorisation',
    text: 'Categorisation',
};
const suppliers = {
    to: '/suppliers',
    icon: <NavSuppliersIcon />,
    intercomTarget: 'Navbar: Suppliers',
    text: 'Suppliers',
};
const operations = {
    to: '/operations',
    icon: <NavOperationsIcon />,
    intercomTarget: 'Navbar: Company Profile',
    text: 'Operations',
};
const targeting = {
    to: '/targeting',
    icon: <NavTargetingIcon />,
    intercomTarget: 'Navbar: Targeting',
    text: 'Targeting',
};
const baseline = {
    to: '/baseline',
    icon: <NavProfileIcon />,
    intercomTarget: 'Navbar: Baseline',
    text: 'Baseline Year',
};

const navigationItemsWithCategorisation = [
    dashboard,
    categorisation,
    operations,
    suppliers,
    targeting,
    baseline,
];

const navigationItemsLegacy = [dashboard, suppliers, operations, targeting, baseline];

const LoggedInLayout: React.FC<LoggedInLayoutProps> = ({
    children,
    currentUser,
    title,
    subtitle,
    tooltip,
    backLink,
    backLinkText,
    separator = false,
    hasNavBar = false,
}) => {
    const isTransactionCategorisationFFEnabled = useFeatureFlag('new_categorisation_page');
    const navigationItems = isTransactionCategorisationFFEnabled
        ? navigationItemsWithCategorisation
        : navigationItemsLegacy;
    return (
        <div className={`flex flex-1 ${bgColorClassnames('layout')} h-screen w-screen`}>
            <div className="flex items-start h-full overflow-y-hidden">
                <div
                    className={`flex flex-col min-h-screen ${bgColorClassnames(
                        'navigation',
                    )} border-r ${outlineColorClassnames('navigation')}/10 ${textColorClassnames(
                        'white',
                    )}`}
                >
                    <nav className="flex flex-col flex-1">
                        <VStack align="start">
                            <div className="pb-10 pr-10 pl-8 pt-5">
                                <div className="h-12 w-10/12">
                                    <Link to="/dashboard" className="h-full">
                                        <SageEarthLogo className="h-full" />
                                    </Link>
                                </div>
                            </div>
                            {navigationItems.map(({to, icon, intercomTarget, text}, idx) => (
                                <NavItem
                                    key={`${to}-${idx}`}
                                    to={to}
                                    icon={icon}
                                    intercomTarget={intercomTarget}
                                >
                                    {text}
                                </NavItem>
                            ))}
                        </VStack>
                    </nav>
                </div>
            </div>
            <div
                className={classnames('flex flex-1 flex-col p-10 overflow-y-auto', {
                    'divide-y': separator,
                })}
            >
                <div
                    className={classnames({
                        'pl-10': hasNavBar,
                    })}
                >
                    <TopHeader
                        user={currentUser}
                        title={title}
                        subtitle={subtitle}
                        backLink={backLink}
                        backLinkText={backLinkText}
                        tooltip={tooltip}
                    />
                </div>
                {children}
            </div>
        </div>
    );
};

type FullWidthLayout = {
    children: React.ReactNode;
};

export function FullWidthLayout({children}: FullWidthLayout) {
    return (
        <div className="flex flex-1 flex-col">
            <div className="my-5 ml-5">
                <Link to="/">
                    <SageEarthLogo />
                </Link>
            </div>
            <div className="flex flex-1 px-20 py-10">{children}</div>
        </div>
    );
}

type LoggedOutLayoutProps = {
    navLinkTo?: string;
    navLinkLabel?: string;
    children: React.ReactNode;
};

export function LoggedOutLayout({children, navLinkLabel, navLinkTo}: LoggedOutLayoutProps) {
    return (
        <div className="flex flex-1">
            <div className="flex w-7/12 flex-col">
                <div className="w-40 my-5 ml-5">
                    <Link to="/">
                        <SageEarthLogo />
                    </Link>
                </div>
                <div className="flex flex-1 px-20 py-10">{children}</div>
            </div>
            <div className="flex fixed right-0 top-0 bottom-0 w-5/12">
                <div className="w-1/3 mt-6">
                    <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        viewBox="0 0 172 236"
                    >
                        <circle fill="#98D26B" cx="86" cy="86" r="86" />
                        <circle fill="#009465" cx="28.5" cy="207.5" r="28.5" />
                    </svg>
                </div>
                <div className="flex relative w-2/3 overflow-hidden justify-end items-start">
                    <div
                        className="absolute inset-0 bg-no-repeat bg-cover rounded-l-full"
                        style={{
                            backgroundImage: `url(${loggedOutBg})`,
                            transform: 'scale(1, 1.3)',
                        }}
                    />
                    <div className="mr-5 mt-5 z-10">
                        {navLinkTo != null && navLinkLabel != null && (
                            <div className={textColorClassnames('logo')}>
                                <ButtonLink to={navLinkTo} outline>
                                    {navLinkLabel}
                                </ButtonLink>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

type LayoutProps = Omit<LoggedInLayoutProps, 'currentUser'> & {
    children: React.ReactNode;
};

export function Layout({children, ...rest}: LayoutProps) {
    const user = useUser();

    return (
        <div className="flex flex-1">
            <LoggedInLayout currentUser={user} {...rest}>
                {children}
            </LoggedInLayout>
        </div>
    );
}
