import React, {useRef} from 'react';
import {NotifiableError} from '@bugsnag/js';

import {
    Heading2,
    LoadingSpinner,
    Paragraph,
    Button,
    Heading1,
    ButtonLink,
    bgColorClassnames,
} from '@sphericsio/design-system';
import {getDisplayMessage} from '@sphericsio/mvp-ui-common';

import errorImg from '../images/error.svg';
import {useErrorNotifier} from '../hooks/error-notifier';
import {SageEarthLogo} from './logo';

export const FullScreenLayout: React.FC<{children: React.ReactNode}> = ({children}) => {
    return (
        <div className="flex flex-1 flex-col">
            <div className={`flex w-full ${bgColorClassnames('black')} py-3.5`}>
                <div className="w-44 pl-8">
                    <a href="/">
                        <SageEarthLogo />
                    </a>
                </div>
            </div>
            <div className="flex flex-1 items-center justify-center p-10">{children}</div>
        </div>
    );
};

export const FullScreenLoading: React.FC<{message?: string}> = ({message}) => {
    return (
        <FullScreenLayout>
            <div className="flex flex-col items-center justify-center">
                <LoadingSpinner size={40} />
                <Heading2>{message ?? 'Loading...'}</Heading2>
            </div>
        </FullScreenLayout>
    );
};

type FullScreenErrorProps = {
    heading?: string;
    message?: string | React.ReactNode;
    buttonLabel?: string;
    buttonHref?: string;
    error?: NotifiableError;
};

const defaultMessage = "🔨 We're currently having problems, please try again later";

export function FullScreenError({
    heading = 'Something went wrong',
    message,
    buttonLabel = 'Try Again',
    buttonHref,
    error,
}: FullScreenErrorProps) {
    const textRef = useRef(null);
    useErrorNotifier(true, error, textRef);
    const displayMessage = getDisplayMessage(message, error, undefined, defaultMessage);
    return (
        <FullScreenLayout>
            <div className="flex flex-col items-center">
                <img className="w-75" src={errorImg} alt="error image" />
                <div className="mt-carbon-500">
                    <Heading1>{heading}</Heading1>
                </div>
                <div className="mt-carbon-125">
                    <Paragraph ref={textRef}>{displayMessage}</Paragraph>
                </div>
                <div className="mt-carbon-400">
                    {buttonHref != null && <ButtonLink href={buttonHref}>{buttonLabel}</ButtonLink>}
                    {buttonHref == null && (
                        <Button onPress={() => document.location.reload()}>{buttonLabel}</Button>
                    )}
                </div>
            </div>
        </FullScreenLayout>
    );
}
