import React from 'react';
import {useQuery} from '@apollo/client';

import {
    Button,
    DetailText,
    HStack,
    StickyHeaderList,
    Strong,
    TextButton,
    Toggle,
    VStack,
} from '@sphericsio/design-system';

import {Loading} from '../../loading';
import {ErrorCard} from '../../error-card';
import {moneyAsValue, MoneyDisplay} from '../../money';
import {DateDisplay} from '../../date-display';
import {TabPageHeader} from '../../page-header';
import {MerchantTransactionData, useSuggestedTransactionsState} from '../hooks';
import {CompanyReportingPeriod} from '../../../graphql/generated/graphql';
import {GetSuggestedGhgSpendsDocument} from './graphql';

type ProfileGHGSuggestedProps = {
    onNext: (value: {transactions: Record<string, boolean>; totalSpend: number}) => void;
    onSkip: () => void;
    reportingPeriod: CompanyReportingPeriod;
    defaultValue?: boolean;
    confirmedSpend?: MerchantTransactionData[];
};
export function ProfileGHGSuggested({
    reportingPeriod,
    onNext,
    onSkip,
    defaultValue,
    confirmedSpend,
}: ProfileGHGSuggestedProps) {
    const {loading, error, data} = useQuery(GetSuggestedGhgSpendsDocument, {
        variables: {from: reportingPeriod.period_start, to: reportingPeriod.period_end},
    });
    React.useEffect(() => {
        if (data != null && data.getSuggestedGHGTransactions.length === 0) {
            onSkip();
        }
    }, [data]);

    const {transactionStates, setAllTransactionsToValue, toggleTransactionState} =
        useSuggestedTransactionsState(
            data?.getSuggestedGHGTransactions,
            defaultValue,
            confirmedSpend,
        );

    const submit = () => {
        const totalSpend = data?.getSuggestedGHGTransactions.reduce((amount, merchant) => {
            return (
                amount +
                merchant.transactions.reduce((merchantSpend, txn) => {
                    if (transactionStates[txn.id]) {
                        return merchantSpend + moneyAsValue(txn.amount);
                    } else {
                        return merchantSpend;
                    }
                }, 0)
            );
        }, 0);
        onNext({transactions: transactionStates, totalSpend: totalSpend ?? 0});
    };

    return (
        <div>
            {loading && <Loading />}
            {error && <ErrorCard />}
            {data && (
                <VStack>
                    <TabPageHeader
                        title="Are the transactions in this list for vehicle fuel (petrol or diesel)?"
                        subtitle="To ensure your carbon impact is accurate, please confirm the following transactions are fuel purchases."
                    />
                    <div className="flex-1">
                        <StickyHeaderList
                            headers={data.getSuggestedGHGTransactions}
                            items={data.getSuggestedGHGTransactions.map(
                                (merchant) => merchant.transactions,
                            )}
                            renderHeader={(merchant) => (
                                <HStack>
                                    <div className="flex-1">
                                        <DetailText inline={false}>
                                            <Strong>{merchant.merchant_name}</Strong>
                                        </DetailText>
                                        <DetailText inline={false} bold>
                                            We have identified {merchant.transactions.length}{' '}
                                            transactions from this supplier.
                                        </DetailText>
                                    </div>
                                    <HStack>
                                        <div
                                            className="cursor-pointer"
                                            onClick={() =>
                                                setAllTransactionsToValue(
                                                    merchant.transactions.map(
                                                        (transaction) => transaction.id,
                                                    ),
                                                    false,
                                                )
                                            }
                                        >
                                            <DetailText bold>No to all</DetailText>
                                        </div>
                                        <div
                                            className="cursor-pointer"
                                            onClick={() =>
                                                setAllTransactionsToValue(
                                                    merchant.transactions.map(
                                                        (transaction) => transaction.id,
                                                    ),
                                                    true,
                                                )
                                            }
                                        >
                                            <DetailText bold>Yes to all</DetailText>
                                        </div>
                                    </HStack>
                                </HStack>
                            )}
                            renderItem={(transaction) => (
                                <DetailText inline={false}>
                                    <HStack>
                                        <div className="w-1/6">
                                            <DetailText bold>
                                                <DateDisplay>
                                                    {transaction.transaction_date}
                                                </DateDisplay>
                                            </DetailText>
                                        </div>
                                        <div className="w-1/12 text-left">
                                            <MoneyDisplay money={transaction.amount} />
                                        </div>
                                        <div className="flex-1">
                                            <DetailText>{transaction.description}</DetailText>
                                        </div>
                                        <div className="pr-5">
                                            <Toggle
                                                value={transactionStates[transaction.id]}
                                                onToggle={() =>
                                                    toggleTransactionState(transaction.id)
                                                }
                                                onLabel="Yes"
                                                offLabel="No"
                                            />
                                        </div>
                                    </HStack>
                                </DetailText>
                            )}
                        />
                    </div>
                    <HStack>
                        {data.getSuggestedGHGTransactions.length > 0 && (
                            <TextButton
                                onPress={() => {
                                    const value = setAllTransactionsToValue(
                                        Object.keys(transactionStates),
                                        false,
                                    );
                                    onNext({transactions: value, totalSpend: 0});
                                }}
                            >
                                I don&apos;t recognise any of these transactions
                            </TextButton>
                        )}
                        <Button onPress={submit}>Save and continue</Button>
                    </HStack>
                </VStack>
            )}
        </div>
    );
}
