import React from 'react';

import {Heading1, VStack} from '@sphericsio/design-system';

import {HSplitLayout} from '../../components/layout/h-split';
import {FinancialProviderButtons} from '../../components/financial-provider-buttons';
import {PosterLayout} from '../../components/onboarding/poster-layout';
import {PosterJoinTheMovement} from '../../components/onboarding/poster-join-the-movement';
import {SubscriptionsModal} from '../../components/subscription-modal';

export function LoginAccounting() {
    const [showModal, setShowModal] = React.useState<boolean>(false);

    return (
        <HSplitLayout wide cta="login">
            <VStack>
                <SubscriptionsModal
                    show={showModal}
                    onClose={() => setShowModal(false)}
                    onError={() => setShowModal(false)}
                    onSuccess={() => setShowModal(false)}
                />
                <Heading1>Sign in to Sage Earth with your chosen accounting platform</Heading1>
                <div className="py-10">
                    <FinancialProviderButtons
                        forLogin
                        hasActiveSubscription={false}
                        hasPendingSubscription={false}
                    />
                </div>
            </VStack>
            <PosterLayout>
                <PosterJoinTheMovement />
            </PosterLayout>
        </HSplitLayout>
    );
}
