import React from 'react';
import {ApolloError, useQuery} from '@apollo/client';
import * as Yup from 'yup';
import {useField} from 'formik';

import {
    DetailText,
    FormControl,
    FormErrorMessage,
    HStack,
    Modal,
    SelectInput,
    TextButton,
    textColorClassnames,
    VStack,
} from '@sphericsio/design-system';
import {Form, Input, Button as FormButton} from '@sphericsio/forms';

import {TabPageHeader} from '../../page-header';
import {ReportingPeriodDates} from '../reporting-period-dates';
import {MinusCircleIcon, PlusCircleIcon} from '../../icons';
import {ErrorCard} from '../../error-card';
import {GHGGasQuantityEntry} from './types';
import {CompanyReportingPeriod} from '../../../graphql/generated/graphql';
import {GetGhgListDocument} from './graphql';

type GHGQuantityEntry = {
    gasType: string;
    kgs: string;
};

const FORM_SCHEMA = Yup.object({
    gases: Yup.array(
        Yup.object({
            gasType: Yup.string().required('Please select a gas'),
            kgs: Yup.number().required('Please enter a value').typeError('Please enter a number'),
        }).required(),
    )
        .min(1, 'Please enter at least one gas')
        .unique('gasType', 'Duplicate gas type'),
});

type GHGEntryEditorProps = {
    options?: {id: string; label: string}[];
    index: number;
    onRemove: (index: number) => void;
    isLoading?: boolean;
};
function GHGEntryEditor({options, index, onRemove, isLoading}: GHGEntryEditorProps) {
    const [gasTypeField, gasTypeMeta, gasTypeHelpers] = useField(`gases.${index}.gasType`);
    const kgsName = `gases.${index}.kgs`;
    const value = gasTypeField.value;
    const item = value != null ? {id: value, label: value} : undefined;

    return (
        <HStack align="start">
            <div
                className={`h-5 w-5 my-3 ${textColorClassnames('error')}`}
                onClick={() => onRemove(index)}
            >
                <MinusCircleIcon />
            </div>
            <div className="w-80">
                <FormControl isInvalid={gasTypeMeta.error != null}>
                    <SelectInput
                        name={gasTypeField.name}
                        value={item}
                        placeholder="Select a gas from the list below"
                        onChange={(item) => {
                            gasTypeHelpers.setValue(item.id);
                            gasTypeHelpers.setTouched(true);
                        }}
                        items={options}
                        isInvalid={gasTypeMeta.error != null}
                        isLoading={isLoading}
                    />
                    <FormErrorMessage>{gasTypeMeta.error}</FormErrorMessage>
                </FormControl>
            </div>
            <Input name={kgsName} placeholder="How much?" showLabel={false} rightLabel="Kg" />
        </HStack>
    );
}

function GHGEntriesEditor() {
    const {loading, error, data} = useQuery(GetGhgListDocument);
    const [field, meta, helpers] = useField<GHGQuantityEntry[]>('gases');
    const onRemove = (indexToRemove: number) =>
        helpers.setValue(field.value.filter((val, index) => index !== indexToRemove));

    const fieldError = Array.isArray(meta.error) ? undefined : meta.error;
    const options =
        data == null ? undefined : data.getGHGList.map((value) => ({id: value, label: value}));
    return (
        <FormControl isInvalid={fieldError != null}>
            {field.value.map((value, index) => (
                <GHGEntryEditor
                    key={index}
                    options={options}
                    isLoading={loading}
                    index={index}
                    onRemove={onRemove}
                />
            ))}
            <div onClick={() => helpers.setValue(field.value.concat({gasType: '', kgs: ''}))}>
                <HStack>
                    <div className={`h-5 w-5 ${textColorClassnames('success')}`}>
                        <PlusCircleIcon />
                    </div>
                    <DetailText>Add another</DetailText>
                </HStack>
            </div>
            <FormErrorMessage>{fieldError}</FormErrorMessage>
            {error && (
                <div className="mb-5">
                    <ErrorCard />
                </div>
            )}
        </FormControl>
    );
}

type ProfileGHGGasQuantitiesProps = {
    reportingPeriod: CompanyReportingPeriod;
    error?: ApolloError;
    submitting?: boolean;
    onNext: (value: GHGGasQuantityEntry[]) => void;
    onSkip: () => void;
    gasEntries?: GHGQuantityEntry[];
};
export function ProfileGHGGasQuantities({
    reportingPeriod,
    error,
    submitting,
    onNext,
    onSkip,
    gasEntries,
}: ProfileGHGGasQuantitiesProps) {
    const [showingModal, setShowingModal] = React.useState(false);
    const subtitle = <ReportingPeriodDates reportingPeriod={reportingPeriod} />;
    return (
        <>
            <VStack testId="fGasesForm">
                <TabPageHeader title="Tell us about your 'F-Gases'" subtitle={subtitle} />
                <Form
                    initialValues={{gases: gasEntries ?? [{gasType: '', kgs: ''}]}}
                    validationSchema={FORM_SCHEMA}
                    onSubmit={(values) => {
                        const payload = values.gases.map((value) => ({
                            gasType: value.gasType,
                            kg: parseFloat(value.kgs),
                        }));
                        onNext(payload);
                    }}
                >
                    <GHGEntriesEditor />
                    <HStack>
                        <TextButton
                            onPress={() => {
                                setShowingModal(true);
                            }}
                        >
                            I don&apos;t know
                        </TextButton>
                        <FormButton type="submit" isLoading={submitting}>
                            Save and continue
                        </FormButton>
                    </HStack>
                </Form>
                {error && <ErrorCard />}
            </VStack>
            <Modal
                isOpen={showingModal}
                onClose={() => {
                    setShowingModal(false);
                    onSkip();
                }}
                panelBg="alert"
            >
                <VStack>
                    <DetailText inline={false}>
                        Your f-gas maintenance partner is legally obliged to keep records of the
                        gases used to refill your units. If you can’t aquire this data we can use a
                        coarse estimate based on spend, however due to the huge range of possible
                        harmful effects from different gases, this estimate is unreliable.
                    </DetailText>
                    <DetailText inline={false} bold>
                        If you can aquire this data, please come back at a later date to complete
                        this section.
                    </DetailText>
                </VStack>
            </Modal>
        </>
    );
}
