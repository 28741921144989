import {ApolloError} from '@apollo/client';
import React from 'react';

import {UploadRequestType} from '../../graphql/generated/graphql';
import {DataExportSource} from '../file-upload-wizard/context';

export type UploadRequestResponse = {
    id: string;
    uploadUrl: string;
};

type FileUploadAction =
    | {type: 'queueFile'; payload: FileUploadState}
    | {type: 'uploadRequested'}
    | {type: 'uploadRequestFailure'; payload: ApolloError}
    | {type: 'uploadStarted'; payload: UploadRequestResponse}
    | {type: 'uploadProgressed'; payload: number}
    | {type: 'uploadSuccess'}
    | {type: 'uploadFailure'; payload: any}
    | {type: 'markUploadCompleteSuccess'}
    | {type: 'markUploadCompleteFailure'; payload: ApolloError};

export type FileUploadStatus =
    | 'queued'
    | 'uploadRequested'
    | 'uploadRequestFailed'
    | 'uploading'
    | 'uploadSuccess'
    | 'uploadComplete'
    | 'uploadFailed'
    | 'markUploadCompleteFailed';

export type FileUploadState = {
    status: FileUploadStatus;
    tempId: string;
    exportSource: DataExportSource;
    uploadRequestType: UploadRequestType;
    file: File;
    id?: string;
    error?: any;
    uploadUrl?: string;
    uploadPercent?: number;
};

export function useFileUploadReducer() {
    const initialState = {} as FileUploadState;

    return React.useReducer((state: FileUploadState, action: FileUploadAction): FileUploadState => {
        switch (action.type) {
            case 'queueFile':
                return {
                    status: 'queued',
                    tempId: action.payload.tempId,
                    file: action.payload.file,
                    exportSource: action.payload.exportSource,
                    uploadRequestType: action.payload.uploadRequestType,
                };

            case 'uploadRequested':
                if (state.status !== 'queued') {
                    return state;
                }

                return {
                    ...state,
                    status: 'uploadRequested',
                };

            case 'uploadRequestFailure':
                if (state.status !== 'uploadRequested') {
                    return state;
                }

                return {
                    ...state,
                    status: 'uploadRequestFailed',
                    error: action.payload,
                };

            case 'uploadStarted':
                if (state.status !== 'uploadRequested') {
                    return state;
                }

                return {
                    ...state,
                    status: 'uploading',
                    id: action.payload.id,
                    uploadUrl: action.payload.uploadUrl,
                    uploadPercent: 0,
                };

            case 'uploadProgressed':
                if (state.status !== 'uploading') {
                    return state;
                }

                return {...state, uploadPercent: action.payload};

            case 'uploadSuccess':
                if (state.status !== 'uploading') {
                    return state;
                }

                return {...state, status: 'uploadSuccess'};

            case 'uploadFailure':
                if (state.status !== 'uploading') {
                    return state;
                }
                return {...state, status: 'uploadFailed', error: action.payload};

            case 'markUploadCompleteSuccess':
                if (state.status !== 'uploadSuccess') {
                    return state;
                }

                return {...state, status: 'uploadComplete'};

            case 'markUploadCompleteFailure':
                return {...state, status: 'markUploadCompleteFailed', error: action.payload};

            default:
                return state;
        }
    }, initialState);
}
