import React from 'react';
import classnames from 'classnames';

import {
    ButtonLabel,
    CategorisationThemeIcon,
    CategoryTheme,
    CircleCross,
    CirclePlusIcon,
    DetailText,
    HStack,
    LoadingSpinner,
    textColorClassnames,
} from '@sphericsio/design-system';

import {FinancialAccounts} from '../../graphql/generated/graphql';
import {useCategoryTheme, usePrepareCategory} from '../../hooks/categorisation';
import {FinancialAccountCategorisation} from '../categorisation/financial-account/financial-account-categorisation';
import {UserCategorisationHierarchyItem} from '../categorisation/hierarchy';
import {useConfirmAccount} from '../../hooks/use-confirm-account';

type FinancialAccountCheckboxProps = {
    onChange: (id: string) => void;
    account: FinancialAccounts;
};

const FinancialAccountCheckbox = ({onChange, account}: FinancialAccountCheckboxProps) => {
    const [checked, setChecked] = React.useState(false);

    const disabled = React.useMemo(() => {
        if (hasTaxonomy(account.user_terms) || !hasTaxonomy(account.terms)) {
            return true;
        }
        return false;
    }, [account]);

    React.useEffect(() => {
        if (hasTaxonomy(account.user_terms)) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [account]);

    return (
        <input
            className="ml-2 w-5 h-5"
            id={account.id}
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={(evt) => {
                setChecked(evt.target.checked);
                onChange(account.id);
            }}
        />
    );
};

const FinancialAccountsCategorisation = ({
    id,
    excludeCategory,
    category,
    theme,
    refreshData,
}: {
    id: string;
    excludeCategory?: string | null;
    category?: UserCategorisationHierarchyItem[];
    theme: CategoryTheme;
    refreshData: () => void;
}) => {
    return (
        <FinancialAccountCategorisation
            id={id}
            excludeCategory={excludeCategory}
            data={category}
            theme={theme}
            onSaveSuccess={refreshData}
        />
    );
};

function hasTaxonomy({
    taxonomy_key,
    taxonomy_term_key,
}: {
    taxonomy_key?: string | null;
    taxonomy_term_key?: string | null;
}) {
    return taxonomy_key && taxonomy_term_key;
}

export const FinancialAccountRow = ({
    account,
    refreshData,
    isAccountIgnored,
    handleExcludeClick,
    handleExpand,
    showTransactions,
    currentAccountId,
}: {
    account: FinancialAccounts;
    refreshData: () => void;
    isAccountIgnored: (account: FinancialAccounts) => boolean;
    handleExcludeClick: (account: FinancialAccounts) => void;
    handleExpand: (account: FinancialAccounts) => void;
    showTransactions: boolean;
    currentAccountId?: string;
}) => {
    const {saveAccount, loading} = useConfirmAccount(refreshData);

    const term = React.useMemo(() => {
        return hasTaxonomy(account.user_terms)
            ? `${account.user_terms.taxonomy_key}/${account.user_terms.taxonomy_term_key}`
            : hasTaxonomy(account.terms)
              ? `${account.terms.taxonomy_key}/${account.terms.taxonomy_term_key}`
              : '';
    }, [account]);

    const excludeCategory = React.useMemo(() => {
        return account.user_terms.taxonomy_key === 'ignored'
            ? account.user_terms.taxonomy_term_key
            : account.terms.taxonomy_key === 'ignored'
              ? account.terms.taxonomy_term_key
              : null;
    }, [account]);

    const showField = React.useMemo(() => {
        if (hasTaxonomy(account.user_terms) || hasTaxonomy(account.terms)) {
            return true;
        }
        return false;
    }, [account]);

    const category = usePrepareCategory(term);
    const theme = useCategoryTheme(category, !!excludeCategory);

    return (
        <div
            className={classnames('flex flex-row justify-between items-center', {
                'text-yin-65': isAccountIgnored(account),
            })}
        >
            <div className="w-20">
                <HStack>
                    <CategorisationThemeIcon theme={theme} />
                    <DetailText bold>{account.name}</DetailText>
                </HStack>
            </div>
            <div
                className={classnames('flex flex-row w-60', {
                    'mr-14': !showField,
                })}
            >
                <FinancialAccountsCategorisation
                    id={account.id}
                    category={category}
                    theme={theme}
                    excludeCategory={excludeCategory}
                    refreshData={refreshData}
                />
            </div>
            <div className="pl-5">
                <HStack>
                    {showField &&
                        (loading ? (
                            <div className="ml-2 w-5 h-5">
                                <LoadingSpinner size={18} />
                            </div>
                        ) : (
                            <FinancialAccountCheckbox account={account} onChange={saveAccount} />
                        ))}
                    <div
                        className={classnames('cursor-pointer pl-10', {
                            '': !showField,
                        })}
                        onClick={() => handleExpand(account)}
                    >
                        <ButtonLabel size="medium" colour="minor-action">
                            {showTransactions && currentAccountId === account.id
                                ? 'hide transactions'
                                : 'view transactions'}
                        </ButtonLabel>
                    </div>
                </HStack>
            </div>
            <div>
                <div className="w-6 h-6 cursor-pointer" onClick={() => handleExcludeClick(account)}>
                    {isAccountIgnored(account) ? (
                        <span data-testid="includeButton" className="text-action-major-500">
                            <CirclePlusIcon />
                        </span>
                    ) : (
                        <span data-testid="excludeButton" className={textColorClassnames('error')}>
                            <CircleCross />
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};
