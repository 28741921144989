import React from 'react';

import {CircleCross, DetailText, textColorClassnames, VStack} from '@sphericsio/design-system';

import {HSplitLayout} from '../../layout/h-split';
import {PageHeader} from '../../page-header';
import {PosterLayout} from '../../onboarding/poster-layout';
import {PosterUploadFullYear} from '../../onboarding/poster-upload-ful-year';
import {NavigationButtons} from '../../navigation-buttons';
import {UploadContext} from '../context';

function WarningListItem({message}: {message: string}) {
    return (
        <div className="flex space-x-4">
            <div className={`w-6 h-6 ${textColorClassnames('error')}`}>
                <CircleCross />
            </div>
            <DetailText>{message}</DetailText>
        </div>
    );
}
export function UploadWarning() {
    const {dispatch} = React.useContext(UploadContext);

    return (
        <HSplitLayout cta="logout">
            <VStack large>
                <PageHeader
                    title="You have not uploaded a complete financial years worth of data"
                    subtitle="Some features within Sage Earth can only be used if we have a complete financial years worth of data."
                />
                <DetailText bold>You will not be able to access:</DetailText>
                <div className="space-y-5">
                    <WarningListItem message="Targeting" />
                    <WarningListItem message="Baseline year" />
                </div>
                <DetailText bold>Would you like to upload more data?</DetailText>
                <NavigationButtons
                    backColour="major-action"
                    backOnClick={() => dispatch({type: 'limitedDataWarningAcknowledged'})}
                    nextOnClick={() => dispatch({type: 'companyDetailsSet'})}
                    nextToDisabled={false}
                    nextLabel="Yes, upload more"
                    backLabel="No, continue without"
                />
            </VStack>
            <PosterLayout bgImage={{type: 'onboarding'}}>
                <PosterUploadFullYear />
            </PosterLayout>
        </HSplitLayout>
    );
}
