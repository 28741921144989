import React from 'react';
import {Link} from 'react-router-dom';

import {Panel, HStack, DetailText, VStack, Heading3} from '@sphericsio/design-system';

import {FragmentType, graphql, useFragment} from '../graphql/generated';
import {ActionsPanel_ActionItemFragment} from '../graphql/generated/graphql';

function actionItemDisplay(
    actionItem: ActionsPanel_ActionItemFragment,
): {message: string; link?: string} | undefined {
    switch (actionItem.action_type) {
        case 'failed_financial_provider_sync':
            return {
                message:
                    'There was a problem syncing your financial data. Please click here to reconnect.',
                link: '/settings/data-connection',
            };
        case 'failed_data_upload':
            return {
                message: 'Your data upload has been unsuccessful. Please re-upload your file.',
                link: '/settings/data-connection',
            };
        default:
            return undefined;
    }
}

const ActionsPanelActionItemFragmentDoc = graphql(/* GraphQL */ `
    fragment ActionsPanel_ActionItem on ActionItem {
        id
        action_type
        created_at
    }
`);

type ActionsPanelProps = {
    actions?: FragmentType<typeof ActionsPanelActionItemFragmentDoc>[];
};
export function ActionsPanel(props: ActionsPanelProps) {
    const actions = useFragment(
        ActionsPanelActionItemFragmentDoc,
        props.actions,
    ) as ActionsPanel_ActionItemFragment[];

    return (
        <Panel borderColor="minor-action" bg="minor-action">
            <VStack full>
                <HStack>
                    <div className="flex flex-1 items-center space-x-1">
                        <Heading3>Actions</Heading3>
                        <DetailText bold>({actions == null ? '0' : actions.length})</DetailText>
                    </div>
                </HStack>
                {actions != null && actions.length > 0 && (
                    <VStack seperator>
                        {actions.map((actionItem) => {
                            const display = actionItemDisplay(actionItem);
                            if (display == null) {
                                return null;
                            }

                            const {message, link} = display;
                            const topElProps = {className: 'py-2.5 last:pb-0'};
                            const messageEl = (
                                <DetailText inline={false} {...topElProps}>
                                    {message}
                                </DetailText>
                            );

                            if (link != null) {
                                return (
                                    <Link key={actionItem.id} to={link} {...topElProps}>
                                        {messageEl}
                                    </Link>
                                );
                            } else {
                                return messageEl;
                            }
                        })}
                    </VStack>
                )}
            </VStack>
        </Panel>
    );
}
