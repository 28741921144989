import React from 'react';
import {ApolloError, useQuery} from '@apollo/client';

import {
    GetSubscriptionDataDocument,
    SubscriptionProductsSubscriptionProductFragmentDocument,
} from './graphql';
import {PromotionDetails} from './promo-code-input';
import {useUser} from '../user-context';
import {useFragment} from '../../graphql/generated';
import {SubscriptionProducts_SubscriptionProductFragment} from '../../graphql/generated/graphql';

type SubscriptionStep = 'init' | 'products' | 'cardDetails' | 'submission' | 'skip';

type SubscriptionAction =
    | {type: 'begin'}
    | {type: 'hasActiveSubscription'}
    | {type: 'productsReceived'; payload: SubscriptionProducts_SubscriptionProductFragment[]}
    | {type: 'selectPackage'}
    | {type: 'productSelected'; payload: string}
    | {type: 'productSelectionSubmitted'}
    | {type: 'stripeSuccess'}
    | {type: 'setPromotion'; payload: PromotionDetails}
    | {type: 'unsetPromotion'};

type SubscriptionState = {
    currentStep: SubscriptionStep;
    subscriptionProducts?: SubscriptionProducts_SubscriptionProductFragment[];
    selectedProduct?: SubscriptionProducts_SubscriptionProductFragment;
    promotion?: PromotionDetails;
    hasActiveSubscription: boolean;
};

function useSubscriptionReducer() {
    const initialState = {
        currentStep: 'init' as SubscriptionStep,
        hasActiveSubscription: false,
    };

    return React.useReducer(
        (state: SubscriptionState, action: SubscriptionAction): SubscriptionState => {
            switch (action.type) {
                case 'begin':
                    return {...state, currentStep: 'init'};
                case 'hasActiveSubscription':
                    return {...state, hasActiveSubscription: true};
                case 'productsReceived':
                    return {
                        ...state,
                        currentStep: 'products',
                        subscriptionProducts: action.payload,
                    };
                case 'selectPackage':
                    return {
                        ...state,
                        currentStep: 'products',
                    };
                case 'productSelected':
                    return {
                        ...state,
                        selectedProduct: state.subscriptionProducts?.find(
                            ({priceId}) => priceId === action.payload,
                        ),
                    };
                case 'productSelectionSubmitted':
                    return {
                        ...state,
                        currentStep: 'cardDetails',
                    };
                case 'setPromotion':
                    return {...state, promotion: action.payload};
                case 'unsetPromotion':
                    return {...state, promotion: undefined};
                case 'stripeSuccess':
                    return {...state, currentStep: 'submission'};
            }
        },
        initialState,
    );
}

type SubscriptionContextValue = {
    dispatch: (action: SubscriptionAction) => void;
    currentStep: SubscriptionStep;
    subscriptionProducts?: SubscriptionProducts_SubscriptionProductFragment[];
    promotion?: PromotionDetails;
    selectedProduct?: SubscriptionProducts_SubscriptionProductFragment;
    hasActiveSubscription: boolean;
    loading: boolean;
    error?: ApolloError;
};

export const SubscriptionContext = React.createContext<SubscriptionContextValue>({
    dispatch: () => {
        // placeholder
    },
    hasActiveSubscription: false,
    currentStep: 'init',
    loading: false,
});

type SubscriptionContextProviderProps = {
    children: React.ReactNode;
};

export function SubscriptionContextProvider({children}: SubscriptionContextProviderProps) {
    const [state, dispatch] = useSubscriptionReducer();
    const user = useUser();

    if (user.subscription?.status === 'active' && state.currentStep === 'init') {
        dispatch({type: 'hasActiveSubscription'});
    }
    const {loading, error, data} = useQuery(GetSubscriptionDataDocument);

    React.useEffect(() => {
        if (data?.listSubscriptionProducts) {
            dispatch({
                type: 'productsReceived',
                payload: useFragment(
                    SubscriptionProductsSubscriptionProductFragmentDocument,
                    data.listSubscriptionProducts,
                ),
            });
        }
    }, [data]);

    return (
        <SubscriptionContext.Provider
            value={{
                dispatch,
                currentStep: state.currentStep,
                hasActiveSubscription: state.hasActiveSubscription,
                subscriptionProducts: state.subscriptionProducts,
                selectedProduct: state.selectedProduct,
                promotion: state.promotion,
                loading,
                error,
            }}
        >
            {children}
        </SubscriptionContext.Provider>
    );
}
