import React from 'react';

import {VStack} from '@sphericsio/design-system';

import {TabPageHeader} from '../../page-header';
import {ButtonLink} from '../../link';

export function ProfilePeoplePlacesRequired() {
    return (
        <VStack>
            <TabPageHeader
                title="We need to know about your places of work"
                subtitle="Please complete the places section first before completing this section."
            />
            <div className="self-start">
                <ButtonLink to="/operations/places">Go to Places</ButtonLink>
            </div>
        </VStack>
    );
}
