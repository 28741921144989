import React from 'react';

import {Layout} from '../../components/layout';
import {DataConnectionSettingsWrapper} from './data-connection';

export function FinancialDataSettings() {
    return (
        <Layout title="Financial data settings" separator hasNavBar>
            <DataConnectionSettingsWrapper />
        </Layout>
    );
}
