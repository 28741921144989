import React from 'react';

import {Panel, Heading2, DetailText, HStack} from '@sphericsio/design-system';

import {ButtonLink} from '../link';

export function TargetingPanel() {
    return (
        <Panel>
            <HStack>
                <Heading2>Set your Target</Heading2>
                <div className="flex-1">
                    <DetailText inline={false}>Align to a net zero target</DetailText>
                </div>
                <div>
                    <ButtonLink to="./set">Start now</ButtonLink>
                </div>
            </HStack>
        </Panel>
    );
}
