import React from 'react';
import {QueryResult} from '@apollo/client';

import {
    DetailText,
    Heading2,
    Link,
    LinkText,
    SegmentSubheader,
    textColorClassnames,
    VStack,
} from '@sphericsio/design-system';

import {
    BenchmarkingProfileEntryFragment,
    BenchmarkingProfileEntryFragmentDocument,
} from './graphql';
import {ErrorCard} from '../error-card';
import {ProfileSectionPanel} from './section-panel';
import {ReportingPeriodContext} from './reporting-period';
import {DateDisplay} from '../date-display';
import {Loading} from '../loading';
import {carbonAmountFormatter, CO2e} from '../carbon-amount';
import {HorizontalSections} from '../horizontal-sections';
import {useFragment} from '../../graphql/generated';

type TotalCarbonAmountProps = {
    amount: number;
};
function TotalCarbonAmount({amount}: TotalCarbonAmountProps) {
    const {formattedAmount, unit} = carbonAmountFormatter({amount});
    return (
        <div className="flex items-center space-x-2">
            <Heading2>{formattedAmount}</Heading2>
            <DetailText>
                {unit} <CO2e />
            </DetailText>
        </div>
    );
}

type ProfileSections = 'energy' | 'ghg' | 'people' | 'vehicles';

const SECTION_DISPLAY_NAMES: Record<ProfileSections, string> = {
    energy: 'energy',
    ghg: 'f-gases',
    people: 'people',
    vehicles: 'vehicles',
};

type ProfileSectionQuery<T extends ProfileSections> = {
    getCompanyReportingPeriodById: {
        [K in T]?: BenchmarkingProfileEntryFragment | null;
    } & {
        totalEmissionsForPeriod: number;
    };
};

type CompletedProfileSectionProps<T extends ProfileSections, Q extends ProfileSectionQuery<T>> = {
    section: T;
    queryResult: QueryResult<Q, any>;
    sectionIconSrc?: string;
};

export function CompletedProfileSection<
    T extends ProfileSections,
    Q extends ProfileSectionQuery<T>,
>({section, queryResult, sectionIconSrc}: CompletedProfileSectionProps<T, Q>) {
    const ctx = React.useContext(ReportingPeriodContext);
    const {loading, error, data} = queryResult;
    const sectionData = useFragment(
        BenchmarkingProfileEntryFragmentDocument,
        data?.getCompanyReportingPeriodById[section],
    );
    const sectionDisplayName = SECTION_DISPLAY_NAMES[section];
    const sectionCtxData = ctx[section];

    return (
        <VStack testId="completedSection">
            <ProfileSectionPanel
                heading={sectionDisplayName}
                type="complete"
                reportingPeriod={ctx}
                sectionIconSrc={sectionIconSrc}
            >
                {sectionCtxData == null ? (
                    'Thank you for completing this section.'
                ) : (
                    <>
                        You completed this section on{' '}
                        <DateDisplay>{sectionCtxData.completed_at}</DateDisplay>
                    </>
                )}
            </ProfileSectionPanel>
            {loading && <Loading />}
            {error && (
                <ErrorCard
                    message={`We could not load your ${sectionDisplayName} summary, please try again`}
                />
            )}
            {data && sectionData && (
                <HorizontalSections>
                    <VStack full={true}>
                        <SegmentSubheader>
                            Your carbon impact for {sectionDisplayName}:
                        </SegmentSubheader>
                        <TotalCarbonAmount amount={sectionData.kgCo2e} />
                    </VStack>
                    <VStack full={true}>
                        <SegmentSubheader> This is equal to:</SegmentSubheader>

                        <DetailText inline={false}>
                            {sectionData.benchmark == null && (
                                <span className={textColorClassnames('secondary')}>
                                    Comparison is not available
                                </span>
                            )}
                            {sectionData.benchmark != null && (
                                <>
                                    <div>
                                        <span>{sectionData.benchmark.benchmarkText}</span>{' '}
                                        <Heading2>{sectionData.benchmark.value}</Heading2>{' '}
                                        <DetailText colour="secondary">
                                            {sectionData.benchmark.benchmarkTextUnit}
                                        </DetailText>
                                    </div>
                                    <Link
                                        href={sectionData.benchmark.reference}
                                        openInNewWindow
                                        uppercase={false}
                                    >
                                        <DetailText>
                                            <LinkText>Click here for reference</LinkText>
                                        </DetailText>
                                    </Link>
                                </>
                            )}
                        </DetailText>
                    </VStack>
                    <VStack>
                        <SegmentSubheader> Proportion of total carbon impact:</SegmentSubheader>
                        <Heading2>
                            {Math.round(
                                (sectionData.kgCo2e /
                                    data.getCompanyReportingPeriodById.totalEmissionsForPeriod) *
                                    100,
                            )}
                            %
                        </Heading2>
                        <DetailText inline={false} colour="secondary">
                            Your {sectionDisplayName} usage is{' '}
                            {Math.round(
                                (sectionData.kgCo2e /
                                    data.getCompanyReportingPeriodById.totalEmissionsForPeriod) *
                                    100,
                            )}
                            % of your total carbon impact
                        </DetailText>
                    </VStack>
                </HorizontalSections>
            )}
        </VStack>
    );
}
