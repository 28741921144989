import React from 'react';
import {ApolloError, useLazyQuery, useQuery} from '@apollo/client';
import {isEmpty} from 'lodash';

import {
    AvailableFinancialDataPeriod,
    DataExportSourceConfig,
    FileConfig,
    FileUploadDetail,
    GetCompanyDefaultsQuery,
    UploadRequestType,
    UploadValidationErrors,
} from '../../graphql/generated/graphql';
import {
    DataExportSourcesConfigDocument,
    GetAvailableFinancialDataPeriodsDocument,
    GetCompanyDefaultsDocument,
} from './graphql';
import {FileUploadState} from '../file-uploader/state';
import {UploadFlow} from '.';
import {GetDataUploadsDocument, FetchLastBackgroundExecutionDocument} from '../settings/graphql';
import {useUser} from '../user-context';

export type DataExportSource = 'sage50_upload';

export type UploadRequestConfig = {
    exportSource: DataExportSource;
    uploadRequestType: UploadRequestType;
};

type UploadStep =
    | 'instructions'
    | 'requestCompanyDefaults'
    | 'companyDefaults'
    | 'upload'
    | 'processing'
    | 'checkBackLater'
    | 'showUploadErrors'
    | 'confirmAvailableReportingPeriods'
    | 'warning'
    | 'companyOnboarding'
    | 'showSuccessScreen'
    | 'showLoading';

type UploadAction =
    | {type: 'begin'}
    | {type: 'requestCompanyDefaults'}
    | {type: 'companyDefaultsReceived'; payload: GetCompanyDefaultsQuery}
    | {type: 'companyDetailsSet'}
    | {type: 'filesUploaded'; payload: {uploads: FileUploadState[]}}
    | {type: 'detailsComplete'}
    | {type: 'uploadSkipped'}
    | {type: 'availableReportingPeriodsReceived'; payload: AvailableFinancialDataPeriod[]}
    | {type: 'processingComplete'}
    | {type: 'processingOngoing'}
    | {type: 'processingError'}
    | {type: 'limitedDataWarningAcknowledged'}
    | {type: 'restart'};

type UploadState = {
    currentStep: UploadStep;
    companyDefaults?: GetCompanyDefaultsQuery;
    exportSource?: DataExportSource;
    uploadRequestType?: UploadRequestType;
    requests?: FileUploadState[];
};

function useUploadReducer(flow: UploadFlow, uploadRequestType: UploadRequestType) {
    const initialState = {
        currentStep: 'showLoading' as UploadStep,
        uploadRequestType,
    };
    return React.useReducer((state: UploadState, action: UploadAction): UploadState => {
        switch (action.type) {
            case 'begin':
                if (flow === 'upload') {
                    return {...state, currentStep: 'upload'};
                } else {
                    return {...state, currentStep: 'instructions'};
                }

            case 'requestCompanyDefaults':
                return {...state, currentStep: 'requestCompanyDefaults'};

            case 'companyDefaultsReceived':
                return {
                    ...state,
                    currentStep: 'companyDefaults',
                    companyDefaults: action.payload,
                };

            case 'companyDetailsSet':
                return {
                    ...state,
                    currentStep: 'upload',
                };

            case 'uploadSkipped':
                if (state.uploadRequestType === 'profit_and_loss') {
                    return {...state, currentStep: 'companyOnboarding'};
                } else {
                    return {
                        ...state,
                        currentStep: 'upload',
                        uploadRequestType: 'profit_and_loss',
                    };
                }

            case 'filesUploaded':
                if (state.uploadRequestType === 'financial_transactions') {
                    return {
                        ...state,
                        requests: action.payload.uploads,
                        currentStep: 'processing',
                    };
                } else {
                    return {...state, requests: action.payload.uploads, currentStep: 'processing'};
                }

            case 'detailsComplete':
                return {...state, currentStep: 'processing'};

            case 'processingComplete':
                if (flow === 'upload') {
                    return {...state, currentStep: 'showSuccessScreen'};
                } else if (
                    flow === 'onboarding' &&
                    state.uploadRequestType === 'financial_transactions'
                ) {
                    return {...state, currentStep: 'confirmAvailableReportingPeriods'};
                } else {
                    return {...state, currentStep: 'companyOnboarding'};
                }

            case 'processingOngoing':
                return {...state, currentStep: 'checkBackLater'};

            case 'processingError':
                return {...state, currentStep: 'showUploadErrors'};

            case 'availableReportingPeriodsReceived':
                if (isEmpty(action.payload)) {
                    return {...state, currentStep: 'warning'};
                } else {
                    return {
                        ...state,
                        currentStep: 'upload',
                        uploadRequestType: 'profit_and_loss',
                    };
                }

            case 'limitedDataWarningAcknowledged':
                return {
                    ...state,
                    currentStep: 'upload',
                    uploadRequestType: 'profit_and_loss',
                };

            case 'restart':
                return {currentStep: 'upload', uploadRequestType: state.uploadRequestType};
        }
    }, initialState);
}

type UploadContextValue = {
    dispatch: (action: UploadAction) => void;
    companyDefaults?: GetCompanyDefaultsQuery;
    requests?: FileUploadState[];
    uploadRequestType?: UploadRequestType;
    exportSource?: DataExportSource;
    currentStep: UploadStep;
    loading: boolean;
    error?: ApolloError;
    exportSourceConfig?: DataExportSourceConfig;
    fileConfig?: FileConfig[];
    flow: UploadFlow;
    previousSpendData: FileUploadDetail[];
    hasTurnoverData: boolean;
    validationErrors: UploadValidationErrors[];
    uploadInProgress: boolean;
    lastExecutionFailed: boolean;
    onClose?: () => void;
    refetchPreviousUpload?: () => void;
    refetchLastBackgroundExecution?: () => void;
    fetchLastBackgroundExecution?: () => void;
};

export const UploadContext = React.createContext<UploadContextValue>({
    dispatch: () => {
        // placeholder
    },
    flow: 'onboarding',
    currentStep: 'upload',
    loading: false,
    previousSpendData: [],
    hasTurnoverData: false,
    validationErrors: [],
    uploadInProgress: false,
    lastExecutionFailed: false,
});

type UploadContextProviderProps = {
    provider: DataExportSource;
    children: React.ReactNode;
    flow: UploadFlow;
    requestType: UploadRequestType;
    uploadErrors: UploadValidationErrors[];
    onClose?: () => void;
};

export function UploadContextProvider({
    provider,
    flow,
    uploadErrors,
    requestType,
    children,
    onClose,
}: UploadContextProviderProps) {
    const [state, dispatch] = useUploadReducer(flow, requestType);
    const user = useUser();
    const {data: previousUploads, refetch: refetchPreviousUpload} = useQuery(
        GetDataUploadsDocument,
        {
            fetchPolicy: 'network-only',
            variables: {
                status: 'completed',
            },
        },
    );

    const [
        fetchLastBackgroundExecution,
        {
            data: lastBackgroundExecution,
            loading: lastExectionLoading,
            refetch: refetchLastBackgroundExecution,
        },
    ] = useLazyQuery(FetchLastBackgroundExecutionDocument, {
        fetchPolicy: 'network-only',
    });

    const {
        loading: dataExportSourceConfigLoading,
        error: dataExportSourceConfigError,
        data: config,
    } = useQuery(DataExportSourcesConfigDocument);

    const {
        loading: companyDefaultsLoading,
        error: companyDefaultsError,
        data: companyDefaults,
    } = useQuery(GetCompanyDefaultsDocument, {
        fetchPolicy: 'network-only',
    });

    const [
        getAvailableFinancialDataPeriods,
        {
            error: errorFetchingReportingPeriods,
            loading: fetchingReportingPeriods,
            data: reportingPeriods,
        },
    ] = useLazyQuery(GetAvailableFinancialDataPeriodsDocument, {
        fetchPolicy: 'network-only',
    });

    const loading =
        dataExportSourceConfigLoading || companyDefaultsLoading || fetchingReportingPeriods;
    const error =
        dataExportSourceConfigError || companyDefaultsError || errorFetchingReportingPeriods;

    /**
     * There are a few scenarios where these would need to be reconciled:
     * 1. In onboarding, we would use last execution errors which we received from the api call,
     * 2. In product, we would use errors we received as a prop (from the settings page),
     * 3. In product, where user sees previous errors (uploadErrors), attempts another upload which throws validation errors
     * so they need to supersede the passed in (previous) errors
     */
    const validationErrors =
        lastBackgroundExecution?.fetchLastBackgroundExecution?.upload_errors ?? uploadErrors;

    /**
     * When coming back to this page, we need to know if the job failed for any reason other than validation
     */
    const lastExecutionFailed =
        !!lastBackgroundExecution?.fetchLastBackgroundExecution?.status &&
        lastBackgroundExecution.fetchLastBackgroundExecution.status === 'failed';

    const uploadInProgress =
        !!lastBackgroundExecution?.fetchLastBackgroundExecution &&
        !lastBackgroundExecution.fetchLastBackgroundExecution.completed_at;

    const exportSourceConfig = React.useMemo(() => {
        if (config?.dataExportSourcesConfig) {
            return config.dataExportSourcesConfig.find(
                (config) => config.dataExportSource === provider,
            );
        }
    }, [config]);

    React.useEffect(() => {
        if (flow === 'onboarding') {
            fetchLastBackgroundExecution();
        }
    }, [flow]);

    React.useEffect(() => {
        if (!lastExectionLoading && !!lastBackgroundExecution) {
            if (!lastBackgroundExecution?.fetchLastBackgroundExecution) {
                dispatch({
                    type: 'begin',
                });
            } else if (
                !user.hasTransactions ||
                user.financialProvider?.sync_status === 'InitialSyncInProgress' ||
                user.financialProvider?.sync_status === 'PreInitialSync' ||
                !lastBackgroundExecution?.fetchLastBackgroundExecution?.completed_at
            ) {
                dispatch({
                    type: 'processingOngoing',
                });
            } else if (
                lastBackgroundExecution?.fetchLastBackgroundExecution?.status === 'completed'
            ) {
                dispatch({
                    type: 'companyDetailsSet',
                });
            }
        }
        if (flow === 'upload') {
            dispatch({
                type: 'begin',
            });
        }
    }, [lastExectionLoading, lastBackgroundExecution?.fetchLastBackgroundExecution, flow, user]);

    React.useEffect(() => {
        if (
            validationErrors.length > 0 ||
            lastBackgroundExecution?.fetchLastBackgroundExecution?.status === 'failed'
        ) {
            dispatch({
                type: 'processingError',
            });
        }
    }, [validationErrors, lastBackgroundExecution]);

    React.useEffect(() => {
        if (companyDefaults == null) {
            return;
        }

        if (state.currentStep === 'requestCompanyDefaults') {
            dispatch({
                type: 'companyDefaultsReceived',
                payload: companyDefaults,
            });
        }
    }, [companyDefaults, state.currentStep]);

    React.useEffect(() => {
        if (state.currentStep === 'confirmAvailableReportingPeriods') {
            getAvailableFinancialDataPeriods();
        }
    }, [state.currentStep]);

    React.useEffect(() => {
        if (reportingPeriods) {
            dispatch({
                type: 'availableReportingPeriodsReceived',
                payload: reportingPeriods.getAvailableFinancialDataPeriods,
            });
        }
    }, [reportingPeriods, dispatch]);

    const previousSpendData = React.useMemo(() => {
        if (previousUploads) {
            return previousUploads?.fileUploads.filter(
                (fu) => fu.upload_request_type === 'financial_transactions',
            );
        } else {
            return [];
        }
    }, [previousUploads]);

    const hasTurnoverData = React.useMemo(() => {
        if (previousUploads) {
            return (
                previousUploads?.fileUploads.filter(
                    (fu) => fu.upload_request_type === 'profit_and_loss',
                ).length > 0
            );
        } else {
            return false;
        }
    }, [previousUploads]);

    return (
        <UploadContext.Provider
            value={{
                dispatch,
                companyDefaults,
                currentStep: state.currentStep,
                requests: state.requests,
                uploadRequestType: state.uploadRequestType,
                exportSource: state.exportSource,
                exportSourceConfig,
                error,
                loading,
                flow,
                previousSpendData,
                hasTurnoverData,
                validationErrors,
                uploadInProgress,
                refetchPreviousUpload,
                refetchLastBackgroundExecution,
                fetchLastBackgroundExecution,
                lastExecutionFailed,
                onClose,
            }}
        >
            {children}
        </UploadContext.Provider>
    );
}
