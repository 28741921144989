import {ApolloError} from '@apollo/client';
import React from 'react';

import {ReportingPeriodContext, reportingPeriodPlaceholder} from '../reporting-period';
import {SuggestedEnergyTransactionActionPayload} from './types';
import {CompanyReportingPeriod} from '../../../graphql/generated/graphql';
import {useModifySection} from '../use-modify-section';
import {ModifyProfileEnergyDocument} from './graphql';

type ProfileEnergyWizardStep =
    | 'unknown'
    | 'intro'
    | 'suggested'
    | 'consumption'
    | 'building_occupancy'
    | 'completed'
    | 'period_open';

type ProfileEnergyState = {
    transactions: SuggestedEnergyTransactionActionPayload;
    electricityKwh?: number;
    gasKwh?: number;
    buildingOccupancy: Record<string, number>;
    currentStep: ProfileEnergyWizardStep;
    shouldSubmit: boolean;
};

type ProfileEnergyAction =
    | {type: 'start'}
    | {
          type: 'identifyTransactions';
          payload: SuggestedEnergyTransactionActionPayload;
      }
    | {
          type: 'setEnergyConsumptions';
          payload: {electricityKwh: number; gasKwh: number};
      }
    | {
          type: 'skipEnergyConsumption';
      }
    | {
          type: 'setBuildingOccupancy';
          payload: Record<string, number>;
      }
    | {type: 'complete'}
    | {type: 'reset'; payload: CompanyReportingPeriod};

function useEnergyReducer(reportingPeriod: CompanyReportingPeriod) {
    const hasEnergyTransactions = (state: ProfileEnergyState) => {
        const values = Object.values(state.transactions);
        return values.length > 0 && values.find((value) => value) != null;
    };
    function initialStepForReportingPeriod(reportingPeriod: CompanyReportingPeriod) {
        return reportingPeriod.isOpen
            ? 'period_open'
            : reportingPeriod.energy != null
              ? 'completed'
              : 'intro';
    }
    const initialState: ProfileEnergyState = {
        transactions: {},
        buildingOccupancy: {},
        currentStep: initialStepForReportingPeriod(reportingPeriod),
        shouldSubmit: false,
    };
    return React.useReducer(
        (state: ProfileEnergyState, action: ProfileEnergyAction): ProfileEnergyState => {
            switch (action.type) {
                case 'start':
                    return {
                        ...state,
                        currentStep: 'suggested',
                    };

                case 'identifyTransactions':
                    return {
                        ...state,
                        transactions: action.payload,
                        currentStep: 'consumption',
                    };

                case 'setEnergyConsumptions':
                    return {
                        ...state,
                        electricityKwh: action.payload.electricityKwh,
                        gasKwh: action.payload.gasKwh,
                        shouldSubmit: true,
                    };

                case 'skipEnergyConsumption':
                    if (hasEnergyTransactions(state)) {
                        return {
                            ...state,
                            shouldSubmit: true,
                        };
                    } else {
                        return {
                            ...state,
                            currentStep: 'building_occupancy',
                        };
                    }

                case 'setBuildingOccupancy':
                    return {
                        ...state,
                        buildingOccupancy: action.payload,
                        shouldSubmit: true,
                    };

                case 'complete':
                    return {
                        ...initialState,
                        shouldSubmit: false,
                        currentStep: 'completed',
                    };

                case 'reset':
                    return {
                        ...initialState,
                        currentStep: initialStepForReportingPeriod(action.payload),
                    };
            }
        },
        initialState,
    );
}

type ProfileEnergyContextValue = {
    dispatch: (action: ProfileEnergyAction) => void;
    currentStep: ProfileEnergyWizardStep;
    reportingPeriod: CompanyReportingPeriod;
    submitting?: boolean;
    error?: ApolloError;
};

export const ProfileEnergyContext = React.createContext<ProfileEnergyContextValue>({
    dispatch: () => {
        // placeholder
    },
    reportingPeriod: reportingPeriodPlaceholder,
    currentStep: 'intro',
});

type ProfileEnergyContextProviderProps = {
    children: React.ReactNode;
};
export function ProfileEnergyContextProvider({children}: ProfileEnergyContextProviderProps) {
    const reportingPeriod = React.useContext(ReportingPeriodContext);
    const [state, dispatch] = useEnergyReducer(reportingPeriod);
    const [submit, {loading, error, finished}] = useModifySection(ModifyProfileEnergyDocument, [
        'GetProfileEnergy',
    ]);
    const reportingPeriodId = reportingPeriod.id;
    React.useEffect(() => {
        if (state.shouldSubmit) {
            submit({
                variables: {
                    params: {
                        energySpendTransactionIds: Object.entries(state.transactions).map(
                            ([transactionId, tarrifType]) => ({
                                id: transactionId,
                                tarrif: tarrifType,
                            }),
                        ),
                        electricityKwh: state.electricityKwh,
                        gasKwh: state.gasKwh,
                        buildingOccupancies: Object.entries(state.buildingOccupancy).map(
                            ([id, occupancyPercent]) => ({
                                id,
                                occupancyPercent,
                            }),
                        ),
                        companyReportingPeriodId: reportingPeriodId,
                    },
                },
            });
        }
    }, [submit, reportingPeriodId, state]);

    React.useEffect(() => {
        if (finished) {
            dispatch({type: 'complete'});
        }
    }, [finished]);

    React.useEffect(() => {
        dispatch({type: 'reset', payload: reportingPeriod});
    }, [reportingPeriod]);

    return (
        <ProfileEnergyContext.Provider
            value={{
                dispatch,
                currentStep: state.currentStep,
                reportingPeriod,
                submitting: loading,
                error,
            }}
        >
            {children}
        </ProfileEnergyContext.Provider>
    );
}
