import React from 'react';
import {useField} from 'formik';
import {useQuery} from '@apollo/client';

import {
    SelectInput,
    SelectInputProps,
    FormControl,
    FormErrorMessage,
} from '@sphericsio/design-system';

import {graphql} from '../../../graphql/generated';

const GetBuildingTypesDocument = graphql(/* GraphQL */ `
    query GetBuildingTypes {
        getSIBSEBuildingTypes {
            id
            label
        }
    }
`);

type BuildingTypeSelectProps = Pick<SelectInputProps, 'name' | 'placeholder'>;
export function BuildingTypeSelect({name, placeholder}: BuildingTypeSelectProps) {
    const {loading, data, error} = useQuery(GetBuildingTypesDocument);
    const [field, meta, helpers] = useField<string>({name});
    const {value, ...fieldProps} = field;
    const buildingType = data?.getSIBSEBuildingTypes?.find(
        (buildingType) => buildingType.id === value,
    );

    return (
        <FormControl isInvalid={meta.error != null || error != null}>
            <SelectInput
                {...fieldProps}
                value={buildingType}
                placeholder={placeholder}
                onChange={(buildingType) => {
                    helpers.setValue(buildingType.id);
                    helpers.setTouched(true);
                }}
                items={data?.getSIBSEBuildingTypes}
                isLoading={loading}
            />
            <FormErrorMessage>{meta.error ?? 'Something went wrong'}</FormErrorMessage>
        </FormControl>
    );
}
