import React from 'react';

import {
    Panel,
    DetailText,
    intercomTargetProps,
    VStack,
    NavLink as SystemNavLink,
    Heading3,
    ButtonLabel,
    outlineColorClassnames,
    textColorClassnames,
} from '@sphericsio/design-system';

import {AvatarIcon, NavGBPIcon, NavGearIcon, NavHelpIcon, NavLogoutIcon} from './icons';
import {NavLink} from './link';
import appConfig from '../../config';
import {CurrentUserFragment} from '../graphql/generated/graphql';

type UserMenuProps = {
    user: CurrentUserFragment;
};
export function UserMenu({user}: UserMenuProps) {
    const [showingMenu, setShowingMenu] = React.useState(false);
    const toggle = () => {
        setShowingMenu((value) => !value);
    };

    const textColour = 'black';
    const iconBg = 'major-action';
    const iconColour = 'white';
    return (
        <div>
            <div
                data-testid="userMenuButton"
                className="flex items-center cursor-pointer justify-end space-x-1"
                onClick={toggle}
                {...intercomTargetProps('User Menu')}
            >
                <div className={`h-8 w-8 ${textColorClassnames('minor-action')}`}>
                    <AvatarIcon filled={showingMenu} />
                </div>
            </div>
            {showingMenu && (
                <div className="relative">
                    <div className="absolute top-5 right-0 z-50">
                        <Panel>
                            <VStack seperator large full>
                                <div>
                                    <div className="pb-5">
                                        <Heading3>User Menu</Heading3>
                                    </div>
                                    <DetailText bold>{user.name}</DetailText>
                                    <DetailText inline={false}>{user.email}</DetailText>
                                </div>
                                <VStack full>
                                    <NavLink
                                        to="/settings/data-connection"
                                        icon={<NavGBPIcon />}
                                        iconBg={iconBg}
                                        linkTextColour={textColour}
                                        iconColour={iconColour}
                                        {...intercomTargetProps('User Menu: Settings')}
                                    >
                                        <ButtonLabel size="medium" colour="default">
                                            Financial Data Settings
                                        </ButtonLabel>
                                    </NavLink>
                                    {appConfig.featureFlags.subscriptions && (
                                        <NavLink
                                            to="/settings/subscription"
                                            icon={<NavGearIcon />}
                                            iconBg={iconBg}
                                            linkTextColour={textColour}
                                            iconColour={iconColour}
                                        >
                                            <ButtonLabel size="medium" colour="default">
                                                My Subscriptions
                                            </ButtonLabel>
                                        </NavLink>
                                    )}
                                    <SystemNavLink
                                        href="https://help.earth.sage.com"
                                        icon={<NavHelpIcon />}
                                        openInNewWindow
                                        iconBg={iconBg}
                                        linkTextColour={textColour}
                                        iconColour={iconColour}
                                    >
                                        <ButtonLabel size="medium" colour="default">
                                            FAQs
                                        </ButtonLabel>
                                    </SystemNavLink>
                                    <NavLink
                                        icon={<NavLogoutIcon />}
                                        to="/logout"
                                        iconBg={iconBg}
                                        linkTextColour={textColour}
                                        iconColour={iconColour}
                                    >
                                        <ButtonLabel size="medium" colour="default">
                                            Logout
                                        </ButtonLabel>
                                    </NavLink>
                                </VStack>
                            </VStack>
                            <DetailText inline={false} size="small" colour="secondary">
                                <div className="mt-5">Version {BUILD_NUMBER}</div>
                            </DetailText>
                        </Panel>
                        <div
                            className={`absolute -top-2 right-7 w-0 h-0 border-0 ${outlineColorClassnames(
                                'transparent',
                            )} border-r-8 border-l-8 border-b-8`}
                            style={{borderBottomColor: 'white'}}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
