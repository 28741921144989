import React from 'react';
import {ApolloError, useQuery} from '@apollo/client';
import {getYear} from 'date-fns';

import {
    Button,
    DetailText,
    Heading2,
    HStack,
    Panel,
    VStack,
    SelectInput,
    SelectListItem,
} from '@sphericsio/design-system';

import {ErrorCard} from '../error-card';
import {Loading} from '../loading';
import {graphql} from '../../graphql/generated';
import {parseDateString} from '../date-display';

const GetSuggestedBaselinePeriodDocument = graphql(/* GraphQL */ `
    query GetSuggestedBaselinePeriod {
        getAvailableBaselineReportingPeriods {
            start
            end
            inConflict
        }
    }
`);

type SetBaselinePanelProps = {
    onCreateBaselineReportingPeriod: (periodStart: string, periodEnd: string) => void;
    isSubmitting?: boolean;
    hasErrorSubmitting?: ApolloError;
};
export function SetBaselinePanel({
    onCreateBaselineReportingPeriod,
    isSubmitting,
    hasErrorSubmitting,
}: SetBaselinePanelProps) {
    const {loading, data} = useQuery(GetSuggestedBaselinePeriodDocument, {
        fetchPolicy: 'network-only',
    });
    const items = React.useMemo(() => {
        if (data?.getAvailableBaselineReportingPeriods == null) {
            return [];
        } else {
            return data.getAvailableBaselineReportingPeriods.map((period, index) => {
                return {
                    id: index.toString(),
                    label: `Financial Year ${getYear(parseDateString(period.start))} - ${getYear(
                        parseDateString(period.end),
                    )}`,
                };
            });
        }
    }, [data?.getAvailableBaselineReportingPeriods]);
    const [selectedItem, setSelectedItem] = React.useState<SelectListItem>();
    React.useEffect(() => {
        if (items.length > 0 && selectedItem == null) {
            setSelectedItem(items[0]);
        }
    }, [items, selectedItem]);
    const getSelectedItem = () => {
        if (data?.getAvailableBaselineReportingPeriods == null) {
            return;
        }

        if (selectedItem == null) {
            return;
        }

        const period = data.getAvailableBaselineReportingPeriods[parseInt(selectedItem.id)];

        if (period == null) {
            return;
        }

        return period;
    };

    const onSubmit = () => {
        const period = getSelectedItem();

        if (period == null) {
            return;
        }

        onCreateBaselineReportingPeriod(period.start, period.end);
    };
    return (
        <VStack>
            <div className="space-y-2">
                <Panel border>
                    <VStack>
                        <HStack>
                            <Heading2>Set your baseline year</Heading2>
                            <div className="flex-1">
                                {loading && <Loading />}
                                {data && items.length === 0 && (
                                    <DetailText inline={false}>
                                        You don&apos;t have a full year of data yet, so you
                                        can&apos;t set a baseline year.
                                    </DetailText>
                                )}
                                {data && items.length > 0 && (
                                    <>
                                        <SelectInput
                                            name="baselineYear"
                                            items={items}
                                            onChange={(item) => setSelectedItem(item)}
                                            value={selectedItem}
                                        />
                                    </>
                                )}
                            </div>
                            <div>
                                <Button
                                    isDisabled={items.length === 0}
                                    isLoading={isSubmitting}
                                    onPress={onSubmit}
                                >
                                    Start now
                                </Button>
                            </div>
                        </HStack>
                        {getSelectedItem()?.inConflict && (
                            <ErrorCard
                                message="Creating this baseline year will overwrite some of your existing
                        profile data!"
                            />
                        )}
                    </VStack>
                </Panel>
                {hasErrorSubmitting && <ErrorCard />}
            </div>
        </VStack>
    );
}
