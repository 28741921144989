import React from 'react';
import {format, parseJSON} from 'date-fns';

import {Chip, DetailText, ButtonLink as DesignSystemButtonLink} from '@sphericsio/design-system';

import {useUser} from '../user-context';
import {DateDisplay} from '../date-display';
import {ButtonLink} from '../link';
import {Table, TBody, TData, THead, THeader, TRow} from '../table';
import {RecurringCompanySubscription, CurrentUserFragment} from '../../graphql/generated/graphql';
import {unreachable} from '../../util';

export function SubscriptionSettings() {
    const user = useUser();
    const {subscription} = user;
    return <SubscriptionSettingsUi subscription={subscription} />;
}

export function SubscriptionSettingsUi({
    subscription,
}: {
    subscription: CurrentUserFragment['subscription'];
}) {
    if (!subscription) {
        return <NoSubscription />;
    }
    switch (subscription.__typename) {
        case 'RecurringCompanySubscription':
            return <RecurringSubscriptionSettings subscription={subscription} />;
        case 'FreeForLifeCompanySubscription':
            return <FreeForLife />;
        default:
            unreachable(subscription);
    }
}

function FreeForLife() {
    return (
        <SubscriptionTable title="Free for life">
            <TData colSpan={2}>Your subscription is free for life.</TData>
        </SubscriptionTable>
    );
}

function NoSubscription() {
    return (
        <SubscriptionTable title="Next payment">
            <TData colSpan={2}>You don&apos;t have a subscription.</TData>
        </SubscriptionTable>
    );
}

function RecurringSubscriptionSettings({
    subscription,
}: {
    subscription: RecurringCompanySubscription;
}) {
    return (
        <SubscriptionTable title="Next payment">
            <TData>
                {subscription.in_trial_period && (
                    <div className="mr-2">
                        <Chip invert>
                            <DetailText size="small" inherit>
                                Trial
                            </DetailText>
                        </Chip>
                    </div>
                )}
                {subscription.payment_status === 'payment_received' && (
                    <div>
                        <DetailText bold>
                            {subscription.in_trial_period && subscription.expires_at ? (
                                <>
                                    Your trial ends at{' '}
                                    {format(parseJSON(subscription.expires_at), 'p, dd MMM yyyy')}
                                </>
                            ) : subscription.expires_at ? (
                                <>
                                    Your next payment is due on{' '}
                                    <DateDisplay>{subscription.expires_at}</DateDisplay>
                                </>
                            ) : (
                                'Your subscription is active'
                            )}
                        </DetailText>
                        <br />
                        <DetailText>
                            To cancel/edit your subscription, please contact customer support.
                        </DetailText>
                    </div>
                )}
                {subscription.payment_status === 'pending_payment' && (
                    <>We are currently processing your subscription payment.</>
                )}
                {subscription.payment_status === 'payment_failed' && (
                    <div className="space-y-2">
                        <div>
                            There was a problem taking your subscription payment on{' '}
                            <DateDisplay>{subscription.updated_at}</DateDisplay>
                        </div>
                        <div>
                            {subscription.failure_reason === 'requires_action' && (
                                <>We need to authenticate your card</>
                            )}
                            {subscription.failure_reason === 'requires_payment_method' && (
                                <>Your card was declined</>
                            )}
                        </div>
                    </div>
                )}
                {subscription.payment_status === 'cancelled' && (
                    <>
                        You cancelled your subscription on{' '}
                        <DateDisplay>{subscription.updated_at}</DateDisplay>
                    </>
                )}
            </TData>
            <TData>
                <div className="flex justify-end">
                    {/* Note that we're using the ButtonLink from the design system here, and not the wrapper.
                            The wrapper's purpose is to integrate with react-router, but we specifically don't want that here.
                            Mailto links don't work with react-router (at least, not through the ButtonLink wrapper),
                            so we'll have to use the design system button directly.
                         */}
                    {subscription.payment_status === 'payment_received' && (
                        <DesignSystemButtonLink
                            href="mailto:earthsupport@sage.com"
                            intercomTarget="Settings: Cancel Subscription Button"
                            openInNewWindow
                        >
                            Contact support
                        </DesignSystemButtonLink>
                    )}
                    {subscription.payment_status === 'payment_failed' && (
                        <ButtonLink
                            to={
                                subscription.failure_reason === 'requires_action'
                                    ? '/subscription/authenticate'
                                    : '/subscription/card'
                            }
                        >
                            {subscription.failure_reason === 'requires_action'
                                ? 'Authenticate'
                                : 'Add new card'}
                        </ButtonLink>
                    )}
                </div>
            </TData>
        </SubscriptionTable>
    );
}

function SubscriptionTable({title, children}: {title: string; children: React.ReactNode}) {
    return (
        <Table>
            <THead>
                <TRow>
                    <THeader colSpan={2}>
                        <DetailText inherit bold>
                            {title}
                        </DetailText>
                    </THeader>
                </TRow>
            </THead>
            <TBody>
                <TRow>{children}</TRow>
            </TBody>
        </Table>
    );
}
