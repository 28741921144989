import React from 'react';

import {Money} from '../graphql/scalars';

export function moneyAsValue(money: Money) {
    const wholeAmount = parseInt(money[1], 10);
    const decimalAmount = parseInt(money[2], 10);

    if (isNaN(wholeAmount) || isNaN(decimalAmount)) {
        return NaN;
    }

    return wholeAmount + decimalAmount / 10 ** money[2].length;
}

export function moneyAmountAsText(currency: string, value: number) {
    return value.toLocaleString('en-GB', {
        style: 'currency',
        currency,
        minimumFractionDigits: Math.floor(value) === value ? 0 : 2,
    });
}

// const formatters: Record<string, Intl.NumberFormat> = {};
export function moneyAsText(money: Money) {
    const value = moneyAsValue(money);
    if (isNaN(value)) {
        return 'Invalid amount';
    }

    return moneyAmountAsText(money[0], value);
}

type MoneyDisplayProps = {
    money: Money;
};
const MoneyDisplay: React.FC<MoneyDisplayProps> = ({money}) => {
    const value = moneyAsText(money);

    return <>{value}</>;
};

export {MoneyDisplay};
