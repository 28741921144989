import React from 'react';
import * as Yup from 'yup';
import {ApolloError} from '@apollo/client';

import {HStack, TextButton, VStack} from '@sphericsio/design-system';
import {Form, Input, Button} from '@sphericsio/forms';

import {TabPageHeader} from '../../page-header';
import {ErrorCard} from '../../error-card';
import {ReportingPeriodDates} from '../reporting-period-dates';
import {CompanyReportingPeriod} from '../../../graphql/generated/graphql';

const FORM_SCHEMA = Yup.object().shape({
    amount: Yup.number().required('Amount is required').typeError('Amount must be a number'),
});

type ProfileVehiclesFuelQuantitesProps = {
    onNext: (value?: number) => void;
    reportingPeriod: CompanyReportingPeriod;
    amount?: string | null;
    error?: ApolloError;
    submitting?: boolean;
};
export function ProfileVehiclesFuelQuantites({
    amount,
    reportingPeriod,
    error,
    onNext,
    submitting,
}: ProfileVehiclesFuelQuantitesProps) {
    const subtitle = <ReportingPeriodDates reportingPeriod={reportingPeriod} />;
    return (
        <VStack testId="fuelQuantityForm">
            <TabPageHeader
                title="Please estimate the volume of fuel you have purchased"
                subtitle={subtitle}
            />
            <Form
                initialValues={{amount: amount || ''}}
                validationSchema={FORM_SCHEMA}
                onSubmit={(values) => onNext(parseFloat(values.amount))}
            >
                <Input name="amount" placeholder="Litres of fuel" />
                {error && <ErrorCard />}
                <HStack>
                    <TextButton onPress={() => onNext()}>I don&apos;t know</TextButton>
                    <Button isLoading={submitting} type="submit">
                        Save and continue
                    </Button>
                </HStack>
            </Form>
        </VStack>
    );
}
