import React from 'react';

import {Header, HighlightText} from '@sphericsio/design-system';

export function PosterJoinTheMovement() {
    return (
        <Header colour="white">
            Join the movement of companies
            <HighlightText> embracing </HighlightText> carbon accountability.
        </Header>
    );
}
