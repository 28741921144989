import React from 'react';
import {useLazyQuery} from '@apollo/client';
import classnames from 'classnames';

import {LoadingSpinner} from '@sphericsio/design-system';

import {graphql} from '../graphql/generated';
import {ErrorCard} from './error-card';
import {useExternalRedirect} from '../hooks/use-external-redirect';

export const FinancialProviderLoginUrlDocument = graphql(/* GraphQL */ `
    query FinancialProviderLoginUrl($provider: FinancialProviderName!, $forLogin: Boolean!) {
        financialProviderLoginUrl(provider: $provider, forLogin: $forLogin)
    }
`);

type FinancialProviderSyncButtonProps = {
    financialProviderName: string;
    forLogin: boolean;
    hasActiveSubscription: boolean;
    hasPendingSubscription: boolean;
    selectedProvider?: string;
    onButtonClick?: (provider: string) => void;
    children: (onClick: () => void) => React.ReactNode;
};

export function FinancialProviderSyncButton({
    financialProviderName,
    forLogin,
    hasActiveSubscription,
    hasPendingSubscription,
    selectedProvider,
    onButtonClick,
    children,
}: FinancialProviderSyncButtonProps) {
    const externalRedirect = useExternalRedirect();
    const [getLoginUrl, {called: awaitingRedirect, error}] = useLazyQuery(
        FinancialProviderLoginUrlDocument,
        {
            onCompleted(data) {
                externalRedirect(data.financialProviderLoginUrl);
            },
        },
    );

    React.useEffect(() => {
        if (
            hasActiveSubscription &&
            selectedProvider === financialProviderName &&
            isApiFinancialProvider(financialProviderName)
        ) {
            getLoginUrl({variables: {provider: financialProviderName, forLogin}});
        }
    }, [hasActiveSubscription, selectedProvider]);

    const disabled = awaitingRedirect || error || hasPendingSubscription;
    const onClick = () => {
        if (!awaitingRedirect) {
            if (onButtonClick) {
                onButtonClick(financialProviderName);
            } else if (isApiFinancialProvider(financialProviderName)) {
                getLoginUrl({variables: {provider: financialProviderName, forLogin}});
            }
        }
    };
    return (
        <div className="shrink-0 max-w-64">
            <div
                data-testid={financialProviderName}
                className={classnames({
                    'cursor-not-allowed': disabled,
                    'opacity-60': awaitingRedirect,
                    'opacity-30': error || hasPendingSubscription,
                    'cursor-pointer': !disabled,
                })}
            >
                {children(onClick)}
            </div>
            {awaitingRedirect && !error && (
                <div className="flex absolute top-0 left-0 right-0 bottom-0 items-center justify-center">
                    <LoadingSpinner />
                </div>
            )}
            {error && <ErrorCard />}
        </div>
    );
}

const apiFinancialProviders = ['xero', 'quickbooks', 'sage'] as const;
type ApiFinancialProvider = (typeof apiFinancialProviders)[number];
function isApiFinancialProvider(providerName: string): providerName is ApiFinancialProvider {
    return !!apiFinancialProviders.find((p) => p === providerName);
}
