import React from 'react';
import {ApolloError, useQuery, useMutation} from '@apollo/client';
import {useNavigate} from 'react-router';
import {useIntercom} from 'react-use-intercom';
import type {ResultOf} from '@graphql-typed-document-node/core';

import {Panel, VStack} from '@sphericsio/design-system';

import {Layout} from '../../components/layout';
import {Loading} from '../../components/loading';
import {ErrorCard} from '../../components/error-card';
import {SetTargetForm} from '../../components/targeting/set-target-form';
import {graphql} from '../../graphql/generated';

const GetSetTargetingDataDocument = graphql(/* GraphQL */ `
    query GetSetTargetingData {
        getTargetingBaseline {
            id
            company_reporting_period {
                period_start
                period_end
                isCompleted
            }
            scope_co2e_breakdown {
                total_kg_co2e
            }
        }
    }
`);

const SubmitReductionTargetDocument = graphql(/* GraphQL */ `
    mutation SubmitReductionTarget($reductionTargetType: String!) {
        createReductionTarget(reductionTargetType: $reductionTargetType) {
            id
        }
    }
`);

type TargetingSetDataDisplayProps = {
    data?: ResultOf<typeof GetSetTargetingDataDocument>['getTargetingBaseline'];
    onSubmit: (reductionTargetType: string) => void;
    isLoading: boolean;
    error?: ApolloError;
};
function TargetingSetDataDisplay({data, onSubmit, isLoading, error}: TargetingSetDataDisplayProps) {
    if (
        data == null ||
        !data.company_reporting_period.isCompleted ||
        data.scope_co2e_breakdown == null
    ) {
        return <Panel bg="alert">Please complete your baseline before setting a target</Panel>;
    }

    return (
        <SetTargetForm
            total_kg_co2e={data.scope_co2e_breakdown.total_kg_co2e}
            baseline_period_start={data.company_reporting_period.period_start}
            baseline_period_end={data.company_reporting_period.period_end}
            onSubmit={onSubmit}
            isLoading={isLoading}
            error={error}
        />
    );
}

export function TargetingSetTarget() {
    const {loading, error, data} = useQuery(GetSetTargetingDataDocument);
    const [submit, {loading: submitting, error: errorSubmitting, data: response}] = useMutation(
        SubmitReductionTargetDocument,
    );
    const navigate = useNavigate();
    const {trackEvent} = useIntercom();
    React.useEffect(() => {
        if (response != null) {
            navigate('/targeting');
        }
    }, [response, navigate]);

    const submitSetReductionTarget = (reductionTargetType: string) => {
        submit({variables: {reductionTargetType}});
    };

    React.useEffect(() => {
        if (data) {
            trackEvent('Target Set');
        }
    }, [data]);

    return (
        <Layout title="Set Your Target">
            <VStack>
                {loading && <Loading />}
                {error && <ErrorCard />}
                {data && (
                    <TargetingSetDataDisplay
                        data={data.getTargetingBaseline}
                        onSubmit={submitSetReductionTarget}
                        isLoading={submitting}
                        error={errorSubmitting}
                    />
                )}
            </VStack>
        </Layout>
    );
}
