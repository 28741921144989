import {useMutation} from '@apollo/client';
import React from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useIntercom} from 'react-use-intercom';

import {Alert, HStack, Tooltip, VStack} from '@sphericsio/design-system';
import {getErrorMessage} from '@sphericsio/mvp-ui-common';

import {formatDateForUrl} from '../../components/date-display';
import {ErrorCard} from '../../components/error-card';
import {HelpIcon} from '../../components/icons';
import {Layout} from '../../components/layout';
import {Loading} from '../../components/loading';
import {OperationsEdit, OperationsMain} from '../../components/operations';
import {GetReportingPeriodsDocument} from '../../components/operations/graphql';
import {ReportingPeriodDisplay} from '../../components/operations/reporting-period-display';
import {IntervalTypes} from '../../components/picker/config';
import {
    FinancialDateFilterContext,
    FinancialDateFilterContextProvider,
    PickerContextProvider,
} from '../../components/picker/context';
import {DatePicker} from '../../components/picker/date-picker';
import {SetBaselinePanel} from '../../components/targeting/set-baseline-panel';
import {useOnChange} from '../../hooks/use-on-change';
import {ReportingPeriodContext, ReportingPeriodContextProvider} from './context';
import {CreateBaselineReportingPeriodDocument} from './graphql';
import {generatePathForReportingPeriodAndPage, getStep, useExpectedPath} from './utils';

const SECTION_COPY = {
    baselineYear: {
        title: 'Baseline Year',
        subtitle:
            'A carbon baseline is an inventory of sources of carbon emissions from business activities. This is a one-year snapshot that serves as a reference point for your business to understand and track your changing emissions over time.',
    },
    operations: {
        title: 'Operations Breakdown',
        subtitle:
            'In this section, you can provide us with more information about your organisation and how it operates.  By adding more detail to your profile, we can be more specific and accurate when calculating your environmental impact assessment.',
    },
};

const TOOLTIP_COPY = {
    tooltipText:
        'A baseline year is the historic year against which a business tracks its emissions reductions over time. Setting a baseline year is central to committing to a net zero target, so Sage Earth encourages every business to do this immediately.',
    linkHref: 'https://help.earth.sage.com/en/articles/5599008-how-to-set-a-baseline-year',
    linkText: 'How to set a baseline year with Sage Earth',
};

type OperationsProps = {
    isBaselineView?: boolean;
};

function OperationComponent({isBaselineView = false}: OperationsProps) {
    const {selectedPeriod, setReportingPeriods} = React.useContext(FinancialDateFilterContext);
    const {
        reportingPeriod,
        fetchingPeriods,
        periodsError,
        errorCreatingOperationsPeriod,
        baselinePeriodSet,
        isPeriodBeforeBaseline,
        availableReportingPeriods,
        creatingOperationsReportingPeriod,
        validationError,
        validating,
        isValidPeriod,
    } = React.useContext(ReportingPeriodContext);
    const {page} = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const {trackEvent} = useIntercom();

    const [
        createBaselineReportingPeriod,
        {loading: creatingBaseline, error: errorCreatingBaseline},
    ] = useMutation(CreateBaselineReportingPeriodDocument);

    const error = periodsError || validationError;
    const loading = fetchingPeriods || validating || creatingBaseline;
    const periodLoading = fetchingPeriods || creatingOperationsReportingPeriod;

    const expectedPath = useExpectedPath(reportingPeriod, page, isBaselineView);

    const showHeader = React.useMemo(() => {
        const noHeader = ['edit-people', 'edit-vehicles', 'edit-ghg', 'edit-energy'];
        return page && !noHeader.includes(page);
    }, [page]);

    const isEditPage = React.useMemo(() => {
        const isEditPage = ['edit-people', 'edit-vehicles', 'edit-ghg', 'edit-energy'];
        return page && isEditPage.includes(page);
    }, [page]);

    const backlink = React.useMemo(() => {
        if (isEditPage) {
            return {
                link: '../summary',
                text: 'Operations',
            };
        }
    }, [isEditPage]);

    const currentSection = React.useMemo(() => {
        return isBaselineView ? SECTION_COPY['baselineYear'] : SECTION_COPY['operations'];
    }, [isBaselineView]);

    React.useEffect(() => {
        setReportingPeriods(availableReportingPeriods);
    }, [availableReportingPeriods]);

    React.useEffect(() => {
        if (expectedPath && location.pathname !== expectedPath && !periodLoading) {
            navigate(expectedPath);
        }
    }, [location.pathname, expectedPath, navigate]);

    useOnChange(reportingPeriod, (prev, cur) => {
        const isSamePeriod = prev?.id === cur?.id;
        const isBaselinePeriod = cur?.isBaselineReportingPeriod;
        const transitionedToCompleted = !prev?.isCompleted && cur?.isCompleted;

        if (isSamePeriod && isBaselinePeriod && transitionedToCompleted) {
            trackEvent('Baseline year completed');
        }
    });

    React.useEffect(() => {
        if (selectedPeriod && !isBaselineView) {
            const period_start = formatDateForUrl(new Date(selectedPeriod?.periodStart));
            const period_end = formatDateForUrl(new Date(selectedPeriod?.periodEnd));
            navigate(
                generatePathForReportingPeriodAndPage(
                    {
                        period_start,
                        period_end,
                    },
                    getStep(page),
                    isBaselineView,
                ),
            );
        }
    }, [selectedPeriod, isBaselineView]);

    return (
        <Layout
            title={showHeader ? currentSection.title : undefined}
            subtitle={showHeader ? currentSection.subtitle : undefined}
            tooltip={
                isBaselineView && showHeader ? (
                    <Tooltip {...TOOLTIP_COPY}>
                        <HelpIcon />
                    </Tooltip>
                ) : undefined
            }
            backLink={backlink?.link}
            backLinkText={backlink?.text}
        >
            <VStack>
                <VStack>
                    {loading && <Loading />}
                    {error && <ErrorCard />}
                    {!baselinePeriodSet && !loading && isBaselineView && (
                        <SetBaselinePanel
                            onCreateBaselineReportingPeriod={(periodStart, periodEnd) =>
                                createBaselineReportingPeriod({
                                    variables: {periodStart, periodEnd},
                                    refetchQueries: [{query: GetReportingPeriodsDocument}],
                                })
                            }
                            isSubmitting={loading}
                            hasErrorSubmitting={errorCreatingBaseline}
                        />
                    )}
                    {!loading && !isEditPage && (
                        <div className="space-y-7 mt-5">
                            {isBaselineView && reportingPeriod && page && (
                                <ReportingPeriodDisplay
                                    reportingPeriods={availableReportingPeriods}
                                    currentReportingPeriod={reportingPeriod}
                                    page={page}
                                    onChangeReportingPeriod={(reportingPeriod) => {
                                        navigate(
                                            generatePathForReportingPeriodAndPage(
                                                reportingPeriod,
                                                page,
                                                isBaselineView,
                                            ),
                                        );
                                    }}
                                />
                            )}
                            {/* picker in operations should always have a period selected */}
                            {!isBaselineView && selectedPeriod && (
                                <div className="flex justify-end">
                                    <HStack intercomTarget="Operations: Period Select">
                                        <PickerContextProvider>
                                            <DatePicker />
                                        </PickerContextProvider>
                                    </HStack>
                                </div>
                            )}
                            {periodLoading && <Loading />}
                            {errorCreatingOperationsPeriod && !reportingPeriod && (
                                <ErrorCard
                                    message={getErrorMessage(errorCreatingOperationsPeriod, {
                                        extraErrorCodes: {
                                            SUGGESTED_DATES_OVERLAP_EXISTING_REPORTING_PERIODS:
                                                'You already have a baseline year set up for these dates.',
                                        },
                                    })}
                                />
                            )}
                            {isPeriodBeforeBaseline && (
                                <Alert
                                    type="info"
                                    title="This quarter pre-dates your baseline year."
                                    subtitle="Entering operational data that pre-dates your baseline year will not affect your reduction journey."
                                />
                            )}
                            {reportingPeriod && page && (
                                <OperationsMain
                                    currentReportingPeriod={reportingPeriod}
                                    isValidPeriod={isValidPeriod}
                                    page={page}
                                />
                            )}
                        </div>
                    )}
                    {!loading && reportingPeriod && isEditPage && page && (
                        <div className="space-y-7 mt-5">
                            <OperationsEdit currentReportingPeriod={reportingPeriod} page={page} />
                        </div>
                    )}
                </VStack>
            </VStack>
        </Layout>
    );
}

export function Operations({isBaselineView = false}: OperationsProps) {
    const {periodStart, periodEnd} = useParams();
    return (
        <FinancialDateFilterContextProvider
            intervalTypesToDisplay={[IntervalTypes.quarter]}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            initialPeriod={{periodStart: periodStart!, periodEnd: periodEnd!}}
            showQuartersEndingInFuture={false}
            disableQuartersWithinBaselinePeriod={true}
            indicateBaselineYearInQuarter={true}
        >
            <ReportingPeriodContextProvider isBaselineView={isBaselineView}>
                <OperationComponent isBaselineView={isBaselineView} />
            </ReportingPeriodContextProvider>
        </FinancialDateFilterContextProvider>
    );
}
