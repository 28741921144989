import React from 'react';
import {useQuery} from '@apollo/client';

import {GraphQLQueryContext} from '@sphericsio/mvp-ui-common';

import {FullScreenLoading, FullScreenError} from './full-screen';
import {CurrentUserFragmentDocument} from '../graphql/fragments';
import {buildValueContext} from './value-context';
import {graphql, useFragment} from '../graphql/generated';
import {CurrentUserFragment} from '../graphql/generated/graphql';

export const GetCurrentUserDocument = graphql(/* GraphQL */ `
    query GetCurrentUser {
        currentUser {
            ...CurrentUser
        }
    }
`);

const {QueryDataProvider, useQueryData} = GraphQLQueryContext.buildContext(
    GetCurrentUserDocument,
    <FullScreenLoading />,
    <FullScreenError />,
    (data) => useFragment(CurrentUserFragmentDocument, data.currentUser),
);

export function useCurrentUserQueryData() {
    return useQueryData();
}

export function useRefreshedUser() {
    return useQuery(GetCurrentUserDocument, {fetchPolicy: 'network-only'});
}

export const CurrentUserQueryDataProvider = QueryDataProvider;
export type User = CurrentUserFragment;

const [useValueContext, ValueContextProvider] = buildValueContext<User>();
export const useUser = useValueContext;
export const UserContext = ValueContextProvider;
