import React from 'react';

import {LoadingSpinner, DetailText} from '@sphericsio/design-system';

type LoadingProps = {
    message?: string;
};

export function Loading({message}: LoadingProps) {
    return (
        <div className="flex space-x-2" data-testid="loading-component">
            <LoadingSpinner />
            <DetailText>{message || 'Please wait...'}</DetailText>
        </div>
    );
}
