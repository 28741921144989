import React from 'react';
import {ApolloError} from '@apollo/client';
import * as Yup from 'yup';

import {VStack} from '@sphericsio/design-system';
import {Input, Button, Form} from '@sphericsio/forms';

import {ErrorCard} from './error-card';

const INITIAL_FORM_VALUES = {
    email: '',
};

const formSchema = Yup.object().shape({
    email: Yup.string().required('Email is missing').email('Email is invalid'),
});

type ForgotPasswordFormProps = {
    submitting: boolean;
    error?: ApolloError;
    onSubmit: (email: string) => void;
};
export function ForgotPasswordForm({submitting, error, onSubmit}: ForgotPasswordFormProps) {
    const buttonLabel = submitting ? 'Please wait...' : 'Reset Password';
    return (
        <Form
            initialValues={INITIAL_FORM_VALUES}
            onSubmit={(values) => {
                onSubmit(values.email);
            }}
            validationSchema={formSchema}
        >
            <Input name="email" type="email" placeholder="Email" />
            <VStack full>
                {error && <ErrorCard />}
                <Button isLoading={submitting} type="submit">
                    {buttonLabel}
                </Button>
            </VStack>
        </Form>
    );
}
