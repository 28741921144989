import React from 'react';
import superagent from 'superagent';

import {graphql} from '../../graphql/generated';

export const FileUploadRequestDocument = graphql(/* GraphQL */ `
    mutation RequestFileUpload(
        $filename: String!
        $mimeType: String!
        $uploadType: String!
        $exportSource: String!
    ) {
        requestFileUpload(
            filename: $filename
            mimeType: $mimeType
            uploadType: $uploadType
            exportSource: $exportSource
        ) {
            id
            uploadUrl
        }
    }
`);

export const MarkUploadCompleteDocument = graphql(/* GraphQL */ `
    mutation MarkUploadComplete($id: ID!) {
        markUploadComplete(id: $id) {
            id
        }
    }
`);

export const DeleteUploadDocument = graphql(/* GraphQL */ `
    mutation deleteFileUpload($id: ID!) {
        deleteFileUpload(id: $id) {
            status
        }
    }
`);
export function useUploadFile() {
    const [loading, setLoading] = React.useState(false);
    const [complete, setComplete] = React.useState(false);
    const [error, setError] = React.useState<any>();
    const [uploadPercent, setUploadPercent] = React.useState<number | null>();
    const [success, setSuccess] = React.useState(false);

    const init = () => {
        setLoading(true);
        setError(null);
        setUploadPercent(null);
        setSuccess(false);
        setComplete(false);
    };

    const start = React.useCallback((uploadUrl: string, file: File) => {
        if (!uploadUrl) {
            throw new Error('file upload url not found');
        }

        init();
        superagent
            .put(uploadUrl)
            .set('Content-type', file.type)
            .send(file)
            .on('progress', (event) => {
                setUploadPercent(event.percent);
            })
            .then((response) => {
                if (response.status === 200) {
                    setSuccess(true);
                }
                setLoading(false);
                setComplete(true);
            })
            .catch((err) => {
                setLoading(false);
                setComplete(true);
                setError(err);
            });
    }, []);

    return [start, {loading, error, uploadPercent, complete, success}] as const;
}
