import React from 'react';

import {Heading2} from '@sphericsio/design-system';

import underConstruction from '../images/under-construction.svg';

type UnderConstructionProps = {
    children?: React.ReactNode;
};
export function UnderConstruction({children}: UnderConstructionProps) {
    return (
        <div className="flex flex-col flex-1 justify-center items-center space-y-5">
            <img className="h-44" src={underConstruction} />
            <Heading2>Under construction</Heading2>
            {children && <div className="w-1/2">{children}</div>}
        </div>
    );
}
