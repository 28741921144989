import React from 'react';

import {EditProfileEnergyContext} from './context';
import {ProfileEnergySuggested} from '../suggested';
import {ProfileEnergyConsumption} from '../consumption';
import {ProfileEnergyBuildingOccupancy} from '../building-occupancy';
import {SuggestedEnergyTransactionActionPayload} from '../types';

export function EditProfileEnergyWizard() {
    const {currentStep, dispatch, reportingPeriod, answers, error, submitting} =
        React.useContext(EditProfileEnergyContext);

    return (
        <div>
            {currentStep === 'consumption' && (
                <ProfileEnergyConsumption
                    onNext={(payload: {electricityKwh: number; gasKwh: number}) =>
                        dispatch({
                            type: 'setEnergyConsumptions',
                            payload,
                        })
                    }
                    onSkip={() => dispatch({type: 'skipEnergyConsumption'})}
                    error={error}
                    submitting={submitting}
                    reportingPeriod={reportingPeriod}
                    electricityKwh={
                        answers.electricity_kwh != null ? answers.electricity_kwh.toString() : ''
                    }
                    gasKwh={answers.gas_kwh != null ? answers.gas_kwh.toString() : ''}
                />
            )}
            {currentStep === 'building_occupancy' && (
                <ProfileEnergyBuildingOccupancy
                    submitError={error}
                    submitting={submitting}
                    reportingPeriod={reportingPeriod}
                    onNext={(payload: Record<string, number>) =>
                        dispatch({type: 'setBuildingOccupancy', payload})
                    }
                    buildingOccupancyAnswers={answers.buildingOccupancies}
                />
            )}
            {currentStep === 'suggested' && (
                <ProfileEnergySuggested
                    reportingPeriod={reportingPeriod}
                    onNext={(payload: SuggestedEnergyTransactionActionPayload) =>
                        dispatch({type: 'identifyTransactions', payload})
                    }
                    confirmedSpend={answers.confirmedSpend}
                />
            )}
        </div>
    );
}
