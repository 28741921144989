import {FragmentType, graphql} from './generated';

export const CurrentUserFragmentDocument = graphql(/* GraphQL */ `
    fragment CurrentUser on User {
        id
        name
        email
        company_name
        company_account_id
        created_at
        intercom_hash
        feature_flags
        hasTransactions
        financialProvider {
            financial_provider
            sync_method
            connection_status
            sync_status
        }
        lastSync {
            completed_at
        }
        isCompanyOnboarded
        isEmailVerified
        terms_and_conditions_accepted
        subscription {
            __typename
            ... on RecurringCompanySubscription {
                id
                payment_status
                status
                expires_at
                updated_at
                in_trial_period
                failure_reason
                cancellation_period_over
            }
            ... on FreeForLifeCompanySubscription {
                status
            }
        }
        isLeadCompanyUser
    }
`);
export type CurrentUserFragment = FragmentType<typeof CurrentUserFragmentDocument>;
