import React from 'react';
import {
    DocumentNode,
    OperationVariables,
    TypedDocumentNode,
    useApolloClient,
    useMutation,
} from '@apollo/client';

import {useBackgroundExecutionStatus} from '../../hooks/background-execution';

export function useModifySection<
    T extends DocumentNode | TypedDocumentNode<any, OperationVariables>,
>(mutation: T, qeuriesToRefetch: string[] = []) {
    const [submit, {loading, error, data}] = useMutation(mutation);
    const client = useApolloClient();
    const poll = useBackgroundExecutionStatus();
    const backgroundExecutionId = React.useMemo(() => {
        if (!data) {
            return;
        }
        if (data.modifyProfileGHG) {
            return data?.modifyProfileGHG.id;
        }
        if (data.modifyProfileEnergy) {
            return data?.modifyProfileEnergy.id;
        }
        if (data.modifyProfilePeople) {
            return data?.modifyProfilePeople.id;
        }
        if (data.modifyProfileVehicles) {
            return data?.modifyProfileVehicles.id;
        }
    }, [data]);

    React.useEffect(() => {
        if (backgroundExecutionId != null && !poll.called) {
            poll.startPolling({id: backgroundExecutionId});
        }
    }, [backgroundExecutionId, poll]);

    React.useEffect(() => {
        if (poll.finished) {
            client.refetchQueries({
                include: ['GetReportingPeriods', ...qeuriesToRefetch],
            });
        }
    }, [poll.finished]);

    if (poll.called) {
        return [
            submit,
            {
                loading: poll.loading || !poll.finished,
                error: poll.error,
                data: poll.data,
                finished: poll.finished,
            },
        ] as const;
    } else {
        return [submit, {loading, error, data, finished: false}] as const;
    }
}
