import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './custom-style.css'; // overridden styles

import * as React from 'react';
import {DateRange, Range} from 'react-date-range';
import * as locale from 'date-fns/locale';
import {isAfter, isBefore} from 'date-fns';

import {Alert, Preset, VStack} from '@sphericsio/design-system';

import {PickerButtons} from './picker-buttons';

type CustomDateRangePickerProps = {
    onChange: (selection: RangeProps) => void;
    minDate?: Date;
};

export type RangeProps = {
    startDate: Date;
    endDate: Date;
};

export function CustomDateRangePicker({onChange, minDate}: CustomDateRangePickerProps) {
    const [selection, setSelection] = React.useState<Range[]>([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    const rdrNoSelection = React.useMemo(() => {
        const range = selection?.[0];
        return !range || (!range.startDate && !range.endDate);
    }, [selection]);

    return (
        <VStack seperator align="center">
            <div className="pb-2">
                <Alert size="xs" type="info" title="Only past dates can be selected." />
            </div>
            <DateRange
                className={rdrNoSelection ? 'rdrNoSelection' : ''}
                minDate={minDate}
                maxDate={new Date()}
                showMonthArrow={false}
                color={Preset.theme.extend.colors.action.minor[500]}
                rangeColors={[
                    Preset.theme.extend.colors.action.minor[500],
                    Preset.theme.extend.colors.action.minor[500],
                    Preset.theme.extend.colors.action.minor[500],
                ]}
                locale={locale.enGB}
                weekStartsOn={1}
                startDatePlaceholder="From"
                endDatePlaceholder="To"
                weekdayDisplayFormat="EEEEE"
                dateDisplayFormat="P"
                editableDateInputs={true}
                ranges={selection}
                onChange={(item) => {
                    const startDateCheck =
                        item.selection.startDate && minDate
                            ? !isBefore(item.selection.startDate, minDate)
                            : true;
                    const endDateCheck = item.selection.endDate
                        ? !isAfter(item.selection.endDate, new Date())
                        : true;
                    if (startDateCheck && endDateCheck) {
                        setSelection([item.selection]);
                    }
                }}
            />
            <PickerButtons
                isDisabled={!selection[0].startDate || !selection[0].endDate}
                onChange={() => {
                    if (selection[0].startDate && selection[0].endDate) {
                        onChange({
                            startDate: selection[0].startDate,
                            endDate: selection[0].endDate,
                        });
                    }
                }}
                onClearSelection={() =>
                    setSelection([
                        {
                            key: 'selection',
                        },
                    ])
                }
            />
        </VStack>
    );
}
