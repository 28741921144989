import React from 'react';
import {useIntercom} from 'react-use-intercom';

import {DetailText, Heading3, HighlightText, VStack} from '@sphericsio/design-system';

import {CardIcon, UploadIcon} from '../icons';
import {PosterList, PosterListItem} from './poster-list';

export function PosterGetReady() {
    const intercom = useIntercom();
    return (
        <VStack>
            <VStack>
                <Heading3 colour="white">
                    To proceed, <HighlightText>you will need:</HighlightText>
                </Heading3>
                <PosterList>
                    <PosterListItem
                        style="image"
                        image={<CardIcon />}
                        header="Company credit card details"
                    >
                        Cancel your subscription at any time.
                    </PosterListItem>
                    <PosterListItem
                        style="image"
                        image={<UploadIcon />}
                        header="Access to your cloud-accounting platform"
                    />
                </PosterList>
            </VStack>
            <VStack>
                <Heading3 colour="white">
                    Do you have a turnover of over <HighlightText>£50 million?</HighlightText>
                </Heading3>
                <DetailText inherit>
                    Get in touch with us{' '}
                    <HighlightText underline>
                        <a className="cursor-pointer" onClick={() => intercom.show()}>
                            here
                        </a>
                    </HighlightText>{' '}
                    to find out how we can help you.
                </DetailText>
            </VStack>
        </VStack>
    );
}
