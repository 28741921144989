import React from 'react';
import {useNavigate} from 'react-router-dom';

import {ButtonLabel, Pill} from '@sphericsio/design-system';

type TabPillProps = {
    id: string;
    isSelected: boolean;
    children: React.ReactNode;
};

export function TabPill({id, isSelected, children}: TabPillProps) {
    const navigate = useNavigate();
    return (
        <Pill
            fillWhenSelected
            isSelected={isSelected}
            fullRounded
            onChange={() => navigate(`../${id}`)}
            wide
            flex
        >
            <ButtonLabel colour={isSelected ? 'text-selected' : 'default'}>
                <div className="whitespace-nowrap">{children}</div>
            </ButtonLabel>
        </Pill>
    );
}
