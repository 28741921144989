import React from 'react';

import {purgeFinancialAccounts} from '@sphericsio/mvp-ui-common';

import {client} from '../../services/graphql';
import {FilterOptions} from '../filters';
import {FinancialAccountFiltersContext} from './filters-context';
import {FinancialAccountsFilterEnum} from '../../graphql/generated/graphql';

export function FinancialAccountFilters() {
    const {filter, filterOptions, setFilter} = React.useContext(FinancialAccountFiltersContext);

    const handleFilterChange = (selected: string) => {
        purgeFinancialAccounts(client.cache);
        setFilter(selected as FinancialAccountsFilterEnum);
    };

    return (
        <FilterOptions
            selected={filter}
            onSelect={(selected) => handleFilterChange(selected)}
            filterOptions={filterOptions}
            filterDisplayOrder={[
                {
                    key: 'all',
                    name: 'all',
                },
                {
                    key: 'system_categorised',
                    name: 'unconfirmed',
                },
                {
                    key: 'uncategorised',
                    name: 'uncategorised',
                },
                {
                    key: 'user_categorised',
                    name: 'confirmed',
                },
            ]}
        />
    );
}
