import React from 'react';

import {Align, DetailText, Heading1, Placement, VStack} from '@sphericsio/design-system';

import {ButtonLink} from '../link';
import {DashboardPanel} from './dashboard-panel';

type TargetComparisonPanelProps = {
    title: string;
    targetCompletionYear?: number;
    yearlyPercentReduction?: number;
    intercomTarget?: string;
};
export function TargetComparisonPanel({
    title,
    targetCompletionYear,
    yearlyPercentReduction,
    intercomTarget,
}: TargetComparisonPanelProps) {
    const tooltip = {
        text: 'Net zero is a target state which the IPCC defines as that point when man-made emissions of greenhouse gases (GHGs) to the atmosphere are balanced by man-made removals over a specified period.',
        link: 'https://help.earth.sage.com/en/articles/5571854-how-to-set-a-target',
        linkText: 'How to use Sage Earth to set a target',
        place: 'left' as Placement,
        align: 'end' as Align,
    };
    const targetSet = targetCompletionYear != null && yearlyPercentReduction != null;
    return (
        <DashboardPanel title={title} intercomTarget={intercomTarget} tooltip={tooltip}>
            <VStack full>
                <div className="flex flex-1 items-center">
                    {!targetSet && <DetailText>You haven&apos;t set a target yet.</DetailText>}
                    {targetSet && <Heading1>Net Zero by {targetCompletionYear}</Heading1>}
                </div>
                {!targetSet && <ButtonLink to="/targeting">Set Target</ButtonLink>}
                {targetSet && (
                    <DetailText colour="secondary">
                        {yearlyPercentReduction}% yearly reduction
                    </DetailText>
                )}
            </VStack>
        </DashboardPanel>
    );
}
