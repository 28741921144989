import React from 'react';

import {ProfileVehiclesFuelQuantites} from './fuel-quantity';
import {ProfileVehiclesIntro} from './intro';
import {ProfileVehiclesSuggested} from './suggested';
import {ProfileVehiclesContext} from './context';
import {ProfileVehiclesComplete} from './complete';
import {ProfileReportingPeriodOpen} from '../reporting-period-open';
import {ConstrainWidthContainer} from '../../constrain-width-container';

export function ProfileVehiclesWizard() {
    const {currentStep, reportingPeriod, error, submitting, dispatch} =
        React.useContext(ProfileVehiclesContext);

    return (
        <div>
            {currentStep === 'intro' && <ProfileVehiclesIntro />}
            {currentStep === 'suggested' && (
                <ProfileVehiclesSuggested
                    reportingPeriod={reportingPeriod}
                    onNext={(payload) => dispatch({type: 'identifyTransactions', payload})}
                />
            )}
            {currentStep === 'completed' && <ProfileVehiclesComplete />}
            <ConstrainWidthContainer>
                {currentStep === 'fuel_quantity' && (
                    <ProfileVehiclesFuelQuantites
                        error={error}
                        submitting={submitting}
                        onNext={(payload) => {
                            if (payload) {
                                dispatch({type: 'setFuelQuantity', payload});
                            } else {
                                dispatch({type: 'setFuelQuantity'});
                            }
                        }}
                        reportingPeriod={reportingPeriod}
                    />
                )}
                {currentStep === 'period_open' && (
                    <ProfileReportingPeriodOpen reportingPeriodEnd={reportingPeriod.period_end} />
                )}
            </ConstrainWidthContainer>
        </div>
    );
}
