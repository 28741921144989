import React from 'react';

import {
    DetailText,
    Header,
    Heading2,
    VStack,
    HStack,
    Heading1,
    textColorClassnames,
} from '@sphericsio/design-system';

type PageHeaderProps = {
    title: string;
    subtitle?: React.ReactNode;
    tooltip?: React.ReactNode;
    useHeading1?: boolean;
};
export function PageHeader({title, tooltip, subtitle, useHeading1 = false}: PageHeaderProps) {
    return (
        <VStack>
            <HStack>
                {useHeading1 ? <Heading1>{title}</Heading1> : <Header>{title}</Header>}
                {tooltip && (
                    <div className="relative">
                        <div className={`w-6 h-6 ${textColorClassnames('minor-action')}`}>
                            {tooltip}
                        </div>
                    </div>
                )}
            </HStack>
            {subtitle != null && <DetailText>{subtitle}</DetailText>}
        </VStack>
    );
}

export function TabPageHeader({title, subtitle}: PageHeaderProps) {
    return (
        <DetailText inline={false}>
            <VStack>
                <Heading2>{title}</Heading2>
                {subtitle != null && <DetailText>{subtitle}</DetailText>}
            </VStack>
        </DetailText>
    );
}
