import React from 'react';
import {ApolloError} from '@apollo/client';

import {DetailText, Heading1, LoadingSpinner} from '@sphericsio/design-system';

import {CO2e} from '../carbon-amount';
import {DashboardPanel} from './dashboard-panel';

type CarbonIntensityValueProps = {
    amount: number;
    prominent?: boolean;
};
function CarbonIntensityValue({amount, prominent = false}: CarbonIntensityValueProps) {
    return (
        <>
            {prominent && (
                <div className="pr-2">
                    <Heading1 element="span">{amount.toFixed(2)} </Heading1>
                </div>
            )}
            {!prominent && <DetailText inherit>{amount.toFixed(2)} </DetailText>}
            <DetailText inherit>
                kg <CO2e />
                /£
            </DetailText>
        </>
    );
}

type IndustryComparisonPanelProps = {
    title: string;
    value?: number;
    comparisonValue: number;
    loading?: boolean;
    error?: ApolloError;
    intercomTarget?: string;
};

export function IndustryComparisonPanel({
    title,
    value,
    comparisonValue,
    loading,
    error,
    intercomTarget,
}: IndustryComparisonPanelProps) {
    const tooltip = {
        text: 'Carbon intensity is a unit to express carbon emissions relative to each £ of income. It indicates the carbon efficiency of your business operations, but is not appropriate for setting targets against.',
        link: 'https://help.earth.sage.com/en/articles/5697144-what-does-carbon-intensity-mean',
        linkText: 'How Sage Earth calculates carbon intensity',
    };
    return (
        <DashboardPanel
            title={title}
            intercomTarget={intercomTarget}
            tooltip={tooltip}
            footer={
                <>
                    Industry average: <CarbonIntensityValue amount={comparisonValue} />
                </>
            }
        >
            <div className="flex flex-row items-baseline">
                {value != null && <CarbonIntensityValue amount={value} prominent />}
                {value == null &&
                    (loading ? (
                        <LoadingSpinner />
                    ) : error ? (
                        <DetailText>{"We can't work out this figure right now."}</DetailText>
                    ) : (
                        <DetailText>
                            {
                                "We can't work this out because you don’t have any sales. Check back once you are revenue generating."
                            }
                        </DetailText>
                    ))}
            </div>
        </DashboardPanel>
    );
}
