import React from 'react';
import * as Yup from 'yup';
import {ApolloError} from '@apollo/client';

import {HStack, TextButton, VStack, DetailText} from '@sphericsio/design-system';
import {Form, Input, Button} from '@sphericsio/forms';

import {TabPageHeader} from '../../page-header';
import {ErrorCard} from '../../error-card';
import {ReportingPeriodDates} from '../reporting-period-dates';
import {CompanyReportingPeriod} from '../../../graphql/generated/graphql';

const FORM_SCHEMA = Yup.object().shape({
    electricityKwh: Yup.number()
        .required('Electricity is required')
        .typeError('Electricity must be a number'),
    gasKwh: Yup.number().required('Gas is required').typeError('Gas must be a number'),
});

type EnergyConsumptionInputProps = {
    name: string;
    label: string;
    unit: React.ReactNode;
};
function EnergyConsumptionInput({name, label, unit}: EnergyConsumptionInputProps) {
    return (
        <HStack align="start">
            <div className="w-20">
                <DetailText bold>{label}</DetailText>
            </div>
            <Input name={name} label={label} showLabel={false} rightLabel={unit} />
        </HStack>
    );
}
type ProfileEnergyConsumptionProps = {
    reportingPeriod: CompanyReportingPeriod;
    error?: ApolloError;
    submitting?: boolean;
    onNext: (value: {electricityKwh: number; gasKwh: number}) => void;
    onSkip: () => void;
    electricityKwh?: string;
    gasKwh?: string;
};
export function ProfileEnergyConsumption({
    reportingPeriod,
    onNext,
    onSkip,
    error,
    submitting,
    electricityKwh = '',
    gasKwh = '',
}: ProfileEnergyConsumptionProps) {
    return (
        <VStack testId="energyConsumptionForm">
            <TabPageHeader
                title="Please tell us how much electricity and gas you have used."
                subtitle="If you do not know the amounts, please leave blank."
            />
            <DetailText inline={false}>
                <ReportingPeriodDates reportingPeriod={reportingPeriod} />
            </DetailText>
            <Form
                initialValues={{electricityKwh, gasKwh}}
                validationSchema={FORM_SCHEMA}
                onSubmit={({electricityKwh, gasKwh}) =>
                    onNext({
                        electricityKwh: parseFloat(electricityKwh),
                        gasKwh: parseFloat(gasKwh),
                    })
                }
            >
                <EnergyConsumptionInput name="electricityKwh" label="Electricity" unit="kWh" />
                <EnergyConsumptionInput name="gasKwh" label="Gas" unit="kWh" />
                {error && <ErrorCard />}
                <HStack>
                    <TextButton onPress={() => onSkip()}>I don&apos;t know</TextButton>
                    <Button isLoading={submitting} type="submit">
                        Save and continue
                    </Button>
                </HStack>
            </Form>
        </VStack>
    );
}
