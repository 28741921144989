import React from 'react';
import {useMutation} from '@apollo/client';

import {graphql} from '../graphql/generated';
import {useBackgroundExecutionStatus} from './background-execution';

export const SetFinancialAccountUserCategorisationDocument = graphql(/* GraphQL */ `
    mutation setFinancialAccountUserCategorisation($params: [FinancialUserCategorisationInput!]!) {
        setFinancialAccountUserCategorisation(params: $params) {
            id
        }
    }
`);

export function useSaveFinancialAccountCategorisation() {
    const [save, {loading, error, data}] = useMutation(
        SetFinancialAccountUserCategorisationDocument,
    );
    const poll = useBackgroundExecutionStatus();
    const backgroundExecutionId = React.useMemo(() => {
        return data?.setFinancialAccountUserCategorisation.id;
    }, [data]);

    React.useEffect(() => {
        if (backgroundExecutionId != null && !poll.called) {
            poll.startPolling({id: backgroundExecutionId});
        }
    }, [backgroundExecutionId, poll]);

    if (poll.called) {
        return [
            save,
            {
                loading: poll.loading || !poll.finished,
                error: poll.error,
                data: poll.data,
                finished: poll.finished,
            },
        ] as const;
    } else {
        return [save, {loading, error, data, finished: false}] as const;
    }
}
