import React from 'react';
import {useMutation} from '@apollo/client';

import {DetailText, HighlightText, HStack, VStack} from '@sphericsio/design-system';

import {graphql} from '../../../graphql/generated';
import {ExecutionState, ProcessingStatus} from '.';
import {ProcessingStatusIcon} from './progress';

export const ProcessUploadDocument = graphql(/* GraphQL */ `
    mutation processUpload($id: ID!) {
        processUpload(id: $id) {
            ... on ProcessUploadResult {
                backgroundExecution {
                    completed_at
                    created_at
                    execution_type
                    id
                    status
                    updated_at
                    user_account_id
                }
            }
        }
    }
`);

type ProcessingQueueProps = {
    onStatusChange: (status: ProcessingStatus) => void;
    requests: ExecutionState[];
    current?: ExecutionState;
};
export function ProcessingQueue({onStatusChange, requests, current}: ProcessingQueueProps) {
    if (!requests) {
        return null;
    }

    return (
        <VStack>
            {requests.map((req) => {
                return (
                    <ProcessingQueueItem
                        key={req.filename}
                        request={req}
                        current={current}
                        onStatusChange={onStatusChange}
                    />
                );
            })}
        </VStack>
    );
}

export type ExecutionStatus = 'queued' | 'ready' | 'processing' | 'ongoing' | 'complete' | 'failed';

type ProcessingQueueItemProps = {
    onStatusChange: (status: ProcessingStatus) => void;
    request: ExecutionState;
    current?: ExecutionState;
};
function ProcessingQueueItem({request, current, onStatusChange}: ProcessingQueueItemProps) {
    const [executionId, setExecutionId] = React.useState<string>();
    const [executionStatus, setExecutionStatus] = React.useState<ExecutionStatus>('queued');
    const [processUpload, {error, data}] = useMutation(ProcessUploadDocument);

    React.useEffect(() => {
        if (current?.requestId === request.requestId && !executionId) {
            processUpload({variables: {id: current.requestId}});
        }
    }, [current, executionId]);

    React.useEffect(() => {
        if (data?.processUpload.backgroundExecution?.id) {
            setExecutionId(data.processUpload.backgroundExecution.id);
            setExecutionStatus('ready');
        }
    }, [data]);

    React.useEffect(() => {
        if (error) {
            onStatusChange('error');
        }
    }, [error]);

    React.useEffect(() => {
        if (executionStatus === 'complete') {
            onStatusChange('finished');
        }
        if (executionStatus === 'ongoing') {
            onStatusChange('ongoing');
        }
        if (executionStatus === 'failed') {
            onStatusChange('error');
        }
    }, [executionStatus]);

    return (
        <VStack>
            <HStack align="end">
                <DetailText bold>{request.filename}</DetailText>
                {executionId ? (
                    <ProcessingStatusIcon
                        onStatusChange={setExecutionStatus}
                        executionId={executionId}
                    />
                ) : (
                    <DetailText>
                        <HighlightText>queued</HighlightText>
                    </DetailText>
                )}
            </HStack>
            {executionStatus === 'ongoing' && (
                <DetailText inline={false}>
                    Processing is taking a while. Please come back later.
                </DetailText>
            )}
        </VStack>
    );
}
