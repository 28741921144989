import React from 'react';

import {
    LoadingSpinner,
    ConditionalWrapper,
    Tooltip,
    HStack,
    CategoryTheme,
    textColorClassnames,
} from '@sphericsio/design-system';

import {CategoryRow, SaveStatus} from '../categorisation';
import {UserCategorisationHierarchyItem} from '../hierarchy';
import {CategorisationContext} from '../context';
import {ErrorCard} from '../../error-card';
import {EditCategory} from '../edit-categorisation';
import {SaveCategorisation} from './save-merchant-categorisation';
import {HelpIcon} from '../../icons';
import {AddCategoryButton} from '../transaction/transaction-categorisation';

function AddMerchantCategoryButton() {
    return (
        <HStack condensed>
            <AddCategoryButton />
            <div>
                <Tooltip
                    place="top"
                    align="end"
                    tooltipText="Applying a category at the merchant level won’t overwrite categories applied to individual transactions. Transactions can be given different categories to their merchants."
                >
                    <div className="w-6 h-6 flex items-center justify-center">
                        <div className={`w-5 h-5 ${textColorClassnames('tooltip')}`}>
                            <HelpIcon />
                        </div>
                    </div>
                </Tooltip>
            </div>
        </HStack>
    );
}

type MerchantCategorisationByCompanyProps = {
    data?: UserCategorisationHierarchyItem[];
    theme: CategoryTheme;
    name: string;
    id: string;
    editable?: boolean;
    onSaveSuccess: () => void;
};

export function MerchantCategorisationByCompany({
    id,
    name,
    data,
    theme,
    editable = true,
    onSaveSuccess,
}: MerchantCategorisationByCompanyProps) {
    const [status, setStatus] = React.useState<SaveStatus>('pending');
    const [selected, setSelected] = React.useState<UserCategorisationHierarchyItem | null>(null);
    const {loading, error} = React.useContext(CategorisationContext);

    React.useEffect(() => {
        if (status === 'success') {
            onSaveSuccess();
        }
    }, [status]);

    const content = React.useMemo(() => {
        if (status === 'saving' || loading) {
            return <LoadingSpinner />;
        }
        if (status === 'error' || error) {
            return <ErrorCard />;
        }

        if (!data?.length) {
            return (
                <EditCategory status={status} onStatusChange={setStatus} onSelected={setSelected}>
                    <AddMerchantCategoryButton />
                </EditCategory>
            );
        }

        return (
            <CategoryRow
                categorisation={data}
                editable={editable}
                theme={theme}
                status={status}
                onStatusChange={setStatus}
                onSelected={setSelected}
            />
        );
    }, [status, data]);

    return (
        <ConditionalWrapper
            condition={status !== 'success'}
            wrapper={(children) => (
                <SaveCategorisation
                    id={id}
                    name={name}
                    selected={selected}
                    status={status}
                    onStatusChange={setStatus}
                >
                    {children}
                </SaveCategorisation>
            )}
        >
            {content}
        </ConditionalWrapper>
    );
}
