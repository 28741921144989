import React from 'react';
import {useNavigate} from 'react-router-dom';

import {
    DetailText,
    VStack,
    HStack,
    CircleCross,
    textColorClassnames,
    outlineColorClassnames,
    Heading2,
} from '@sphericsio/design-system';

import {HSplitLayout} from '../../layout/h-split';
import {NavigationButtons} from '../../navigation-buttons';
import {PosterLayout} from '../../onboarding/poster-layout';
import {PosterForgotTemplate} from '../../onboarding/poster-forgot-template';
import {PageHeader} from '../../page-header';
import {UploadContext} from '../context';
import {UploadValidationErrors} from '../../../graphql/generated/graphql';
import {ErrorCard} from '../../error-card';

function generateErrorMessage(uploadError: UploadValidationErrors) {
    let e = uploadError.message;
    if (uploadError.line) {
        e += ` on row ${uploadError.line}`;
    }
    if (uploadError.header) {
        e += `: ${uploadError.header}`;
    }
    if (uploadError.sheetName) {
        e += ` on sheet ${uploadError.sheetName}`;
    } else if (uploadError.sheetNo) {
        e += ` on sheet ${uploadError.sheetNo}`;
    }
    return e;
}

function UploadErrorList({errors}: {errors: UploadValidationErrors[]}) {
    return (
        <div className={`border-l-2 ${outlineColorClassnames('error')} space-y-2 pl-2 w-96`}>
            {errors.map((error) => (
                <DetailText key={error.id}>{generateErrorMessage(error)}</DetailText>
            ))}
        </div>
    );
}

function UploadErrorFile({fileName}: {fileName: string}) {
    return (
        <HStack>
            <div className={`w-6 h-6 ${textColorClassnames('error')}`}>
                <CircleCross />
            </div>
            <DetailText>{fileName}</DetailText>
        </HStack>
    );
}

function ExecutionFailure() {
    return <ErrorCard reportError={false} message="There was an issue processing your upload." />;
}

function InProductUploadErrors({fileName}: {fileName: string}) {
    const {dispatch, validationErrors, onClose} = React.useContext(UploadContext);

    return (
        <VStack>
            {validationErrors.length && (
                <>
                    <Heading2>Upload failed</Heading2>
                    <div className="pt-3">
                        <UploadErrorFile fileName={fileName} />
                    </div>
                    <VStack align="start">
                        <DetailText bold>The following errors occurred:</DetailText>
                        <UploadErrorList errors={validationErrors} />
                    </VStack>
                </>
            )}
            {validationErrors.length === 0 && <ExecutionFailure />}
            <div className="flex w-full flex-row justify-end pt-5">
                <NavigationButtons
                    backOnClick={() => onClose && onClose()}
                    backLabel="Cancel"
                    nextLabel="Re-upload file"
                    nextOnClick={() => dispatch({type: 'restart'})}
                />
            </div>
        </VStack>
    );
}

export function OnboardingUploadErrors({fileName}: {fileName: string}) {
    const navigate = useNavigate();
    const {dispatch, validationErrors, lastExecutionFailed} = React.useContext(UploadContext);

    if (!lastExecutionFailed) {
        navigate('/');
    }
    return (
        <HSplitLayout cta="logout" wide>
            <VStack large full>
                <PageHeader title="Your upload has failed" />
                {validationErrors.length > 0 && (
                    <>
                        <div className="flex flex-col space-y-10">
                            <div className="space-y-5">
                                <DetailText bold>
                                    We have been unable to validate the following file:
                                </DetailText>
                                <UploadErrorFile fileName={fileName} />
                            </div>
                            <div className="space-y-5">
                                <DetailText bold>The following errors occurred:</DetailText>
                                <UploadErrorList errors={validationErrors} />
                            </div>
                        </div>
                    </>
                )}
                {validationErrors.length === 0 && <ExecutionFailure />}
                <div className="flex w-full flex-row justify-end pt-5">
                    <NavigationButtons
                        backOnClick={() => {
                            /* do nothing*/
                        }}
                        hideBack={true}
                        nextLabel="Re-upload file"
                        nextOnClick={() => dispatch({type: 'restart'})}
                    />
                </div>
            </VStack>
            <PosterLayout bgImage={{type: 'onboarding'}}>
                <PosterForgotTemplate />
            </PosterLayout>
        </HSplitLayout>
    );
}

/**
 * this component assumes we only have errors for one file, as this is currently our set up (one background execution per file)
 * if this ever changes, we will need to adjust layout here
 */
export const UploadErrors: React.FC = () => {
    const {validationErrors, flow} = React.useContext(UploadContext);

    const fileName =
        validationErrors.length > 0
            ? `${validationErrors[0].file_upload_request?.filename}.${validationErrors[0].file_upload_request?.file_extension}`
            : '';

    if (flow === 'upload') {
        return <InProductUploadErrors fileName={fileName} />;
    } else {
        return <OnboardingUploadErrors fileName={fileName} />;
    }
};
