import React from 'react';
import Highlighter from 'react-highlight-words';
import {sortBy} from 'lodash';
import {useQuery} from '@apollo/client';

import {
    SearchInput,
    SearchInputRef,
    Input,
    DetailText,
    DropdownList,
    CloseIcon,
} from '@sphericsio/design-system';

import {graphql} from '../graphql/generated';

type SearchMatch<T> = {
    key: string;
    item: T;
    entry: string;
    matches: string[];
    matchesKey: boolean;
};

function findMatches<T>(
    data: T[],
    query: string,
    getSearchEntry: (entry: T) => string,
    getSearchKey: (entry: T) => string,
) {
    const terms = query
        .split(' ')
        .filter((term) => term !== '')
        .map((term) => term.toLowerCase());
    const searchMatches = data.reduce((searchMatches, entry) => {
        const searchEntry = getSearchEntry(entry);
        const key = getSearchKey(entry);
        const matches = terms.reduce((matches, term) => {
            if (!searchEntry.toLowerCase().includes(term.toLowerCase())) {
                return matches;
            }

            return matches.concat(term);
        }, [] as string[]);

        if (matches.length === 0) {
            return searchMatches;
        }

        return searchMatches.concat({
            key,
            item: entry,
            entry: searchEntry,
            matches,
            matchesKey: terms.includes(key.toLowerCase()),
        });
    }, [] as SearchMatch<T>[]);

    return sortBy(
        searchMatches,
        (match) => (match.matchesKey ? 1 : 0),
        (match) => -match.matches.length,
        (match) => match.entry,
    );
}

const GetSicCodesDocument = graphql(/* GraphQL */ `
    query GetSicCodes {
        getSicCodes {
            sic_code
            description
        }
    }
`);

type SicCode = {
    sic_code: string;
    description: string;
};

type SicCodeSearchInputProps = {
    onChooseItem: (item?: SicCode) => boolean | void;
    disabled?: boolean;
    name: string;
    placeholder?: string;
};
export function SicCodeSearchInput({
    onChooseItem,
    disabled,
    name,
    placeholder,
}: SicCodeSearchInputProps) {
    const [searchTerm, setSearchTerm] = React.useState('');
    const [chosenTerm, setChosenTerm] = React.useState<string>();
    const [searchResults, setSearchResults] = React.useState<SearchMatch<SicCode>[]>([]);
    const [focused, setFocused] = React.useState(false);
    const {loading, error, data} = useQuery(GetSicCodesDocument);
    const [hoveringOverResults, setHoveringOverResults] = React.useState(false);
    const searchInputRef = React.useRef<SearchInputRef>(null);

    const onPerformSearch = React.useRef((query: string) => {
        setSearchTerm(query);
    });

    React.useEffect(() => {
        if (data == null || error) {
            setSearchResults([]);
        } else if (searchTerm === '') {
            setSearchResults([]);
        } else {
            setSearchResults(
                findMatches(
                    data.getSicCodes,
                    searchTerm,
                    (entry) => `(${entry.sic_code}) ${entry.description}`,
                    (entry) => entry.sic_code,
                ),
            );
        }
    }, [data, searchTerm, error]);

    const showResults = focused || hoveringOverResults;

    return (
        <div className="flex flex-col">
            {chosenTerm != null && (
                <Input
                    name={name}
                    value={chosenTerm}
                    placeholder={placeholder}
                    icon={
                        <div className="h-6 w-6">
                            <CloseIcon />
                        </div>
                    }
                    onChange={() => {
                        //do nothing
                    }}
                    onPressIcon={() => {
                        setChosenTerm(undefined);
                        onChooseItem(undefined);
                    }}
                />
            )}
            {chosenTerm == null && (
                <SearchInput
                    ref={searchInputRef}
                    name={name}
                    throttleSearch={false}
                    onPerformSearch={onPerformSearch.current}
                    minCharacters={3}
                    loading={loading && searchTerm !== ''}
                    initialSearch={searchTerm}
                    placeholder={placeholder}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                />
            )}
            {chosenTerm == null && searchResults.length > 0 && showResults && (
                <div className="relative">
                    <div
                        className="absolute h-52 w-full top-1 z-10"
                        onMouseEnter={() => setHoveringOverResults(true)}
                        onMouseLeave={() => setHoveringOverResults(false)}
                    >
                        <DropdownList
                            items={searchResults}
                            isDisabled={disabled}
                            itemKey={(result) => result.item.sic_code}
                            renderItem={(result) => (
                                <DetailText inherit>
                                    <Highlighter
                                        textToHighlight={result.item.description}
                                        searchWords={result.matches}
                                        highlightClassName="font-body bg-transparent text-current"
                                    />
                                </DetailText>
                            )}
                            onClickItem={(result) => {
                                const chooseTerm = onChooseItem(result.item);
                                if (chooseTerm !== false) {
                                    setChosenTerm(result.item.description);
                                } else {
                                    setChosenTerm(undefined);
                                    searchInputRef.current?.clearSearch();
                                }
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
