import React from 'react';
import {ApolloError} from '@apollo/client';

import {
    DetailText,
    HStack,
    Slider,
    TextButton,
    VStack,
    Button,
    Panel,
} from '@sphericsio/design-system';

import {TabPageHeader} from '../../page-header';
import {ErrorCard} from '../../error-card';
import {ReportingPeriodDates} from '../reporting-period-dates';
import {ConstrainWidthContainer} from '../../constrain-width-container';
import {CompanyReportingPeriod} from '../../../graphql/generated/graphql';

type ProfileGHGSpendSplitProps = {
    reportingPeriod: CompanyReportingPeriod;
    getTransactionSpend: () => number;
    error?: ApolloError;
    submitting?: boolean;
    onNext: (value: number) => void;
    splitPercent?: number | null;
};

const DEFAULT_SPLIT_PERCENT = 10;
export function ProfileGHGSpendSplit({
    reportingPeriod,
    getTransactionSpend,
    error,
    submitting,
    onNext,
    splitPercent,
}: ProfileGHGSpendSplitProps) {
    const [spendSplitPercent, setSpendSplitPercent] = React.useState(
        splitPercent ?? DEFAULT_SPLIT_PERCENT,
    );
    const title = `You’ve told us you’ve spent £${getTransactionSpend()} on fridge, air conditioning and/or fire suppressant maintenance.  How much of this total was spent on gases?`;
    const subtitle = <ReportingPeriodDates reportingPeriod={reportingPeriod} />;
    return (
        <HStack align="start">
            <ConstrainWidthContainer>
                <VStack>
                    <TabPageHeader title={title} subtitle={subtitle} />
                    <Slider
                        value={spendSplitPercent}
                        onChange={(val) => setSpendSplitPercent(val)}
                        renderValue={(value) => <DetailText>{value}%</DetailText>}
                    />
                    {error && <ErrorCard />}
                    <HStack>
                        <TextButton onPress={() => onNext(DEFAULT_SPLIT_PERCENT)}>
                            I don&apos;t know
                        </TextButton>
                        <Button onPress={() => onNext(spendSplitPercent)} isLoading={submitting}>
                            Save and continue
                        </Button>
                    </HStack>
                </VStack>
            </ConstrainWidthContainer>
            <Panel bg="alert">
                <DetailText>
                    If you can find out this piece of information, it will stop us assuming that the
                    whole spend was on harmful F-gases.
                </DetailText>
            </Panel>
        </HStack>
    );
}
