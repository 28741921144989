import React from 'react';

import {
    DetailText,
    Heading2,
    Input,
    LoadingSpinner,
    Paragraph,
    SelectInput,
    SelectListItem,
    textColorClassnames,
} from '@sphericsio/design-system';

import {ExclusionContentTypeEnum} from '../../graphql/generated/graphql';
import {ErrorCard} from '../error-card';
import {ExcludeTransactionContext} from '../financial-transactions/exclude-transaction-context';
import {NavigationButtons} from '../navigation-buttons';
import {useGeneratePlaceholder} from '../../hooks/exclusion';

type ExcludeModalProps = {
    onCancel: () => void;
    id: string;
    type: ExclusionContentTypeEnum;
    children: React.ReactElement;
};
export function ExcludeModal({onCancel, id, children, type}: ExcludeModalProps) {
    const [dropdownItem, setDropdownItem] = React.useState<SelectListItem>();
    const [userExcludeReason, setUserExcludeReason] = React.useState<string>('');
    const {submitExclude, reasons, loading, error, submitting} =
        React.useContext(ExcludeTransactionContext);
    const {placeholder} = useGeneratePlaceholder(type);

    const canSubmit = React.useMemo(() => {
        if (dropdownItem?.id === 'other') {
            return userExcludeReason.length !== 0;
        }
        return dropdownItem != null;
    }, [dropdownItem, userExcludeReason]);

    const exclude = () => {
        if (userExcludeReason) {
            submitExclude(id, userExcludeReason, type);
        } else if (dropdownItem) {
            submitExclude(id, dropdownItem.id, type);
        }
    };

    const maxLength = 40;

    if (error) {
        return <ErrorCard />;
    }

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <DetailText>
            <div className="flex flex-col items-start space-y-5">
                <Heading2>You are excluding the following {placeholder}</Heading2>
                <Paragraph>
                    <span className={textColorClassnames('secondary')}>
                        This will no longer contribute to your emission calculations.
                    </span>
                </Paragraph>
                {children}
                <DetailText bold>What is the reason for excluding this {placeholder}?</DetailText>
                <div className="w-full space-y-5">
                    <SelectInput
                        name="Exclusion reasons"
                        placeholder={`Why are you excluding this ${placeholder}?`}
                        value={dropdownItem}
                        onChange={(item) => setDropdownItem(item)}
                        items={reasons}
                    />
                    {dropdownItem?.id === 'other' && (
                        <Input
                            name="reason"
                            placeholder="Please provide a brief reason (40 character limit)"
                            value={userExcludeReason}
                            maxLength={maxLength}
                            onChange={(e) => {
                                setUserExcludeReason(e.target.value);
                            }}
                        />
                    )}
                </div>
                <NavigationButtons
                    backLabel="Cancel"
                    backOnClick={() => onCancel()}
                    nextLabel="Exclude"
                    nextToDisabled={!canSubmit}
                    nextOnClick={() => exclude()}
                    loading={submitting}
                />
            </div>
        </DetailText>
    );
}
