import React from 'react';
import {useQuery} from '@apollo/client';

import {VStack} from '@sphericsio/design-system';
import {purgeCompanyTransactions} from '@sphericsio/mvp-ui-common';

import {graphql} from '../../graphql/generated';
import {TransactionFilterEnum} from '../../graphql/generated/graphql';
import {Filter, FilterEnums, FilterOptions} from '../filters';
import {TransactionFeed} from '../financial-transactions/transaction-feed';
import {client} from '../../services/graphql';

const FetchCustomerTransactionCountsDocument = graphql(/* GraphQL */ `
    query getTransactionFilterCounts {
        getTransactionFilterCounts {
            filter
            count
        }
    }
`);

export function Transactions() {
    const {data, refetch} = useQuery(FetchCustomerTransactionCountsDocument, {
        fetchPolicy: 'network-only',
    });
    const [filterOptions, setFilterOptions] = React.useState<Filter[]>([]);
    const [filter, setFilter] = React.useState<FilterEnums>('all');

    const handleFilterChange = (selected: FilterEnums) => {
        purgeCompanyTransactions(client.cache);
        setFilter(selected);
    };

    React.useEffect(() => {
        if (data?.getTransactionFilterCounts) {
            setFilterOptions(data.getTransactionFilterCounts);
        }
    }, [data?.getTransactionFilterCounts]);

    return (
        <VStack large>
            {data && (
                <FilterOptions
                    selected={filter}
                    onSelect={(selected) => handleFilterChange(selected as FilterEnums)}
                    filterOptions={filterOptions}
                    filterDisplayOrder={[
                        {
                            key: 'all',
                            name: 'all',
                        },
                        {
                            key: 'categorised',
                            name: 'categorised',
                        },
                        {
                            key: 'uncategorised',
                            name: 'uncategorised',
                        },
                        {
                            key: 'excluded',
                            name: 'excluded',
                        },
                    ]}
                />
            )}
            <TransactionFeed onRefetch={refetch} filter={filter as TransactionFilterEnum} />
        </VStack>
    );
}
