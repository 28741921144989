import React, {useRef} from 'react';
import {NotifiableError} from '@bugsnag/js';

import {
    DetailText,
    DetailTextRef,
    outlineColorClassnames,
    textColorClassnames,
} from '@sphericsio/design-system';
import {getDisplayMessage} from '@sphericsio/mvp-ui-common';

import {Bugsnag} from '../util/bugsnag';
import {useErrorNotifier} from '../hooks/error-notifier';

type ErrorCardProps = {
    /**
     * The error message to show on screen. If set, then this value is used regardless of other parameters.
     * If unset, then we'll use an error derived from the `error` prop, or fallback to the `defaultMessage`.
     */
    message?: React.ReactNode;
    /**
     * The error message to show on screen if no `message` is given and we cannot derive a message from `error`.
     */
    defaultMessage?: React.ReactNode;
    /**
     * The error to send to bugsnag. If none is given, then we'll send the (default)message instead.
     *
     * This error will also be used to derive the error message, if no `message` is passed in explicitely.
     */
    error?: NotifiableError;
    /**
     * Additional error codes for deriving error messages from the error.
     *
     * These will take priority over the default error mapping.
     */
    extraErrorCodes?: Record<string, React.ReactNode>;
    /**
     * If we want to report the error message to bugsnag.
     *
     * Default: true.
     */
    reportError?: boolean;
    /**
     * If set, then the component won't be styled and just render a plain div
     */
    noStyle?: boolean;
};

/**
 * Note: message takes precedence over defaultMessage
 */
export function ErrorCard({
    message,
    defaultMessage,
    reportError = true,
    error,
    extraErrorCodes,
    noStyle = false,
}: ErrorCardProps) {
    if (message && defaultMessage) {
        const message =
            'Both `message` and `defaultMessage` are set. `message` will always overwrite `defaultMessage`, and therefore `defaultMessage` is useless. Remove `defaultMessage` to get rid of this warning.';
        Bugsnag.notify({name: 'Incorrect use of ErrorCard component', message});
        console.warn(message);
    }

    const displayMessage = getDisplayMessage(message, error, extraErrorCodes, defaultMessage);

    // We need to extract the text representation (`textContent`) of the `displayMessage` from the ReactNode,
    // so we use a `ref` to access the dom in our effect.
    const textRef = useRef<DetailTextRef>(null);
    useErrorNotifier(reportError, error, textRef);

    const classNames = noStyle
        ? ''
        : `${textColorClassnames('error')} border-l-2 ${outlineColorClassnames('error')} pl-5`;

    return (
        <div className={classNames} data-testid="error-component">
            <DetailText ref={textRef}>{displayMessage}</DetailText>
        </div>
    );
}
