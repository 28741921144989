import React from 'react';

import {LoadingSpinner} from '@sphericsio/design-system';

import {SubscriptionProducts} from './products';
import {SubscriptionContext, SubscriptionContextProvider} from './context';
import {PaymentDetails} from './payment-details';
import {SubscriptionSubmission} from './submission';

type SubscriptionWizardProps = {
    onClose: () => void;
    onError: () => void;
    onSuccess: () => void;
};
export function SubscriptionWizard({onClose, onError, onSuccess}: SubscriptionWizardProps) {
    return (
        <SubscriptionContextProvider>
            <SubscriptionStep onClose={onClose} onError={onError} onSuccess={onSuccess} />
        </SubscriptionContextProvider>
    );
}

type SubscriptionStepProps = {
    onClose: () => void;
    onError: () => void;
    onSuccess: () => void;
};
function SubscriptionStep({onClose, onError, onSuccess}: SubscriptionStepProps) {
    const {currentStep, hasActiveSubscription, subscriptionProducts} =
        React.useContext(SubscriptionContext);

    if (hasActiveSubscription) {
        onClose();
    }

    switch (currentStep) {
        case 'products':
            return (
                <SubscriptionProducts
                    subscriptionProducts={subscriptionProducts}
                    onCancel={() => onClose()}
                />
            );
        case 'cardDetails':
            return <PaymentDetails onCancel={() => onClose()} />;
        case 'submission':
            return <SubscriptionSubmission onError={onError} onSuccess={() => onSuccess()} />;
        default:
            return (
                <div className="self-center">
                    <LoadingSpinner />
                </div>
            );
    }
}
