import React from 'react';
import {useNavigate} from 'react-router';
import {ApolloError, useMutation} from '@apollo/client';

import {
    DetailText,
    VStack,
    LinkText,
    Button,
    Modal,
    Heading3,
    DownloadIcon,
} from '@sphericsio/design-system';

import {HSplitLayout} from '../components/layout/h-split';
import {PosterLayout} from '../components/onboarding/poster-layout';
import {PosterJoinTheMovement} from '../components/onboarding/poster-join-the-movement';
import {PageHeader} from '../components/page-header';
import {GetCurrentUserDocument, useUser} from '../components/user-context';
import {graphql} from '../graphql/generated';
import {ErrorCard} from '../components/error-card';
import {NavigationButtons} from '../components/navigation-buttons';

export const UpdateTermsAndConditionsApprovalDocument = graphql(/* GraphQL */ `
    mutation UpdateTermsAndConditionsApproval($approve: Boolean!) {
        updateTermsAndConditionsApproval(approve: $approve) {
            ...CurrentUser
        }
    }
`);

const CancelSubscriptionDocument = graphql(/* GraphQL */ `
    mutation CancelSubscription {
        cancelSubscription {
            ...CurrentUser
        }
    }
`);

export function TermsLink({label}: {label?: string}) {
    return (
        <a href="https://www.sage.com/en-gb/-/media/files/sagedotcom/uk/documents/pdf/sage-earth-terms-of-service-gb.pdf">
            <LinkText>
                <div className="flex flex-row space-x-1">
                    <div>{label ?? 'terms & conditions'}</div>
                    <div className="w-4 h-4">
                        <DownloadIcon />
                    </div>
                </div>
            </LinkText>
        </a>
    );
}

export const termsAndConditionsRoute = '/terms-and-conditions';

export function TermsAndConditions() {
    const user = useUser();
    const navigate = useNavigate();
    const [approveTermsAndConditions, {loading, error}] = useMutation(
        UpdateTermsAndConditionsApprovalDocument,
    );

    const [cancelSubscription, {error: cancelSubscriptionError}] = useMutation(
        CancelSubscriptionDocument,
    );

    React.useEffect(() => {
        if (user.subscription?.status === 'cancelled') {
            navigate('/sorry-to-see-you-go');
        }
        if (user.terms_and_conditions_accepted) {
            navigate('/');
        }
    }, [user]);

    const hasPaidSubscription = user.subscription?.__typename === 'RecurringCompanySubscription';

    return (
        <TermsUi
            hasPaidSubscription={hasPaidSubscription}
            loading={loading}
            error={error || cancelSubscriptionError}
            onCancelSubscription={() =>
                cancelSubscription({refetchQueries: [{query: GetCurrentUserDocument}]})
            }
            onApprove={() => approveTermsAndConditions({variables: {approve: true}})}
            onLogout={() => navigate('/logout')}
        />
    );
}

export type TermsUiProps = {
    hasPaidSubscription: boolean;
    loading: boolean;
    error: ApolloError | undefined;
    onCancelSubscription: () => unknown;
    onApprove: () => unknown;
    onLogout: () => unknown;
};
export function TermsUi({
    hasPaidSubscription,
    loading,
    error,
    onCancelSubscription,
    onApprove,
    onLogout,
}: TermsUiProps) {
    const [showModal, setShowModal] = React.useState(false);

    const declineLabel = hasPaidSubscription ? 'Cancel subscription' : 'Logout';

    const onDecline = hasPaidSubscription ? onCancelSubscription : onLogout;

    return (
        <>
            <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                <div className="w-120">
                    <div className="text-left">
                        <VStack align="start" full>
                            <Heading3>
                                To continue using Sage Earth, you must accept our terms and
                                conditions.
                            </Heading3>
                            <DetailText>
                                If you do not wish you accept, you will no longer have access to
                                Sage Earth and your data within our platform.
                            </DetailText>
                            <div className="flex w-full flex-row justify-end pt-5">
                                <NavigationButtons
                                    backLabel={declineLabel}
                                    backColour="error"
                                    backOnClick={onDecline}
                                    nextLabel="Close"
                                    nextOnClick={() => setShowModal(false)}
                                />
                            </div>
                        </VStack>
                    </div>
                </div>
            </Modal>
            <HSplitLayout cta="logout" wide>
                <VStack full>
                    <PageHeader title="Accept our terms and conditions" />
                    {error && <ErrorCard />}
                    <VStack full>
                        <DetailText bold>
                            Please download and read the terms from the link below.
                        </DetailText>
                        <TermsLink label={'Terms and conditions'} />
                    </VStack>
                    <div className="flex flex-row justify-end pt-40">
                        <Button
                            onPress={() => setShowModal(true)}
                            bg="transparent"
                            textColor="error"
                        >
                            Disagree
                        </Button>
                        <Button onPress={onApprove} isLoading={loading}>
                            Agree
                        </Button>
                    </div>
                </VStack>
                <PosterLayout>
                    <PosterJoinTheMovement />
                </PosterLayout>
            </HSplitLayout>
        </>
    );
}
