import React from 'react';
import {ApolloError} from '@apollo/client';

import {ReportingPeriodContext, reportingPeriodPlaceholder} from '../reporting-period';
import {CompanyReportingPeriod} from '../../../graphql/generated/graphql';
import {useModifySection} from '../use-modify-section';
import {ModifyProfileVehiclesDocument} from './graphql';

type ProfileVehiclesWizardStep =
    | 'unknown'
    | 'intro'
    | 'suggested'
    | 'fuel_quantity'
    | 'completed'
    | 'period_open';

type ProfileVehiclesState = {
    transactions: Record<string, boolean>;
    fuelQuantity?: number;
    currentStep: ProfileVehiclesWizardStep;
    shouldSubmit: boolean;
};

type ProfileVehiclesAction =
    | {type: 'start'}
    | {
          type: 'identifyTransactions';
          payload: Record<string, boolean>;
      }
    | {
          type: 'setFuelQuantity';
          payload?: number;
      }
    | {type: 'complete'}
    | {type: 'reset'; payload: CompanyReportingPeriod};

function useVehiclesReducer(reportingPeriod: CompanyReportingPeriod) {
    function initialStepForReportingPeriod(reportingPeriod: CompanyReportingPeriod) {
        return reportingPeriod.isOpen
            ? 'period_open'
            : reportingPeriod.vehicles != null
              ? 'completed'
              : 'intro';
    }

    const initialState: ProfileVehiclesState = {
        transactions: {},
        currentStep: initialStepForReportingPeriod(reportingPeriod),
        shouldSubmit: false,
    };
    return React.useReducer(
        (state: ProfileVehiclesState, action: ProfileVehiclesAction): ProfileVehiclesState => {
            switch (action.type) {
                case 'start':
                    return {
                        ...state,
                        currentStep: 'suggested',
                    };

                case 'identifyTransactions':
                    return {
                        ...state,
                        transactions: action.payload,
                        currentStep: 'fuel_quantity',
                    };

                case 'setFuelQuantity': {
                    return {
                        ...state,
                        fuelQuantity: action.payload,
                        shouldSubmit: true,
                    };
                }

                case 'complete':
                    return {
                        ...initialState,
                        shouldSubmit: false,
                        currentStep: 'completed',
                    };

                case 'reset':
                    return {
                        ...initialState,
                        currentStep: initialStepForReportingPeriod(action.payload),
                    };
            }
        },
        initialState,
    );
}

type ProfileVehiclesContextValue = {
    dispatch: (action: ProfileVehiclesAction) => void;
    currentStep: ProfileVehiclesWizardStep;
    reportingPeriod: CompanyReportingPeriod;
    submitting?: boolean;
    error?: ApolloError;
};

export const ProfileVehiclesContext = React.createContext<ProfileVehiclesContextValue>({
    dispatch: () => {
        // placeholder
    },
    reportingPeriod: reportingPeriodPlaceholder,
    currentStep: 'intro',
});

type ProfileVehiclesContextProviderProps = {
    children: React.ReactNode;
};
export function ProfileVehiclesContextProvider({children}: ProfileVehiclesContextProviderProps) {
    const reportingPeriod = React.useContext(ReportingPeriodContext);
    const [state, dispatch] = useVehiclesReducer(reportingPeriod);
    const [submit, {loading, error, finished}] = useModifySection(ModifyProfileVehiclesDocument, [
        'GetProfileVehicle',
    ]);
    const reportingPeriodId = reportingPeriod.id;

    React.useEffect(() => {
        if (state.shouldSubmit) {
            const fuelSpendTransactionIds = Object.entries(state.transactions).map(
                ([id, isRelatedSpend]) => ({id, isRelatedSpend}),
            );
            submit({
                variables: {
                    params: {
                        companyReportingPeriodId: reportingPeriodId,
                        fuelSpendTransactionIds,
                        fuelQuantity: state.fuelQuantity,
                    },
                },
            });
        }
    }, [submit, reportingPeriodId, state]);

    React.useEffect(() => {
        if (finished) {
            dispatch({type: 'complete'});
        }
    }, [finished]);

    React.useEffect(() => {
        dispatch({type: 'reset', payload: reportingPeriod});
    }, [reportingPeriod]);

    return (
        <ProfileVehiclesContext.Provider
            value={{
                dispatch,
                currentStep: state.currentStep,
                reportingPeriod,
                submitting: loading,
                error: error,
            }}
        >
            {children}
        </ProfileVehiclesContext.Provider>
    );
}
