import React from 'react';
import {useQuery} from '@apollo/client';

import {DetailText, HStack, LoadingSpinner, VStack} from '@sphericsio/design-system';

import {ErrorCard} from '../../error-card';
import {ReportingPeriodContext} from '../reporting-period';
import {GetProfilePeopleEntryDocument} from './graphql';
import {useFragment} from '../../../graphql/generated';
import {ProfilePeopleEntryFragmentDocument} from './fragments';

export function PeopleProfileAnswers() {
    const reportingPeriod = React.useContext(ReportingPeriodContext);
    const {
        loading,
        error,
        data: res,
    } = useQuery(GetProfilePeopleEntryDocument, {
        variables: {companyReportingPeriodId: reportingPeriod.id},
    });

    if (error) {
        return <ErrorCard />;
    }

    if (loading) {
        return <LoadingSpinner />;
    }
    const data = useFragment(ProfilePeopleEntryFragmentDocument, res?.getProfilePeopleEntry);

    if (!data?.number_of_employees) {
        return (
            <div className="py-5">
                <DetailText colour="secondary">No data for this section.</DetailText>
            </div>
        );
    }

    return (
        <VStack seperator>
            <div className="py-5">
                <DetailText bold>Number of employees</DetailText>
                <div className="pt-2">
                    <DetailText>{data.number_of_employees}</DetailText>
                </div>
            </div>
            <div className="py-5">
                <DetailText bold>Percent working from home</DetailText>
                <div className="pt-2">
                    <DetailText>{data.percent_working_from_home}%</DetailText>
                </div>
            </div>
            {data.workplace_entries && (
                <div className="py-5">
                    <DetailText bold>Percent working from</DetailText>
                    {data.workplace_entries.map((w) => {
                        return (
                            <div className="py-2" key={w.address.id}>
                                <HStack>
                                    <div className="w-60">
                                        <DetailText inline={false}>
                                            <div>{w.address.address}</div>
                                            <div>{w.address.postcode}</div>
                                        </DetailText>
                                    </div>
                                    <DetailText>{w.percent}%</DetailText>
                                </HStack>
                            </div>
                        );
                    })}
                </div>
            )}
        </VStack>
    );
}
