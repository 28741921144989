import React from 'react';
import {useQuery} from '@apollo/client';
import {useNavigate, useParams} from 'react-router-dom';

import {HStack, VStack} from '@sphericsio/design-system';

import {Layout} from '../components/layout';
import {Loading} from '../components/loading';
import {ErrorCard} from '../components/error-card';
import {DashboardTopActivities} from '../components/dashboard/top-activities';
import {formatDateForUrl} from '../components/date-display';
import {graphql} from '../graphql/generated';
import {DatePicker} from '../components/picker/date-picker';
import {
    FinancialDateFilterContext,
    FinancialDateFilterContextProvider,
    PickerContextProvider,
} from '../components/picker/context';
import {IntervalTypes} from '../components/picker/config';

const FetchEmittingActivitiesDocument = graphql(/* GraphQL */ `
    query FetchEmittingActivities($from: Date!, $to: Date!) {
        getEmissionsForTaxonomy(from: $from, to: $to, taxonomyKey: "accounting") {
            taxonomyTerm {
                id
                name
                description
            }
            kgCo2e
        }
    }
`);

function TopActivities() {
    const {selectedPeriod, setSelection} = React.useContext(FinancialDateFilterContext);
    const {periodStart, periodEnd} = useParams();
    const {loading, error, data} = useQuery(FetchEmittingActivitiesDocument, {
        fetchPolicy: 'network-only',
        variables: {from: periodStart, to: periodEnd},
    });
    const navigate = useNavigate();

    React.useEffect(() => {
        if (periodStart && periodEnd) {
            setSelection({
                type: IntervalTypes.custom,
                periodStart,
                periodEnd,
            });
        }
    }, []);

    React.useEffect(() => {
        if (selectedPeriod?.periodStart && selectedPeriod.periodEnd) {
            navigate(
                `/activities/${formatDateForUrl(
                    new Date(selectedPeriod.periodStart),
                )}/${formatDateForUrl(new Date(selectedPeriod.periodEnd))}`,
            );
        }
    }, [selectedPeriod]);

    return (
        <Layout
            title="Your Activities"
            subtitle="Get a breakdown of your activities and their emissions."
        >
            {loading && <Loading />}
            {error && <ErrorCard />}
            {data && (
                <VStack>
                    <HStack>
                        <PickerContextProvider>
                            <DatePicker />
                        </PickerContextProvider>
                    </HStack>
                    <DashboardTopActivities
                        data={data.getEmissionsForTaxonomy.map((entry) => ({
                            taxonomyTermKey: entry.taxonomyTerm.id,
                            activityName: entry.taxonomyTerm.name,
                            activityDescription: entry.taxonomyTerm.description ?? undefined,
                            kgCo2e: entry.kgCo2e,
                        }))}
                    />
                </VStack>
            )}
        </Layout>
    );
}

export function TopActivitiesWrapper() {
    return (
        <FinancialDateFilterContextProvider>
            <TopActivities />
        </FinancialDateFilterContextProvider>
    );
}
