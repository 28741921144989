import React from 'react';
import {upperCase} from 'lodash';

import config from '../../config';
import {FinancialProviderSyncButton} from '../components/financial-provider-sync-button';
import xeroConnect from '../images/xero-connect-blue.svg';
import xeroLogin from '../images/xero-login.svg';
import quickbooksConnect from '../images/quickbooks-connect.svg';
import quickbooksLogin from '../images/quickbooks-sign-in.svg';
import sageConnect from '../images/sage-connect.svg';
import sageLogin from '../images/sage-login.svg';
import sage50UploadButton from '../images/sage50-upload-button.svg';
import fakeDataUploadButton from '../images/fake-data-upload-button.svg';
import {useUser} from './user-context';
import {FinancialDataSyncButton} from './financial-data-sync-button';
import {useFeatureFlag} from '../hooks/use-feature-flag';

type SyncType = 'api' | 'spreadsheet';
type DataSyncType = 'sage50' | 'fake';

type FinancialProviderUIConfig = {
    name: string;
    connectButtonImage: string;
    loginButtonImage: string;
    syncType: SyncType;
    dataSyncType?: DataSyncType;
};

const PROVIDERS: FinancialProviderUIConfig[] = [
    {
        name: 'sage',
        connectButtonImage: sageConnect,
        loginButtonImage: sageLogin,
        syncType: 'api',
    },
    {
        name: 'sage50',
        connectButtonImage: sage50UploadButton,
        loginButtonImage: sage50UploadButton,
        syncType: 'spreadsheet',
        dataSyncType: 'sage50',
    },
    {
        name: 'xero',
        connectButtonImage: xeroConnect,
        loginButtonImage: xeroLogin,
        syncType: 'api',
    },
    {
        name: 'quickbooks',
        connectButtonImage: quickbooksConnect,
        loginButtonImage: quickbooksLogin,
        syncType: 'api',
    },
    {
        name: 'fake',
        connectButtonImage: fakeDataUploadButton,
        loginButtonImage: fakeDataUploadButton,
        syncType: 'spreadsheet',
        dataSyncType: 'fake',
    },
];

export function useAccountingApiUser(forLogin: boolean) {
    if (forLogin) {
        return true;
    } else {
        const user = useUser();
        return user.financialProvider && user.financialProvider.sync_method !== 'data-upload';
    }
}

type FinancialProviderButtonsProps = {
    forLogin: boolean;
    hasActiveSubscription: boolean;
    hasPendingSubscription: boolean;
    selectedProvider?: string;
    onButtonClick?: (provider: string) => void;
};

export function FinancialProviderButtons({
    forLogin,
    hasActiveSubscription,
    hasPendingSubscription,
    selectedProvider,
    onButtonClick,
}: FinancialProviderButtonsProps) {
    const fakeCallbackEnabled = config.allowFakeFinancialProvider;
    const accountingApiUser = useAccountingApiUser(forLogin);

    const providers = PROVIDERS.filter((s) => {
        if (s.name === 'sage50' && !forLogin) {
            return useFeatureFlag('sage50');
        } else {
            return true;
        }
    });
    return (
        <div className="flex flex-col space-y-3">
            {providers.map((providerUiConfig) => {
                if (providerUiConfig.syncType === 'api') {
                    return (
                        <div className="flex items-center space-x-10" key={providerUiConfig.name}>
                            <FinancialProviderSyncButton
                                key={providerUiConfig.name}
                                financialProviderName={providerUiConfig.name}
                                forLogin={forLogin}
                                onButtonClick={onButtonClick}
                                hasActiveSubscription={hasActiveSubscription}
                                hasPendingSubscription={hasPendingSubscription}
                                selectedProvider={selectedProvider}
                            >
                                {(onClick) => (
                                    <img
                                        src={
                                            forLogin
                                                ? providerUiConfig.loginButtonImage
                                                : providerUiConfig.connectButtonImage
                                        }
                                        alt={`Connect to ${upperCase(providerUiConfig.name)}`}
                                        onClick={onClick}
                                    />
                                )}
                            </FinancialProviderSyncButton>
                        </div>
                    );
                } else if (
                    providerUiConfig.syncType === 'spreadsheet' &&
                    !accountingApiUser &&
                    !forLogin &&
                    providerUiConfig.dataSyncType
                ) {
                    if (providerUiConfig.dataSyncType === 'fake') {
                        if (fakeCallbackEnabled) {
                            return (
                                <div
                                    className="flex items-center space-x-10"
                                    key={providerUiConfig.dataSyncType}
                                >
                                    <FinancialDataSyncButton
                                        providerName={providerUiConfig.dataSyncType}
                                        onButtonClick={onButtonClick}
                                        hasActiveSubscription={hasActiveSubscription}
                                        hasPendingSubscription={hasPendingSubscription}
                                        selectedProvider={selectedProvider}
                                    >
                                        {(onClick) => (
                                            <img
                                                src={providerUiConfig.connectButtonImage}
                                                alt="Generate test data"
                                                onClick={onClick}
                                            />
                                        )}
                                    </FinancialDataSyncButton>
                                </div>
                            );
                        }
                    } else {
                        return (
                            <div
                                className="flex items-center space-x-10"
                                key={providerUiConfig.dataSyncType}
                            >
                                <FinancialDataSyncButton
                                    providerName={providerUiConfig.dataSyncType}
                                    onButtonClick={onButtonClick}
                                    hasActiveSubscription={hasActiveSubscription}
                                    hasPendingSubscription={hasPendingSubscription}
                                    selectedProvider={selectedProvider}
                                >
                                    {(onClick) => (
                                        <img
                                            src={providerUiConfig.connectButtonImage}
                                            alt={`Upload a ${providerUiConfig.name} spreadsheet`}
                                            onClick={onClick}
                                        />
                                    )}
                                </FinancialDataSyncButton>
                            </div>
                        );
                    }
                }
            })}
        </div>
    );
}
