import React from 'react';

import config from '../../config';

// rewardful snippet
/* eslint-disable */
// @ts-ignore
(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');
/* eslint-enable */

type RewardfulProviderProps = {
    children: React.ReactNode;
};
export function RewardfulProvider({children}: RewardfulProviderProps) {
    React.useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://r.wdfl.co/rw.js';
        script.async = true;
        script.dataset.rewardful = config.rewardful.apiKey;
        document.head.appendChild(script);
        return () => {
            document.head.removeChild(script);
        };
    }, []);
    const search = window.location.search;
    React.useEffect(() => {
        const searchParams = new URLSearchParams(search);
        const via = searchParams.get('via');
        if (via != null) {
            window.rewardful('source', via);
        }
    }, [search]);
    return <>{children}</>;
}
