import React from 'react';

import {VStack, DetailText, Modal, outlineColorClassnames} from '@sphericsio/design-system';
import {
    DataTableRef,
    relayConnectionQuery,
    RelayConnectionVariables,
} from '@sphericsio/mvp-ui-common';

import {
    FinancialAccounts,
    GetFinancialAccountsForCompanyQuery,
} from '../../graphql/generated/graphql';
import {GetCompanyFinancialAccountsDataDocument} from './graphql';
import {DataList} from '../data-list';
import {CategorisationContextProvider} from '../categorisation/context';
import {FinancialAccountTransactionsList} from './transactions-list';
import {ExcludeTransactionContextProvider} from '../financial-transactions/exclude-transaction-context';
import {IncludeModal} from '../modals/include-modal';
import {ExcludeModal} from '../modals/exclude-modal';
import {FinancialAccountRow} from './financial-account-row';
import {FinancialAccountFiltersContext} from './filters-context';

export function FinancialAccountsFeed() {
    const ref = React.useRef<DataTableRef>(null);
    const [showTransactions, setShowTransactions] = React.useState<boolean>(false);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [currentAccount, setCurrentAccount] = React.useState<FinancialAccounts>();
    const [excludeAccount, setAccountToExclude] = React.useState<FinancialAccounts>();

    const {filter, refetch: onRefetch} = React.useContext(FinancialAccountFiltersContext);

    const queryConfig = relayConnectionQuery(
        GetCompanyFinancialAccountsDataDocument,
        (data: GetFinancialAccountsForCompanyQuery) => data.getFinancialAccountsForCompany,
        {filter} as Partial<RelayConnectionVariables>,
        'network-only',
    );

    const refreshData = () => {
        if (onRefetch) {
            onRefetch();
        }
        ref.current?.refreshData();
    };

    const headerData = [
        {key: 'name', value: <>Account</>},
        {key: 'category', value: <>Category</>},
        {key: 'confirmed', value: <>Confirm?</>},
        {key: 'exclude', value: <>Exclude</>},
    ];

    const handleExpand = (account: FinancialAccounts) => {
        if (currentAccount?.id !== account.id) {
            setCurrentAccount(account);
            setShowTransactions(true);
        } else {
            setCurrentAccount(undefined);
            setShowTransactions(!showTransactions);
        }
    };

    const handleExcludeClick = (account: FinancialAccounts) => {
        setAccountToExclude(account);
        setShowModal(true);
    };

    const isAccountIgnored = (account: FinancialAccounts) => {
        return (
            account.user_terms.taxonomy_key === 'ignored' ||
            account.terms.taxonomy_key === 'ignored'
        );
    };

    return (
        <ExcludeTransactionContextProvider
            onSaveSuccess={() => {
                setShowModal(false);
                refreshData();
            }}
        >
            {
                <Modal
                    testId="financialAccountsInclusion"
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                >
                    {excludeAccount?.id &&
                        (isAccountIgnored(excludeAccount) ? (
                            <IncludeModal
                                onCancel={() => setShowModal(false)}
                                id={excludeAccount?.id}
                                type={'financial_account'}
                            >
                                <div
                                    className={` border ${outlineColorClassnames(
                                        'major-action',
                                    )} w-full p-5`}
                                >
                                    <DetailText>{excludeAccount.name}</DetailText>
                                </div>
                            </IncludeModal>
                        ) : (
                            <ExcludeModal
                                onCancel={() => setShowModal(false)}
                                id={excludeAccount.id}
                                type={'financial_account'}
                            >
                                <div
                                    className={`border ${outlineColorClassnames(
                                        'error',
                                    )} w-full p-5`}
                                >
                                    <DetailText>{excludeAccount.name}</DetailText>
                                </div>
                            </ExcludeModal>
                        ))}
                </Modal>
            }
            <DataList
                ref={ref}
                idField="id"
                queryConfig={queryConfig}
                sortableFields={['amount']}
                initialSortDirection="desc"
                headerData={headerData}
            >
                {(account, _index) => (
                    <VStack>
                        <CategorisationContextProvider>
                            <FinancialAccountRow
                                account={account}
                                refreshData={refreshData}
                                isAccountIgnored={isAccountIgnored}
                                handleExcludeClick={handleExcludeClick}
                                handleExpand={handleExpand}
                                showTransactions={showTransactions}
                                currentAccountId={currentAccount?.id}
                            />
                        </CategorisationContextProvider>
                        {currentAccount?.id === account.id && (
                            <FinancialAccountTransactionsList accountId={account.id} />
                        )}
                    </VStack>
                )}
            </DataList>
        </ExcludeTransactionContextProvider>
    );
}
