import {graphql} from '../../../graphql/generated';

export const GetProfileVehicleDocument = graphql(/* GraphQL */ `
    query GetProfileVehicle($companyReportingPeriodId: ID!) {
        getProfileVehicle(companyReportingPeriodId: $companyReportingPeriodId) {
            ...ProfileVehiclesEntry
        }
    }
`);

export const GetVehiclesEmissionsDocument = graphql(/* GraphQL */ `
    query GetVehiclesEmissionsForReportingPeriod($id: ID!) {
        getCompanyReportingPeriodById(id: $id) {
            vehicles {
                ...BenchmarkingProfileEntry
            }
            totalEmissionsForPeriod
        }
    }
`);

export const ModifyProfileVehiclesDocument = graphql(/* GraphQL */ `
    mutation ModifyProfileVehicles($params: ProfileVehiclesInput!) {
        modifyProfileVehicles(params: $params) {
            id
        }
    }
`);

export const GetSuggestedVehicleSpendsDocument = graphql(/* GraphQL */ `
    query GetSuggestedVehicleSpends($from: Date!, $to: Date!) {
        getSuggestedVehicleTransactions(from: $from, to: $to) {
            id
            merchant_name
            transactions {
                id
                description
                amount
                transaction_date
            }
        }
    }
`);
