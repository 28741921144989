import React from 'react';

import {FullScreenError} from '../components/full-screen';

export function NotFound() {
    return (
        <FullScreenError
            heading="404"
            message="This page doesn't exist."
            buttonLabel="Back home"
            buttonHref="/"
        />
    );
}
