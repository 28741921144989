import React from 'react';
import {useApolloClient} from '@apollo/client';
import classnames from 'classnames';
import {useIntercom} from 'react-use-intercom';

import {VStack, DetailText, Button, Heading2} from '@sphericsio/design-system';

import {ErrorCard} from '../../error-card';
import {UploadContext} from '../context';
import {HSplitLayout} from '../../layout/h-split';
import {PosterLayout} from '../../onboarding/poster-layout';
import {PosterJoinTheMovement} from '../../onboarding/poster-join-the-movement';
import {PageHeader} from '../../page-header';
import {ProcessingQueue} from './queue';

export type ProcessingStatus =
    | 'pending'
    | 'ready'
    | 'processing'
    | 'finished'
    | 'ongoing'
    | 'complete'
    | 'error';
export type ExecutionState = {
    requestId: string;
    filename: string;
};
export type ExecutionData = Record<string, ExecutionState>;

function Processing() {
    const intercom = useIntercom();
    const {
        requests,
        dispatch,
        flow,
        refetchPreviousUpload,
        uploadRequestType,
        refetchLastBackgroundExecution,
        fetchLastBackgroundExecution,
    } = React.useContext(UploadContext);
    const [requestsById, setRequestsById] = React.useState<ExecutionData>({});
    const [queue, setQueue] = React.useState<ExecutionState[]>([]);
    const [current, setCurrent] = React.useState<ExecutionState>();
    const [queueStatus, setQueueStatus] = React.useState<ProcessingStatus>('pending');
    const client = useApolloClient();

    React.useEffect(() => {
        if (queueStatus === 'pending' && requests) {
            const byId = requests.reduce((acc, req) => {
                if (req.status === 'uploadComplete' && req.id) {
                    return {
                        ...acc,
                        [req.id]: {
                            requestId: req.id,
                            filename: req.file.name,
                        },
                    };
                }
                return acc;
            }, {});
            setRequestsById(byId);
            setQueue(Object.values(byId));
            setQueueStatus('ready');
        }
    }, [queueStatus, requests]);

    React.useEffect(() => {
        if (queueStatus === 'ready') {
            const next = queue.shift();

            if (next) {
                setCurrent(next);
                setQueueStatus('processing');
            } else {
                setQueueStatus('complete');
            }
        }
    }, [queueStatus]);

    React.useEffect(() => {
        if (current) {
            if (queueStatus === 'finished') {
                setCurrent(undefined);
                setQueueStatus('ready');
            }
        }
        if (queueStatus === 'error') {
            refetchLastBackgroundExecution
                ? refetchLastBackgroundExecution()
                : fetchLastBackgroundExecution && fetchLastBackgroundExecution();
        }
        if (queueStatus === 'ongoing') {
            dispatch({type: 'processingOngoing'});
        }
        if (queueStatus === 'complete') {
            if (flow === 'onboarding' && uploadRequestType === 'financial_transactions') {
                refetchPreviousUpload && refetchPreviousUpload();
                client.refetchQueries({
                    include: ['GetCurrentUser'],
                });
            }
            dispatch({type: 'processingComplete'});
        }
    }, [current, queueStatus]);

    const title = 'Please wait whilst we analyse your data';

    return (
        <VStack>
            {flow === 'onboarding' && <PageHeader title={title} />}
            {flow === 'upload' && <Heading2>{title}</Heading2>}
            <div
                className={classnames({
                    'w-96 text-left': flow !== 'onboarding',
                })}
            >
                <DetailText inline={false}>
                    Processing usually takes a couple of minutes but, depending on the volume of
                    data can take a while. Please be patient with us as this is a crucial part of
                    the journey.
                </DetailText>
            </div>
            <ProcessingQueue
                onStatusChange={(status: ProcessingStatus) => setQueueStatus(status)}
                requests={Object.values(requestsById)}
                current={current}
            />
            {/* If there is an error, we should be redirected to the error page. In case there's an issue with that, this remains so the user can contact support */}
            {queueStatus === 'error' && (
                <VStack>
                    <ErrorCard
                        reportError={false}
                        message="There was an issue processing your upload."
                    />
                    <Button onPress={() => intercom.show()}>Contact support</Button>
                </VStack>
            )}
        </VStack>
    );
}

export function UploadProcessing() {
    const {flow} = React.useContext(UploadContext);
    if (flow !== 'onboarding') {
        return <Processing />;
    }
    return (
        <HSplitLayout cta="logout">
            <Processing />
            <PosterLayout bgImage={{type: 'onboarding'}}>
                <PosterJoinTheMovement />
            </PosterLayout>
        </HSplitLayout>
    );
}
