import {ApolloError} from '@apollo/client';
import React from 'react';

import {CompanyReportingPeriod} from '../../../graphql/generated/graphql';
import {ReportingPeriodContext, reportingPeriodPlaceholder} from '../reporting-period';
import {GHGGasQuantityEntry, IdentifyTransactionsPayload} from './types';
import {useModifySection} from '../use-modify-section';
import {ModifyProfileGHGDocument} from './graphql';

type ProfileGHGWizardStep =
    | 'unknown'
    | 'intro'
    | 'suggested'
    | 'own'
    | 'topped_up'
    | 'gas_quantities'
    | 'spend_split'
    | 'completed'
    | 'period_open';

type ProfileGHGState = {
    transactions: Record<string, boolean>;
    transactionSpend?: number;
    ownedAppliences?: boolean;
    toppedUp?: boolean;
    gasQuantites: GHGGasQuantityEntry[];
    spendSplitPercent?: number;
    currentStep: ProfileGHGWizardStep;
    shouldSubmit: boolean;
};

type ProfileGHGAction =
    | {type: 'start'}
    | {
          type: 'identifyTransactions';
          payload: IdentifyTransactionsPayload;
      }
    | {type: 'skipIdentifyTransactions'}
    | {type: 'setOwnedAppliencesInPeriod'; payload: boolean}
    | {type: 'setToppedUpInPeriod'; payload: boolean}
    | {type: 'setGasQuantities'; payload: GHGGasQuantityEntry[]}
    | {type: 'skipGasQuantites'}
    | {type: 'setSpendSplit'; payload: number}
    | {type: 'complete'}
    | {type: 'reset'; payload: CompanyReportingPeriod};

function useGHGReducer(reportingPeriod: CompanyReportingPeriod) {
    function initialStepForReportingPeriod(reportingPeriod: CompanyReportingPeriod) {
        return reportingPeriod.isOpen
            ? 'period_open'
            : reportingPeriod.ghg != null
              ? 'completed'
              : 'intro';
    }

    function hasIdentifiedTransactions(state: ProfileGHGState) {
        const identifiedTransactionsCount = Object.values(state.transactions).filter(
            (value) => value,
        ).length;

        return identifiedTransactionsCount > 0;
    }

    const initialState: ProfileGHGState = {
        transactions: {},
        gasQuantites: [],
        shouldSubmit: false,
        currentStep: initialStepForReportingPeriod(reportingPeriod),
    };

    return React.useReducer((state: ProfileGHGState, action: ProfileGHGAction): ProfileGHGState => {
        switch (action.type) {
            case 'start':
                return {
                    ...state,
                    currentStep: 'suggested',
                };

            case 'identifyTransactions':
                return {
                    ...state,
                    transactions: action.payload.transactions,
                    transactionSpend: action.payload.totalSpend,
                    currentStep: 'topped_up',
                };

            case 'skipIdentifyTransactions':
                return {
                    ...state,
                    transactions: {},
                    transactionSpend: undefined,
                    currentStep: 'own',
                };

            case 'setOwnedAppliencesInPeriod':
                return {
                    ...state,
                    ownedAppliences: action.payload,
                    shouldSubmit: !action.payload,
                    currentStep: action.payload ? 'topped_up' : state.currentStep,
                };

            case 'setToppedUpInPeriod':
                return {
                    ...state,
                    toppedUp: action.payload,
                    shouldSubmit: !action.payload,
                    currentStep: action.payload ? 'gas_quantities' : state.currentStep,
                };

            case 'setGasQuantities':
                return {
                    ...state,
                    gasQuantites: action.payload,
                    shouldSubmit: true,
                };

            case 'skipGasQuantites':
                if (hasIdentifiedTransactions(state)) {
                    return {
                        ...state,
                        currentStep: 'spend_split',
                    };
                } else {
                    return state;
                }

            case 'setSpendSplit':
                return {
                    ...state,
                    spendSplitPercent: action.payload,
                    shouldSubmit: true,
                };

            case 'complete':
                return {
                    ...initialState,
                    shouldSubmit: false,
                    currentStep: 'completed',
                };

            case 'reset':
                return {
                    ...initialState,
                    currentStep: initialStepForReportingPeriod(action.payload),
                };
        }
    }, initialState);
}

export type ProfileGHGContextValue = {
    dispatch: (action: ProfileGHGAction) => void;
    getTransactionSpend: () => number;
    currentStep: ProfileGHGWizardStep;
    reportingPeriod: CompanyReportingPeriod;
    submitting?: boolean;
    error?: ApolloError;
};

export const ProfileGHGContext = React.createContext<ProfileGHGContextValue>({
    dispatch: () => {
        // placeholder
    },
    getTransactionSpend: () => 0,
    reportingPeriod: reportingPeriodPlaceholder,
    currentStep: 'intro',
});

type ProfileGHGContextProviderProps = {
    children: React.ReactNode;
};
export function ProfileGHGContextProvider({children}: ProfileGHGContextProviderProps) {
    const reportingPeriod = React.useContext(ReportingPeriodContext);
    const [state, dispatch] = useGHGReducer(reportingPeriod);
    const [submit, {loading, error, finished}] = useModifySection(ModifyProfileGHGDocument, [
        'GetProfileGHG',
    ]);
    const reportingPeriodId = reportingPeriod.id;
    React.useEffect(() => {
        if (state.shouldSubmit) {
            const ghgTransactionIds = Object.entries(state.transactions).map(
                ([id, isRelatedSpend]) => ({
                    id,
                    isRelatedSpend,
                }),
            );
            submit({
                variables: {
                    params: {
                        companyReportingPeriodId: reportingPeriodId,
                        ghgTransactionIds,
                        gasQuantities: state.gasQuantites,
                        ownedAppliences: state.ownedAppliences,
                        toppedUp: state.toppedUp,
                        spendSplitPercent: state.spendSplitPercent,
                    },
                },
            });
        }
    }, [reportingPeriodId, state]);

    React.useEffect(() => {
        if (finished) {
            dispatch({type: 'complete'});
        }
    }, [finished, dispatch]);

    React.useEffect(() => {
        dispatch({type: 'reset', payload: reportingPeriod});
    }, [reportingPeriod, dispatch]);

    return (
        <ProfileGHGContext.Provider
            value={{
                dispatch,
                getTransactionSpend: () => {
                    return state.transactionSpend ?? 0;
                },
                currentStep: state.currentStep,
                reportingPeriod,
                submitting: loading,
                error,
            }}
        >
            {children}
        </ProfileGHGContext.Provider>
    );
}
