import React from 'react';
import * as Yup from 'yup';
import {useMutation} from '@apollo/client';

import {DetailText, HStack, Strong, VStack, TextButton} from '@sphericsio/design-system';
import {Form, Button} from '@sphericsio/forms';

import {formatPostcode} from './postcode';
import {TabPageHeader} from '../../page-header';
import {BuildingTypeSelect} from './building-type-select';
import {ErrorCard} from '../../error-card';
import {graphql} from '../../../graphql/generated';
import {CompanyAddress} from '../../../graphql/generated/graphql';

const UpdateAddressBuildingTypesDocument = graphql(/* GraphQL */ `
    mutation UpdateAddressBuildingTypes($params: [UpdateCompanyAddressBuildingTypeInput!]!) {
        updateCompanyAddressesWithBuildingTypes(params: $params) {
            ...CompanyAddresses
        }
    }
`);

const FORM_SCHEMA = Yup.array().of(Yup.string().required('Building type is required'));

type ProfilePlacesBuildingTypesProps = {
    addresses: CompanyAddress[];
    selectedAddressId?: string;
    onNext: () => void;
};
export function ProfilePlacesBuildingTypes({
    addresses,
    selectedAddressId,
    onNext,
}: ProfilePlacesBuildingTypesProps) {
    const [submit, {loading, error, data}] = useMutation(UpdateAddressBuildingTypesDocument);

    React.useEffect(() => {
        if (data != null) {
            onNext();
        }
    }, [data, onNext]);

    let filteredAddresses = addresses;
    if (selectedAddressId != null) {
        filteredAddresses = addresses.filter((address) => address.id === selectedAddressId);
    }

    return (
        <Form
            initialValues={addresses.map((address) => address.building_type?.id ?? '')}
            validationSchema={FORM_SCHEMA}
            onSubmit={(values) => {
                submit({
                    variables: {
                        params: addresses.map((address, index) => ({
                            companyAddressId: address.id,
                            buildingType: values[index],
                        })),
                    },
                });
            }}
        >
            <VStack testId="buildingUsageForm">
                <TabPageHeader
                    title="Please tell us what your building(s) are used for."
                    subtitle="Please select the most accurate category of building from the list."
                />
                {filteredAddresses.map((address, index) => (
                    <DetailText key={address.id}>
                        <HStack align="start">
                            <div className="flex-1 whitespace-pre-line">
                                {address.address.split('\n').join(', ')}
                                <div>
                                    <Strong>{formatPostcode(address.postcode)}</Strong>
                                </div>
                            </div>
                            <div className="w-72">
                                <BuildingTypeSelect
                                    name={`[${index}]`}
                                    placeholder="Please select from the list below"
                                />
                            </div>
                        </HStack>
                    </DetailText>
                ))}
                {error && <ErrorCard />}
                <HStack>
                    <TextButton onPress={onNext}>I&apos;m not sure</TextButton>
                    <Button isLoading={loading} type="submit">
                        Confirm
                    </Button>
                </HStack>
            </VStack>
        </Form>
    );
}
