import React from 'react';

import {ProfileVehiclesFuelQuantites} from '../fuel-quantity';
import {ProfileVehiclesSuggested} from '../suggested';
import {EditProfileVehiclesContext} from './context';

export function EditProfileVehiclesWizard() {
    const {currentStep, answers, reportingPeriod, dispatch, error, submitting} = React.useContext(
        EditProfileVehiclesContext,
    );

    return (
        <div>
            {currentStep === 'transactions' && (
                <ProfileVehiclesSuggested
                    reportingPeriod={reportingPeriod}
                    onNext={(payload) => dispatch({type: 'identifyTransactions', payload})}
                    defaultValue={false}
                    confirmedSpend={answers.confirmedSpend}
                />
            )}
            {currentStep === 'fuel_quantity' && (
                <ProfileVehiclesFuelQuantites
                    amount={answers.fuel_quantity?.toString()}
                    onNext={(payload) => {
                        if (payload) {
                            dispatch({type: 'setFuelQuantity', payload});
                        } else {
                            dispatch({type: 'setFuelQuantity'});
                        }
                    }}
                    error={error}
                    submitting={submitting}
                    reportingPeriod={reportingPeriod}
                />
            )}
        </div>
    );
}
