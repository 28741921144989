import React from 'react';
import {capitalize} from 'lodash';

import {Button, DetailText, Heading2, HStack, Panel} from '@sphericsio/design-system';

import {formatDateString} from '../date-display';
import {CompanyReportingPeriod} from '../../graphql/generated/graphql';

type ProfileSectionPanelProps = {
    reportingPeriod: CompanyReportingPeriod;
    heading: string;
    sectionIconSrc?: string;
} & (
    | {
          type: 'pending';
          onPressStart: () => void;
          children: React.ReactNode;
      }
    | {
          type: 'complete';
          children: React.ReactNode;
      }
);
export function ProfileSectionPanel(props: ProfileSectionPanelProps) {
    return (
        <Panel>
            <HStack>
                {props.sectionIconSrc && (
                    <div className="flex items-center">
                        <img className="h-20" src={props.sectionIconSrc} />
                    </div>
                )}
                <div>
                    <Heading2>{capitalize(props.heading)}</Heading2>
                </div>
                <div>
                    <DetailText bold>
                        {formatDateString(props.reportingPeriod.period_start)} to{' '}
                        {formatDateString(props.reportingPeriod.period_end)}
                    </DetailText>
                </div>
                <div className="flex-1">
                    <DetailText inline={false}>{props.children}</DetailText>
                </div>
                <Button
                    onPress={props.type === 'pending' ? props.onPressStart : undefined}
                    isDisabled={props.type === 'complete'}
                    intercomTarget={
                        props.type === 'pending'
                            ? `Company Profile: Start ${props.heading}`
                            : undefined
                    }
                >
                    {props.type === 'complete' ? 'Complete' : 'Start Now'}
                </Button>
            </HStack>
        </Panel>
    );
}
