import React from 'react';
import {ApolloError, useMutation} from '@apollo/client';

import {SelectListItem} from '@sphericsio/design-system';

import {graphql} from '../../graphql/generated';
import {ExclusionContentTypeEnum} from '../../graphql/generated/graphql';
import {useExcludeReasons} from '../../hooks/exclusion';
import {GetCompanyExclusionReasonsDocument} from '../../hooks/graphql';
import {useBackgroundExecutionStatus} from '../../hooks/background-execution';

export const SetUserExcludedDocument = graphql(/* GraphQL */ `
    mutation SetUserExcluded($id: ID!, $reason: String!, $type: ExclusionContentTypeEnum!) {
        setUserExcluded(id: $id, reason: $reason, type: $type) {
            id
        }
    }
`);

const SetUserIncludedDocument = graphql(/* GraphQL */ `
    mutation SetUserIncluded($id: ID!, $type: ExclusionContentTypeEnum!) {
        setUserIncluded(id: $id, type: $type) {
            id
        }
    }
`);

type ExcludeTransactionContextValue = {
    submitExclude: (id: string, reason: string, type: ExclusionContentTypeEnum) => void;
    submitInclude: (id: string, type: ExclusionContentTypeEnum) => void;
    reasons?: SelectListItem[];
    loading: boolean;
    submitting: boolean;
    error?: ApolloError;
};
export const ExcludeTransactionContext = React.createContext<ExcludeTransactionContextValue>({
    submitExclude: () => {
        // placeholder
    },
    submitInclude: () => {
        // placeholder
    },
    loading: false,
    submitting: false,
});

type ExcludeTransactionContextProviderProps = {
    children: React.ReactNode;
    onSaveSuccess: () => void;
};
export function ExcludeTransactionContextProvider({
    children,
    onSaveSuccess,
}: ExcludeTransactionContextProviderProps) {
    const [submitting, setSubmitting] = React.useState(false);
    const [pollError, setPollError] = React.useState<ApolloError>();
    const [canPoll, setCanPoll] = React.useState<boolean>(false);
    const {items, fetchError, fetching} = useExcludeReasons();

    const [exclude, {loading: saving, error: excludeSaveError, data: excludeResult}] = useMutation(
        SetUserExcludedDocument,
        {
            refetchQueries: [{query: GetCompanyExclusionReasonsDocument}],
        },
    );
    const [include, {loading: savingInclude, error: includeSaveError, data: includeResult}] =
        useMutation(SetUserIncludedDocument);

    const error = fetchError || excludeSaveError || includeSaveError || pollError;

    const onFinish = () => {
        setSubmitting(false);
        setCanPoll(false);
        onSaveSuccess();
    };

    const onExclude = (id: string, reason: string, type: ExclusionContentTypeEnum) => {
        setCanPoll(true);
        exclude({variables: {id, reason, type}});
    };

    const onInclude = (id: string, type: ExclusionContentTypeEnum) => {
        setCanPoll(true);
        include({variables: {id, type}});
    };

    const backgroundExecutionId = React.useMemo(() => {
        const hasData = excludeResult?.setUserExcluded.id || includeResult?.setUserIncluded.id;
        const isNotLoading = !saving && !savingInclude;
        if (hasData && isNotLoading) {
            return excludeResult?.setUserExcluded.id ?? includeResult?.setUserIncluded.id;
        }
    }, [
        excludeResult?.setUserExcluded.id,
        includeResult?.setUserIncluded.id,
        saving,
        savingInclude,
    ]);

    React.useEffect(() => {
        if (saving || savingInclude) {
            setSubmitting(true);
        }
        if (error) {
            setSubmitting(false);
        }
    }, [saving, savingInclude, error]);

    return (
        <ExcludeTransactionContext.Provider
            value={{
                reasons: items,
                submitExclude: (id, reason, type) => onExclude(id, reason, type),
                submitInclude: (id, type) => onInclude(id, type),
                loading: fetching,
                submitting,
                error,
            }}
        >
            {backgroundExecutionId && canPoll && (
                <Poll
                    backgroundExecutionId={backgroundExecutionId}
                    onLoading={(s) => setSubmitting(s)}
                    onError={(e) => setPollError(e)}
                    onFinish={onFinish}
                />
            )}
            {children}
        </ExcludeTransactionContext.Provider>
    );
}

type PollProps = {
    backgroundExecutionId: string;
    onLoading: (s: boolean) => void;
    onError: (e: ApolloError) => void;
    onFinish: () => void;
};
function Poll({backgroundExecutionId, onLoading, onError, onFinish}: PollProps) {
    const poll = useBackgroundExecutionStatus(backgroundExecutionId);

    React.useEffect(() => {
        if (poll.loading) {
            onLoading(true);
        }
    }, [poll.loading]);

    React.useEffect(() => {
        if (poll.error) {
            onError(poll.error);
        }
    }, [poll.error]);

    React.useEffect(() => {
        if (poll.finished) {
            onFinish();
        }
    }, [poll.finished]);

    return null;
}
