import React from 'react';
import * as Yup from 'yup';
import {ApolloError} from '@apollo/client';

import {Button, HStack, TextButton, VStack} from '@sphericsio/design-system';
import {Form, FormikPillRadio, PillRadioOption} from '@sphericsio/forms';

import {ReportingPeriodDates} from '../reporting-period-dates';
import {TabPageHeader} from '../../page-header';
import {ErrorCard} from '../../error-card';
import {CompanyReportingPeriod} from '../../../graphql/generated/graphql';

const FORM_SCHEMA = Yup.object().shape({
    yesNo: Yup.string()
        .required('This answer is required')
        .oneOf(['yes', 'no'])
        .typeError('Amount must be a number'),
});

type ProfileGHGYesNoQuestionProps = {
    title: string;
    unknownValue?: boolean;
    reportingPeriod: CompanyReportingPeriod;
    error?: ApolloError;
    submitting?: boolean;
    onNext: (value: boolean) => void;
    prevAnswer?: boolean | null;
    testId?: string;
};
export function ProfileGHGYesNoQuestion({
    title,
    unknownValue,
    reportingPeriod,
    error,
    submitting,
    onNext,
    prevAnswer,
    testId,
}: ProfileGHGYesNoQuestionProps) {
    const initialAnswer = React.useMemo(() => {
        if (prevAnswer == null) {
            return '';
        } else if (prevAnswer) {
            return 'yes';
        } else {
            return 'no';
        }
    }, [prevAnswer]);
    const subtitle = <ReportingPeriodDates reportingPeriod={reportingPeriod} />;
    return (
        <VStack testId={testId}>
            <TabPageHeader title={title} subtitle={subtitle} />
            <Form
                initialValues={{yesNo: initialAnswer}}
                validationSchema={FORM_SCHEMA}
                onSubmit={({yesNo}) => {
                    const value = yesNo === 'yes';
                    onNext(value);
                }}
            >
                <FormikPillRadio name="yesNo">
                    <PillRadioOption value="yes">Yes</PillRadioOption>
                    <PillRadioOption value="no">No</PillRadioOption>
                </FormikPillRadio>
                <HStack>
                    {unknownValue != null && (
                        <TextButton onPress={() => onNext(unknownValue)}>
                            I don&apos;t know
                        </TextButton>
                    )}
                    <Button isLoading={submitting} type="submit">
                        Save and continue
                    </Button>
                </HStack>
            </Form>
            {error && <ErrorCard />}
        </VStack>
    );
}
