import React from 'react';
import {useQuery} from '@apollo/client';

import {useFragment} from '../../../../graphql/generated';
import {ReportingPeriodContext} from '../../reporting-period';
import {EditProfileEnergyContextProvider} from './context';
import {EditProfileEnergyWizard} from './wizard';
import {Loading} from '../../../loading';
import {ErrorCard} from '../../../error-card';
import {GetProfileEnergyDocument} from '../graphql';
import {ProfileEnergyFragmentDocument} from '../fragments';

export function OperationsEditEnergySection() {
    const reportingPeriod = React.useContext(ReportingPeriodContext);
    const {loading, error, data} = useQuery(GetProfileEnergyDocument, {
        variables: {companyReportingPeriodId: reportingPeriod.id},
    });

    const answers = useFragment(ProfileEnergyFragmentDocument, data?.getProfileEnergy);

    return (
        <div>
            {loading && <Loading />}
            {error && <ErrorCard />}
            {answers && (
                <EditProfileEnergyContextProvider answers={answers}>
                    <EditProfileEnergyWizard />
                </EditProfileEnergyContextProvider>
            )}
        </div>
    );
}
