import React from 'react';
import {Elements} from '@stripe/react-stripe-js';

import sofiaProFont from '@sphericsio/design-system/src/fonts/Sofia Pro Regular.otf';
import {Modal} from '@sphericsio/design-system';

import {initStripe} from '../../services/stripe';
import {SubscriptionWizard} from './wizard';

type SubscriptionsModalProps = {
    show: boolean;
    onClose: () => void;
    onError: () => void;
    onSuccess: () => void;
};
export function SubscriptionsModal({show, onClose, onError, onSuccess}: SubscriptionsModalProps) {
    const stripePromise = initStripe();

    return (
        <Modal isOpen={show} onClose={onClose} testId="subscription-modal">
            <Elements
                stripe={stripePromise}
                options={{
                    fonts: [{src: `url(${sofiaProFont})`, family: 'Sofia Pro Regular'}],
                }}
            >
                <div className="w-96">
                    <SubscriptionWizard onClose={onClose} onError={onError} onSuccess={onSuccess} />
                </div>
            </Elements>
        </Modal>
    );
}
