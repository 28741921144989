import React from 'react';
import {useQuery} from '@apollo/client';

import {HSplitLoading} from '../../../components/layout/h-split';
import {useExternalRedirect} from '../../../hooks/use-external-redirect';
import {graphql} from '../../../graphql/generated';
import {PosterJoinTheMovement} from '../../../components/onboarding/poster-join-the-movement';

export const xeroLoginUrlQuery = graphql(/* GraphQL */ `
    query GetXeroLoginUrl {
        financialProviderLoginUrl(
            provider: xero
            forLogin: true
            redirectPath: "/xero/app-store-signup-return"
        )
    }
`);

export function XeroAppStoreSignup() {
    const externalRedirect = useExternalRedirect();
    const {error} = useQuery(xeroLoginUrlQuery, {
        onCompleted(data) {
            externalRedirect(data.financialProviderLoginUrl, true);
        },
    });
    return (
        <HSplitLoading error={error}>
            <PosterJoinTheMovement />
        </HSplitLoading>
    );
}
