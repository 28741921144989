import React from 'react';

import {EditProfileGHGContext} from './context';
import {ProfileGHGSuggested} from '../suggested';
import {ProfileGHGYesNoQuestion} from '../yes-no-question';
import {ProfileGHGGasQuantities} from '../gas-quantities';
import {ProfileGHGSpendSplit} from '../spend-split';
import {GHGGasQuantityEntry, IdentifyTransactionsPayload} from '../types';

export function ProfileGHGWizard() {
    const {
        currentStep,
        answers,
        reportingPeriod,
        getTransactionSpend,
        dispatch,
        error,
        submitting,
    } = React.useContext(EditProfileGHGContext);

    return (
        <div>
            {currentStep === 'suggested' && (
                <ProfileGHGSuggested
                    onNext={(payload: IdentifyTransactionsPayload) =>
                        dispatch({type: 'identifyTransactions', payload})
                    }
                    onSkip={() => dispatch({type: 'skipIdentifyTransactions'})}
                    reportingPeriod={reportingPeriod}
                    confirmedSpend={answers.confirmedSpend}
                    defaultValue={false}
                />
            )}
            {currentStep === 'spend_split' && (
                <ProfileGHGSpendSplit
                    onNext={(payload) => dispatch({type: 'setSpendSplit', payload})}
                    getTransactionSpend={getTransactionSpend}
                    error={error}
                    submitting={submitting}
                    reportingPeriod={reportingPeriod}
                    splitPercent={answers.spend_split_percent}
                />
            )}
            {currentStep === 'own' && (
                <ProfileGHGYesNoQuestion
                    title="Do you own or operate any fridges, air conditioning and/or fire suppressant systems?"
                    onNext={(payload: boolean) =>
                        dispatch({type: 'setOwnedAppliancesInPeriod', payload})
                    }
                    error={error}
                    submitting={submitting}
                    reportingPeriod={reportingPeriod}
                    prevAnswer={answers.owned_appliences}
                />
            )}
            {currentStep === 'topped_up' && (
                <ProfileGHGYesNoQuestion
                    title="Have you had any fridges, air conditioning and/or fire supressant systems topped up in this period?"
                    error={error}
                    submitting={submitting}
                    reportingPeriod={reportingPeriod}
                    onNext={(payload: boolean) => dispatch({type: 'setToppedUpInPeriod', payload})}
                    prevAnswer={answers.topped_up}
                />
            )}
            {currentStep === 'gas_quantities' && (
                <ProfileGHGGasQuantities
                    error={error}
                    submitting={submitting}
                    gasEntries={answers.gasEntries.map((e) => ({
                        gasType: e.gas_type,
                        kgs: e.kg.toString(),
                    }))}
                    reportingPeriod={reportingPeriod}
                    onNext={(payload: GHGGasQuantityEntry[]) =>
                        dispatch({type: 'setGasQuantities', payload})
                    }
                    onSkip={() => dispatch({type: 'skipGasQuantites'})}
                />
            )}
        </div>
    );
}
