import React from 'react';
import {Group} from '@visx/group';
import {Pie} from '@visx/shape';
import {ParentSize} from '@visx/responsive';

import {Preset, Heading3, SegmentTitle} from '@sphericsio/design-system';

import {carbonAmountFormatter} from '../carbon-amount';

const SCOPE_COLORS = [
    Preset.theme.extend.colors.graph.scope1,
    Preset.theme.extend.colors.graph.scope2,
    Preset.theme.extend.colors.graph.scope3,
];

const NO_DATA_COLORS = ['rgb(151,151,151)', 'rgb(47,56,63)', 'rgb(196,196,196)'];

type ScopeData = [scope1: number, scope2: number, scope3: number];

type ScopeGraphProps = {
    data?: ScopeData;
    width: number;
    height: number;
};
export function ScopeGraph({width, height, data}: ScopeGraphProps) {
    const margin = {top: 0, right: 0, bottom: 0, left: 0};
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const radius = Math.min(innerWidth, innerHeight) / 2;
    const centerY = innerHeight / 2;
    const centerX = innerWidth / 2;

    const pieData = data ?? [20, 25, 55];
    const pieColors = data == null ? NO_DATA_COLORS : SCOPE_COLORS;

    return (
        <svg width={width} height={height}>
            <Group top={centerY + margin.top} left={centerX + margin.left}>
                <Pie
                    data={pieData.map((kgCo2e, index) => ({kgCo2e, index}))}
                    outerRadius={radius}
                    innerRadius={radius - 50}
                    cornerRadius={3}
                    padAngle={0.005}
                    pieValue={(d) => d.kgCo2e}
                    fill={(d) => pieColors[d.data.index]}
                />
            </Group>
        </svg>
    );
}

export function ResponsiveScopeGraph(props: Omit<ScopeGraphProps, 'width' | 'height'>) {
    return (
        <div className="flex flex-col flex-1 h-80 items-center justify-between">
            <div className="flex flex-1 h-full self-stretch">
                <ParentSize>
                    {(parent) => (
                        <ScopeGraph width={parent.width} height={parent.height} {...props} />
                    )}
                </ParentSize>
            </div>
            <ScopeBreakdownSummary data={props.data} />
        </div>
    );
}

type ScopeBreakdownSummaryItemProps = {
    scope: 1 | 2 | 3;
    amount?: number;
};
export function ScopeBreakdownSummaryItem({scope, amount}: ScopeBreakdownSummaryItemProps) {
    let formattedAmount = '-';
    let unit = 'kg';
    if (amount != null) {
        const formatted = carbonAmountFormatter({amount});
        formattedAmount = formatted.formattedAmount;
        unit = formatted.unit;
    }
    return (
        <div className="flex flex-col space-y-3 min-w-max">
            <div
                className="h-2 w-9 rounded-2xl"
                style={{backgroundColor: SCOPE_COLORS[scope - 1]}}
            />
            <div className="uppercase">
                <SegmentTitle colour="secondary">Scope {scope}</SegmentTitle>
            </div>
            <div>
                <Heading3 element="span">{`${formattedAmount} ${unit}`}</Heading3>
            </div>
        </div>
    );
}

type ScopeBreakdownSummaryProps = {
    data?: ScopeData;
};
export function ScopeBreakdownSummary({data}: ScopeBreakdownSummaryProps) {
    return (
        <div className="grid grid-cols-3 gap-x-5 mt-10 min-w-max">
            <ScopeBreakdownSummaryItem scope={1} amount={data ? data[0] : undefined} />
            <ScopeBreakdownSummaryItem scope={2} amount={data ? data[1] : undefined} />
            <ScopeBreakdownSummaryItem scope={3} amount={data ? data[2] : undefined} />
        </div>
    );
}
