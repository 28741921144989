import React from 'react';
import {useNavigate} from 'react-router';

import {
    VStack,
    Button,
    DetailText,
    outlineColorClassnames,
    bgColorClassnames,
} from '@sphericsio/design-system';

import {OperationsHeader} from './header';
import {ProfilePlacesWizard} from './places/wizard';
import {ProfileVehicles} from './vehicles';
import {ProfileEnergy} from './energy';
import {ProfilePeople} from './people';
import {ProfileGHG} from './ghg';
import {ConstrainWidthContainer} from '../constrain-width-container';
import {ReportingPeriodContext} from './reporting-period';
import {ErrorCard} from '../error-card';
import {useUser} from '../user-context';
import {OperationsSummary} from './operations-summary';
import {OperationsEditPeopleSection} from './people/edit';
import {DateDisplay} from '../date-display';
import {OperationsEditVehiclesSection} from './vehicles/edit';
import {OperationsEditGHGSection} from './ghg/edit';
import {OperationsEditEnergySection} from './energy/edit';
import {CompanyReportingPeriod} from '../../graphql/generated/graphql';

function InsufficientData() {
    const user = useUser();
    const navigate = useNavigate();
    let destination = '/sync';
    let label = 'Sync your data';

    if (user.financialProvider?.sync_method === 'data-upload') {
        destination = '/upload';
        label = 'Data upload';
    }

    return (
        <VStack>
            <ErrorCard message="Insufficent data for the selected time period." />
            <ConstrainWidthContainer>
                <Button onPress={() => navigate(destination)}>{label}</Button>
            </ConstrainWidthContainer>
        </VStack>
    );
}

type OperationsMainProps = {
    currentReportingPeriod: CompanyReportingPeriod;
    isValidPeriod?: boolean;
    page: string;
};
export function OperationsMain({currentReportingPeriod, isValidPeriod, page}: OperationsMainProps) {
    return (
        <VStack>
            {isValidPeriod ? (
                <ReportingPeriodContext.Provider value={currentReportingPeriod}>
                    {page === 'summary' ? <OperationsSummary /> : <OperationsHeader page={page} />}
                    {page === 'places' && <ProfilePlacesWizard />}
                    {page === 'vehicles' && <ProfileVehicles />}
                    {page === 'energy' && <ProfileEnergy />}
                    {page === 'people' && <ProfilePeople />}
                    {page === 'ghg' && <ProfileGHG />}
                </ReportingPeriodContext.Provider>
            ) : (
                <InsufficientData />
            )}
        </VStack>
    );
}

type OperationsEditProps = {
    currentReportingPeriod: CompanyReportingPeriod;
    page: string;
};
export function OperationsEdit({currentReportingPeriod, page}: OperationsEditProps) {
    return (
        <VStack>
            <ReportingPeriodContext.Provider value={currentReportingPeriod}>
                <div
                    className={`border-2 ${outlineColorClassnames()} w-2/5 flex justify-center rounded-lg ${bgColorClassnames(
                        'white',
                    )} px-10 py-1`}
                >
                    <DetailText>
                        Edit accounting period:{' '}
                        <DateDisplay>{currentReportingPeriod.period_start}</DateDisplay> to{' '}
                        <DateDisplay>{currentReportingPeriod.period_end}</DateDisplay>
                    </DetailText>
                </div>
                <div>
                    <DetailText bold colour="error">
                        <div className="pr-2">!</div>
                    </DetailText>
                    <DetailText colour="secondary">
                        Please be aware that changing your answers will affect your emissions.
                    </DetailText>
                </div>
                {page === 'edit-people' && <OperationsEditPeopleSection />}
                {page === 'edit-vehicles' && <OperationsEditVehiclesSection />}
                {page === 'edit-ghg' && <OperationsEditGHGSection />}
                {page === 'edit-energy' && <OperationsEditEnergySection />}
            </ReportingPeriodContext.Provider>
        </VStack>
    );
}
