import React from 'react';
import classnames from 'classnames';
import {Field, useField} from 'formik';
import * as Yup from 'yup';
import {chain} from 'lodash';
import type {ResultOf} from '@graphql-typed-document-node/core';

import {
    Button,
    ButtonLabel,
    DetailText,
    FormControl,
    FormErrorMessage,
    Heading3,
    Heading4,
    Heading5,
    HStack,
    LoadingSpinner,
    SegmentTitle,
    VStack,
    outlineColorClassnames,
} from '@sphericsio/design-system';
import {Form} from '@sphericsio/forms';

import {MoneyDisplay, moneyAsValue} from '../money';
import {SubscriptionContext} from './context';
import {SubscriptionProductsSubscriptionProductFragmentDocument} from './graphql';
import {VatBreakdown} from './vat-breakdown';

const formSchema = Yup.object({
    priceId: Yup.string().required('Please choose a subscription package'),
});

const initialValues = {
    priceId: '',
};

export type SubscriptionProduct = ResultOf<
    typeof SubscriptionProductsSubscriptionProductFragmentDocument
>;

type SubscriptionProductsProps = {
    onCancel: () => void;
    subscriptionProducts?: SubscriptionProduct[];
};
export function SubscriptionProducts({subscriptionProducts, onCancel}: SubscriptionProductsProps) {
    const {dispatch, selectedProduct} = React.useContext(SubscriptionContext);

    const onSubmit = () => {
        dispatch({type: 'productSelectionSubmitted'});
    };
    const onSelect = (value: string) => {
        dispatch({type: 'productSelected', payload: value});
    };

    if (!subscriptionProducts) {
        return <LoadingSpinner />;
    }

    return (
        <VStack large condensed>
            <VStack align="start" condensed>
                <Heading3>Start your subscription</Heading3>
                <DetailText>
                    <span className="flex text-left">
                        Before you sync your data, you need to start your subscription.
                    </span>
                </DetailText>
            </VStack>
            <VStack align="start">
                <Heading5>Select your package:</Heading5>
                <Form
                    initialValues={initialValues}
                    validationSchema={formSchema}
                    onSubmit={onSubmit}
                    fullWidth
                >
                    <VStack>
                        <SubscriptionProductsList
                            onSelect={(priceId) => onSelect(priceId)}
                            subscriptionProducts={subscriptionProducts}
                        />
                        <VatBreakdown selectedProduct={selectedProduct} />
                        <hr />

                        <HStack>
                            <ButtonLabel colour="minor-action">
                                <span className="cursor-pointer" onClick={() => onCancel()}>
                                    Cancel
                                </span>
                            </ButtonLabel>
                            <Button type="submit">Continue to payment</Button>
                        </HStack>
                    </VStack>
                </Form>
            </VStack>
        </VStack>
    );
}

function SubscriptionProductsList({
    subscriptionProducts,
    onSelect,
}: {
    subscriptionProducts: SubscriptionProduct[];
    onSelect: (priceId: string) => void;
}) {
    const [field, meta, helpers] = useField({name: 'priceId'});
    const products = React.useMemo(() => {
        return chain(subscriptionProducts)
            .sortBy((product) => moneyAsValue(product.amount))
            .value();
    }, [subscriptionProducts]);

    React.useEffect(() => {
        if (field.value == null && products.length === 1) {
            helpers.setValue(products[0].priceId);
        }
    }, [field, products, helpers]);

    React.useLayoutEffect(() => {
        if (field.value != null) {
            onSelect(field.value);
        }
    }, [field.value]);

    return (
        <FormControl isInvalid={meta.error != null}>
            <VStack condensed>
                {products.map((product) => {
                    return (
                        <div
                            key={product.priceId}
                            className={classnames(
                                'flex cursor-pointer border rounded-lg p-5',
                                outlineColorClassnames(),
                                `focus:${outlineColorClassnames('navigation')}`,
                            )}
                            onClick={() => helpers.setValue(product.priceId)}
                        >
                            <label>
                                <HStack align="start">
                                    <span className="flex self-center">
                                        <Field
                                            type="radio"
                                            name="product"
                                            value={product.productId}
                                            style={{width: '20px', height: '20px'}}
                                        />
                                    </span>
                                    <VStack align="start" condensed>
                                        <SegmentTitle colour="secondary">
                                            <span className="flex justify-start uppercase">
                                                {product.name}
                                            </span>
                                        </SegmentTitle>
                                        <Heading4 colour="black">
                                            <MoneyDisplay money={product.amount} /> per{' '}
                                            {product.recurringPeriod} (price excluding VAT)
                                        </Heading4>
                                    </VStack>
                                </HStack>
                            </label>
                        </div>
                    );
                })}
                {meta.error && <FormErrorMessage>{meta.error}</FormErrorMessage>}
            </VStack>
        </FormControl>
    );
}
