import React from 'react';
import {ApolloError} from '@apollo/client';
import * as Yup from 'yup';
import {Link} from 'react-router-dom';

import {DetailText, HStack, LinkText, VStack} from '@sphericsio/design-system';
import {Input, PasswordInput, Button, Form} from '@sphericsio/forms';

import {ErrorCard} from '../error-card';

const SAGE_ID_INITIAL_FORM_VALUES = {
    email: '',
};

const sageIDformSchema = Yup.object().shape({
    email: Yup.string().required('Email is missing').email('Email is invalid'),
});

const formSchema = Yup.object().shape({
    email: Yup.string().required('Email is missing').email('Email is invalid'),
    password: Yup.string().required('Password is missing'),
});

type LoginFormProps = {
    submitting: boolean;
    error?: ApolloError;
    email?: string;
    onSubmit: (email: string, password: string) => void;
};

type SageIDLoginFormProps = {
    submitting: boolean;
    error?: ApolloError;
    onSubmit: (email: string) => void;
};

const defaultErrorMessage = 'We could not log you in at this time. Please try again later.';

const AccountingLoginLink = () => (
    <DetailText inline={false}>
        Signed up with your cloud accounting platform?{' '}
        <Link to="/login/accounting">
            <LinkText>Click here to log in</LinkText>
        </Link>
    </DetailText>
);

export function LoginForm({email, submitting, error, onSubmit}: LoginFormProps) {
    const buttonLabel = submitting ? 'Please wait...' : 'Login';
    return (
        <Form
            initialValues={{email: email ?? '', password: ''}}
            onSubmit={(values) => {
                onSubmit(values.email, values.password);
            }}
            validationSchema={formSchema}
        >
            <Input name="email" type="email" placeholder="Email" />
            <PasswordInput name="password" placeholder="Password" />
            <div className="mt-5">
                <VStack>
                    {error && <ErrorCard error={error} defaultMessage={defaultErrorMessage} />}
                    <HStack>
                        <Button isLoading={submitting} type="submit">
                            {buttonLabel}
                        </Button>
                        <DetailText>
                            <Link to="/forgot-password">
                                <LinkText>Forgot your password?</LinkText>
                            </Link>
                        </DetailText>
                    </HStack>
                    <AccountingLoginLink />
                </VStack>
            </div>
        </Form>
    );
}

export function SageIDLogin({submitting, error, onSubmit}: SageIDLoginFormProps) {
    const buttonLabel = submitting ? 'Please wait...' : 'Login';
    return (
        <Form
            initialValues={SAGE_ID_INITIAL_FORM_VALUES}
            onSubmit={(values) => {
                onSubmit(values.email);
            }}
            validationSchema={sageIDformSchema}
        >
            <Input name="email" type="email" placeholder="Email" />
            <div className="mt-5">
                <VStack>
                    {error && <ErrorCard error={error} defaultMessage={defaultErrorMessage} />}
                    <HStack>
                        <Button isLoading={submitting} type="submit">
                            {buttonLabel}
                        </Button>
                    </HStack>
                    <AccountingLoginLink />
                </VStack>
            </div>
        </Form>
    );
}
