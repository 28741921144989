import React from 'react';

import {Button, DetailText, VStack} from '@sphericsio/design-system';

import {TabPageHeader} from '../../page-header';

type ProfilePagesIntroProps = {
    onNext: () => void;
};
export function ProfilePlacesIntro({onNext}: ProfilePagesIntroProps) {
    return (
        <VStack>
            <TabPageHeader
                title="Tell us about your places of work"
                subtitle="By answering some questions about the locations you operate your business from, we can make better estimations of your energy use, staff commuting patterns and more"
            />
            <div>
                <DetailText>Completing these questions takes on average:</DetailText>{' '}
                <DetailText bold>1–5 mins</DetailText>
            </div>
            <div>
                <Button onPress={onNext} intercomTarget="Company Profile: Start Places">
                    Start Now
                </Button>
            </div>
        </VStack>
    );
}
