import React from 'react';
import {useParams} from 'react-router';
import {ApolloError, useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {isBefore} from 'date-fns';

import {
    CompanyReportingPeriod,
    CreateOperationsReportingPeriodDocument,
    ValidateReportingPeriodDocument,
} from '../../graphql/generated/graphql';
import {GetReportingPeriodsDocument} from '../../components/operations/graphql';
import {formatDateForUrl} from '../../components/date-display';
import {useUser} from '../../components/user-context';

type ReportingPeriodContextValue = {
    fetchingPeriods: boolean;
    creatingOperationsReportingPeriod: boolean;
    validating: boolean;
    isValidPeriod: boolean;
    baselinePeriodSet: boolean;
    isPeriodBeforeBaseline: boolean;
    availableReportingPeriods: CompanyReportingPeriod[];
    periodsError?: ApolloError;
    errorCreatingOperationsPeriod?: ApolloError;
    validationError?: ApolloError;
    reportingPeriod?: CompanyReportingPeriod;
};

export const ReportingPeriodContext = React.createContext<ReportingPeriodContextValue>({
    fetchingPeriods: false,
    creatingOperationsReportingPeriod: false,
    validating: false,
    baselinePeriodSet: false,
    isValidPeriod: false,
    availableReportingPeriods: [],
    isPeriodBeforeBaseline: false,
});

type ReportingPeriodContextProviderProps = {
    children: React.ReactNode;
    isBaselineView: boolean;
};

export function ReportingPeriodContextProvider({
    children,
    isBaselineView = false,
}: ReportingPeriodContextProviderProps) {
    const {
        loading: fetchingPeriods,
        error: periodsError,
        data,
    } = useQuery(GetReportingPeriodsDocument);
    const [
        create,
        {loading: creatingOperationsReportingPeriod, error: errorCreatingOperationsPeriod},
    ] = useMutation(CreateOperationsReportingPeriodDocument);

    const [validatePeriod, {loading: validating, error: validationError, data: validationResult}] =
        useLazyQuery(ValidateReportingPeriodDocument, {fetchPolicy: 'cache-and-network'});

    const user = useUser();
    const {periodStart, periodEnd} = useParams();

    const isDataUploadAccount = user.financialProvider?.sync_method === 'data-upload';

    const isValidPeriod = React.useMemo(() => {
        if (isDataUploadAccount) {
            return validationResult?.validateReportingPeriod.isValid ?? false;
        } else {
            return true;
        }
    }, [isDataUploadAccount, validationResult]);

    const reportingPeriod = React.useMemo(() => {
        if (
            data?.getCompanyReportingPeriods == null ||
            data.getCompanyReportingPeriods.length === 0
        ) {
            return;
        }

        if (periodStart == null || periodEnd == null) {
            return data?.getCompanyReportingPeriods.filter(
                (period) => !period.isOpen && period.isBaselineReportingPeriod === isBaselineView,
            )[0];
        }

        return data?.getCompanyReportingPeriods.find(
            ({period_start, period_end}) =>
                periodStart === period_start && periodEnd === period_end,
        );
    }, [data?.getCompanyReportingPeriods, periodStart, periodEnd, isBaselineView]);

    const baselinePeriod = React.useMemo(() => {
        return data?.getCompanyReportingPeriods.find((period) => period.isBaselineReportingPeriod);
    }, [data]);

    const baselinePeriodSet = React.useMemo(() => !!baselinePeriod, [baselinePeriod]);

    const isPeriodBeforeBaseline = React.useMemo(() => {
        if (!baselinePeriod || !reportingPeriod) {
            return false;
        } else {
            return isBefore(
                new Date(reportingPeriod?.period_start),
                new Date(baselinePeriod?.period_start),
            );
        }
    }, [baselinePeriod, reportingPeriod]);

    const availableReportingPeriods = React.useMemo(() => {
        if (!data) {
            return [];
        }
        return data.getCompanyReportingPeriods.filter(
            (periods) => periods.isBaselineReportingPeriod === isBaselineView,
        );
    }, [data, isBaselineView]);

    React.useEffect(() => {
        if (isDataUploadAccount && reportingPeriod) {
            validatePeriod({
                variables: {from: reportingPeriod.period_start, to: reportingPeriod.period_end},
            });
        }
    }, [reportingPeriod, isDataUploadAccount, validatePeriod]);

    React.useEffect(() => {
        if (!isBaselineView && !fetchingPeriods && !reportingPeriod && periodStart && periodEnd) {
            const period_start = formatDateForUrl(new Date(periodStart));
            const period_end = formatDateForUrl(new Date(periodEnd));
            create({
                variables: {
                    periodStart: period_start,
                    periodEnd: period_end,
                },
                refetchQueries: [{query: GetReportingPeriodsDocument}],
            });
        }
    }, [reportingPeriod, fetchingPeriods, periodStart, periodEnd, isBaselineView]);

    return (
        <ReportingPeriodContext.Provider
            value={{
                availableReportingPeriods: availableReportingPeriods as CompanyReportingPeriod[],
                baselinePeriodSet,
                isPeriodBeforeBaseline,
                isValidPeriod,
                creatingOperationsReportingPeriod,
                fetchingPeriods,
                validating,
                periodsError,
                errorCreatingOperationsPeriod,
                validationError,
                reportingPeriod: reportingPeriod as CompanyReportingPeriod,
            }}
        >
            {children}
        </ReportingPeriodContext.Provider>
    );
}
