import {FragmentType, graphql} from '../../graphql/generated';

const CompanyReportingPeriodFragmentDocument = graphql(/* GraphQL */ `
    fragment CompanyReportingPeriod on CompanyReportingPeriod {
        id
        period_start
        period_end
        vehicles {
            completed_at
        }
        energy {
            completed_at
        }
        people {
            completed_at
        }
        ghg {
            completed_at
        }
        isOpen
        isBaselineReportingPeriod
        isCompleted
    }
`);
export type CompanyReportingPeriodFragment = FragmentType<
    typeof CompanyReportingPeriodFragmentDocument
>;

export const OperationsSummaryEmissionsFragmentDocument = graphql(/* GraphQL */ `
    fragment OperationsSummaryEmissions on CompanyReportingPeriod {
        totalEmissionsForPeriod
        vehicles {
            kgCo2e
        }
        energy {
            kgCo2e
        }
        people {
            kgCo2e
        }
        ghg {
            kgCo2e
        }
    }
`);

export type OperationsSummaryEmissionsFragment = FragmentType<
    typeof OperationsSummaryEmissionsFragmentDocument
>;

export const BenchmarkingProfileEntryFragmentDocument = graphql(/* GraphQL */ `
    fragment BenchmarkingProfileEntry on ProfileEntry {
        kgCo2e
        benchmark {
            benchmarkText
            benchmarkTextUnit
            benchmarkKey
            value
            reference
        }
    }
`);
export type BenchmarkingProfileEntryFragment = FragmentType<
    typeof BenchmarkingProfileEntryFragmentDocument
>;

export const GetReportingPeriodsDocument = graphql(/* GraphQL */ `
    query GetReportingPeriods {
        getCompanyReportingPeriods {
            id
            period_start
            period_end
            vehicles {
                completed_at
            }
            energy {
                completed_at
            }
            people {
                completed_at
            }
            ghg {
                completed_at
            }
            isOpen
            isBaselineReportingPeriod
            isCompleted
        }
    }
`);

export const OperationsSummaryEmissionsDocument = graphql(/* GraphQL */ `
    query GetEmissionsForReportingPeriod($id: ID!) {
        getCompanyReportingPeriodById(id: $id) {
            ...OperationsSummaryEmissions
        }
    }
`);
