import React from 'react';
import {useNavigate} from 'react-router';
import classnames from 'classnames';

export type ProviderName = 'sage50' | 'fake';

type FinancialDataSyncButtonProps = {
    providerName: ProviderName;
    hasActiveSubscription: boolean;
    hasPendingSubscription: boolean;
    selectedProvider?: string;
    onButtonClick?: (provider: string) => void;
    children: (onClick: () => void) => React.ReactNode;
};
export function FinancialDataSyncButton({
    providerName,
    hasActiveSubscription,
    hasPendingSubscription,
    selectedProvider,
    onButtonClick,
    children,
}: FinancialDataSyncButtonProps) {
    const navigate = useNavigate();

    const nextUri = React.useMemo(() => {
        switch (selectedProvider) {
            case 'sage50':
                return '/sage50';
            case 'fake':
                return '/fake/callback';
            default:
                return '/';
        }
    }, [selectedProvider]);

    React.useEffect(() => {
        if (
            hasActiveSubscription &&
            (selectedProvider === 'sage50' || selectedProvider === 'fake')
        ) {
            navigate(nextUri);
        }
    }, [hasActiveSubscription, selectedProvider]);

    const onClick = () => {
        onButtonClick ? onButtonClick(providerName) : navigate(nextUri);
    };

    return (
        <a
            data-testid={providerName}
            className={classnames('max-w-64', {
                'opacity-30': hasPendingSubscription,
                'cursor-pointer': !hasPendingSubscription,
            })}
        >
            {children(onClick)}
        </a>
    );
}
