import {FinancialYear, SeriesData} from './types';

export function formatYear(year: FinancialYear): string {
    const startYear = year.startInclusive.split('-')[0];
    const endYear = year.endInclusive.split('-')[0];

    if (startYear === endYear) {
        return `${startYear}`;
    } else {
        return `${startYear}-${endYear.slice(-2)}`;
    }
}

export function maxEmissions(current: SeriesData, previous?: SeriesData | null) {
    const allData = previous ? [...current, ...previous] : current;
    return Math.max(...allData.map((data) => data.dataPoint?.kg_co2e || 0));
}
