import React from 'react';

import {ProfileGHGIntro} from './intro';
import {ProfileGHGSuggested} from './suggested';
import {ProfileGHGContext} from './context';
import {ProfileGHGComplete} from './complete';
import {ProfileReportingPeriodOpen} from '../reporting-period-open';
import {ConstrainWidthContainer} from '../../constrain-width-container';
import {ProfileGHGYesNoQuestion} from './yes-no-question';
import {ProfileGHGGasQuantities} from './gas-quantities';
import {ProfileGHGSpendSplit} from './spend-split';
import {GHGGasQuantityEntry, IdentifyTransactionsPayload} from './types';

export function ProfileGHGWizard() {
    const {currentStep, reportingPeriod, dispatch, getTransactionSpend, error, submitting} =
        React.useContext(ProfileGHGContext);

    return (
        <div>
            {currentStep === 'suggested' && (
                <ProfileGHGSuggested
                    onNext={(payload: IdentifyTransactionsPayload) =>
                        dispatch({type: 'identifyTransactions', payload})
                    }
                    onSkip={() => dispatch({type: 'skipIdentifyTransactions'})}
                    reportingPeriod={reportingPeriod}
                />
            )}
            {currentStep === 'spend_split' && (
                <ProfileGHGSpendSplit
                    onNext={(payload) => dispatch({type: 'setSpendSplit', payload})}
                    getTransactionSpend={getTransactionSpend}
                    error={error}
                    submitting={submitting}
                    reportingPeriod={reportingPeriod}
                />
            )}
            {currentStep === 'intro' && <ProfileGHGIntro />}
            {currentStep === 'completed' && <ProfileGHGComplete />}
            <ConstrainWidthContainer>
                {currentStep === 'own' && (
                    <ProfileGHGYesNoQuestion
                        testId="fridgeOperationForm"
                        title="Do you own or operate any fridges, air conditioning and/or fire suppressant systems?"
                        onNext={(payload: boolean) =>
                            dispatch({type: 'setOwnedAppliencesInPeriod', payload})
                        }
                        error={error}
                        submitting={submitting}
                        reportingPeriod={reportingPeriod}
                    />
                )}
                {currentStep === 'topped_up' && (
                    <ProfileGHGYesNoQuestion
                        testId="fridgeTopupForm"
                        title="Have you had any fridges, air conditioning and/or fire supressant systems topped up in this period?"
                        error={error}
                        submitting={submitting}
                        reportingPeriod={reportingPeriod}
                        onNext={(payload: boolean) =>
                            dispatch({type: 'setToppedUpInPeriod', payload})
                        }
                    />
                )}
                {currentStep === 'period_open' && (
                    <ProfileReportingPeriodOpen reportingPeriodEnd={reportingPeriod.period_end} />
                )}
                {currentStep === 'gas_quantities' && (
                    <ProfileGHGGasQuantities
                        error={error}
                        submitting={submitting}
                        reportingPeriod={reportingPeriod}
                        onNext={(payload: GHGGasQuantityEntry[]) =>
                            dispatch({type: 'setGasQuantities', payload})
                        }
                        onSkip={() => dispatch({type: 'skipGasQuantites'})}
                    />
                )}
            </ConstrainWidthContainer>
        </div>
    );
}
