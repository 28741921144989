import React from 'react';
import {useQuery, useMutation} from '@apollo/client';
import {capitalize} from 'lodash';

import {Button, DetailText, HStack, Panel, VStack} from '@sphericsio/design-system';

import {
    AddCompanyAddressesDocument,
    GetSuggestedAddressesDocument,
} from '../../../graphql/generated/graphql';
import {Loading} from '../../loading';
import {ErrorCard} from '../../error-card';
import {TabPageHeader} from '../../page-header';

function addressSourceText(source: string, financialProvider?: string) {
    if (source === 'financial_provider') {
        return `This address was found in your ${
            financialProvider == null ? 'accounting' : capitalize(financialProvider)
        } records`;
    } else if (source === 'companies_house') {
        return 'This address was found in your Companies House records';
    } else {
        return undefined;
    }
}

type ProfilePlacesSuggestedAddressesProps = {
    onNext: () => void;
};
export function ProfilePlacesSuggestedAddresses({onNext}: ProfilePlacesSuggestedAddressesProps) {
    const {loading, error, data} = useQuery(GetSuggestedAddressesDocument);
    const [addAddresses, {loading: submitting, error: errorSubmitting, data: submitData}] =
        useMutation(AddCompanyAddressesDocument);
    const [confirmedAddresses, setConfirmedAddresses] = React.useState<
        {
            source: string;
            address: string;
            postcode: string;
        }[]
    >([]);
    React.useEffect(() => {
        if (data != null) {
            setConfirmedAddresses(data.getCompanySuggestedAddresses);
        }
    }, [data, setConfirmedAddresses]);

    React.useEffect(() => {
        if (submitData != null) {
            onNext();
        }
    });

    const onSubmit = () => {
        if (setConfirmedAddresses.length === 0) {
            return;
        }

        addAddresses({
            variables: {
                params: confirmedAddresses.map((address) => ({
                    address: address.address,
                    postcode: address.postcode,
                })),
            },
        });
    };

    return (
        <div>
            {loading && <Loading />}
            {error && <ErrorCard />}
            {data && (
                <VStack testId="confirmAddressForm">
                    <TabPageHeader
                        title="Confirm your addresses"
                        subtitle="From your records, we have found the following addresses.  Please deselect any addresses that are not linked to your business activities (i.e. if one of these addresses is for your accountant or other mailing address)"
                    />
                    <HStack>
                        {data.getCompanySuggestedAddresses.map((address, index) => {
                            const addressSource = addressSourceText(
                                address.source,
                                data.currentUser?.financialProvider?.financial_provider,
                            );
                            return (
                                <div key={index} className="max-w-xs">
                                    <Panel border>
                                        <VStack>
                                            {addressSource && (
                                                <DetailText
                                                    inline={false}
                                                    size="small"
                                                    colour="secondary"
                                                >
                                                    {addressSource}
                                                </DetailText>
                                            )}
                                            <DetailText inline={false}>
                                                <div className="whitespace-pre-line">
                                                    {address.address}
                                                </div>
                                                <div>{address.postcode}</div>
                                            </DetailText>
                                            <HStack>
                                                <input
                                                    id={index.toString()}
                                                    type="checkbox"
                                                    checked={confirmedAddresses.includes(address)}
                                                    onChange={() =>
                                                        setConfirmedAddresses((value) => {
                                                            const without = value.filter(
                                                                (item) => item !== address,
                                                            );
                                                            if (without.length === value.length) {
                                                                return without.concat(address);
                                                            } else {
                                                                return without;
                                                            }
                                                        })
                                                    }
                                                />
                                                <label htmlFor={index.toString()}>
                                                    <DetailText size="small">
                                                        This address is used to carry out business
                                                        activities
                                                    </DetailText>
                                                </label>
                                            </HStack>
                                        </VStack>
                                    </Panel>
                                </div>
                            );
                        })}
                    </HStack>
                    {data.getCompanySuggestedAddresses.length === 0 && (
                        <DetailText inline={false}>
                            We don&apos;t know any of your registered addresses. Add one in the next
                            step.
                        </DetailText>
                    )}
                    {errorSubmitting && <ErrorCard />}
                    <div>
                        <Button onPress={onSubmit} isLoading={submitting}>
                            Next
                        </Button>
                    </div>
                </VStack>
            )}
        </div>
    );
}
