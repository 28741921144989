import {graphql} from '../graphql/generated';

export const GetCompanyExclusionReasonsDocument = graphql(/* GraphQL */ `
    query GetCompanyExclusionReasons {
        getCompanyExclusionReasons {
            name
            key
        }
    }
`);
