import React from 'react';

export type MerchantTransactionData = {
    id: string;
    transactions: {
        id: string;
    }[];
};
export function useSuggestedTransactionsState(
    data?: MerchantTransactionData[],
    defaultState = true,
    confirmedSpend?: MerchantTransactionData[],
) {
    const [transactionStates, setTransactionStates] = React.useState<Record<string, boolean>>({});

    React.useEffect(() => {
        if (data != null) {
            const states = data.reduce(
                (memo, merchant) => {
                    const confirmedMerchant = confirmedSpend?.find((m) => m.id == merchant.id);
                    return merchant.transactions.reduce((memo2, transaction) => {
                        const confirmedTransaction = confirmedMerchant?.transactions.find(
                            (t) => t.id === transaction.id,
                        );
                        return {
                            ...memo2,
                            [transaction.id]: confirmedTransaction ? true : defaultState,
                        };
                    }, memo);
                },
                {} as Record<string, boolean>,
            );
            setTransactionStates(states);
        }
    }, [data, setTransactionStates]);

    const setAllTransactionsToValue = (ids: string[], value: boolean) => {
        const newState = ids.reduce(
            (memo, id) => ({
                ...memo,
                [id]: value,
            }),
            transactionStates,
        );
        setTransactionStates(newState);
        return newState;
    };

    const toggleTransactionState = (id: string) => {
        setTransactionStates((value) => {
            return {
                ...value,
                [id]: !value[id],
            };
        });
    };

    return {transactionStates, setAllTransactionsToValue, toggleTransactionState};
}
