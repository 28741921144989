import React from 'react';

import {ProfileEnergyContextProvider} from './context';
import {ProfileEnergyWizard} from './wizard';

export function ProfileEnergy() {
    return (
        <ProfileEnergyContextProvider>
            <ProfileEnergyWizard />
        </ProfileEnergyContextProvider>
    );
}
