import React from 'react';
import {ApolloError} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import {useFragment} from '../../../../graphql/generated';
import {
    Company,
    CompanyAddress,
    CompanyReportingPeriod,
    PeopleWorkplaceEntry,
    ProfilePeople,
} from '../../../../graphql/generated/graphql';
import {ReportingPeriodContext, reportingPeriodPlaceholder} from '../../reporting-period';
import {CompanyAddressesFragment, CompanyAddressesFragmentDocument} from '../../places/fragments';
import {ModifyProfilePeopleDocument} from '../graphql';
import {useModifySection} from '../../use-modify-section';

type EditProfilePeopleWizardStep =
    | 'review_addresses'
    | 'add_address'
    | 'building_types'
    | 'employee_count'
    | 'places';

type EditProfilePeopleState = {
    addressId?: string;
    employeeCount?: number;
    placesPercentages: Record<string, number>;
    workingFromHomePercent?: number;
    currentStep: EditProfilePeopleWizardStep;
    shouldSubmit: boolean;
};

type EditProfilePeopleAction =
    | {type: 'start'}
    | {type: 'addAddress'}
    | {type: 'verifyBuildingType'; payload: {addressId: string}}
    | {type: 'getEmployeeCount'}
    | {type: 'setEmployeeCount'; payload: number}
    | {
          type: 'setPlacesPercentages';
          payload: {placesPercentages: Record<string, number>; workingFromHomePercent: number};
      };

function useEditPeopleReducer() {
    const initialState: EditProfilePeopleState = {
        placesPercentages: {},
        shouldSubmit: false,
        currentStep: 'review_addresses',
    };
    return React.useReducer(
        (
            state: EditProfilePeopleState,
            action: EditProfilePeopleAction,
        ): EditProfilePeopleState => {
            switch (action.type) {
                case 'start':
                    return {
                        ...state,
                        currentStep: 'review_addresses',
                    };
                case 'addAddress':
                    return {
                        ...state,
                        currentStep: 'add_address',
                    };
                case 'verifyBuildingType':
                    return {
                        ...state,
                        addressId: action.payload.addressId,
                        currentStep: 'building_types',
                    };
                case 'getEmployeeCount':
                    return {
                        ...state,
                        currentStep: 'employee_count',
                    };
                case 'setEmployeeCount':
                    return {
                        ...state,
                        employeeCount: action.payload,
                        currentStep: 'places',
                    };
                case 'setPlacesPercentages':
                    return {
                        ...state,
                        placesPercentages: action.payload.placesPercentages,
                        workingFromHomePercent: action.payload.workingFromHomePercent,
                        shouldSubmit: true,
                    };
            }
        },
        initialState,
    );
}
type EditProfileCompany = Pick<Company, 'id' | 'addresses'>;
type EditWorkplaceEntriesAddress = Pick<CompanyAddress, 'address' | 'id' | 'postcode'>;
type EditWorkplaceEntries = Pick<PeopleWorkplaceEntry, 'percent'> & {
    address: EditWorkplaceEntriesAddress;
};
type EditProfilePeople = Pick<
    ProfilePeople,
    'number_of_employees' | 'percent_working_from_home'
> & {workplace_entries: EditWorkplaceEntries[]};

type ProfilePeopleContextValue = {
    dispatch: (action: EditProfilePeopleAction) => void;
    currentStep: EditProfilePeopleWizardStep;
    reportingPeriod: CompanyReportingPeriod;
    company: EditProfileCompany;
    answers: EditProfilePeople;
    submitting?: boolean;
    error?: ApolloError;
    addressId?: string;
};

export const EditProfilePeopleContext = React.createContext<ProfilePeopleContextValue>({
    dispatch: () => {
        // placeholder
    },
    reportingPeriod: reportingPeriodPlaceholder,
    company: {
        addresses: [],
        id: '-1',
    },
    answers: {
        number_of_employees: 0,
        workplace_entries: [],
        percent_working_from_home: 0,
    },
    currentStep: 'review_addresses',
});

type ProfilePeopleContextProviderProps = {
    children: React.ReactNode;
    company: CompanyAddressesFragment;
    answers: EditProfilePeople;
};
export function EditProfilePeopleContextProvider(props: ProfilePeopleContextProviderProps) {
    const reportingPeriod = React.useContext(ReportingPeriodContext);
    const navigate = useNavigate();
    const [state, dispatch] = useEditPeopleReducer();
    const [submit, {loading, error, finished}] = useModifySection(ModifyProfilePeopleDocument, [
        'GetProfilePeopleEntry',
    ]);
    const reportingPeriodId = reportingPeriod.id;
    const company = useFragment(CompanyAddressesFragmentDocument, props.company);

    React.useEffect(() => {
        const {shouldSubmit, employeeCount, workingFromHomePercent} = state;

        if (shouldSubmit && employeeCount != null && workingFromHomePercent != null) {
            submit({
                variables: {
                    params: {
                        numberOfEmployees: employeeCount,
                        percentWorkingFromHome: workingFromHomePercent,
                        percentWorkingFromPlaces: Object.entries(state.placesPercentages).map(
                            ([id, percent]) => ({
                                id,
                                percent,
                            }),
                        ),
                        companyReportingPeriodId: reportingPeriodId,
                    },
                },
            });
        }
    }, [submit, reportingPeriodId, state]);

    React.useEffect(() => {
        if (finished) {
            navigate('../summary');
        }
    }, [finished]);

    return (
        <EditProfilePeopleContext.Provider
            value={{
                dispatch,
                currentStep: state.currentStep,
                reportingPeriod,
                company,
                answers: props.answers,
                addressId: state.addressId,
                submitting: loading,
                error,
            }}
        >
            {props.children}
        </EditProfilePeopleContext.Provider>
    );
}
