import React from 'react';

import {ProfilePlacesAddAddress} from '../../places/add-address';
import {ProfilePlacesBuildingTypes} from '../../places/building-types';
import {ProfilePlacesReview} from '../../places/review';
import {ProfilePeopleEmployees} from '../employees';
import {ProfilePeoplePlaces} from '../places';
import {EditProfilePeopleContext} from './context';

export function EditProfilePeopleWizard() {
    const {currentStep, reportingPeriod, company, dispatch, addressId, answers, error, submitting} =
        React.useContext(EditProfilePeopleContext);

    return (
        <div>
            {currentStep === 'review_addresses' && (
                <ProfilePlacesReview
                    addresses={company.addresses}
                    onAddAnother={() => dispatch({type: 'addAddress'})}
                    onEditAddress={(addressId) =>
                        dispatch({type: 'verifyBuildingType', payload: {addressId}})
                    }
                    onNext={() => dispatch({type: 'getEmployeeCount'})}
                />
            )}
            {currentStep === 'add_address' && (
                <ProfilePlacesAddAddress onNext={() => dispatch({type: 'start'})} />
            )}
            {currentStep === 'building_types' && (
                <ProfilePlacesBuildingTypes
                    addresses={company.addresses}
                    selectedAddressId={addressId}
                    onNext={() => dispatch({type: 'start'})}
                />
            )}
            {currentStep === 'employee_count' && (
                <ProfilePeopleEmployees
                    error={error}
                    submitting={submitting}
                    reportingPeriod={reportingPeriod}
                    employees={answers.number_of_employees?.toString()}
                    onNext={(values) =>
                        dispatch({
                            type: 'setEmployeeCount',
                            payload: parseFloat(values.employees),
                        })
                    }
                />
            )}
            {currentStep === 'places' && (
                <ProfilePeoplePlaces
                    editMode
                    error={error}
                    submitting={submitting}
                    reportingPeriod={reportingPeriod}
                    percent_working_from_home={answers.percent_working_from_home}
                    addresses={company.addresses.map((a) => {
                        const entry = answers.workplace_entries.find((w) => w.address.id === a.id);
                        return {
                            id: a.id,
                            address: a.address,
                            value: entry && entry.percent ? entry.percent : 0,
                        };
                    })}
                    onNext={(placesPercentages, workingFromHomePercent) =>
                        dispatch({
                            type: 'setPlacesPercentages',
                            payload: {
                                placesPercentages,
                                workingFromHomePercent,
                            },
                        })
                    }
                />
            )}
        </div>
    );
}
