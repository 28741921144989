import React from 'react';
import {useQuery} from '@apollo/client';

import {useFragment} from '../../../../graphql/generated';
import {ReportingPeriodContext} from '../../reporting-period';
import {GetProfileGHGDocument} from '../graphql';
import {EditProfileGHGContextProvider} from './context';
import {ProfileGHGWizard} from './wizard';
import {Loading} from '../../../loading';
import {ErrorCard} from '../../../error-card';
import {ProfileGHGFragmentDocument} from '../fragments';

export function OperationsEditGHGSection() {
    const reportingPeriod = React.useContext(ReportingPeriodContext);
    const {
        loading,
        error,
        data: res,
    } = useQuery(GetProfileGHGDocument, {
        variables: {companyReportingPeriodId: reportingPeriod.id},
    });

    const data = useFragment(ProfileGHGFragmentDocument, res?.getProfileGHG);

    return (
        <div>
            {loading && <Loading />}
            {error && <ErrorCard />}
            {data && (
                <EditProfileGHGContextProvider answers={data}>
                    <ProfileGHGWizard />
                </EditProfileGHGContextProvider>
            )}
        </div>
    );
}
