import React from 'react';
import {ApolloError} from '@apollo/client';

import {
    DetailText,
    HStack,
    Slider,
    VStack,
    Button,
    FormControl,
    FormErrorMessage,
    Panel,
} from '@sphericsio/design-system';

import {TabPageHeader} from '../../page-header';
import {ErrorCard} from '../../error-card';
import {ReportingPeriodDates} from '../reporting-period-dates';
import {CompanyAddress, CompanyReportingPeriod} from '../../../graphql/generated/graphql';

type PercentSlider = {id: string; label: string; value: number};
function reducer(state: PercentSlider[], action: {id: string; newValue: number}) {
    const sliderToChangeIndex = state.findIndex((slider) => slider.id === action.id);
    if (sliderToChangeIndex === -1) {
        return state;
    }

    const sliderToChange = state[sliderToChangeIndex];
    const percentChange = action.newValue - sliderToChange.value;
    const percentRemaining = state.reduce((val, slider) => val - slider.value, 100) - percentChange;
    const newState = [...state];

    if (state.length === 2) {
        newState[sliderToChangeIndex] = {...sliderToChange, value: action.newValue};
        const secondSliderIndex = sliderToChangeIndex === 0 ? 1 : 0;
        const secondSlider = newState[secondSliderIndex];
        newState[secondSliderIndex] = {...secondSlider, value: 100 - action.newValue};
    } else {
        let newValue = action.newValue;
        if (percentRemaining <= 0) {
            newValue += percentRemaining;
        }

        newState[sliderToChangeIndex] = {
            ...sliderToChange,
            value: newValue,
        };
    }
    return newState;
}

type MultipleSliderPercentControlProps = {
    values: PercentSlider[];
    onChange: (values: PercentSlider[]) => void;
};
function MultipleSliderPercentControl({values, onChange}: MultipleSliderPercentControlProps) {
    return (
        <VStack>
            {values.map((slider) => (
                <Panel key={slider.id}>
                    <HStack>
                        <div className="w-2/5">
                            <DetailText>{slider.label}</DetailText>
                        </div>
                        <div className="w-3/5">
                            <Slider
                                value={slider.value}
                                onChange={(value) =>
                                    onChange(reducer(values, {id: slider.id, newValue: value}))
                                }
                                renderValue={(value) => <DetailText>{value}%</DetailText>}
                            />
                        </div>
                    </HStack>
                </Panel>
            ))}
        </VStack>
    );
}

function isFormDataValid(data: {value: number}[]) {
    return data.reduce((amount, slider) => amount + slider.value, 0) === 100;
}

const WORK_FROM_HOME_SLIDER_DEFAULT = {id: 'wfh', label: 'Working from home', value: 0};

type Address = Pick<CompanyAddress, 'id' | 'address'> & {value?: number};
type ProfilePeoplePlacesProps = {
    onNext: (placesPercentages: Record<string, number>, workingFromHomePercent: number) => void;
    error?: ApolloError;
    submitting?: boolean;
    reportingPeriod: CompanyReportingPeriod;
    addresses: Address[];
    percent_working_from_home?: number | null;
    editMode?: boolean;
};

function prepareSliders(addresses: Address[], percent_working_from_home?: number | null) {
    return addresses
        .map((address) => ({id: address.id, label: address.address, value: address.value || 0}))
        .concat({...WORK_FROM_HOME_SLIDER_DEFAULT, value: percent_working_from_home || 0});
}

export function ProfilePeoplePlaces({
    onNext,
    error,
    submitting,
    reportingPeriod,
    addresses,
    percent_working_from_home,
    editMode = false,
}: ProfilePeoplePlacesProps) {
    const subtitle = <ReportingPeriodDates reportingPeriod={reportingPeriod} />;

    const allAddresses = React.useMemo(() => {
        return prepareSliders(addresses, percent_working_from_home);
    }, [addresses, percent_working_from_home]);
    const [sliders, setSliders] = React.useState(allAddresses);
    const [attemptedSubmit, setAttemptedSubmit] = React.useState(false);
    const isValid = React.useMemo(() => {
        return !attemptedSubmit || isFormDataValid(sliders);
    }, [sliders, attemptedSubmit]);

    const percentRemaining = React.useMemo(() => {
        return sliders.reduce((val, slider) => val - slider.value, 100);
    }, [sliders]);

    return (
        <VStack testId="employeeLocationForm">
            <TabPageHeader
                title="Please estimate where your employees work from"
                subtitle={subtitle}
            />
            <FormControl isInvalid={!isValid}>
                <MultipleSliderPercentControl values={sliders} onChange={setSliders} />
                <FormErrorMessage>Please make sure the values all total 100%</FormErrorMessage>
            </FormControl>

            {error && <ErrorCard />}
            <HStack>
                <Button
                    isLoading={submitting}
                    isDisabled={!isValid || percentRemaining > 0}
                    onPress={() => {
                        setAttemptedSubmit(true);
                        if (!isValid) {
                            return;
                        }
                        if (!editMode && sliders === allAddresses) {
                            // we're in an initial state
                            return;
                        }
                        const wfh = sliders.find(
                            (slider) => slider.id === WORK_FROM_HOME_SLIDER_DEFAULT.id,
                        );
                        const wfhPercent = wfh?.value ?? 0;
                        const addressPercents = sliders.reduce(
                            (memo, slider) => {
                                if (slider.id === WORK_FROM_HOME_SLIDER_DEFAULT.id) {
                                    return memo;
                                }

                                return {
                                    ...memo,
                                    [slider.id]: slider.value,
                                };
                            },
                            {} as Record<string, number>,
                        );

                        onNext(addressPercents, wfhPercent);
                    }}
                >
                    Save and continue
                </Button>
                <DetailText>{percentRemaining}% left to allocate</DetailText>
            </HStack>
        </VStack>
    );
}
