import React from 'react';

import {Alert} from '@sphericsio/design-system';

import {useUser} from '../user-context';

type InitialSyncBlockerProps = {
    children: React.ReactNode;
};
export function InitialSyncBlocker({children}: InitialSyncBlockerProps) {
    const user = useUser();
    return (
        <>
            {user.financialProvider?.sync_status === 'InitialSyncInProgress' && (
                <Alert
                    type="info"
                    title="We're currently processing your financial data."
                    subtitle="Please check back in a
                few minutes."
                />
            )}
            {user.financialProvider?.sync_status !== 'InitialSyncInProgress' && children}
        </>
    );
}
