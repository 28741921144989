import React, {createContext, useContext} from 'react';

// testable alternative to directly interacting with window.location
export function useExternalRedirect() {
    const redirector = useContext(ExternalRedirectorContext);
    return (href: string, replace = false) => redirector(href, replace);
}

type ExternalRedirector = (href: string, replace: boolean) => void;

const windowLocationRedirector: ExternalRedirector = (href, replace) => {
    if (replace) {
        window.location.replace(href);
    } else {
        window.location.href = href;
    }
};

// test utility to provide an in-memory redirector to users of the useExternalRedirect hook and a way to check where the
// redirect was to
export function createMemoryRedirectorContext(): {
    MemoryRedirectorProvider: React.FunctionComponent<{children: React.ReactNode}>;
    getRedirect(): string;
    wasRedirected(): boolean;
} {
    const redirects: string[] = [];
    const memoryRedirector: ExternalRedirector = (href) => {
        redirects.push(href);
    };
    function MemoryRedirectorProvider({children}: {children?: React.ReactNode}) {
        return (
            <ExternalRedirectorContext.Provider value={memoryRedirector}>
                {children}
            </ExternalRedirectorContext.Provider>
        );
    }
    function getRedirect() {
        if (redirects.length === 0) {
            throw new Error('no redirects');
        }
        if (redirects.length > 1) {
            throw new Error(`multiple redirects: ${redirects.join(', ')}`);
        }
        return redirects[0];
    }
    function wasRedirected() {
        return redirects.length > 0;
    }
    return {MemoryRedirectorProvider, getRedirect, wasRedirected};
}
export const ExternalRedirectorContext =
    createContext<ExternalRedirector>(windowLocationRedirector);
