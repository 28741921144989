import React from 'react';

import {ProfileVehiclesContextProvider} from './context';
import {ProfileVehiclesWizard} from './wizard';

export function ProfileVehicles() {
    return (
        <ProfileVehiclesContextProvider>
            <ProfileVehiclesWizard />
        </ProfileVehiclesContextProvider>
    );
}
