import React from 'react';
import {useMutation} from '@apollo/client';

import {
    bgColorClassnames,
    CircleTick,
    textColorClassnames,
    DetailText,
    DropdownList,
    LoadingSpinner,
    VStack,
} from '@sphericsio/design-system';

import {graphql} from '../../../graphql/generated';
import {ErrorCard} from '../../error-card';
import {GetCompanyDefaultsDocument} from '../graphql';
import {UploadContext} from '../context';

const SetCompanyDefaultCurrencyDocument = graphql(/* GraphQL */ `
    mutation setCompanyDefaultCurrency($currencyCode: String!) {
        setCompanyDefaultCurrency(currencyCode: $currencyCode) {
            code
        }
    }
`);

const CURRENCY_CODES = [
    {
        code: 'GBP',
        currency: 'Pound Sterling',
    },
    {
        code: 'EUR',
        currency: 'Euros',
    },
    {
        code: 'USD',
        currency: 'United States dollar',
    },
];

type DefaultCurrencyStatus = 'pending' | 'saving' | 'saved' | 'failed';
type DefaultCurrencyProps = {
    companyCurrencyCode?: string;
};
export function DefaultCurrency({companyCurrencyCode}: DefaultCurrencyProps) {
    const {exportSourceConfig} = React.useContext(UploadContext);

    const [save, {error, data}] = useMutation(SetCompanyDefaultCurrencyDocument, {
        refetchQueries: [{query: GetCompanyDefaultsDocument}],
    });

    const [status, setStatus] = React.useState<DefaultCurrencyStatus>();

    // FIXME this is a bit of a hack
    // we found that we only support GBP currency so we need to disable the selection for now for sage50
    // but will need it down the line once fix is in place
    React.useEffect(() => {
        if (exportSourceConfig?.dataExportSource === 'sage50_upload' && !companyCurrencyCode) {
            selectCurrencyCode('GBP');
        }
    }, [exportSourceConfig?.dataExportSource]);

    const selectCurrencyCode = (code: string) => {
        setStatus('saving');
        save({variables: {currencyCode: code}});
    };

    React.useEffect(() => {
        if (companyCurrencyCode) {
            setStatus('saved');
        } else {
            setStatus('pending');
        }
    }, [companyCurrencyCode]);

    React.useEffect(() => {
        if (data?.setCompanyDefaultCurrency) {
            setStatus('saved');
        }
    }, [data]);

    React.useEffect(() => {
        if (error) {
            setStatus('failed');
        }
    }, [error]);

    if (exportSourceConfig?.dataExportSource === 'sage50_upload') {
        return <></>;
    } else {
        return (
            <VStack>
                <DetailText bold>What is your primary accounting currency?</DetailText>

                {status == 'pending' && (
                    <DropdownList
                        items={CURRENCY_CODES}
                        renderItem={({code, currency}) => (
                            <DetailText inherit>{`${code}: ${currency}`}</DetailText>
                        )}
                        onClickItem={({code}) => selectCurrencyCode(code)}
                    />
                )}
                {status == 'saving' && <LoadingSpinner />}
                {status == 'saved' && companyCurrencyCode && (
                    <div
                        className={`flex flex-row items-center justify-between ${bgColorClassnames(
                            'white',
                        )} rounded-xl py-2.5 px-5 border-2 w-60`}
                    >
                        <DetailText>{companyCurrencyCode}</DetailText>
                        <div className={`w-6 h-6 ${textColorClassnames('success')}`}>
                            <CircleTick />
                        </div>
                    </div>
                )}
                {status == 'failed' && (
                    <ErrorCard message="An error occured trying to save the currency. Please contact support for assistance." />
                )}
            </VStack>
        );
    }
}
