import React from 'react';

import {useSaveFinancialAccountCategorisation} from '../../../hooks/use-save-financial-account-categorisation';
import {UserCategorisationHierarchyItem} from '../hierarchy';
import {SaveStatus} from '../categorisation';

type SaveCategorisationProps = {
    id: string;
    selected: UserCategorisationHierarchyItem | null;
    status: SaveStatus;
    onStatusChange: (status: SaveStatus) => void;
    children?: React.ReactNode;
};
export function SaveFinancialAccountCategorisation({
    id,
    selected,
    status,
    onStatusChange,
    children,
}: SaveCategorisationProps) {
    const [save, {loading, error, finished}] = useSaveFinancialAccountCategorisation();

    React.useEffect(() => {
        if (selected && status === 'pending') {
            save({
                variables: {
                    params: [
                        {
                            id,
                            taxonomyKey: selected?.taxonomyKey,
                            taxonomyTermKey: selected?.taxonomyTermKey,
                        },
                    ],
                },
            });
            onStatusChange('saving');
        }
    }, [selected]);

    React.useEffect(() => {
        if (finished && !error) {
            onStatusChange('success');
        }
        if (loading && status !== 'saving') {
            onStatusChange('saving');
        }
        if (error || (finished && error)) {
            onStatusChange('error');
        }
    }, [loading, error, finished]);

    return <div className="relative">{children}</div>;
}
