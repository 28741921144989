import React from 'react';
import {ApolloError, useQuery} from '@apollo/client';

import {graphql} from '../../graphql/generated';
import {
    DataExportSourceConfig,
    FinancialProviderSync,
    UploadRequestType,
} from '../../graphql/generated/graphql';
import {DataExportSourcesConfigDocument} from '../../components/file-upload-wizard/graphql';

export const SettingsDocument = graphql(/* GraphQL */ `
    query Settings {
        financialProviderSyncStatus {
            id
            sync_type
            sync_method
            sync_status
            created_at
        }
    }
`);

type ConnectionContextValue = {
    syncs: FinancialProviderSync[];
    setCurrentSync: (sync: FinancialProviderSync) => void;
    isCurrentSync: (id: string) => boolean;
    currentSync: FinancialProviderSync | null;
    isCurrentSyncActive: boolean;
    config?: DataExportSourceConfig;
    currentReportType?: string;
    setCurrentReportType: (v: UploadRequestType) => void;
    loading: boolean;
    error?: ApolloError;
};
export const ConnectionContext = React.createContext<ConnectionContextValue>({
    syncs: [],
    setCurrentSync: () => {
        //
    },
    isCurrentSync: () => false,
    currentSync: null,
    isCurrentSyncActive: false,
    setCurrentReportType: () => {
        //
    },
    loading: true,
});

type ConnectionContextProviderProps = {
    children: React.ReactNode;
};
export function ConnectionContextProvider({children}: ConnectionContextProviderProps) {
    const {loading, error, data} = useQuery(SettingsDocument);
    const {data: exportConfig} = useQuery(DataExportSourcesConfigDocument);

    const [currentSync, setCurrentSync] = React.useState<FinancialProviderSync | null>(null);
    const [currentReportType, setCurrentReportType] = React.useState<UploadRequestType>();
    const [config, setConfig] = React.useState<DataExportSourceConfig | undefined>();

    const isCurrentSyncActive = React.useMemo(() => {
        if (data?.financialProviderSyncStatus && currentSync) {
            return data?.financialProviderSyncStatus[0].id === currentSync.id;
        } else {
            return false;
        }
    }, [currentSync, data?.financialProviderSyncStatus]);

    React.useEffect(() => {
        if (!currentSync && data?.financialProviderSyncStatus) {
            setCurrentSync(data.financialProviderSyncStatus[0]);
        }
    }, [currentSync, data?.financialProviderSyncStatus]);

    React.useEffect(() => {
        if (currentSync && exportConfig?.dataExportSourcesConfig) {
            const config = exportConfig?.dataExportSourcesConfig.find(
                (exportConfig) => exportConfig.dataExportSource === currentSync.sync_type,
            );
            setConfig(config);
            if (config) {
                setCurrentReportType(config.supports[0].requestType);
            }
        }
    }, [currentSync, exportConfig?.dataExportSourcesConfig]);

    const isCurrentSync = (id: string) => !!currentSync && id === currentSync.id;

    return (
        <ConnectionContext.Provider
            value={{
                syncs: data?.financialProviderSyncStatus ?? [],
                setCurrentSync,
                isCurrentSync,
                currentSync,
                isCurrentSyncActive,
                config,
                currentReportType,
                setCurrentReportType,
                loading,
                error,
            }}
        >
            {children}
        </ConnectionContext.Provider>
    );
}
