import {graphql} from '../../../graphql/generated';

export const GetProfileEnergyDocument = graphql(/* GraphQL */ `
    query GetProfileEnergy($companyReportingPeriodId: ID!) {
        getProfileEnergy(companyReportingPeriodId: $companyReportingPeriodId) {
            ...ProfileEnergyEntry
        }
    }
`);

export const GetCompanyBuildingsDocument = graphql(/* GraphQL */ `
    query GetCompanyBuildings {
        currentCompany {
            id
            addresses {
                id
                address
                postcode
                building_type {
                    id
                    label
                }
            }
        }
    }
`);

export const GetEnergyEmissionsForReportingPeriodDocument = graphql(/* GraphQL */ `
    query GetEnergyEmissionsForReportingPeriod($id: ID!) {
        getCompanyReportingPeriodById(id: $id) {
            energy {
                ...BenchmarkingProfileEntry
            }
            totalEmissionsForPeriod
        }
    }
`);

export const GetSuggestedEnergySpendsDocument = graphql(/* GraphQL */ `
    query GetSuggestedEnergySpends($from: Date!, $to: Date!) {
        getSuggestedEnergyTransactions(from: $from, to: $to) {
            id
            merchant_name
            transactions {
                id
                description
                amount
                transaction_date
            }
        }
    }
`);

export const ModifyProfileEnergyDocument = graphql(/* GraphQL */ `
    mutation ModifyProfileEnergy($params: ProfileEnergyInput!) {
        modifyProfileEnergy(params: $params) {
            id
        }
    }
`);
