import React from 'react';
import {ApolloError} from '@apollo/client';
import * as Yup from 'yup';

import {DetailText, Paragraph, VStack} from '@sphericsio/design-system';
import {PasswordInput, Button, Form} from '@sphericsio/forms';

import {ErrorCard} from './error-card';
import {MarkdownContent} from './markdown-content';

const ERROR_CODES: Record<string, string> = {
    UNKNOWN_VERIFICATION: 'Sorry, your reset password link was invalid.',
    INVALID_TOKEN: 'Sorry, your reset password link was invalid.',
    ALREADY_VERIFIED: 'Sorry, your reset password link was invalid.',
    VERIFICATION_EXPIRED: 'Sorry, your reset password link was invalid.',
};
function errorMessage(error: ApolloError) {
    const {
        graphQLErrors: [gqlError],
        networkError,
    } = error;
    if (
        gqlError &&
        gqlError.extensions?.code === 'BAD_USER_INPUT' &&
        gqlError.message === 'INSUFFICIENT_PASSWORD_COMPLEXITY'
    ) {
        return (
            <>
                <DetailText>Your password is invalid, it must meet the following rules:</DetailText>
                <Paragraph>
                    <MarkdownContent>{gqlError.extensions?.message as string}</MarkdownContent>
                </Paragraph>
            </>
        );
    } else if (gqlError && gqlError.extensions?.code === 'FORBIDDEN') {
        return ERROR_CODES[gqlError.message];
    } else if (networkError) {
        return 'Our service is currently having problems. Please try again later.';
    }
}

const INITIAL_FORM_VALUES = {
    password: '',
};

const formSchema = Yup.object().shape({
    password: Yup.string().required('Password is missing'),
});

type ResetPasswordFormProps = {
    submitting: boolean;
    error?: ApolloError;
    onSubmit: (password: string) => void;
};
export function ResetPasswordForm({submitting, error, onSubmit}: ResetPasswordFormProps) {
    const buttonLabel = submitting ? 'Please wait...' : 'Reset Password';
    return (
        <Form
            initialValues={INITIAL_FORM_VALUES}
            onSubmit={(values) => {
                onSubmit(values.password);
            }}
            validationSchema={formSchema}
        >
            <PasswordInput name="password" placeholder="New password" />
            <VStack>
                {error && <ErrorCard message={errorMessage(error)} />}
                <Button isLoading={submitting} type="submit">
                    {buttonLabel}
                </Button>
            </VStack>
        </Form>
    );
}
