import React from 'react';

import {VStack} from '@sphericsio/design-system';

import {HSplitLayout} from '../../layout/h-split';
import {PosterLayout} from '../../onboarding/poster-layout';
import {PosterForgotTemplate} from '../../onboarding/poster-forgot-template';
import {PageHeader} from '../../page-header';
import {UploadContext} from '../context';
import {DefaultCurrency} from './default-currency';
import {FinancialYearEnd} from './financial-year-end';
import {NavigationButtons} from '../../navigation-buttons';

export function CompanyDefaults() {
    const {companyDefaults, dispatch, previousSpendData} = React.useContext(UploadContext);

    return (
        <HSplitLayout cta="logout" wide>
            <VStack large full>
                <PageHeader title="Please tell us about your company financials" />
                <DefaultCurrency
                    companyCurrencyCode={companyDefaults?.getCompanyDefaultCurrencyCode?.code}
                />
                <FinancialYearEnd
                    financialYearEnd={companyDefaults?.getCompanyFinancialYearEnd}
                    canEdit={previousSpendData.length === 0}
                />
                <div className="flex w-full flex-row justify-end pt-5">
                    <NavigationButtons
                        backColour="major-action"
                        backOnClick={() => dispatch({type: 'begin'})}
                        nextOnClick={() => dispatch({type: 'companyDetailsSet'})}
                        nextToDisabled={
                            !companyDefaults?.getCompanyDefaultCurrencyCode?.code ||
                            !companyDefaults?.getCompanyFinancialYearEnd
                        }
                    />
                </div>
            </VStack>
            <PosterLayout bgImage={{type: 'onboarding'}}>
                <PosterForgotTemplate />
            </PosterLayout>
        </HSplitLayout>
    );
}
