import React from 'react';

import {
    DetailText,
    Heading2,
    LoadingSpinner,
    Paragraph,
    textColorClassnames,
} from '@sphericsio/design-system';

import {ErrorCard} from '../error-card';
import {ExcludeTransactionContext} from '../financial-transactions/exclude-transaction-context';
import {NavigationButtons} from '../navigation-buttons';
import {useGeneratePlaceholder} from '../../hooks/exclusion';
import {ExclusionContentTypeEnum} from '../../graphql/generated/graphql';

type IncludeModalProps = {
    onCancel: () => void;
    id: string;
    type: ExclusionContentTypeEnum;
    children: React.ReactElement;
};
export function IncludeModal({onCancel, id, type, children}: IncludeModalProps) {
    const {submitInclude, loading, error, submitting} = React.useContext(ExcludeTransactionContext);
    const {placeholder} = useGeneratePlaceholder(type);

    const include = () => {
        submitInclude(id, type);
    };

    if (error) {
        return <ErrorCard />;
    }

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <DetailText>
            <div className="flex flex-col items-start space-y-5">
                <Heading2>You are re-including the following {placeholder}</Heading2>
                <Paragraph>
                    <span className={textColorClassnames('secondary')}>
                        This will now contribute towards your emission calculations.
                    </span>
                </Paragraph>
                {children}
                <DetailText bold>
                    Please confirm you would like to include this {placeholder}?
                </DetailText>
                <NavigationButtons
                    backLabel="Cancel"
                    backOnClick={() => onCancel()}
                    nextLabel="Confirm"
                    nextOnClick={() => include()}
                    loading={submitting}
                />
            </div>
        </DetailText>
    );
}
