import React from 'react';

import {Panel, Heading2, DetailText, HStack} from '@sphericsio/design-system';

import {ButtonLink} from '../link';

export function CompleteBaselinePanel() {
    return (
        <Panel>
            <HStack>
                <Heading2>Complete your baseline year</Heading2>
                <div className="flex-1">
                    <DetailText inline={false}>
                        You must complete all sections of your baseline year before you can set a
                        target
                    </DetailText>
                </div>
                <div>
                    <ButtonLink to="/baseline">Go to baseline</ButtonLink>
                </div>
            </HStack>
        </Panel>
    );
}
