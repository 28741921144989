import React from 'react';
import {useQuery} from '@apollo/client';

import {ExclusionContentTypeEnum} from '../graphql/generated/graphql';
import {GetCompanyExclusionReasonsDocument} from './graphql';

export function useExcludeReasons() {
    const {
        loading: fetching,
        error: fetchError,
        data: exclusions,
    } = useQuery(GetCompanyExclusionReasonsDocument);

    const items = React.useMemo(() => {
        if (exclusions?.getCompanyExclusionReasons) {
            return exclusions.getCompanyExclusionReasons
                .map(({name, key}) => ({
                    id: key,
                    label: name,
                }))
                .concat([{id: 'other', label: 'Other'}]);
        }
    }, [exclusions]);

    return {items, fetching, fetchError};
}

export function useGeneratePlaceholder(type: ExclusionContentTypeEnum) {
    const placeholder = React.useMemo(() => {
        switch (type) {
            case 'financial_account':
                return 'account';
            case 'financial_transaction':
                return 'transaction';
            default:
                return 'item';
        }
    }, [type]);
    return {placeholder};
}
