import React from 'react';

import {UploadRequestType, UploadValidationErrors} from '../../graphql/generated/graphql';
import {DataExportSource, UploadContextProvider} from './context';
import {UploadWizard} from './wizard';

export type UploadFlow = 'onboarding' | 'upload';

export function FileUploadWizard({
    flow = 'onboarding',
    requestType = 'financial_transactions',
    uploadErrors = [],
    provider,
    onClose,
}: {
    flow?: UploadFlow;
    requestType?: UploadRequestType;
    provider: DataExportSource;
    uploadErrors?: UploadValidationErrors[];
    onClose?: () => void;
}) {
    return (
        <UploadContextProvider
            provider={provider}
            flow={flow}
            requestType={requestType}
            onClose={onClose}
            uploadErrors={uploadErrors}
        >
            <UploadWizard />
        </UploadContextProvider>
    );
}
