import React from 'react';

import {Alert, Button, DetailText, Heading2, VStack} from '@sphericsio/design-system';

import {HSplitLayout} from '../../layout/h-split';
import {PosterLayout} from '../../onboarding/poster-layout';
import {PageHeader} from '../../page-header';
import {PosterJoinTheMovement} from '../../onboarding/poster-join-the-movement';
import {UploadContext} from '../context';
import automation from '../../../images/automation.svg';

export const CheckBackLater: React.FC = () => {
    const title = 'Your data is still uploading';
    const {flow, onClose} = React.useContext(UploadContext);
    const handleRefresh = () => window.location.reload();
    if (flow === 'upload') {
        return (
            <VStack>
                <Heading2>Upload spend data</Heading2>
                <div className="w-120">
                    <Alert
                        type="info"
                        title={title}
                        subtitle="Your file contains large amounts of data and therefore is taking longer to upload and validate. You can close this window and we will continue to upload your file in the background."
                    />
                </div>
                <div className="self-end">
                    {onClose && <Button onPress={() => onClose()}>Close</Button>}
                </div>
            </VStack>
        );
    } else {
        return (
            <HSplitLayout cta="logout" wide>
                <VStack large full>
                    <div>
                        <img src={automation} />
                    </div>
                    <PageHeader title={title} />
                    <div className="flex flex-col space-y-7">
                        <DetailText>
                            Your file contains large amounts of data therefore is taking longer to
                            upload and validate. We will continue to upload your file in the
                            background, please check back later.
                        </DetailText>
                        <DetailText>
                            If you wish to wait here, refresh the page to check if your upload is
                            complete.
                        </DetailText>
                        <Button onPress={handleRefresh}>Refresh</Button>
                    </div>
                </VStack>
                <PosterLayout>
                    <PosterJoinTheMovement />
                </PosterLayout>
            </HSplitLayout>
        );
    }
};
