import React from 'react';

import {HStack} from '@sphericsio/design-system';

import {TabPill} from '../tab-pill';
import {PeriodSelect, PeriodSelectItem} from '../period-select';

const TAB_CONFIG = [
    {
        id: 'financial-accounts',
        label: 'Financial Accounts',
    },
    {
        id: 'transactions',
        label: 'Transactions',
    },
    {
        id: 'top',
        label: 'Suppliers',
    },
];

type SuppliersHeaderProps = {
    page: string;
    onSelect: (item: PeriodSelectItem) => void;
};
export function SuppliersHeader({page, onSelect}: SuppliersHeaderProps) {
    const selectedTab = TAB_CONFIG.find((tabItem) => tabItem.id === page);
    if (selectedTab == null) {
        return null;
    }

    return (
        <div className="mt-5 flex justify-between">
            <HStack>
                {TAB_CONFIG.map((tab) => (
                    <TabPill key={tab.id} id={tab.id} isSelected={selectedTab === tab}>
                        {tab.label}
                    </TabPill>
                ))}
            </HStack>
            {selectedTab.id === 'top' && (
                <PeriodSelect
                    period="quarter"
                    onSelect={onSelect}
                    label="supplier-emissions-period-select"
                />
            )}
        </div>
    );
}
