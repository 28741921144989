import {graphql} from '../../graphql/generated';

export const GetDataUploadsDocument = graphql(/* GraphQL */ `
    query GetDataUploads($status: String) {
        fileUploads(status: $status) {
            id
            created_at
            upload_request_type
            sync_type
            sync_status
            request {
                id
                file_extension
                filename
            }
            transaction_upload {
                period_start
                period_end
            }
            turnover_upload {
                coverage_from_date
                coverage_to_date
            }
        }
    }
`);

export const FetchLastBackgroundExecutionDocument = graphql(/* GraphQL */ `
    query FetchLastBackgroundExecution {
        fetchLastBackgroundExecution {
            status
            completed_at
            updated_at
            upload_errors {
                id
                message
                code
                header
                line
                sheetName
                sheetNo
                file_upload_request {
                    id
                    filename
                    file_extension
                    upload_request_type
                }
            }
        }
    }
`);
