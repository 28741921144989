import React from 'react';
import {capitalize} from 'lodash';
import {useQuery} from '@apollo/client';

import {HSplitLoading} from '../../components/layout/h-split';
import {FinancialProviderLoginUrlDocument} from '../../components/financial-provider-sync-button';
import {AccountingComponentProps} from './types';
import {PosterJoinTheMovement} from '../../components/onboarding/poster-join-the-movement';
import {useExternalRedirect} from '../../hooks/use-external-redirect';

export function AccountingSignup({provider}: AccountingComponentProps) {
    const externalRedirect = useExternalRedirect();
    const {error} = useQuery(FinancialProviderLoginUrlDocument, {
        variables: {forLogin: false, provider},
        onCompleted(data) {
            externalRedirect(data.financialProviderLoginUrl);
        },
    });

    return (
        <HSplitLoading
            error={error}
            callToAction="logout"
            text={`Please wait while we redirect you to ${capitalize(provider)}...`}
        >
            <PosterJoinTheMovement />
        </HSplitLoading>
    );
}
