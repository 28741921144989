import {graphql} from '../../graphql/generated';

export const GetCompanyFinancialAccountsDataDocument = graphql(/* GraphQL */ `
    query GetFinancialAccountsForCompany(
        $first: Int
        $after: String
        $last: Int
        $before: String
        $orderDirection: SortDirection!
        $orderBy: FinancialAccountsOrder!
        $filter: FinancialAccountsFilterEnum!
    ) {
        getFinancialAccountsForCompany(
            first: $first
            after: $after
            last: $last
            before: $before
            orderDirection: $orderDirection
            orderBy: $orderBy
            filter: $filter
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                node {
                    id
                    name
                    terms {
                        taxonomy_term_key
                        taxonomy_key
                    }
                    user_terms {
                        taxonomy_term_key
                        taxonomy_key
                    }
                }
            }
        }
    }
`);

export const ConfirmFinancialAccountCategorisationDocument = graphql(/* GraphQL */ `
    mutation confirmFinancialAccountCategorisation($params: [String!]!) {
        confirmFinancialAccountCategorisation(params: $params) {
            id
        }
    }
`);

export const FetchFinancialAccountCountsDocument = graphql(/* GraphQL */ `
    query getFinancialAccountFilterCounts {
        getFinancialAccountFilterCounts {
            filter
            count
        }
    }
`);
