import React from 'react';
import {subMonths, startOfToday} from 'date-fns';

import {DetailText, Tooltip, VStack} from '@sphericsio/design-system';

import {Layout} from '../components/layout';
import {SuppliersHeader} from '../components/suppliers/header';
import {HighestEmittingSuppliers} from '../components/suppliers/highest-emitting';
import {UnderConstruction} from '../components/under-construction';
import {Transactions} from '../components/suppliers/transactions';
import {PeriodSelectItem} from '../components/period-select';
import {HelpIcon} from '../components/icons';
import {FinancialAccountFiltersContextProvider} from '../components/financial-accounts/filters-context';
import {FinancialAccounts} from '../components/suppliers/financial-accounts';

type SuppliersProps = {
    page: 'top' | 'transactions' | 'financial-accounts' | 'identify';
};

const SupplierTooltip = () => {
    return (
        <Tooltip
            tooltipText="This is a list of all your expenditure data, showing all transactions and suppliers that you have spent money with. Sage Earth analyses the information attached to each expense to guess what you have purchased and apply a category.

        To make sure that our guesses are accurate, please regularly review the list below and check that you agree with the category we’ve applied. If you have connected your accounting platform to Sage Earth, then the list below will update in near real time."
        >
            <HelpIcon />
        </Tooltip>
    );
};

export function Suppliers({page}: SuppliersProps) {
    const [selected, setSelected] = React.useState<PeriodSelectItem>();

    const {from, to} = React.useMemo(() => {
        const today = startOfToday();

        if (!selected) {
            return {
                from: subMonths(today, 3),
                to: today,
            };
        }

        return {from: selected.start, to: selected.end};
    }, [selected]);

    return (
        <Layout title="Your Suppliers" tooltip={<SupplierTooltip />}>
            <VStack full>
                <SuppliersHeader page={page} onSelect={setSelected} />
                {page === 'transactions' && <Transactions />}
                {page === 'top' && <HighestEmittingSuppliers from={from} to={to} />}
                {page === 'financial-accounts' && (
                    <FinancialAccountFiltersContextProvider>
                        <FinancialAccounts />
                    </FinancialAccountFiltersContextProvider>
                )}
                {page === 'identify' && (
                    <UnderConstruction>
                        <div className="flex flex-col justify-center items-center space-y-5 text-center">
                            <DetailText>
                                We are currently working on a new version of the identify your
                                suppliers page. Please sit tight and we will let you know when it’s
                                ready for you to explore.
                            </DetailText>
                            <DetailText>Thank you for your patience! </DetailText>
                        </div>
                    </UnderConstruction>
                )}
            </VStack>
        </Layout>
    );
}
